
import Vue from "vue";

import { isElectron } from "@/common/environment";
import { isIE } from "@/common/lib";
import FixedHeader from "@/layouts/FixedHeader.vue";

import AxArtboardUploads from "@/components/AxArtboardUploads.vue";
import AxHeading from "@/components/AxHeading.vue";
import AxPushNotificationsAllWorkspaces from "@/components/AxPushNotifications/AxPushNotificationsAllWorkspaces.vue";
import AxPushNotificationsGlobal from "@/components/AxPushNotifications/AxPushNotificationsGlobal.vue";
import AxPushNotificationsMentioned from "@/components/AxPushNotifications/AxPushNotificationsMentioned.vue";
import AxWorkspacesMigration from "@/components/AxWorkspacesMigration.vue";

const AxInstallingSketchPluginNotification = () => import("@/components/AxInstallingSketchPluginNotification.vue");
const AxBadgeNotifications = () => import("@/components/AxBadgeNotifications.vue");
const AxOutdatedBrowserBanner = () => import("@/components/AxOutdatedBrowserBanner.vue");

export default Vue.extend({
  components: {
    FixedHeader,
    AxHeading,
    AxInstallingSketchPluginNotification,
    AxOutdatedBrowserBanner,
    AxArtboardUploads,
    AxBadgeNotifications,
    AxPushNotificationsGlobal,
    AxPushNotificationsMentioned,
    AxPushNotificationsAllWorkspaces,
    AxWorkspacesMigration,
  },

  data() {
    return {
      isElectron,
      isOutdatedBrowser: isIE,
    };
  },
});
