
import PerfectScrollbar from "perfect-scrollbar";
import Vue, { VNode } from "vue";

export default Vue.extend({
  props: {
    options: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    tag: {
      type: String,
      required: false,
      default: "div",
    },
  },

  data() {
    return {
      ps: undefined as PerfectScrollbar | undefined,
    };
  },

  mounted() {
    // delay initialization of perfect-scrollbar due to left-side scrollbars rendering bug
    // https://github.com/mdbootstrap/perfect-scrollbar/issues/792
    window.setTimeout(() => {
      if (!(this.ps && this.$isServer)) {
        const container = this.$refs.container as HTMLElement | undefined;
        if (container) {
          this.ps = new PerfectScrollbar(container, this.options);
        }
      }
    }, 0);
  },

  updated() {
    this.update();
  },

  beforeDestroy() {
    this.destroy();
  },

  methods: {
    update() {
      if (this.ps) {
        this.ps.update();
      }
    },
    destroy() {
      if (this.ps) {
        this.ps.destroy();
        this.ps = undefined;
      }
    },
  },

  render(h): VNode {
    return h(
      this.tag,
      {
        ref: "container",
        on: this.$listeners,
      },
      this.$slots.default
    );
  },
});
