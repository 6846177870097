export interface ArtboardUploadBatch {
  id: string;
  shortcut: string;
  projectName: string;
  created: Date;
  contents: Record<string, ArtboardUploadInfo>;
  dismissed: boolean;
  source: ArtboardUploadBatchSource;
}

export enum ArtboardUploadBatchSource {
  Web,
  Desktop,
}

export enum ArtboardUploadStatus {
  NotStarted,
  Exporting,
  InProgress,
  Success,
  Error,
  Canceled,
}

export const ArtboardUploadStatusCompleted = [
  ArtboardUploadStatus.Success,
  ArtboardUploadStatus.Error,
  ArtboardUploadStatus.Canceled,
];

export interface ArtboardUploadInfo {
  id: string;
  shortcut: string;
  name: string;
  status: ArtboardUploadStatus;
  progress: number;
  serverArtboardId: string;
  cancel?(): Promise<void>;
  errorMessage?: string;
}

export interface AxShareUploads {
  batches: Record<string, ArtboardUploadBatch>;
}

const state = (): AxShareUploads => ({
  batches: {},
});

export default state;
