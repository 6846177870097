import Vue from "vue";

import { MutationPayloadMap, MutationTypes } from "@/store/project/mutationTypes";
import { AxShareProject, EmptyIframeBridgeProp } from "@/store/project/state";
import { MutationTree } from "@/store/typed";

const mutations: MutationTree<AxShareProject, MutationPayloadMap> = {
  [MutationTypes.SetIframeBridge](state, { iframeBridgeTarget, iframe, target }) {
    let iframeBridgeProps = state.iframeBridgeProps[iframeBridgeTarget];

    if (!iframeBridgeProps) {
      iframeBridgeProps = EmptyIframeBridgeProp();
    }

    iframeBridgeProps.iframe = iframe;
    iframeBridgeProps.iframeTargetOrigin = target;
    iframeBridgeProps.iframeBridgeReady = false;
    iframeBridgeProps.iframeReady = false;

    Vue.set(state.iframeBridgeProps, iframeBridgeTarget, iframeBridgeProps);
  },

  [MutationTypes.SetIframeBridgeReady](state, { iframeBridgeTarget }) {
    const iframeBridgeProps = state.iframeBridgeProps[iframeBridgeTarget];

    if (iframeBridgeProps) {
      iframeBridgeProps.iframeBridgeReady = true;
    }
  },

  [MutationTypes.SetIframeReady](state, { iframeBridgeTarget }) {
    const iframeBridgeProps = state.iframeBridgeProps[iframeBridgeTarget];

    if (iframeBridgeProps) {
      iframeBridgeProps.iframeReady = true;
    }
  },
};

export default mutations;
