
import Vue, { VNode, VNodeData } from "vue";
import { PropValidator } from "vue/types/options";

type Sizes = "large" | "medium" | "small" | "tiny";
type DefaultSize = "large";
type NotDefaultSizes = Exclude<Sizes, DefaultSize>;
type SizeProps = {
  [K in NotDefaultSizes]: PropValidator<boolean>;
} &
{
  [K in DefaultSize]: PropValidator<boolean> & { default: true };
};

const OTHER_SIZES: NotDefaultSizes[] = ["medium", "small", "tiny"];
const SIZE_PROPS: SizeProps = {
  large: {
    type: Boolean,
    default: true,
  },
  medium: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
  tiny: {
    type: Boolean,
    default: false,
  },
};

export default Vue.extend({
  props: {
    tag: {
      type: String,
      default: "button",
    },

    circle: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: "button",
    },

    tabindex: {
      type: [String, Number],
      required: false,
      default: undefined,
    },

    color: {
      type: String,
      default: "default",
    },

    to: {
      type: [String, Object],
      default: undefined,
    },

    exactActiveClass: {
      type: String,
      default: "ax-button--primary router-link-exact-active",
    },

    activeClass: {
      type: String,
      default: "ax-button--primary router-link-active",
    },

    noActiveClass: {
      type: Boolean,
      default: false,
    },

    blurOnClick: {
      type: Boolean,
      default: false,
    },

    append: {
      type: Boolean,
      default: false,
    },

    replace: {
      type: Boolean,
      default: false,
    },

    exact: {
      type: Boolean,
      default: undefined,
    },

    href: {
      type: [String, Object],
      default: undefined,
    },

    target: {
      type: String,
      default: "",
    },

    block: {
      type: Boolean,
      default: false,
    },

    uppercase: {
      type: Boolean,
      default: false,
    },

    wrap: {
      type: Boolean,
      default: false,
    },

    rounded: {
      type: Boolean,
      default: false,
    },

    lifted: {
      type: Boolean,
      default: false,
    },

    highlighted: {
      type: Boolean,
      default: false,
    },

    icon: {
      type: Boolean,
      default: false,
    },

    ...SIZE_PROPS,
  },

  computed: {
    classes(): Record<string, boolean> {
      const otherSizeIsSet = OTHER_SIZES.some(size => this[size]);

      return {
        "ax-button": true,
        [`ax-button--${this.color}`]: true,
        "ax-button--link-highlighted": this.highlighted,
        "ax-button--circle": this.circle,
        "ax-button--block": this.block,
        "ax-button--large": this.large && !otherSizeIsSet,
        "ax-button--medium": this.medium,
        "ax-button--small": this.small,
        "ax-button--tiny": this.tiny,
        "ax-button--uppercase": this.uppercase,
        "ax-button--wrap": this.wrap,
        "ax-button--rounded": this.rounded,
        "ax-button--lifted": this.lifted,
        "ax-button--icon": this.icon,
        disabled: this.disabled,
      };
    },
  },

  methods: {
    click(e: MouseEvent) {
      if (this.disabled) return;
      this.$emit("click", e);
      if (this.blurOnClick) {
        this.blur();
      }
    },

    blur() {
      const button = this.getButton();
      if (button && typeof button.blur === "function") {
        button.blur();
      }
    },

    focus() {
      const button = this.getButton();
      if (button && typeof button.focus === "function") {
        button.focus();
      }
    },

    getButton() {
      const button = this.$refs.button as HTMLElement | Vue | undefined;
      if (button) {
        return button instanceof HTMLElement ? button : (button.$el as HTMLElement);
      }
    },
  },

  render(h): VNode {
    let exact = this.exact;
    let tag;
    const data: VNodeData = {
      attrs: {
        disabled: this.disabled || this.loading, // TODO: move "loading" to separate logic - disable and show loading indicator for example
      },

      ref: "button",
      class: this.classes,
      props: {},
      [this.to ? "nativeOn" : "on"]: {
        ...this.$listeners,
        click: this.click,
      },
    };

    if (typeof this.exact === "undefined") {
      exact = this.to === "/" || (this.to === Object(this.to) && this.to.path === "/");
    }

    if (this.to) {
      const activeClass = this.activeClass;
      const exactActiveClass = this.exactActiveClass || activeClass;

      tag = "router-link";
      Object.assign(data.props ?? {}, {
        to: this.to,
        exact,
        append: this.append,
        replace: this.replace,
        ...(this.noActiveClass === false
          ? {
            activeClass,
            exactActiveClass,
          }
          : undefined),
      });
    } else {
      tag = (this.href && "a") || this.tag || "a";

      if (tag === "a" && this.href) data.attrs!.href = this.href;
    }

    if (tag === "button") data.attrs!.type = this.type;
    if (this.target) data.attrs!.target = this.target;
    if (this.tabindex !== undefined) data.attrs!.tabindex = this.tabindex;

    const children = this.$scopedSlots.default && this.$scopedSlots.default({});

    return h(tag, data, children);
  },
});
