
import Vue from "vue";
import { Location } from "vue-router";
import { Store } from "vuex";

import {
  FilesystemNodeType,
  FilesystemNodeTypes,
  FilesystemNodeWithContents,
  FilesystemNodeWorkspace,
} from "@/common/fs";
import { ROUTES } from "@/router";
import { Navigate } from "@/store/fs/actionTypes";
import { AxShare } from "@/store/state";

import AxConnectionTrouble from "@/components/AxConnectionTrouble.vue";

export default Vue.extend({
  components: {
    AxConnectionTrouble,
  },

  methods: {
    async retryNavigation() {
      const { node }: FilesystemNodeWithContents = this.$store.getters.current;
      if (node.type === FilesystemNodeType.Workspace) {
        await this.navigateToWorkspace(node);
      } else {
        this.$store.dispatch(new Navigate(node));
        if (!this.$store.getters.navigatingError) {
          const retriedNode: FilesystemNodeTypes | undefined = this.$store.getters.getFsNode(node.id);
          if (retriedNode && retriedNode.type === FilesystemNodeType.Workspace) {
            await this.$store.dispatch(new Navigate(retriedNode));
          }
        }
      }
    },

    async navigateToWorkspace(node: FilesystemNodeWorkspace) {
      const store = this.$store as Store<AxShare>;
      const routeBeforeNavigate = this.$route;
      await store.dispatch(new Navigate(node));

      // check if still this route is selected
      if (routeBeforeNavigate === this.$route) {
        const workspaceNode: FilesystemNodeWorkspace | undefined = store.getters.getFsNode(node.id);
        if (workspaceNode) {
          const { id, rootFolderId } = workspaceNode;
          if (id) {
            let location: Location;
            if (rootFolderId) {
              location = {
                name: ROUTES["fs.node"].name,
                params: { workspaceId: id, id: rootFolderId },
              };
            } else {
              location = {
                name: ROUTES["fs.workspace"].name,
                params: { workspaceId: id },
              };
            }
            this.$router.replace(location);
          }
        }
      }
    },
  },
});
