// eslint-disable-next-line no-empty-function
export const noop = () => { };

export function memoizer<T>(fun: (n: string) => T) {
  const cache: Record<string, T> = {};
  return (n: string) => {
    if (cache[n] !== undefined) {
      return cache[n];
    }
    const result = fun(n);
    cache[n] = result;
    return result;
  };
}
