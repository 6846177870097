import { GetterTree } from "vuex";
import { AxShareProject, IframeBridgeProps, IframeBridgeTarget } from "@/store/project/state";
import { AxShare } from "@/store/state";

const getterTree: GetterTree<AxShareProject, AxShare> = {
  iframeBridgeProps(state) {
    return (target: IframeBridgeTarget): IframeBridgeProps | undefined => {
      return state.iframeBridgeProps[target];
    };
  },

  otherIframeBridgeTargets(state) {
    return (target: IframeBridgeTarget): string[] => {
      return Object.keys(state.iframeBridgeProps).filter(prop => prop !== target);
    };
  },

  totalIframeBridgesReady(state) {
    return Object.values(state.iframeBridgeProps).filter(target => !!target && target.iframeBridgeReady).length;
  },
};

export default getterTree;
