import { RouteConfig, Route } from "vue-router";

import prototypePasswordPageLocators from "tests/e2e/utils/prototypePasswordPageLocators";

import { isString } from "@/common/lib/guards";
import { validateRpRequestId } from "@/services/user.service";

import RpAuthBase from "@/components/RpAuth/RpAuthBase.vue";
import RpAuthSidebar from "@/components/RpAuth/RpAuthSidebar.vue";
import RpError from "@/components/RpAuth/RpError.vue";
import RpKey from "@/components/RpAuth/RpKey.vue";
import RpRedirect from "@/components/RpAuth/RpRedirect.vue";
import RpSignin from "@/components/RpAuth/RpSignin.vue";
import RpSignup from "@/components/RpAuth/RpSignup.vue";

export type Keys = "rp" | "rp.signin" | "rp.signup" | "rp.key" | "rp.redirect" | "rp.error";

// a more elegant version of this function would take arguments like this:
//      queryToProps({requestId: ["r", "requestId"] }, machineId: "m"});
const rpQueryToProps = (route: Route) => {
  const props: Record<string, string | (string | null)[]> = {};
  const requestId = route.query.r || route.query.requestId;
  props.requestId = requestId;
  props.machineId = route.query.m;
  props.rpVersion = route.query.rp;
  props.token = route.params?.token;
  props.redemptionKey = route.query.k;

  return props;
};

const sidebarQueryToProps = (route: Route) => {
  const props: Record<string, string | Boolean | (string | null)[]> = {};
  props.signin = route.name === "rp.signin" || route.name === "rp.redirect";
  props.isRp10 = Boolean(route.query.rp === "10");
  return props;
};

async function validateRequestIdParam(to: Route) {
  const requestId = to.query.r || to.query.requestId;
  const valid = !!requestId && isString(requestId) && (await validateRpRequestId(requestId)).IsValid;
  return valid;
}

const routes: { readonly [R in Keys]: RouteConfig } = {
  rp: {
    path: "/rp",
    name: "rp",
    component: RpAuthBase,
    meta: { requiresAuth: false },
  },
  "rp.signin": {
    path: "signin",
    name: "rp.signin",
    components: {
      default: RpSignin,
      sidebar: RpAuthSidebar,
    },
    props: {
      default: rpQueryToProps,
      sidebar: sidebarQueryToProps,
    },
    async beforeEnter(to, _from, next) {
      if (await validateRequestIdParam(to)) {
        return next();
      }
      return next({ name: "rp.error" });
    },
    meta: { requiresAuth: false },
  },
  "rp.signup": {
    path: "signup",
    name: "rp.signup",
    components: {
      default: RpSignup,
      sidebar: RpAuthSidebar,
    },
    props: {
      default: rpQueryToProps,
      sidebar: sidebarQueryToProps,
    },
    async beforeEnter(to, _from, next) {
      if (await validateRequestIdParam(to)) {
        return next();
      }
      return next({ name: "rp.error", query: to.query });
    },
    meta: { requiresAuth: false },
  },
  "rp.key": {
    path: "key",
    name: "rp.key",
    components: {
      default: RpKey,
      sidebar: RpAuthSidebar,
    },
    props: {
      default: rpQueryToProps,
      sidebar: sidebarQueryToProps,
    },
    async beforeEnter(to, _from, next) {
      if (await validateRequestIdParam(to)) {
        return next();
      }
      return next({ name: "rp.error" });
    },
    meta: { requiresAuth: false },
  },
  "rp.redirect": {
    path: "back-to-rp",
    name: "rp.redirect",
    components: {
      default: RpRedirect,
      sidebar: RpAuthSidebar,
    },
    props: {
      default: rpQueryToProps,
      sidebar: sidebarQueryToProps,
    },
    meta: { requiresAuth: false },
    beforeEnter(to, _from, next) {
      if ((to.query.r || to.query.requestId) && to.params.token) {
        return next();
      }
      return next({ name: "rp.error" });
    },
  },
  "rp.error": {
    path: "error",
    name: "rp.error",
    components: {
      default: RpError,
      sidebar: RpAuthSidebar,
    },
    props: {
      sidebar: { isRp10: true },
    },
    meta: { requiresAuth: false },
  },
};

const config = [
  {
    ...routes.rp,
    redirect: routes["rp.signin"],
    children: [routes["rp.signin"], routes["rp.signup"], routes["rp.key"], routes["rp.redirect"], routes["rp.error"]],
  },
];

export default { routes, config };
