
import Vue from "vue";
import { PropOptions } from "vue/types/options";

import {
  FilesystemNodeShortcut,
  FilesystemNodeWorkspace,
  WorkspaceRights,
  FilesystemNodeType,
} from "@/common/fs";
import { FilesystemFolderInfo } from "@/generated/models";
import { fsRouterLocation } from "@/router/fs";
import { ManageUsersInviteModel } from "@/services/models/filesystem";
import { ProjectShare } from "@/store/fs/actionTypes";

import AxButton from "@/components/AxButton.vue";
import AxFormGroup from "@/components/AxFormGroup.vue";
import AxManageUsersInvite from "@/components/AxManageUsers/AxManageUsersInvite.vue";

const projectProp: PropOptions<FilesystemNodeShortcut> = {
  type: Object,
  required: false,
};

const workspaceProp: PropOptions<FilesystemNodeWorkspace> = {
  type: Object,
  required: false,
};

export default Vue.extend({
  components: {
    AxFormGroup,
    AxButton,
    AxManageUsersInvite,
  },

  props: {
    project: projectProp,

    ws: workspaceProp,
  },

  computed: {
    workspace(): FilesystemNodeWorkspace | undefined {
      if (this.ws) return this.ws;

      const workspace: FilesystemNodeWorkspace = this.$store.getters.findWorkspace(this.project.id);
      if (!workspace) return undefined;
      return workspace;
    },

    isDefaultWorkspace(): boolean | undefined {
      return this.workspace && this.workspace.isDefault;
    },

    inviteUsersTitle(): String {
      return this.isDefaultWorkspace ? "Invite Others to a New Workspace" : "Invite Others to this Workspace";
    },

    canInviteUsers(): boolean | undefined {
      return this.isDefaultWorkspace || (this.rights && this.rights.usersInvite);
    },

    rights(): WorkspaceRights | undefined {
      return this.workspace && this.$store.getters.workspaceRights(this.workspace);
    },
  },

  methods: {
    async shareDefaultWorkspaceProject(inviteUsers: ManageUsersInviteModel, recaptchaToken: string | undefined) {
      if (this.workspace && this.workspace.isDefault) {
        const newWorkspace: FilesystemFolderInfo | undefined = await this.$store.dispatch(
          new ProjectShare(this.workspace, this.project, inviteUsers, recaptchaToken),
        );
        if (newWorkspace) {
          const { FilesystemInfo } = newWorkspace;

          await this.$router.replace(
            fsRouterLocation({
              id: FilesystemInfo.FilesystemId,
              type: FilesystemNodeType.Workspace,
            }),
          );
        }
      }
    },

    cancel() {
      this.$emit("cancel", true);
    },
  },
});
