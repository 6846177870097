
import Vue from "vue";

import AxIcon from "@/components/AxIcon.vue";
import AxTooltip from "@/components/AxTooltip.vue";

export default Vue.extend({
  components: {
    AxIcon,
    AxTooltip,
  },

  props: {
    content: {
      type: String,
      default: "",
    },
  },
});
