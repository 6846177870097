import { FilesystemNodeWorkspace } from "@/common/fs";
import { SsoResponse } from "@/generated/models";

export function filterWorkspacesUserIsMember(userInfo: SsoResponse | undefined, workspaces: FilesystemNodeWorkspace[]) {
  let userId: string | undefined;

  if (userInfo) {
    userId = userInfo.userId;
  }

  return workspaces.filter(ws => ws.isOwner || ws.sharedWith.some(shared => userId && userId === shared.user.userId));
}
