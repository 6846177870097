import { render, staticRenderFns } from "./AxWorkspaceInvitation.vue?vue&type=template&id=4db3477a&scoped=true&"
import script from "./AxWorkspaceInvitation.vue?vue&type=script&lang=ts&"
export * from "./AxWorkspaceInvitation.vue?vue&type=script&lang=ts&"
import style0 from "./AxWorkspaceInvitation.vue?vue&type=style&index=0&id=4db3477a&prod&lang=scss&"
import style1 from "./AxWorkspaceInvitation.vue?vue&type=style&index=1&id=4db3477a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4db3477a",
  null
  
)

export default component.exports