import { RouteConfig } from "vue-router";

const ManageAccount = () => import("@/pages/ManageAccount.vue");

export type Keys =
  | "manage.account";

const routes: { readonly [R in Keys]: RouteConfig } = {
  "manage.account": {
    path: "/account/manage",
    name: "manage.account",
    component: ManageAccount,
  },
};

const config = [
  {
    ...routes["manage.account"],
  },
];

export default {
  routes,
  config,
};
