import axios, { AxiosError, AxiosResponse, Cancel } from "axios";

import { ApiResponseObject, ResponseObjectOf, RawResponse } from "@/services/models/responseObject";

export function mapResponse<T>(o: any): ApiResponseObject<T> {
  if (axios.isCancel(o)) return fromAxiosCancel(o);
  if (axios.isAxiosError(o)) return fromAxiosError(o);
  if (o instanceof Error) return fromError(o);
  if (isAxiosResponse(o)) return fromAxiosResponse<T>(o);
  if (isResponse(o)) return fromResponse(o, "");
  return {
    data: {} as T,
    success: false,
    message: "",
    errorMessage: "Couldn't map response payload.",
    redirecturl: "",
    requestUrl: "",
  };
}

function fromError(o: Error): ApiResponseObject {
  return {
    data: {},
    errorMessage: o.message,
    message: o.message,
    success: false,
    redirecturl: "",
    requestUrl: "",
  };
}

function fromAxiosCancel({ message }: Cancel): ApiResponseObject {
  return {
    data: {},
    message: message ?? "Operation cancelled",
    errorMessage: message ?? "Operation cancelled",
    success: false,
    requestCancelled: true,
    redirecturl: "",
    requestUrl: "",
  };
}

function fromAxiosError(o: AxiosError): ApiResponseObject {
  const value: ApiResponseObject = {
    data: {},
    errorMessage: o.message,
    message: o.message,
    success: false,
    redirecturl: "",
    requestUrl: o.config?.url || o.request.responseURL,
  };

  if (o.response && isAxiosResponse(o.response)) {
    value.data = fromAxiosResponse(o.response).data;
    value.response = rawResponse(o.response);
  }

  return value;
}

function fromAxiosResponse<T = any>(o: AxiosResponse): ApiResponseObject<T> {
  const requestUrl = o.config.url || o.request.responseURL;
  if (isResponse<T>(o.data)) {
    return fromResponse(o.data, requestUrl, o);
  }
  return {
    requestUrl,
    success: true,
    data: o.data,
    message: o.data.message,
    errorMessage: o.data.errorMessage,
    redirecturl: o.data.redirecturl,
    response: rawResponse(o),
  };
}

function fromResponse<T>(
  response: ResponseObjectOf<T>,
  requestUrl: string,
  axiosResponse?: AxiosResponse<T>,
): ApiResponseObject<T> {
  return {
    ...response,
    requestUrl,
    response: axiosResponse ? rawResponse(axiosResponse) : undefined,
  };
}

function rawResponse({ status, statusText, headers }: AxiosResponse): RawResponse {
  return { status, statusText, headers };
}

function isAxiosResponse(o: any): o is AxiosResponse {
  return o instanceof Object && "data" in o && "config" in o && "status" in o && "statusText" in o && "headers" in o;
}

function isResponse<T = any>(o: any): o is ResponseObjectOf<T> {
  return (
    o instanceof Object && "success" in o
    // &&
    // "message" in o &&
    // "errorMessage" in o &&
    // "redirecturl" in o
  );
}
