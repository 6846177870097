
import Vue, { VNode } from "vue";
import { mergeData } from "vue-functional-data-merge";

export default Vue.extend({
  functional: true,
  props: {
    stacked: {
      type: String,
      default: "horizontal",
    },
    noWrap: {
      type: Boolean,
      default: false,
    },
  },

  render(h, ctx): VNode {
    const data = {
      staticClass: "ax-button-group",
      class: {
        [`ax-button-group--${ctx.props.stacked}`]: true,
        "ax-button-group--no-wrap": ctx.props.noWrap,
      },
    };
    return h("div", mergeData(ctx.data, data), ctx.slots().default);
  },
});
