import { render, staticRenderFns } from "./AxUserAvatarDefault.vue?vue&type=template&id=07f73dbc&"
import script from "./AxUserAvatarDefault.vue?vue&type=script&lang=ts&"
export * from "./AxUserAvatarDefault.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./AxUserAvatarDefault.vue?vue&type=custom&index=0&blockType=styles&lang=scss&scoped=true"
if (typeof block0 === 'function') block0(component)

export default component.exports