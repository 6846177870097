
import Vue from "vue";
import { Location } from "vue-router";
import { Store } from "vuex";

import { FilesystemNodeWorkspace } from "@/common/fs";
import { ROUTES } from "@/router";
import { Navigate, NavigationRestore } from "@/store/fs/actionTypes";
import { AxShare } from "@/store/state";

export default Vue.extend({
  props: {
    workspaceId: {
      type: String,
      required: true,
    },
  },

  computed: {
    loading(): boolean {
      return this.$store.getters.navigating;
    },

    isRoot(): boolean {
      return this.$store.getters.isRoot;
    },
  },

  watch: {
    workspaceId() {
      this.loadWorkspace();
    },
  },

  mounted() {
    this.loadWorkspace();
  },

  methods: {
    async loadWorkspace() {
      const workspaceId = this.workspaceId;
      const store = this.$store as Store<AxShare>;
      let node: FilesystemNodeWorkspace = store.getters.getFsNode(workspaceId);

      if (!node) {
        await store.dispatch(new NavigationRestore(workspaceId, "workspace"));
        node = store.getters.getFsNode(workspaceId);
      }

      await this.navigateToWorkspace(node);
    },

    async navigateToWorkspace(node: FilesystemNodeWorkspace) {
      const store = this.$store as Store<AxShare>;
      const routeBeforeNavigate = this.$route;
      await store.dispatch(new Navigate(node));

      // check if still this route is selected
      if (routeBeforeNavigate === this.$route) {
        const workspaceNode: FilesystemNodeWorkspace | undefined = store.getters.getFsNode(node.id);
        if (workspaceNode) {
          const { id, rootFolderId } = workspaceNode;
          if (id) {
            let location: Location;
            if (rootFolderId) {
              location = {
                name: ROUTES["fs.node"].name,
                params: { workspaceId: id, id: rootFolderId },
              };
            } else {
              location = {
                name: ROUTES["fs.workspace"].name,
                params: { workspaceId: id },
              };
            }
            await this.$router.replace(location);
          }
        }
      }
    },
  },
});
