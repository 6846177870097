import { AccountRowJs, AxShareCurrentUserInfo, AxShareSubInstanceInfo } from "@shared/models";

export interface AxShareAdmin {
  userInfo?: AxShareCurrentUserInfo;
  subInstanceInfo?: AxShareSubInstanceInfo;
  subInstanceAccounts?: AccountRowJs[];
}

const state = (): AxShareAdmin => ({
  userInfo: undefined,
  subInstanceInfo: undefined,
  subInstanceAccounts: undefined,
});

export default state;
