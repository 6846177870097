import { Prop, PropValidator } from "vue/types/options";

export function arrayProp<T>(overrides?: PropValidator<T[]>): PropValidator<T[]> {
  return {
    type: Array,
    default: () => [],
    ...overrides,
  };
}

export function prop<T>(typeOrOverrides?: Prop<T> | PropValidator<T>, overrides?: PropValidator<T>): PropValidator<T> {
  let resolvedType;
  let resolvedOverrides: PropValidator<T> | undefined;

  if (typeOrOverrides !== undefined) {
    if (typeof typeOrOverrides === "function") {
      resolvedType = typeOrOverrides;
      resolvedOverrides = overrides;
    } else if (!Array.isArray(typeOrOverrides) && typeOrOverrides.type !== undefined) {
      resolvedType = typeOrOverrides.type;
      resolvedOverrides = overrides;
    } else {
      resolvedOverrides = typeOrOverrides;
    }
  }

  return {
    type: resolvedType,
    ...resolvedOverrides,
  };
}
