import FormData from "form-data";

const toString = Object.prototype.toString;

export function isArrayBuffer(val: any): val is ArrayBuffer {
  return toString.call(val) === "[object ArrayBuffer]";
}

export function isFormData(val: any): val is FormData {
  return typeof FormData !== "undefined" && val instanceof FormData;
}

export function isArrayBufferView(val: any): val is ArrayBufferView {
  let result: boolean;
  if (typeof ArrayBuffer !== "undefined" && ArrayBuffer.isView) {
    result = ArrayBuffer.isView(val);
  } else {
    result = val && val.buffer && val.buffer instanceof ArrayBuffer;
  }
  return result;
}

export function isFile(val: any): val is File {
  return toString.call(val) === "[object File]";
}

export function isBlob(val: any): val is Blob {
  return toString.call(val) === "[object Blob]";
}

export function isFunction(val: any): val is Function {
  return toString.call(val) === "[object Function]";
}

export function isString(val: any): val is string {
  return typeof val === "string";
}

export function isObject(val: any) {
  return val !== null && typeof val === "object";
}

export function isURLSearchParams(val: any): val is URLSearchParams {
  return typeof URLSearchParams !== "undefined" && val instanceof URLSearchParams;
}
