
import Vue from "vue";

import { AxShare } from "@/store/state";

import AxFilesystemNavigationError from "@/components/AxFilesystemNavigationError.vue";

export default Vue.extend({
  components: {
    AxFilesystemNavigationError,
  },

  computed: {
    profileLoaded(): boolean {
      // await till initial user info will be fetched before making FS requests
      // this resolves reace-condition issue when default filesystem isn't initialized
      // but client already makes request
      // consider removing this computed property after back-end issue is addressed
      const { user } = this.$store.state as AxShare;
      return !!user.userInfo && !!user.profile;
    },

    navigatingError(): boolean {
      return this.$store.getters.navigatingError;
    },
  },
});
