
import Vue from "vue";
import { Location } from "vue-router";
import { PropOptions } from "vue/types/options";

import {
  FilesystemNodeShortcut,
  FilesystemNodeFolder,
  FilesystemNodeType,
  FilesystemNodeTypes,
  getUnreadPublishNotesMentions,
  isShortcutSearchNode,
  isFilesystemFolderNode,
} from "@/common/fs";
import { jsDateFromAspDateString as toJsDate } from "@/common/lib";
import { equalsIgnoringCase } from "@/common/lib/string";
import { ROUTES } from "@/router";
import { fsRouterLocation } from "@/router/fs";

import AxButton from "@/components/AxButton.vue";
import AxDataTable from "@/components/AxDataTable.vue";
import AxDataTableCellCheckbox from "@/components/AxDataTableCellCheckbox.vue";
import AxDataTableNoData from "@/components/AxDataTableNoData.vue";
import AxFilesystemNodeLink from "@/components/AxFilesystemNodeLink.vue";
import AxFilesystemNodesActions from "@/components/AxFilesystemNodesActions.vue";
import AxShortcutCommentsCount from "@/components/AxShortcutCommentsCount.vue";
import AxShortcutDropdownButton from "@/components/AxShortcutDropdownButton.vue";
import AxShortcutModified from "@/components/AxShortcutModified.vue";
import AxShortcutPublicLink from "@/components/AxShortcutPublicLink.vue";
import AxUnreadMentionsIndicator from "@/components/AxUnreadMentionsIndicator.vue";
import { Header } from "@/components/types/AxDataTable";
import { FilesystemSearchItem } from "@/components/types/AxSearch";
import { arrayProp } from "@/components/utils";

export default Vue.extend({
  components: {
    AxDataTable,
    AxDataTableNoData,
    AxDataTableCellCheckbox,
    AxFilesystemNodeLink,
    AxShortcutCommentsCount,
    AxShortcutDropdownButton,
    AxShortcutModified,
    AxShortcutPublicLink,
    AxButton,
    AxUnreadMentionsIndicator,
    AxFilesystemNodesActions,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    selectDisabled: {
      type: Boolean,
      default: false,
    },

    viewOnly: {
      type: Boolean,
      default: false,
    },

    headers: arrayProp<Header>({
      type: Array,
      default: () => [
        {
          text: "Project name",
          value: "name",
        },
        {
          text: "Comments",
          value: "listingNode.NotificationState.TotalCommentsCount",
          width: "23%",
        },
        {
          text: "Share",
          value: "shortcut",
          sortable: false,
          width: "18%",
        },
        {
          text: "Modified",
          value: "updated",
          width: "20%",
        },
        {
          // actions
          text: "",
          sortable: false,
          width: "50px",
        },
      ],
    }),

    hideHeaders: {
      type: Boolean,
      default: false,
    },

    headerOffsetTop: {
      type: Number,
      default: 0,
    },

    pagination: {
      type: Object,
      default: () => ({
        sortBy: "updated",
        descending: true,
      }),
    },

    paginationDisabled: {
      type: Boolean,
      default: false,
    },

    customSort: {
      type: Function,
      default: undefined,
    },

    items: arrayProp<FilesystemNodeTypes>({
      required: false,
      default: () => [],
    }),

    itemNavigationDisabled: {
      type: Function,
      required: false,
      default: () => false,
    } as PropOptions<(item: FilesystemNodeTypes) => boolean>,
  },

  data() {
    return {
      dataTablePagination: this.paginationDisabled ? {} : this.pagination,
      FilesystemNodeType,
      selected: [] as FilesystemNodeTypes[],
    };
  },

  computed: {
    fixedHeaderStyles(): any {
      return {
        top: `${this.headerOffsetTop}px`,
      };
    },

    isSelectCheckboxEnabled(): boolean {
      const { selectDisabled, viewOnly } = this;

      if (selectDisabled) {
        return false;
      }

      return !viewOnly;
    },

    dataTableParams(): any {
      const options: Record<string, any> = {};
      if (this.customSort) {
        options.customSort = this.customSort;
      }
      return options;
    },

    createProjectRoute(): Location {
      return {
        name: ROUTES["fs.node.createproject"].name,
        params: { id: this.$route.params.id },
      };
    },

    createFolderRoute(): Location {
      return {
        name: ROUTES["fs.node.createfolder"].name,
        params: { id: this.$route.params.id },
      };
    },

    emptyState(): Boolean {
      return !this.items.length;
    },
  },

  methods: {
    selectionChanged(selection: any) {
      this.selected = selection;
    },

    clearSelection(deleted: FilesystemNodeTypes) {
      this.selected = this.selected.filter(item => item.id !== deleted.id);
    },

    isNavigatableTarget(target: HTMLElement): boolean {
      // don't include <a> tag as valid navigation target, as those clicks are handled by router-link component
      const validNavigationTargets = ["tr", "td"];
      return (
        validNavigationTargets.some(validTarget => equalsIgnoringCase(validTarget, target.nodeName))
        || target.classList.contains("allow-navigation")
      );
    },

    showShortcutDropdown(item: FilesystemNodeTypes) {
      return item.type === FilesystemNodeType.Shortcut && item.viewOnly === false;
    },

    isViewOnly(item: FilesystemNodeTypes) {
      if (item.type === FilesystemNodeType.Shortcut) {
        if (this.viewOnly) {
          return !this.viewOnly;
        }
        return !item.viewOnly;
      }
    },

    hasUnreadPublishNotesMentions(item: FilesystemNodeTypes) {
      if (!item.listingNode) return false;
      const mentionsCount = getUnreadPublishNotesMentions(item.listingNode.NotificationState);
      return mentionsCount > 0;
    },

    async navigateToItem(item: FilesystemNodeTypes, event: MouseEvent) {
      if (this.itemNavigationDisabled(item)) {
        return false;
      }
      const target = event.target as HTMLElement;
      if (this.isNavigatableTarget(target)) {
        if (item.type === FilesystemNodeType.Shortcut) {
          await this.navigateToShortcutOverview(item);
        } else {
          await this.$router.push(fsRouterLocation(item));
        }
      }
    },

    async navigateToShortcutOverview(item: FilesystemNodeShortcut) {
      await this.$router.push({
        name: ROUTES["project.overview"].name,
        params: {
          shortcut: item.shortcut || item.id,
        },
      });
    },

    getFolderModifiedDate(item: FilesystemNodeFolder | FilesystemSearchItem): Date | undefined {
      let folder: FilesystemNodeFolder | undefined;
      if (isShortcutSearchNode(item)) {
        const node = this.$store.getters.getFsNode(item.id);
        if (isFilesystemFolderNode(node)) {
          folder = node;
        }
      } else {
        folder = item;
      }
      if (folder && folder.listingNode) {
        return toJsDate(folder.listingNode.ModifiedOn.Value);
      }
    },

    getFolderModifiedOnShortcut(item: FilesystemNodeFolder | FilesystemSearchItem): string | undefined {
      let folder: FilesystemNodeFolder | undefined;
      if (isShortcutSearchNode(item)) {
        const node = this.$store.getters.getFsNode(item.id);
        if (isFilesystemFolderNode(node)) {
          folder = node;
        }
      } else {
        folder = item;
      }
      if (folder && folder.listingNode) {
        return folder.listingNode.ModifiedOn.Shortcut;
      }
    },
  },
});
