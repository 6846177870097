import { Payload, PayloadMap } from "@/store/typed";
import { ArtboardUploadBatchSource, ArtboardUploadStatus } from "@/store/uploads/state";

export enum MutationTypes {
  NewUpload = "[Artboard Uploads] New Upload",
  DismissUpload = "[Artboard Uploads] Dismiss Upload",
  AddToBatch = "[Artboard Uploads] Add To Batch",
  RemoveFromBatch = "[Artboard Uploads] Remove From Batch",
  UpdateProgress = "[Artboard Uploads] Update Upload",
  UploadSuccess = "[Artboard Uploads] Upload Success",
  UploadCancel = "[Artboard Uploads] Upload Cancel",
  UploadError = "[Artboard Uploads] Upload Error",
}

export class NewUpload implements Payload {
  public readonly type = MutationTypes.NewUpload;
  constructor(
    public uploadData: {
      source: ArtboardUploadBatchSource;
      batchId: string;
      shortcut: string;
      projectName: string;
    }
  ) {}
}

export class DismissUpload implements Payload {
  public readonly type = MutationTypes.DismissUpload;
  constructor(public batchId: string) {}
}

export class AddToBatch implements Payload {
  public readonly type = MutationTypes.AddToBatch;
  constructor(
    public uploadData: {
      batchId: string;
      shortcut: string;
      artboardId: string;
      artboardName: string;
      cancel?(): Promise<void>;
    }
  ) {}
}

export class RemoveFromBatch implements Payload {
  public readonly type = MutationTypes.RemoveFromBatch;
  constructor(public batchId: string, public artboardId: string) {}
}

export class UpdateProgress implements Payload {
  public readonly type = MutationTypes.UpdateProgress;
  constructor(
    public uploadData: {
      batchId: string;
      shortcut: string;
      artboardId: string;
      progress: number;
      status?: ArtboardUploadStatus;
      cancel?(): Promise<void>;
    }
  ) {}
}

export class UploadSuccess implements Payload {
  public readonly type = MutationTypes.UploadSuccess;
  constructor(
    public uploadData: {
      batchId: string;
      shortcut: string;
      artboardId: string;
      serverArtboardId: string;
    }
  ) {}
}

export class UploadCancel implements Payload {
  public readonly type = MutationTypes.UploadCancel;
  constructor(
    public uploadData: {
      batchId: string;
      shortcut: string;
      artboardId: string;
    }
  ) {}
}

export class UploadError implements Payload {
  public readonly type = MutationTypes.UploadError;
  constructor(
    public uploadData: {
      batchId: string;
      shortcut: string;
      artboardId: string;
      errorMessage: string;
    }
  ) {}
}

export interface MutationPayloadMap extends PayloadMap<MutationTypes> {
  [MutationTypes.NewUpload]: NewUpload;
  [MutationTypes.DismissUpload]: DismissUpload;
  [MutationTypes.AddToBatch]: AddToBatch;
  [MutationTypes.UpdateProgress]: UpdateProgress;
  [MutationTypes.UploadCancel]: UploadCancel;
  [MutationTypes.UploadError]: UploadError;
  [MutationTypes.UploadSuccess]: UploadSuccess;
  [MutationTypes.RemoveFromBatch]: RemoveFromBatch;
}
