import { MutationPayloadMap, MutationTypes } from "@/store/user/mutationsTypes";
import { AxShareUser } from "@/store/user/state";
import { MutationTree } from "../typed";

const mutations: MutationTree<AxShareUser, MutationPayloadMap> = {
  [MutationTypes.SetInfo](state, { info }) {
    state.userInfo = info;
  },

  [MutationTypes.SetProfile](state, { profile }) {
    state.profile = profile;
  },
};

export default mutations;
