
import Vue from "vue";
import { Location } from "vue-router";

import { persistentStorage } from "@/common/axshare/persistent";
import { filterWorkspacesUserIsMember } from "@/common/axshare/workspaces";
import { FilesystemNodeInvitation, FilesystemNodeWorkspace } from "@/common/fs";
import { orderBy } from "@/common/lib";
import { ROUTES } from "@/router";
import adminRouter from "@/router/admin";
import { fsRouterLocation, RefreshOptionsQuery } from "@/router/fs";
import { ApiCall, ApiCallOptions } from "@/store/actionTypes";
import { WorkspacesRefresh } from "@/store/fs/actionTypes";
import { rootNode } from "@/store/fs/state";
import { MigrateWorkspaces } from "@/store/migration/mutationTypes";
import { SetShowAllWorkspaces, SetShowArchived } from "@/store/settings/mutationTypes";
import { AxShare } from "@/store/state";

import AxButton from "@/components/AxButton.vue";
import AxCreateWorkspace from "@/components/AxCreateWorkspace.vue";
import AxFilesystemNavigationWorkspace from "@/components/AxFilesystemNavigationWorkspace.vue";
import AxIcon from "@/components/AxIcon.vue";
import AxToggleButton from "@/components/AxToggleButton.vue";

export default Vue.extend({
  components: {
    AxIcon,
    AxButton,
    AxCreateWorkspace,
    AxFilesystemNavigationWorkspace,
    AxToggleButton,
  },

  data() {
    const { axShareConfig } = this.$store.state as AxShare;
    const roMessage = axShareConfig != null ? axShareConfig.ManagePageMessage.trim() : "";

    return {
      workspacesMigrationAcknowledged: persistentStorage.WorkspacesMigrationAcknowledged,
      creatingWorkspace: false,
      workspacesLoading: false,
      workspacesLoaded: false,
      enableListAnimation: false,
      scrollExist: false,
      adminSettingsRoute: {
        name: adminRouter.routes.admin.name,
        path: adminRouter.routes.admin.path,
      },

      roMsg: roMessage === "" ? null : roMessage,
    };
  },

  computed: {
    isRecentActive(): boolean {
      return this.$route.name === ROUTES["fs.recents"].name;
    },

    recentsRoute(): Location {
      const recentRouteName = ROUTES["fs.recents"].name;
      return {
        name: recentRouteName,
        query: {
          refresh: this.isRecentActive ? "true" : "",
        },
      };
    },

    workspacesTransitionName(): string {
      return this.enableListAnimation ? "workspaces-list" : "";
    },

    workspacesList(): FilesystemNodeWorkspace[] {
      return this.allNonDefaultActualWorkspaces.filter(ws => !ws.isArchived);
    },

    archivedWorkspacesList(): FilesystemNodeWorkspace[] {
      if (!this.showArchived) return [];
      return this.allArchivedWorkspaces;
    },

    allArchivedWorkspaces(): FilesystemNodeWorkspace[] {
      return this.allNonDefaultActualWorkspaces.filter(ws => ws.isArchived);
    },

    allNonDefaultActualWorkspaces(): FilesystemNodeWorkspace[] {
      let items = this.workspaces.filter(ws => !ws.isDefault);

      if (this.isSubInstanceAdmin && !this.showAllWorkspaces) {
        items = filterWorkspacesUserIsMember(this.$store.state.user.userInfo, items);
      }

      return orderBy(
        items,
        item => ({ value: item.isFavorite, descending: true }),
        item => ({ value: item.created, descending: true }),
      );
    },

    hasArchivedWorkspaces(): boolean {
      return this.allArchivedWorkspaces.length > 0;
    },

    archivedWorkspacesButtonText(): string {
      return this.showArchived ? "Hide Archived" : "Show Archived";
    },

    workspaces(): FilesystemNodeWorkspace[] {
      return Object.values(this.$store.getters.workspaces);
    },

    invitations(): FilesystemNodeInvitation[] {
      return Object.values(this.$store.getters.invitations);
    },

    defaultWorkspace(): FilesystemNodeWorkspace | undefined {
      return this.$store.getters.defaultWorkspace;
    },

    shouldHandleRouteChange(): boolean {
      const currentRouteName = this.$route.name;
      const fsRouteName = ROUTES.fs.name;
      return !!(currentRouteName && fsRouteName && currentRouteName.startsWith(fsRouteName));
    },

    emptyParams(): boolean {
      const { id, workspaceId } = this.$route.params;
      return !id && !workspaceId;
    },

    isAdminPage(): boolean {
      const currentRoutePath = this.$route.path;
      return !!(
        currentRoutePath
        && this.adminSettingsRoute.path
        && currentRoutePath.startsWith(this.adminSettingsRoute.path)
      );
    },

    isSubInstance(): boolean {
      const { axShareConfig } = this.$store.state as AxShare;
      return axShareConfig !== null && axShareConfig.IsSubInstance;
    },

    isSubInstanceReviewer(): boolean {
      return this.$store.getters.isSubInstanceReviewer;
    },

    isSubInstanceAdmin(): boolean {
      return this.$store.getters.isSubInstanceAdmin;
    },

    isSubInstanceTechnicalAdmin(): boolean {
      return this.$store.getters.isSubInstanceTechnicalAdmin;
    },

    showCreateWorkspace(): boolean {
      const { axShareConfig } = this.$store.state as AxShare;
      if (axShareConfig !== null && (axShareConfig.IsSubInstance === false || this.$store.getters.hasAuthorRights)) {
        return true;
      }
      return false;
    },

    showAllWorkspaces(): boolean {
      const { settings } = this.$store.state as AxShare;
      return settings && settings.showAllWorkspaces;
    },

    showArchived(): boolean {
      const { settings } = this.$store.state as AxShare;
      return settings && settings.showArchived;
    },

    allowWorkspacesMigrate(): boolean {
      return this.$store.getters.allowWorkspacesMigrate;
    },

    workspacesMigrationSuggest(): boolean {
      if (this.workspacesMigrationAcknowledged) {
        return false;
      }
      if (this.workspacesLoading) return false;
      if (this.isSubInstance) {
        const totalWorkspaces = this.allNonDefaultActualWorkspaces.length;
        if (totalWorkspaces > 1) {
          this.workspacesMigrationAcknowledge();
          return false;
        }
        if (totalWorkspaces === 0) return true;
        if (totalWorkspaces === 1) {
          const { 0: singleWorkspace } = this.allNonDefaultActualWorkspaces;
          return singleWorkspace.name === "First Workspace";
        }
      }
      return this.isSubInstance && this.allNonDefaultActualWorkspaces.length > 1;
    },
  },

  watch: {
    "$route.query": async function _(query: any) {
      const { workspaces } = query as RefreshOptionsQuery;
      const refresh = workspaces === String(true);
      if (refresh) {
        const options: ApiCallOptions = {
          action: () => this.loadWorkspaces(),
        };
        await this.$store.dispatch(new ApiCall(options));
      }
    },

    "$route.params": {
      async handler() {
        if (this.shouldHandleRouteChange && this.emptyParams) {
          const options: ApiCallOptions = {
            action: async () => {
              await this.loadWorkspaces();
              await this.navigateToDefaultWorkspace();
            },
          };
          await this.$store.dispatch(new ApiCall(options));
        }
      },
    },
  },

  async mounted() {
    const fetchWorkspaces = async () => {
      await this.loadWorkspaces();
      if (this.shouldHandleRouteChange && this.emptyParams) {
        if (this.isSubInstanceTechnicalAdmin) {
          await this.navigateToAdminPage();
        } else {
          await this.navigateToWorkspace();
        }
      }
      this.scrollToCurrentWorkspace();
    };

    const options: ApiCallOptions = {
      action: fetchWorkspaces,
    };
    await this.$store.dispatch(new ApiCall(options));
  },

  methods: {
    async loadWorkspaces() {
      if (!this.workspacesLoading) {
        try {
          this.workspacesLoading = true;
          await this.$store.dispatch(new WorkspacesRefresh());
          this.workspacesLoading = false;
        } catch (error) {
          this.workspacesLoading = false;
          throw error;
        } finally {
          this.workspacesLoaded = true;
        }
      }
    },

    async navigateToAdminPage() {
      if (this.isAdminPage) return;
      await this.$router.replace(this.adminSettingsRoute);
    },

    async navigateToWorkspace() {
      if ((!this.workspaces || !this.workspaces.length) && (!this.invitations || !this.invitations.length)) {
        // drop in private workspace if empty workspaces
        await this.navigateToDefaultWorkspace();
        return;
      }

      const { settings } = this.$store.state as AxShare;
      if (settings && settings.savedWorkspaceId) {
        const { savedWorkspaceId } = settings;
        const workspace: FilesystemNodeWorkspace | undefined = this.$store.getters.findWorkspace(savedWorkspaceId);
        if (workspace) {
          // land on the workspace that has been
          await this.$router.replace(fsRouterLocation(workspace));
          return;
        }
      }

      await this.navigateToFirstAvailableWorkspace();
    },

    async navigateToDefaultWorkspace() {
      const { defaultWorkspace } = this;

      if (defaultWorkspace) {
        const { current } = this.$store.getters;
        if (current.node !== this.defaultWorkspace) {
          await this.$router.replace(fsRouterLocation(defaultWorkspace || rootNode));
        }
      } else {
        // if for some reason there is no default workspace, then navigate to the first available workspace,
        // or if there isn't one fallback to Recents page
        await this.navigateToFirstAvailableWorkspace();
      }
    },

    async navigateToFirstAvailableWorkspace() {
      const { workspacesList, invitations, recentsRoute } = this;
      let fallbackWorkspaceRoute: Location = recentsRoute as Location;
      if (invitations.length) {
        fallbackWorkspaceRoute = fsRouterLocation(invitations[0]);
      } else if (workspacesList.length) {
        fallbackWorkspaceRoute = fsRouterLocation(workspacesList[0]);
      }
      await this.$router.replace(fallbackWorkspaceRoute);
    },

    createWorkspace() {
      this.creatingWorkspace = true;
    },

    setShowAllWorkspaces() {
      this.$store.commit(new SetShowAllWorkspaces(!this.showAllWorkspaces));
    },

    setShowArchived() {
      this.$store.commit(new SetShowArchived(!this.showArchived));
    },

    getWorkspaceNavigationItemElement(id: string): Element | undefined {
      if (!id) {
        return undefined;
      }

      const workspaceNavigationItem: Element | undefined = this.$refs[`workspaceNavigationItem_${id}`] as any;
      if (Array.isArray(workspaceNavigationItem)) {
        return workspaceNavigationItem[0];
      }
      return workspaceNavigationItem;
    },

    scrollToCurrentWorkspace() {
      if (this.emptyParams) {
        return;
      }
      const { workspaceId } = this.$route.params;
      const currentWorkspaceNavigationItem = this.getWorkspaceNavigationItemElement(workspaceId);
      if (currentWorkspaceNavigationItem && currentWorkspaceNavigationItem.scrollIntoView) {
        currentWorkspaceNavigationItem.scrollIntoView({ block: "center" });
      }
    },

    disableAnimation() {
      setTimeout(() => {
        this.enableListAnimation = true;
      }, 500);
    },

    beginMigration() {
      this.$store.commit(new MigrateWorkspaces(true));
    },

    workspacesMigrationAcknowledge() {
      persistentStorage.WorkspacesMigrationAcknowledged = true;
      this.workspacesMigrationAcknowledged = true;
    },
  },
});
