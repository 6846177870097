
import Vue from "vue";
import { PropOptions } from "vue/types/options";

import AxButton from "@/components/AxButton.vue";

const defaultOptions = {
  paramName: "fileData",
  url: "",
  autoProcessQueue: false,
  uploadMultiple: false,
  clickable: false,
  maxFiles: 1,
  autoQueue: false,
};

export default Vue.extend({
  components: {
    AxButton,
  },

  props: {
    value: {
      type: Object,
      required: false,
      default: null,
    },

    /* eslint-disable vue/require-default-prop */
    options: {
      type: Object,
      required: false,
      default: null,
    } as PropOptions<any>,
    /* eslint-enable vue/require-default-prop */

    uploaderClasses: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      selected: null,
      maxFilesReached: false,
      vTransmitOptions: {
        ...defaultOptions,
        ...this.options,
      },
    };
  },

  computed: {
    scopeProps(): any {
      return {
        triggerBrowse: this.triggerBrowse,
        removeFile: this.removeFile,
      };
    },
  },

  methods: {
    triggerBrowse() {
      const uploader = this.$refs.uploader as any;
      uploader.triggerBrowseFiles();
    },

    removeFile(file: any) {
      const uploader = this.$refs.uploader as any;
      uploader.removeFile(file);
      this.selected = null;
      this.$emit("input", this.selected);
    },

    addedFile() {
      if (this.selected) {
        this.removeFile(this.selected);
      }
    },

    acceptedFile(file: any) {
      this.selected = file;
      this.$emit("input", this.selected);
      this.$emit("accepted-file", this.selected);
    },

    error() {
      if (this.selected) {
        this.removeFile(this.selected);
      }
      this.$emit("error", ...arguments);
    },
  },
});
