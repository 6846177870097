
import Vue from "vue";
import { Location } from "vue-router";
import { PropOptions } from "vue/types/options";

import { FilesystemNodeType, FilesystemNodeTypes } from "@/common/fs";
import { ROUTES } from "@/router";

import AxNavigationLinkFs from "./AxNavigationLinkFs.vue";

import AxIcon from "@/components/AxIcon.vue";

const itemsProp: PropOptions<FilesystemNodeTypes[]> = {
  type: Array,
  default: () => [],
};

const props = {
  items: itemsProp,
};

export default Vue.extend({
  components: {
    AxNavigationLinkFs,
    AxIcon,
  },

  props,

  data() {
    return {
      FilesystemNodeType,
      fsRootLocation: {
        name: ROUTES.fs.name,
      },
    };
  },

  computed: {
    noRootItems(): FilesystemNodeTypes[] {
      return this.items.filter(item => item.type !== FilesystemNodeType.Root);
    },

    breadcrumbs(): (FilesystemNodeTypes | string)[] {
      const noRootItems = this.noRootItems;
      if (!this.shouldCollapse) {
        return noRootItems;
      }
      return [noRootItems[0], "...", noRootItems[noRootItems.length - 2], noRootItems[noRootItems.length - 1]];
    },

    shouldCollapse(): boolean {
      return this.noRootItems.length > 3;
    },

    recentsRoute(): Location {
      const recentRouteName = ROUTES["fs.recents"].name;
      return {
        name: recentRouteName,
        query: {
          refresh: "true",
        },
      };
    },
  },
});
