
import { Canceler } from "axios";
import Vue from "vue";

import { AnalyticsClickEvents } from "@/common/axshare";
import { enterpriseLicenseCheckFailed } from "@/common/axshare/messages";
import { ProjectCreateModelValidator } from "@/common/fs";
import { getFilenameWithoutExtension } from "@/common/lib/string";
import { RichText } from "@/generated/models";
import { ROUTES } from "@/router";
import { ProjectCreateModel } from "@/services/models/project";
import { checkPrototypeNameValid, createEmptyProject, createProject } from "@/services/project.service";
import { Fetch } from "@/store/fs/actionTypes";
import { AxShare } from "@/store/state";

import AxButton from "@/components/AxButton.vue";
import AxCreateProjectCompact from "@/components/AxCreateProjectCompact.vue";
import AxForm from "@/components/AxForm.vue";
import AxIcon from "@/components/AxIcon.vue";
import AxInput from "@/components/AxInput.vue";
import AxPanel from "@/components/AxPanel.vue";
import AxProjectFilePicker from "@/components/AxProjectFilePicker.vue";
import { Progress } from "@/components/AxProjectFileUploadIndicator.vue";
import AxPublishNotes from "@/components/AxPublishNotes.vue";
import AxToggleButton from "@/components/AxToggleButton.vue";
import { Submit } from "@/components/types/AxForm";

export default Vue.extend({
  components: {
    AxCreateProjectCompact,
    AxForm,
    AxButton,
    AxPanel,
    AxInput,
    AxIcon,
    AxProjectFilePicker,
    AxPublishNotes,
    AxToggleButton,
  },

  props: {
    parentFolder: {
      type: String,
      required: true,
    },

    compact: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const model: ProjectCreateModel = {
      Name: "",
      Password: "",
      FolderId: this.parentFolder,
      currentFolderId: this.parentFolder,
      fileData: null,
      PublishNote: "",
      PublishNoteRichText: "",
    };
    return {
      AnalyticsClickEvents,
      model,
      projectNameUserChanged: false,
      file: null,
      uploading: false,
      uploadCanceled: false,
      errors: [] as string[],
      canceler: null as Canceler | null,
      progress: null as Progress | null,
      publishNote: null as RichText | null,
      passwordProtect: false,
      options: {
        url: "",
        autoProcessQueue: false,
        uploadMultiple: false,
        clickable: false,
        paramName: "fileData",
        maxFiles: 1,
        autoQueue: false,
      },

      validator: ProjectCreateModelValidator,
      loading: false,
    };
  },

  computed: {
    currentUserId(): string {
      const { user } = this.$store.state as AxShare;
      if (user.userInfo) {
        return user.userInfo.userId;
      }
      return "";
    },

    currentUserToken(): string {
      const { accountService } = this.$store.state;
      if (accountService) {
        return accountService().AuthToken;
      }
      return "";
    },

    currentWorkspaceId(): string {
      const { workspaceId } = this.$route.params;
      return workspaceId;
    },

    isSubInstance(): boolean {
      const { axShareConfig } = this.$store.state as AxShare;
      return axShareConfig !== null && axShareConfig.IsSubInstance;
    },
  },

  methods: {
    async submit({ values }: Submit<ProjectCreateModel>) {
      // save reference to file in case if "Cancel" will be click while name check hasn't finished yet
      const file = this.file;
      await checkPrototypeNameValid(values.Name, values.FolderId);

      // selected file was removed, cancel submit completely
      if (file != null && this.file == null) return;

      let response;
      if (file == null) {
        response = await createEmptyProject(values);
      } else {
        if (this.$store.getters.enterpriseLicenseCheckFailed) {
          this.errors = [enterpriseLicenseCheckFailed];
          return;
        }

        // manually set fileData because AxForm doesn't preserve File
        // eslint-disable-next-line no-param-reassign
        values.fileData = file;
        this.uploading = true;
        this.uploadCanceled = false;
        this.progress = {
          current: 0,
          total: 0,
          lengthComputable: false,
        };

        if (this.publishNote) {
          // manually set PublishNoteRichText to be able to correctly send IFormCollection post parameter
          // eslint-disable-next-line no-param-reassign
          values.PublishNoteRichText = JSON.stringify(this.publishNote);
        }

        // eslint-disable-next-line no-param-reassign
        if (!this.passwordProtect) values.Password = "";

        response = await createProject(
          values,
          canceler => {
            this.canceler = canceler;
          },
          progress => {
            if (!this.uploadCanceled) {
              this.progress = {
                current: progress.loaded,
                total: progress.total ?? 0,
                lengthComputable: !!(progress.total),
              };
            }
          },
        );
        this.uploading = false;
      }

      if (response.Vars && response.Vars.shortcut) {
        const shortcut = response.Vars.shortcut.toLowerCase();
        this.loading = true;
        await this.$store.dispatch(new Fetch(this.$store.getters.getNodeByFolderId(this.parentFolder)));
        this.$router.push(
          {
            name: ROUTES["project.overview"].name,
            params: {
              shortcut,
            },
          },
          () => {
            this.loading = false;
          },
        );
      } else {
        this.$emit("submit");
      }
    },

    fileChanged(file: any) {
      if (file && typeof file.name === "string") {
        const fileName = getFilenameWithoutExtension(file.name);
        if (!this.model.Name || !this.projectNameUserChanged) {
          this.model.Name = fileName;
        }
      }
    },

    fileAccepted() {
      this.errors = [];
    },

    fileError(errors: string[]) {
      this.errors = errors;
    },

    cancelUpload() {
      if (this.uploading && this.canceler) {
        this.progress = null;
        this.uploading = false;
        this.uploadCanceled = true;
        this.canceler("Prototype file upload was canceled by user.");
      }
    },

    passwordValid(password: string): boolean {
      if (!this.passwordProtect) return true;
      return !!password && password.length >= 4;
    },
  },
});
