
import Vue from "vue";

import { SsoResponse } from "@shared/models";

import { FilesystemNodeInvitation, FilesystemNodeType } from "@/common/fs";
import { fsRouterLocation } from "@/router/fs";
import { UserProfile } from "@/services/models/account";
import { InvitationRespond } from "@/store/fs/actionTypes";

import AxButton from "@/components/AxButton.vue";
import AxDialog from "@/components/AxDialog.vue";
import AxForm from "@/components/AxForm.vue";
import AxUser from "@/components/AxUser.vue";

export default Vue.extend({
  components: {
    AxButton,
    AxDialog,
    AxUser,
    AxForm,
  },
  props: {
    workspaceId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      dialog: true,
      confirmed: true,
    };
  },

  computed: {
    loaded(): boolean {
      return this.invitation.type === FilesystemNodeType.Invitation;
    },

    invitation(): FilesystemNodeInvitation {
      return this.$store.getters.current.node;
    },

    sender(): SsoResponse {
      return this.invitation.sender;
    },

    senderProfile(): UserProfile {
      return {
        Name: this.sender.nickname,
        Bio: this.sender.bio,
        Img: this.sender.profileImageUrl,
      };
    },
  },

  methods: {
    async submit() {
      if (this.confirmed) {
        await this.accept();
      } else {
        await this.decline();
      }
    },

    respond(accept: boolean) {
      return this.$store.dispatch(new InvitationRespond(this.invitation.id, accept));
    },

    async decline() {
      await this.respond(false);
      // redirect to root
      await this.$router.push(fsRouterLocation(this.$store.getters.defaultWorkspace));
    },

    async accept() {
      const workspaceId = this.invitation.id;
      await this.respond(true);

      // trick router that redirect is happening into workspace
      const workspace = {
        id: workspaceId,
        type: FilesystemNodeType.Workspace,
      };
      await this.$router.push(fsRouterLocation(workspace, { refresh: { workspaces: true } }));
    },
  },
});
