
import Vue from "vue";

import { SsoResponse, UserProfileJs } from "@shared/models";

import { getUserName } from "@/common/axshare";
import { trimEmailDomain } from "@/common/lib";

import { prop } from "./utils";

import AxUserAvatarDefault from "@/components/AxUserAvatarDefault.vue";

// eslint-disable-next-line import/order
const defaultIcon = require("@/assets/defaultProfile.png");

export default Vue.extend({
  components: {
    AxUserAvatarDefault,
  },

  props: {
    w: {
      type: String,
      default: "24px",
    },

    h: {
      type: String,
      default: "24px",
    },

    profileImg: {
      type: String,
      default: "",
    },

    hideTitle: {
      type: Boolean,
      default: false,
    },

    forceDefault: {
      type: Boolean,
      default: false,
    },

    user: prop<SsoResponse | UserProfileJs>({
      type: Object,
      required: false,
      default: null,
    }),
  },

  computed: {
    style(): Partial<CSSStyleDeclaration> {
      return {
        width: this.w,
        height: this.h,
        fontSize: this.isDefaultAvatar ? `${this.h}` : "unset",
      };
    },

    profileImgResolved(): string {
      if (this.profileImg) return this.profileImg;
      if (this.user) {
        if (isSsoResponse(this.user)) {
          if (this.user.profileImageUrl) return this.user.profileImageUrl;
        } else if (this.user.profileImg) return this.user.profileImg;
      }
      return defaultIcon;
    },

    isDefaultAvatar(): boolean {
      return this.forceDefault || (!!this.user && this.profileImgResolved === defaultIcon);
    },

    title(): string {
      const userInfo = this.user;
      if (!userInfo) return "";
      const name = getUserName(userInfo);
      return name || userInfo.userEmail;
    },

    emailName(): string {
      return trimEmailDomain(this.user.userEmail);
    },
  },
});

function isSsoResponse(user: UserProfileJs | SsoResponse): user is SsoResponse {
  return (user as any).profileImageUrl !== undefined;
}

