export function stripProtocol(url: string) {
  return url.replace(/(^\w+:|^)\/\//, "");
}

export function isRelativeUrl(url: string) {
  return url.indexOf("://") < 0 && url.indexOf("//") !== 0;
}

export const updateQueryStringParam = (url: string, key: string, value: string) => {
  const [baseUrl, urlQueryString] = url.split("?");
  const newParam = `${key}=${value}`;
  let params = `?${newParam}`;

  // If the "search" string exists, then build params from it
  if (urlQueryString) {
    const updateRegex = new RegExp(`([?&])${key}[^&]*`);
    const removeRegex = new RegExp(`([?&])${key}=[^&;]+[&;]?`);

    if (typeof value === "undefined" || value === null || value === "") {
      // Remove param if value is empty
      params = urlQueryString.replace(removeRegex, "$1");
      params = params.replace(/[&;]$/, "");
    } else if (urlQueryString.match(updateRegex) !== null) {
      // If param exists already, update it
      params = urlQueryString.replace(updateRegex, `$1${newParam}`);
    } else {
      // Otherwise, add it to end of query string
      params = `?${urlQueryString}&${newParam}`;
    }
  }

  // no parameter was set so we don't need the question mark
  params = params === "?" ? "" : params;

  return baseUrl + params;
};

export function getFileExtension(fileName: string) {
  return fileName.split(".").pop();
}

export function getFilenameWithoutExtension(fileName: string) {
  return fileName.substring(0, fileName.lastIndexOf("."));
}

export function equalsIgnoringCase(s1?: string, s2?: string, useLocale = true) {
  if (s1 == null || s2 == null) return false;

  if (useLocale) {
    return s1.localeCompare(s2, "en", { sensitivity: "base" }) === 0;
  }

  if (s1.length !== s2.length) return false;
  return s1.toLowerCase() === s2.toLowerCase();
}

export function dashify(str: string) {
  return str
    .trim()
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/\W/g, m => (/[À-ž]/.test(m) ? m : "-"))
    .replace(/^-+|-+$/g, "")
    .toLowerCase();
}

export function toKebabCase(str: string) {
  const KEBAB_REGEX = /[A-Z\u00C0-\u00D6\u00D8-\u00DE]/g;
  return str.replace(KEBAB_REGEX, match => `-${match.toLowerCase()}`);
}

export function removeDuplicateForwardSlashes(str: string) {
  return str.replace(/(^\/|[^:]\/)\/+/g, "$1");
}

export function isHttps(str: string) {
  return str.toLowerCase().startsWith("https://");
}

export function isHttp(str: string) {
  return str.toLowerCase().startsWith("http://");
}

export function isNullOrEmpty(str: string | undefined | null) {
  return str === undefined || str === null || str.trim() === "";
}

export function parseBool(str: string) {
  if (isNullOrEmpty(str)) {
    return false;
  }

  return str.trim().toLowerCase() === "true";
}

// Remove dashes in string (mainly used for guids)
export function removeDashes(s: string): string {
  return s.replace(/-/g, "");
}

// Empty Guid in string format
export const EmptyGuid = "00000000-0000-0000-0000-000000000000";

const lut: string[] = [];
for (let i = 0; i < 256; i++) {
  lut[i] = (i < 16 ? "0" : "") + i.toString(16);
}

// Pseudo-generate a guid via js
export function guid(includeDashes?: boolean, isSecure?: boolean): string {
  const rVals = new Uint32Array(4);
  const hasCrypto = isSecure && window.crypto != null && window.crypto.getRandomValues != null;

  if (hasCrypto) {
    window.crypto.getRandomValues(rVals);
  }

  /* eslint-disable no-bitwise */
  const d0 = hasCrypto ? (rVals[0] * 0x100000000) >>> 0 : (Math.random() * 0xffffffff) | 0;
  const d1 = hasCrypto ? (rVals[1] * 0x100000000) >>> 0 : (Math.random() * 0xffffffff) | 0;
  const d2 = hasCrypto ? (rVals[2] * 0x100000000) >>> 0 : (Math.random() * 0xffffffff) | 0;
  const d3 = hasCrypto ? (rVals[3] * 0x100000000) >>> 0 : (Math.random() * 0xffffffff) | 0;

  return (
    lut[d0 & 0xff]
    + lut[(d0 >> 8) & 0xff]
    + lut[(d0 >> 16) & 0xff]
    + lut[(d0 >> 24) & 0xff]
    + (includeDashes ? "-" : "")
    + lut[d1 & 0xff]
    + lut[(d1 >> 8) & 0xff]
    + (includeDashes ? "-" : "")
    + lut[((d1 >> 16) & 0x0f) | 0x40]
    + lut[(d1 >> 24) & 0xff]
    + (includeDashes ? "-" : "")
    + lut[(d2 & 0x3f) | 0x80]
    + lut[(d2 >> 8) & 0xff]
    + (includeDashes ? "-" : "")
    + lut[(d2 >> 16) & 0xff]
    + lut[(d2 >> 24) & 0xff]
    + lut[d3 & 0xff]
    + lut[(d3 >> 8) & 0xff]
    + lut[(d3 >> 16) & 0xff]
    + lut[(d3 >> 24) & 0xff]
  );
  /* eslint-enable no-bitwise */
}

export function removeParameterFromHash(hash: string, parameter: string) {
  if (hash.length === 0) return "";
  const prms = hash.replace("#", "").split("&");
  const frmElements = [];
  for (let i = 0; i < prms.length; i++) {
    const current = prms[i].split("=");
    if (current[0] === parameter) continue;
    frmElements.push(prms[i]);
  }
  const fragment = frmElements.join("&");
  return fragment.length > 0 ? `#${fragment}` : "";
}

export function getQueryVarString(href: string, query: string) {
  const qstring = href.split("?");
  if (qstring.length < 2) return "";
  return getParameter(qstring, query);
}

export function getHashVarString(href: string, query: string) {
  const qstring = href.split("#");
  if (qstring.length < 2) return "";
  return getParameter(qstring, query);
}

function getParameter(qstring: string[], query: string) {
  const prms = qstring[1].split("&");
  const frmelements: string[][] = [];
  let current;
  let querystr = "";

  for (let i = 0; i < prms.length; i++) {
    current = prms[i].split("=");
    frmelements[i] = [];
    frmelements[i][0] = current[0];
    frmelements[i][1] = current[1];
  }
  for (const frmelement of frmelements) {
    if (frmelement[0].toLowerCase() === query.toLowerCase()) {
      querystr = frmelement[1];
      break;
    }
  }

  return querystr;
}

export function uuid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

export function uuidDashify(str: string) {
  return `${str.substr(0, 8)}-${str.substr(8, 4)}-${str.substr(12, 4)}-${str.substr(16, 4)}-${str.substr(20)}`;
}

export const makeId = (length: number) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const multiSplit = (str: string, separators: string[], removeEmptyEntries = false): string[] => {
  let value = str;
  if (separators.length === 0) return str.split("");

  const tempChar = separators[0];
  for (let i = 1; i < separators.length; i++) {
    value = value.split(separators[i]).join(tempChar);
  }
  const result = value.split(tempChar);
  if (removeEmptyEntries) {
    return result.filter(Boolean);
  }
  return result;
};

export const pluralize = (singular: string, plural: string, count: number) => (count === 1 ? singular : plural);

export function trimEmailDomain(email: string) {
  if (!email || email.length === 1) return email;

  // Strip everything after and including the '@'
  const atSymbolIndex = email.indexOf("@");
  if (atSymbolIndex !== -1) {
    return email.substring(0, atSymbolIndex);
  }
  return email;
}

export function tryDecodeURIComponent(str: string) {
  try {
    return decodeURIComponent(str);
  } catch {
    return str;
  }
}
