import Vue from "vue";

import { Artboard, Asset } from "@shared/expo";

import { EmptyGuid, sort } from "@/common/lib";
import { normalizeHotspotMeasurements } from "@/common/utils";
import { ExpoProjectNode, ExpoStore, NewExpoProjectStore } from "@/store/expo/state";
import { MutationTree } from "@/store/typed";

import { MutationPayloadMap, MutationTypes } from "./mutationTypes";

const mutationsTree: MutationTree<ExpoStore, MutationPayloadMap> = {
  [MutationTypes.ProjectSetup](state, { setup }) {
    const shortcut = setup.shortcut.toLowerCase();

    let project = state.projects[shortcut];
    if (!project) {
      project = NewExpoProjectStore();
    }

    project = {
      ...project,
      shortcut,
      mode: setup.mode,
      platform: setup.platform,
      bgImgUrl: setup.bgImgUrl,
      archiveMode: setup.archiveMode,
      resourceFolder: setup.resourceFolder,
      resourceAxureFolder: setup.resourceAxureFolder,
      artboardsOrder: setup.artboardsOrder,
      hasArtboards: setup.hasArtboards,
      lastModifiedDate: setup.lastModified,
      lastSavedDate: setup.lastModified,
    };

    if (setup.platform) {
      project.projectDimensions = {
        height: setup.platform.Settings.Dimensions.Height,
        width: setup.platform.Settings.Dimensions.Width,
      };
    }

    Vue.set(state.projects, shortcut, project);
  },

  [MutationTypes.ProjectIsLoadingSet](state, { shortcut, value }) {
    const project = state.projects[shortcut];
    if (!project) return;

    project.isProjectLoaded = false;
    project.isProjectLoading = value;
  },

  [MutationTypes.ProjectContentClear](state, { shortcut }) {
    const project = state.projects[shortcut];
    if (!project) return;

    for (const artboardId in project.artboards) {
      if (Object.prototype.hasOwnProperty.call(project.artboards, artboardId)) {
        deleteArtboard(project, artboardId);

        project.lastModifiedDate = Date.now();
      }
    }
  },

  [MutationTypes.ProjectContentSet](state, { shortcut, content }) {
    const project = state.projects[shortcut];
    if (!project) return;

    for (const artboardId in content.artboards) {
      if (Object.prototype.hasOwnProperty.call(content.artboards, artboardId)) {
        const artboard = content.artboards[artboardId];

        if (artboard.IsDeleted) {
          continue;
        }

        const assetImgUrl = content.images[artboard.AssetId];
        const assetImgThumbnailUrl = content.thumbnails[artboard.AssetId];
        addArtboard(project, artboard, content.assets[artboard.AssetId], assetImgUrl, assetImgThumbnailUrl);

        const subAssets = artboard.SubAssets;

        for (const subAssetId in subAssets) {
          if (Object.prototype.hasOwnProperty.call(subAssets, subAssetId)) {
            const subAsset = subAssets[subAssetId];
            const subAssetImgUrl = content.images[subAssetId];
            const subAssetImgThumbnailUrl = content.thumbnails[subAssetId];

            addArtboardAsset(project, subAsset, subAssetImgUrl, subAssetImgThumbnailUrl);
          }
        }
      }
    }

    const addedArtboards = project.artboards;
    const artboardAdded = (artboardId: string) => addedArtboards[artboardId] !== undefined;
    project.artboardsOrder = content.project.ArtboardIds.filter(artboardAdded);
    project.projectDimensions = {
      height: content.project.Platform.Settings.Dimensions.Height,
      width: content.project.Platform.Settings.Dimensions.Width,
    };
    project.masters = content.masters;
    project.bgImgUrl = content.bgImgLink;
    project.platform = content.project.Platform;
    project.name = content.project.ProjectName;
    project.id = content.project.Id;
    project.modifiedOn = content.project.ModifiedOn;

    // Set state that project has loaded
    project.isProjectLoading = false;
    project.isProjectLoaded = true;
  },

  [MutationTypes.ProjectZoomSet](state, { shortcut, zoom }) {
    const project = state.projects[shortcut];
    if (!project) return;

    project.projectZoom = zoom;
  },

  [MutationTypes.ProjectPlatformUpdate](state, { shortcut, platform }) {
    const project = state.projects[shortcut];
    if (!project) return;

    project.platform = platform;
    project.projectDimensions = {
      height: platform.Settings.Dimensions.Height,
      width: platform.Settings.Dimensions.Width,
    };
    project.lastModifiedDate = Date.now();
  },

  [MutationTypes.ProjectPlatformBgImgUpload](state, { shortcut, response }) {
    const project = state.projects[shortcut];
    if (!project) return;

    project.platform = response.platform;
    project.bgImgUrl = response.url;
    project.lastModifiedDate = Date.now();
  },

  [MutationTypes.ProjectPlatformBgImgDelete](state, { shortcut, response }) {
    const project = state.projects[shortcut];
    if (!project) return;

    project.platform = response.platform;
    project.bgImgUrl = "";
    project.lastModifiedDate = Date.now();
  },

  [MutationTypes.ArtboardAdd](state, { shortcut, artboard, asset, url }) {
    const project = state.projects[shortcut];
    if (!project) return;

    addArtboard(project, artboard, asset, url);
    project.lastModifiedDate = Date.now();
  },

  [MutationTypes.ArtboardRemove](state, { shortcut, artboardId }) {
    const project = state.projects[shortcut];
    if (!project) return;

    deleteArtboard(project, artboardId);
    project.lastModifiedDate = Date.now();
  },

  [MutationTypes.ArtboardActionAdd](state, { shortcut, artboardId, action }) {
    const project = state.projects[shortcut];
    if (!project) return;

    const artboard = project.artboards[artboardId];
    if (artboard) {
      Vue.set(artboard.ActionPool.Actions, action.Id, action);
      project.lastModifiedDate = Date.now();
    }
  },

  [MutationTypes.ArtboardActionUpdate](state, { shortcut, artboardId, actionId, originalAction, action }) {
    const project = state.projects[shortcut];
    if (!project) return;

    const artboard = project.artboards[artboardId];
    if (artboard) {
      // master -> ab
      if (originalAction.ParentId !== action.ParentId && originalAction.IsMaster) {
        const master = project.masters[originalAction.ParentId];
        if (master) {
          Vue.delete(master.Actions, actionId);
        }
      } else {
        // ab -> SAME ab
        Vue.delete(artboard.ActionPool.Actions, actionId);
      }

      // Add new action back into artboard
      Vue.set(artboard.ActionPool.Actions, action.Id, action);
      project.lastModifiedDate = Date.now();
    }
  },

  [MutationTypes.ArtboardActionRemove](state, { shortcut, artboard, action }) {
    const project = state.projects[shortcut];
    if (!project) return;

    const stateArtboard = project.artboards[artboard.Id];
    if (stateArtboard) {
      Vue.delete(artboard.ActionPool.Actions, action.Id);
      project.lastModifiedDate = Date.now();
    }
  },

  [MutationTypes.ArtboardActionHotspotPositionSet](state, { shortcut, artboardId, actionId, position }) {
    const project = state.projects[shortcut];
    if (!project) return;

    const artboard = project.artboards[artboardId];
    if (artboard) {
      const action = artboard.ActionPool.Actions[actionId];
      if (action) {
        action.Hotspot.Dimensions.Height = position.height;
        action.Hotspot.Dimensions.Width = position.width;
        action.Hotspot.Start.X = position.x;
        action.Hotspot.Start.Y = position.y;
        action.Hotspot = normalizeHotspotMeasurements(action.Hotspot);

        project.lastModifiedDate = Date.now();
      }
    }
  },

  // [MutationTypes.MasterActionAdd](state, { masterId, action }) {
  //   const master = state.masters[masterId];
  //   if (master) {
  //     Vue.set(master.Actions, action.Id, action);
  //   }
  // },

  [MutationTypes.MasterActionUpdate](state, { shortcut, artboardId, masterId, actionId, originalAction, action }) {
    const project = state.projects[shortcut];
    if (!project) return;

    const master = project.masters[masterId];
    if (master) {
      // For new action -> Remove the temporary action from the artboard
      if (artboardId) {
        const artboard = project.artboards[artboardId];
        if (artboard) {
          Vue.delete(artboard.ActionPool.Actions, actionId);
        }
      }

      if (originalAction.ParentId !== action.ParentId) {
        if (originalAction.IsMaster) {
          // master -> diff master
          const originalMaster = project.masters[originalAction.ParentId];

          if (originalMaster) {
            Vue.delete(originalMaster.Actions, actionId);
          }
        } else {
          // artboard -> master
          const originalAb = project.artboards[originalAction.ParentId];

          if (originalAb) {
            Vue.delete(originalAb.ActionPool.Actions, actionId);
          }
        }
      } else {
        // master -> SAME master
        Vue.delete(master.Actions, actionId);
      }

      // Add new action back into master
      Vue.set(master.Actions, action.Id, action);
      project.lastModifiedDate = Date.now();
    }
  },

  [MutationTypes.MasterActionRemove](state, { shortcut, master, action }) {
    const project = state.projects[shortcut];
    if (!project) return;

    const stateMaster = project.masters[master.Id];
    if (stateMaster) {
      Vue.delete(master.Actions, action.Id);
      project.lastModifiedDate = Date.now();
    }
  },

  [MutationTypes.MasterActionHotspotPositionSet](state, { shortcut, masterId, actionId, position, relativeToBottom }) {
    const project = state.projects[shortcut];
    if (!project) return;

    const master = project.masters[masterId];
    if (master) {
      const action = master.Actions[actionId];
      if (action) {
        action.Hotspot.Dimensions.Height = position.height;
        action.Hotspot.Dimensions.Width = position.width;
        action.Hotspot.Start.X = position.x;
        action.Hotspot.Start.Y = position.y;

        action.Hotspot = normalizeHotspotMeasurements(action.Hotspot);
        let relativeToBottomResolved;
        if (relativeToBottom === undefined) {
          relativeToBottomResolved = undefined;
        } else if (relativeToBottom % 1 < 0.5) {
          relativeToBottomResolved = Math.floor(relativeToBottom);
        } else {
          relativeToBottomResolved = Math.round(relativeToBottom);
        }
        action.RelativeToBottom = relativeToBottomResolved;

        project.lastModifiedDate = Date.now();
      }
    }
  },

  [MutationTypes.ProjectMasterCreate](state, { shortcut, master, artboard, callback }) {
    const project = state.projects[shortcut];
    if (!project) return;

    const masters = project.masters;
    if (masters && master) {
      // Add new master to state and append id to ab's master list
      Vue.set(masters, master.Id, master);
      project.artboards[artboard.Id].MasterIds.push(master.Id);

      // if (window.$axExpo) {
      //   window.$axExpo.masterMap[master.Id] = master;
      // }

      callback(master);
      // state.lastModifiedDate = Date.now();
    }
  },

  [MutationTypes.ProjectMasterRename](state, { shortcut, master, name }) {
    const project = state.projects[shortcut];
    if (!project) return;

    const masters = project.masters;
    if (masters && master) {
      Vue.set(masters[master.Id], "Name", name);

      // if (window.$axExpo) {
      //   window.$axExpo.masterMap[master.Id].Name = name;
      // }

      project.lastModifiedDate = Date.now();
    }
  },

  [MutationTypes.ProjectMasterCopy](state, { shortcut, master, artboard, callback }) {
    const project = state.projects[shortcut];
    if (!project) return;

    const masters = project.masters;
    if (masters && master) {
      Vue.set(masters, master.Id, master);
      project.artboards[artboard.Id].MasterIds.push(master.Id);

      // if (window.$axExpo) {
      //   window.$axExpo.masterMap[master.Id] = master;
      // }

      callback(master);
      project.lastModifiedDate = Date.now();
    }
  },

  [MutationTypes.ProjectMasterDelete](state, { shortcut, master }) {
    const project = state.projects[shortcut];
    if (!project) return;

    const masters = project.masters;
    const artboards = project.artboards;
    if (masters && master) {
      Vue.delete(masters, master.Id);

      // if (window.$axExpo) {
      //   delete window.$axExpo.masterMap[master.Id];
      // }

      for (const abId in artboards) {
        if (Object.prototype.hasOwnProperty.call(artboards, abId)) {
          // Remove master id from ab's master list
          artboards[abId].MasterIds = project.artboards[abId].MasterIds.filter(value => {
            return value !== master.Id;
          });
        }
      }

      project.lastModifiedDate = Date.now();
    }
  },

  [MutationTypes.ProjectUpdateLastModifiedDate](state, { shortcut }) {
    const project = state.projects[shortcut];
    if (!project) return;

    project.lastModifiedDate = Date.now();
  },

  [MutationTypes.ProjectUpdateLastSavedDate](state, { shortcut }) {
    const project = state.projects[shortcut];
    if (!project) return;

    project.lastSavedDate = Date.now();
  },

  [MutationTypes.ArtboardSelect](state, { shortcut, artboardId }) {
    const project = state.projects[shortcut];
    if (!project) return;

    selectArtboard(project, artboardId);
  },

  [MutationTypes.ArtboardSelectAll](state, { shortcut }) {
    const project = state.projects[shortcut];
    if (!project) return;

    selectAllArtboards(project);
  },

  [MutationTypes.ArtboardDeselect](state, { shortcut, artboardId }) {
    const project = state.projects[shortcut];
    if (!project) return;

    deselectArtboard(project, artboardId);
  },

  [MutationTypes.ArtboardDeselectAll](state, { shortcut }) {
    const project = state.projects[shortcut];
    if (!project) return;

    deselectAllArtboards(project);
  },

  [MutationTypes.ArtboardSelectToggle](state, { shortcut, artboardId }) {
    const project = state.projects[shortcut];
    if (!project) return;

    toggleArtboardSelection(project, artboardId);
  },

  [MutationTypes.ArtboardSelectSingle](state, { shortcut, artboardId }) {
    const project = state.projects[shortcut];
    if (!project) return;

    project.selectedArtboards = {};
    selectArtboard(project, artboardId);
  },

  [MutationTypes.ArtboardSelectSpan](state, { shortcut, artboardId, extend }) {
    const project = state.projects[shortcut];
    if (!project) return;

    const lastSelected = project.lastSelectedArtboard;
    if (!lastSelected) {
      selectArtboard(project, artboardId);
      return;
    }
    const indexOfCurrentSelected = project.artboardsOrder.findIndex(abOrdered => abOrdered === artboardId);
    const indexOfLastSelected = project.artboardsOrder.findIndex(abOrdered => abOrdered === lastSelected);

    if (indexOfLastSelected >= 0 && indexOfCurrentSelected >= 0) {
      if (!extend) {
        project.selectedArtboards = {};
      }

      const [start, end] = sort([indexOfCurrentSelected, indexOfLastSelected]);
      const artboardsToToggle = project.artboardsOrder.slice(start, end + 1);
      for (const artboardToToggle of artboardsToToggle) {
        selectArtboard(project, artboardToToggle, false);
      }
    }
  },

  [MutationTypes.ArtboardOrderUpdated](state, { shortcut, artboardIds }) {
    const project = state.projects[shortcut];
    if (!project) return;

    artboardIds.forEach((id, index) => {
      const artboard = project.artboards[id];
      // it might happen that full artboard state hasn't been loaded yet
      // so we just skip those artboards
      if (artboard) {
        artboard.NextArtboardId = index + 1 < artboardIds.length ? artboardIds[index + 1] : EmptyGuid;
      }
    });

    project.artboardsOrder = artboardIds;
    project.lastModifiedDate = Date.now();
  },

  // Verify that this shouldn't mess with other hotspots
  [MutationTypes.ArtboardActionMenuToggle](state, { shortcut, artboardId, actionId, isOpened }) {
    const project = state.projects[shortcut];
    if (!project) return;

    project.artboardHotspotOpenedMenu = isOpened
      ? {
          artboardId,
          actionId,
        }
      : {
          artboardId: "",
          actionId: "",
        };
  },

  [MutationTypes.ArtboardSetHeaderFooterHeight](state, { shortcut, artboard, height, isHeader }) {
    const project = state.projects[shortcut];
    if (!project) return;

    if (isHeader) {
      project.artboards[artboard.Id].HeaderHeight = height;
      project.artboards[artboard.Id].HasHeader = height !== 0;
    } else {
      project.artboards[artboard.Id].FooterHeight = height;
      project.artboards[artboard.Id].HasFooter = height !== 0;
    }

    project.lastModifiedDate = Date.now();
  },

  [MutationTypes.ArtboardAddMaster](state, { shortcut, artboardId, masterId }) {
    const project = state.projects[shortcut];
    if (!project) return;

    project.artboards[artboardId].MasterIds.push(masterId);
    project.lastModifiedDate = Date.now();
  },

  [MutationTypes.ArtboardRemoveMaster](state, { shortcut, artboardId, masterId }) {
    const project = state.projects[shortcut];
    if (!project) return;

    // if (window.$axExpo) {
    //   delete window.$axExpo.masterMap[masterId];
    // }

    // Remove master id from ab's master list
    project.artboards[artboardId].MasterIds = project.artboards[artboardId].MasterIds.filter(value => {
      return value !== masterId;
    });
    project.lastModifiedDate = Date.now();
  },
};

function toggleArtboardSelection(state: ExpoProjectNode, artboardId: string, rememberLastSelected = true) {
  if (state.selectedArtboards[artboardId]) {
    deselectArtboard(state, artboardId, rememberLastSelected);
  } else {
    selectArtboard(state, artboardId, rememberLastSelected);
  }
}

function deselectAllArtboards(state: ExpoProjectNode) {
  state.selectedArtboards = {};
}

function deselectArtboard(state: ExpoProjectNode, artboardId: string, rememberLastSelected = true) {
  Vue.delete(state.selectedArtboards, artboardId);
  if (rememberLastSelected) {
    state.lastSelectedArtboard = artboardId;
  }
}

function selectAllArtboards(state: ExpoProjectNode) {
  const currAbs = Object.keys(state.selectedArtboards);
  const missingAbs = Object.keys(state.artboards).filter(e => {
    return currAbs.indexOf(e) < 0;
  });

  missingAbs.forEach(element => {
    Vue.set(state.selectedArtboards, element, element);
  });
}

function selectArtboard(state: ExpoProjectNode, artboardId: string, rememberLastSelected = true) {
  Vue.set(state.selectedArtboards, artboardId, artboardId);
  if (rememberLastSelected) {
    state.lastSelectedArtboard = artboardId;
  }
}

function addArtboard(
  project: ExpoProjectNode,
  artboard: Artboard,
  asset: Asset,
  assetImageUrl: string,
  assetImgThumbnailUrl?: string
) {
  Vue.set(project.artboards, artboard.Id, artboard);
  Vue.set(project.assets, asset.Id, asset);
  addArtboardAsset(project, asset, assetImageUrl, assetImgThumbnailUrl);
  project.artboardsOrder.push(artboard.Id);
  // eslint-disable-next-line no-param-reassign
  project.hasArtboards = project.artboardsOrder.length > 0;
}

function addArtboardAsset(
  project: ExpoProjectNode,
  asset: Asset,
  assetImageUrl: string,
  assetImgThumbnailUrl?: string
) {
  Vue.set(project.assetsImages, asset.Id, assetImageUrl);
  Vue.set(project.assetsImagesThumbnails, asset.Id, assetImgThumbnailUrl);
}

// TODO: Do we want to use an Artboard? Keep track even after delete
function deleteArtboard(state: ExpoProjectNode, artboardId: string) {
  const artboard = state.artboards[artboardId];
  Vue.delete(state.artboards, artboardId);
  Vue.delete(state.selectedArtboards, artboardId);
  if (artboard && artboard.AssetId) {
    Vue.delete(state.assetsImages, artboard.AssetId);
  }

  state.artboardsOrder = state.artboardsOrder.filter(abOrderId => abOrderId !== artboardId);
  state.hasArtboards = state.artboardsOrder.length > 0;
}

export default mutationsTree;
