import { MutationPayloadMap, MutationTypes } from "@/store/desktop/mutationTypes";
import { MutationTree } from "../typed";
import { DesktopStore } from "./state";

const mutations: MutationTree<DesktopStore, MutationPayloadMap> = {
  [MutationTypes.SetDesktopParams](state, { desktopParams }) {
    if (desktopParams) {
      if (desktopParams.isMaximized !== undefined) {
        state.desktopParams.isMaximized = desktopParams.isMaximized;
      }
      if (desktopParams.isFullScreen !== undefined) {
        state.desktopParams.isFullScreen = desktopParams.isFullScreen;
      }
    }
  },
};

export default mutations;
