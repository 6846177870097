export function find<T>(obj: { [key: string]: T }, func: (obj: T) => boolean) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (func(obj[key])) return obj[key];
    }
  }
}

interface Sorter {
  value: SortingArg;
  descending?: boolean;
}
interface SorterNormalized {
  value1: any;
  value2: any;
  descending: boolean;
}

type SortingPrimitive = Date | number | string | boolean;
type SortingArg = Sorter | SortingPrimitive;
type SortingFn<T> = (item: T) => SortingArg;

export function sort<T extends number | string | Date>(items: T[], isDescending = false) {
  return isDescending ? orderByDescending(items, a => a) : orderBy(items, a => a);
}

export function orderByDescending<T>(items: T[], func: SortingFn<T>) {
  return orderBy(items, item => ({ value: func(item), descending: true }));
}

export function orderBy<T>(items: T[], ...funcs: Array<SortingFn<T>>) {
  return items.sort((a, b) => {
    let result = 0;
    for (const func of funcs) {
      const { value1, value2, descending } = normalizeSortingArgument(func, a, b);
      result = order(value1, value2, descending);
      if (result !== 0) break;
    }
    return result;
  });
}

function normalizeSortingArgument<T>(func: SortingFn<T>, a: T, b: T): SorterNormalized {
  const first = func(a);
  const second = func(b);
  let descending: boolean = false;
  let value1;
  let value2;
  if (isSortingObject(first) && isSortingObject(second)) {
    descending = first.descending || false;
    value1 = first.value;
    value2 = second.value;
  } else {
    value1 = first;
    value2 = second;
  }
  return { value1, value2, descending };
}

function isSortingObject(arg: SortingArg): arg is Sorter {
  return typeof arg === "object" && arg !== null && !(arg instanceof Date);
}

export function order(first: any, second: any, isDescending = false) {
  if (isDescending) {
    [first, second] = [second, first];
  }

  // Check if both are numbers
  if (!isNaN(first) && !isNaN(second)) {
    return first - second;
  }

  // Check if both cannot be evaluated
  if (first === null && second === null) {
    return 0;
  }

  [first, second] = [first, second].map(s => (s || "").toString().toLocaleLowerCase());

  if (first > second) return 1;
  if (first < second) return -1;

  return 0;
}

export function removeFirst<T>(array: T[], func: (item: T) => boolean) {
  return array.splice(array.findIndex(item => func(item)), 1);
}

export function removeAll<T>(array: T[], func: (item: T) => boolean) {
  const indexesToRemove = findIndexes(array, func);
  const removed: T[] = [];
  for (let i = indexesToRemove.length - 1; i >= 0; i--) {
    const indexToRemove = indexesToRemove[i];
    removed.push(...array.splice(indexToRemove, 1));
  }
  return removed;
}

export function findIndexes<T>(array: T[], func: (item: T) => boolean) {
  const initial: number[] = [];
  const indexes = array.reduce((a, e, i) => {
    if (func(e)) {
      a.push(i);
    }
    return a;
  }, initial);
  return indexes;
}

export function formatArray(a: any[]) {
  return [a.slice(0, -1).join(", "), a.slice(-1)[0]].join(a.length < 2 ? "" : " and ");
}

export function splitArray<T>(list: T[], chunkLength: number) {
  const chunks = [];
  let chunk = [];
  let i = 0;
  let l = 0;
  const n = list.length;
  while (i < n) {
    chunk.push(list[i]);
    l++;
    if (l === chunkLength) {
      chunks.push(chunk);
      chunk = [];
      l = 0;
    }
    i++;
  }
  if (chunk.length) {
    chunks.push(chunk);
  }
  return chunks;
}

export function flatten<T>(items: T[]): T[] {
  return items.reduce((a: T[], b: T | T[]) => a.concat(Array.isArray(b) ? flatten(b) : b), []);
}

export function arrayToMap<TKey extends keyof TVal, TVal extends Record<TKey, any>>(
  array: TVal[],
  keyField: ((item: TVal) => string | number) | keyof TVal
): Record<string | number, TVal> {
  return array.reduce(
    (acc, item) => {
      let keyValue: string | number;
      if (typeof keyField === "function") {
        keyValue = keyField(item);
      } else {
        keyValue = item[keyField];
      }

      // eslint-disable-next-line no-param-reassign
      acc[keyValue] = item;

      return acc;
    },
    {} as Record<string | number, TVal>
  );
}

export function distinct<T>(value: T, index: number, self: T[]) {
  return self.indexOf(value) === index;
}
