
import Vue from "vue";

import { FilesystemNodeWorkspace, WorkspaceRights } from "@/common/fs";
import { fsRouterLocation } from "@/router/fs";

import AxButton from "@/components/AxButton.vue";
import AxDialog from "@/components/AxDialog.vue";
import AxFormGroup from "@/components/AxFormGroup.vue";
import AxProjectInviteSettings from "@/components/AxProjectInviteSettings.vue";

export default Vue.extend({
  components: {
    AxDialog,
    AxProjectInviteSettings,
    AxButton,
    AxFormGroup,
  },

  props: {
    workspaceId: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      show: true,
    };
  },

  computed: {
    workspace(): FilesystemNodeWorkspace | undefined {
      return this.$store.getters.getFsNode(this.workspaceId);
    },

    rights(): WorkspaceRights | undefined {
      return this.workspace && this.$store.getters.workspaceRights(this.workspace);
    },
  },

  methods: {
    toggled(modalOpened: boolean) {
      if (!modalOpened) {
        this.cancel();
      }
    },

    cancel(refresh: boolean = false) {
      this.$router.push(fsRouterLocation(this.id, { refresh: { contents: refresh } }));
    },
  },
});
