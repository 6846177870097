import { render, staticRenderFns } from "./AxHeaderSearchResultLink.vue?vue&type=template&id=6275fa74&scoped=true&"
import script from "./AxHeaderSearchResultLink.vue?vue&type=script&lang=ts&"
export * from "./AxHeaderSearchResultLink.vue?vue&type=script&lang=ts&"
import style0 from "./AxHeaderSearchResultLink.vue?vue&type=style&index=0&id=6275fa74&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6275fa74",
  null
  
)

export default component.exports