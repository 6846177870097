
import Vue from "vue";
import { PropValidator } from "vue/types/options";

import {
  FilesystemNodeShortcut,
  isShortcutNode,
  ShortcutState,
  isShortcutSearchNode,
  NodeOptions,
  getNodeOptions,
} from "@/common/fs";
import { ROUTES } from "@/router";
import { ShortcutType } from "@/services/models";
import { FetchCurrent, NavigationRestore } from "@/store/fs/actionTypes";

import AxButton from "@/components/AxButton.vue";
import AxDropdownButton from "@/components/AxDropdownButton.vue";
import AxIcon from "@/components/AxIcon.vue";
import AxList from "@/components/AxList.vue";
import AxListItem from "@/components/AxListItem.vue";
import AxProjectFileDownload from "@/components/AxProjectFileDownload.vue";
import AxShortcutFilesystemActions, { ShortcutActionsOptions } from "@/components/AxShortcutFilesystemActions.vue";
import { FilesystemSearchItem } from "@/components/types/AxSearch";

interface Options extends NodeOptions {
  canClone: boolean;
  canRename: boolean;
  canDownload: boolean;
  canUpload: boolean;
}

const projectProp: PropValidator<FilesystemNodeShortcut | FilesystemSearchItem> = {
  type: Object,
  required: true,
  validator(item) {
    return isShortcutNode(item);
  },
};

export default Vue.extend({
  components: {
    AxDropdownButton,
    AxButton,
    AxIcon,
    AxList,
    AxListItem,
    AxProjectFileDownload,
    AxShortcutFilesystemActions,
  },

  props: {
    project: projectProp,
    refreshDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      moving: false,
      deleting: false,
      cloning: false,
      renaming: false,
      uploading: false,
    };
  },

  computed: {
    isUploadable(): boolean {
      const uploadableTypes = [ShortcutType.Rp, ShortcutType.Lib];
      let projectType: ShortcutType | undefined;
      if (isShortcutSearchNode(this.project)) {
        projectType = this.project.subtype;
      } else {
        projectType = this.project.shortcutType;
      }
      return this.project.viewOnly === false && uploadableTypes.some(type => projectType === type);
    },

    isDownloadable(): boolean {
      const downloadableTypes = [ShortcutType.Rp, ShortcutType.TeamRp, ShortcutType.Lib, ShortcutType.TeamLib];
      let projectType: ShortcutType | undefined;
      if (isShortcutSearchNode(this.project)) {
        projectType = this.project.subtype;
        // eslint-disable-next-line no-else-return
      } else {
        projectType = this.project.shortcutType;
      }
      return (
        this.project.viewOnly === false
        && downloadableTypes.some(type => projectType === type)
        && this.project.shortcutState === ShortcutState.Ok
      );
    },

    isCloneable(): boolean {
      if (!isShortcutSearchNode(this.project)) {
        const cloneableTypes = [ShortcutType.Rp, ShortcutType.Lib];
        const projectType = this.project.shortcutType;
        return cloneableTypes.some(type => projectType === type);
      }
      return false;
    },

    options(): Options {
      if (isShortcutSearchNode(this.project)) {
        let folderId = "";
        if (this.project.folderId) {
          folderId = this.project.folderId;
        }
        return {
          folderId,
          canMove: this.project.viewOnly === false,
          canDelete: this.project.viewOnly === false,
          canClone: this.isCloneable,
          canRename: this.project.viewOnly === false,
          canDownload: this.isDownloadable,
          canUpload: this.isUploadable,
        };
        // eslint-disable-next-line no-else-return
      } else {
        return {
          ...getNodeOptions(this.project.parent),
          canClone: this.project.viewOnly === false && this.isCloneable,
          canRename: this.project.viewOnly === false,
          canDownload: this.isDownloadable,
          canUpload: this.isUploadable,
        };
      }
    },

    shortcutActionsOptions(): ShortcutActionsOptions {
      return {
        move: {
          show: this.moving,
          submit: this.moveSubmit,
          cancel: this.moveCancel,
        },

        delete: {
          show: this.deleting,
          submit: this.deleteSubmit,
          cancel: this.deleteCancel,
        },

        clone: {
          show: this.cloning,
          submit: this.cloneSubmit,
          cancel: this.cloneCancel,
        },

        rename: {
          show: this.renaming,
          submit: this.renameSubmit,
          cancel: this.renameCancel,
        },

        upload: {
          show: this.uploading,
          submit: this.uploadSubmit,
          cancel: this.uploadCancel,
        },

        folderId: this.options.folderId,
        items: [this.project],
      };
    },
  },

  methods: {
    async lazyLoadNode() {
      if (!this.$store.getters.getFsNode(this.project.id)) {
        await this.$store.dispatch(new NavigationRestore(this.project.id, "shortcut"));
      }
    },

    async moveSubmit() {
      this.moving = false;
      this.$emit("modified");
      this.$emit("moved", this.project);
      if (!this.refreshDisabled) {
        await this.refresh();
      }
    },

    moveCancel() {
      this.moving = false;
    },

    async deleteSubmit() {
      this.deleting = false;
      this.$emit("modified");
      this.$emit("deleted", this.project);
    },

    deleteCancel() {
      this.deleting = false;
    },

    async cloneSubmit() {
      this.cloning = false;
      this.$emit("modified");
      this.$emit("cloned", this.project);
      if (!this.refreshDisabled) {
        await this.refresh();
      }
    },

    cloneCancel() {
      this.cloning = false;
    },

    async renameSubmit() {
      this.renaming = false;
      this.$emit("modified");
      this.$emit("renamed", this.project);
      if (!this.refreshDisabled) {
        await this.refresh();
      }
    },

    renameCancel() {
      this.renaming = false;
    },

    async uploadSubmit() {
      this.uploading = false;
      await this.$router.push({
        name: ROUTES["project.overview"].name,
        params: {
          shortcut: !isShortcutSearchNode(this.project) ? this.project.shortcut : this.project.id,
        },
      });
    },

    uploadCancel() {
      this.uploading = false;
    },

    async refresh() {
      await this.$store.dispatch(new FetchCurrent());
    },
  },
});
