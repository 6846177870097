import { LocalStorage, Storage } from "@/common/axshare/storage";
import { equalsIgnoringCase } from "@/common/lib";
import { VersionInfoResponse } from "@/generated/models";

export interface VersionInfoLastPolling {
  date: Date;
  value: VersionInfoResponse;
}

export interface LastVersion {
  version: string;
  releaseDate: number;
  acknowledgedAt?: number;
}

export class PersistentStorage {
  private apiVersionInfoKey = "api-version-info";
  private continueWithOldBrowserKey = "continue-with-old-browser";
  private desktopNotificationsEnabled = "desktop-notifications-enabled";
  private workspacesMigrationAcknowledged = "workspaces-migration-acknowledged";
  private lastVersionKey = "last-version";
  private store: Storage;

  constructor(store: Storage) {
    this.store = store;
  }

  public get(key: string): string | undefined {
    return this.store.get(key);
  }

  public set(key: string, value: string) {
    return this.store.set(key, value);
  }

  public getJson<T>(key: string): T | undefined {
    const value = this.get(key);
    if (value) {
      return JSON.parse(value) as T;
    }
  }

  public setJson<T>(key: string, value: T) {
    return this.set(key, JSON.stringify(value));
  }

  public getLastVersion(): LastVersion | undefined {
    return this.getJson<LastVersion>(this.lastVersionKey);
  }

  public setLastVersion(value: LastVersion): void {
    return this.setJson<LastVersion>(this.lastVersionKey, value);
  }

  public getApiVersionInfo(): VersionInfoLastPolling | undefined {
    return this.getJson<VersionInfoLastPolling>(this.apiVersionInfoKey);
  }

  public setApiVersionInfo(value: VersionInfoResponse): void {
    return this.setJson<VersionInfoLastPolling>(this.apiVersionInfoKey, { date: new Date(), value });
  }

  public getContinueWithOldBrowser(): boolean {
    return this.getBoolean(this.continueWithOldBrowserKey);
  }

  public setContinueWithOldBrowser(value: boolean) {
    this.setBoolean(this.continueWithOldBrowserKey, value);
  }

  public getDesktopNotificationsEnabled(): boolean | undefined {
    return this.getBoolean(this.desktopNotificationsEnabled);
  }

  public setDesktopNotificationsEnabled(value: boolean) {
    this.setBoolean(this.desktopNotificationsEnabled, value);
  }

  public get WorkspacesMigrationAcknowledged(): boolean {
    return this.getBoolean(this.workspacesMigrationAcknowledged);
  }

  public set WorkspacesMigrationAcknowledged(value: boolean) {
    this.setBoolean(this.workspacesMigrationAcknowledged, value);
  }

  private getBoolean(key: string) {
    const value = this.store.get(key);
    if (value !== undefined) {
      return equalsIgnoringCase(value, "true");
    }
    return false;
  }

  private setBoolean(key: string, value: boolean) {
    this.store.set(key, value.toString());
  }
}

const storage = new LocalStorage();
export const persistentStorage = new PersistentStorage(storage);
