import { order } from "./array";

export function deepCopy<T>(obj: T) {
  return JSON.parse(JSON.stringify(obj)) as T;
}

export function objectsSort<T>(items: T[], path: string, isDescending = false): T[] {
  return items.sort((a, b) => {
    const sortA = getObjectValueByPath(a, path);
    const sortB = getObjectValueByPath(b, path);

    return order(sortA, sortB, isDescending);
  });
}

export function getObjectValueByPath<T>(obj: T, path: string, fallback?: any): any {
  if (!path || path.constructor !== String) return fallback;
  path = path.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  path = path.replace(/^\./, ""); // strip a leading dot
  return getNestedValue(obj, path.split("."), fallback);
}

export function getNestedValue(obj: any, path: Array<string | number>, fallback?: any): any {
  const last = path.length - 1;

  if (last < 0) return obj === undefined ? fallback : obj;

  for (let i = 0; i < last; i++) {
    if (obj == null) {
      return fallback;
    }
    obj = obj[path[i]];
  }

  if (obj == null) return fallback;

  return obj[path[last]] === undefined ? fallback : obj[path[last]];
}

export const fromEntries = <T>(arr: Array<[string, T]>): { [key: string]: T } =>
  Object.assign({}, ...Array.from(arr, ([k, v]) => ({ [k]: v })));

function noopSelector<T, V>(el: T): V {
  return (el as unknown) as V;
}

export function filterByKeys<T, V>(obj: Record<string, T>, keys: string[], valueSelector: (el: T) => V = noopSelector) {
  const filteredKeys = Object.keys(obj).filter(key => keys.includes(key));
  const filtered: Record<string, V> = {};

  const mapped = filteredKeys.reduce((acc, current) => {
    acc[current] = valueSelector(obj[current]);
    return acc;
  }, filtered);

  return mapped;
}
