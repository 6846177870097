
import Vue from "vue";

import AxButton from "@/components/AxButton.vue";

export default Vue.extend({
  components: {
    AxButton,
  },

  methods: {
    reload() {
      window.location.reload();
    },
  },
});
