import { Action } from "@shared/expo";

// Determines if the action has been saved and committed already
export function isSavedAction(action: Action) {
  return !!action.Id && !!action.CreatedOn;
}

// Determines if an action belongs to a master or not
export function isMasterAction(action: Action) {
  return isSavedAction(action) && action.IsMaster;
}
