
import Vue from "vue";
import { PropValidator } from "vue/types/options";

import { PrototypeUrls } from "@/common/axshare";
import { FilesystemNodeShortcut, isShortcutNode } from "@/common/fs";

import AxButton from "@/components/AxButton.vue";
import AxCopyToClipboard from "@/components/AxCopyToClipboard.vue";
import AxIcon from "@/components/AxIcon.vue";
import AxShortcutLink from "@/components/AxShortcutLink.vue";
import AxTooltip from "@/components/AxTooltip.vue";

const projectProp: PropValidator<FilesystemNodeShortcut> = {
  type: Object,
  required: true,
  validator(item) {
    return isShortcutNode(item);
  },
};

const prototypeUrlsProp = {
  type: Object,
  required: false,
};

export default Vue.extend({
  components: {
    AxButton,
    AxCopyToClipboard,
    AxShortcutLink,
    AxIcon,
    AxTooltip,
  },

  props: {
    project: projectProp,
    prototypeUrls: prototypeUrlsProp,
    disabled: {
      type: Boolean,
      default: false,
    },

    full: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    urls(): PrototypeUrls {
      if (this.prototypeUrls) {
        return this.prototypeUrls;
      }
      return this.$store.getters.prototypeUrls(this.project);
    },

    urlFull(): string {
      return this.urls.full.toLowerCase();
    },

    urlDisplay(): string {
      return this.urls.display.toLowerCase();
    },

    shortcutId(): string {
      return this.project.shortcut || this.project.id;
    },

    linkDisabled(): boolean {
      return this.project.isUserOnly || this.disabled;
    },
  },
});
