
import Vue from "vue";
import { Location } from "vue-router";

import { AnalyticsClickEvents } from "@/common/axshare";
import { ROUTES } from "@/router";

import AxButton from "./AxButton.vue";
import AxButtonGroup from "./AxButtonGroup.vue";
import AxIcon from "./AxIcon.vue";

export default Vue.extend({
  components: {
    AxButton,
    AxButtonGroup,
    AxIcon,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      AnalyticsClickEvents,
    };
  },

  computed: {
    canCreate(): boolean {
      return !!this.id;
    },

    createFolderRoute(): Location {
      return {
        name: ROUTES["fs.node.createfolder"].name,
        params: { id: this.id },
      };
    },

    createProjectRoute(): Location {
      return {
        name: ROUTES["fs.node.createproject"].name,
        params: { id: this.id },
      };
    },
  },
});
