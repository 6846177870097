
import Vue from "vue";

const AxReply = () => import("@/components/standalone/feedback/AxReply.vue");

export default Vue.extend({
  components: {
    AxReply,
  },

  props: {
    value: {
      type: [Object, String],
      required: false,
      default: "",
    },

    shortcut: {
      type: String,
      required: false,
      default: "",
    },

    autofocus: {
      type: Boolean,
      default: false,
    },

    workspaceId: {
      type: String,
      required: false,
      default: "",
    },

    folderId: {
      type: String,
      required: false,
      default: "",
    },

    userId: {
      type: String,
      required: true,
    },

    userToken: {
      type: [Function, String],
      default: "",
    },

    placeholder: {
      type: String,
      default: "Let your team know what's changed",
    },
  },
});
