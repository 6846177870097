
import { Canceler } from "axios";
import Vue from "vue";

import { enterpriseLicenseCheckFailed } from "@/common/axshare/messages";
import { RichText } from "@/generated/models";
import { ChangeProjectInfo } from "@/services/models/project";
import { changeProjectInfo } from "@/services/project.service";
import { AxShare } from "@/store/state";

import AxButton from "@/components/AxButton.vue";
import AxDialog from "@/components/AxDialog.vue";
import AxForm from "@/components/AxForm.vue";
import AxFormGroup from "@/components/AxFormGroup.vue";
import AxProjectFilePicker from "@/components/AxProjectFilePicker.vue";
import { Progress } from "@/components/AxProjectFileUploadIndicator.vue";
import AxPublishNotes from "@/components/AxPublishNotes.vue";

export default Vue.extend({
  components: {
    AxButton,
    AxDialog,
    AxForm,
    AxFormGroup,
    AxProjectFilePicker,
    AxPublishNotes,
  },

  props: {
    shortcut: {
      type: String,
      required: true,
    },

    value: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      uploadDestroyed: false,
      file: null,
      uploading: false,
      uploadCanceled: false,
      errors: [] as string[],
      canceler: null as Canceler | null,
      progress: null as Progress | null,
      publishNote: null as RichText | null,
    };
  },

  computed: {
    show: {
      get(): boolean {
        return this.value;
      },

      set(value: boolean) {
        this.$emit("input", value);
      },
    },

    currentUserId(): string {
      const { user } = this.$store.state as AxShare;
      if (user.userInfo) {
        return user.userInfo.userId;
      }
      return "";
    },

    currentUserToken(): string {
      const { accountService } = this.$store.state;
      if (accountService) {
        return accountService().AuthToken;
      }
      return "";
    },
  },

  watch: {
    value(val) {
      if (val) {
        this.uploadDestroyed = false;
      }
    },
  },

  methods: {
    uploadDestroy() {
      this.clearUpload();
      this.file = null;
      this.uploadDestroyed = true;
      this.$emit("after-leave");
    },

    async submit() {
      if (this.file) {
        if (this.$store.getters.enterpriseLicenseCheckFailed) {
          this.errors = [enterpriseLicenseCheckFailed];
          return;
        }

        this.uploading = true;
        this.uploadCanceled = false;
        this.progress = {
          current: 0,
          total: 0,
          lengthComputable: false,
        };

        const prototypeInfo: Partial<ChangeProjectInfo> = {
          fileData: this.file,
        };

        if (this.publishNote) {
          prototypeInfo.PublishNoteRichText = JSON.stringify(this.publishNote);
        }

        try {
          await changeProjectInfo(
            this.shortcut,
            prototypeInfo,
            canceler => { this.canceler = canceler; },
            progress => {
              if (!this.uploadCanceled) {
                this.progress = {
                  current: progress.loaded,
                  total: progress.total ?? 0,
                  lengthComputable: !!(progress.total),
                };
              }
            },
          );
        } finally {
          this.clearUpload();
        }
        this.$emit("upload");
      }
    },

    fileAccepted() {
      this.errors = [];
    },

    fileError(errors: string[]) {
      this.errors = errors;
    },

    clearUpload() {
      this.progress = null;
      this.uploading = false;
    },

    cancelUpload() {
      if (this.uploading && this.canceler) {
        this.clearUpload();
        this.uploadCanceled = true;
        this.canceler("Prototype file upload was canceled by user.");
      }
    },
  },
});
