import Router, { NavigationGuard, Route, RouteMeta } from "vue-router";

import { resolveRedirect, redirectParamName, wellKnownRoutes } from "@/common/axshare";
import { ROUTES } from "@/router";
import {
  configureRoutes, RouteStatus, routeStatus, setRouteStatus,
} from "@/router/init";
import setupRouter from "@/router/setup";
import store from "@/store";
import { Initialize, ApiCall, ApiCallOptions } from "@/store/actionTypes";

export const init = (router: Router) => {
  router.beforeEach(async (to, _from, next) => {
    const initImpl = async () => {
      // give a chance for routes to finish initialization
      if (routeStatus === RouteStatus.Ok && to.name === ROUTES.notFound.name) return next();

      await store.dispatch(new Initialize());
      await configureRoutes(store.state.axShareConfig);
      if (store.state.initializationStatus === "failure") {
        return next(false);
      }

      const { axShareConfig } = store.state;
      const isOnPrem = !!axShareConfig && axShareConfig.AxShareOnPrem === true;
      const appConfigured: boolean = store.getters.appConfigured;
      const toOnPremSetupRoute = to.name !== setupRouter.routes.setup.name && !appConfigured && isOnPrem;
      if (toOnPremSetupRoute) {
        return next(setupRouter.routes.setup);
      }

      if (routeStatus === RouteStatus.Reconfigured) {
        setRouteStatus(RouteStatus.Ok);

        // trying to re-resolve new next() if routes were changed
        const resolvedRoute = router.resolve({ path: to.path });
        return next({
          path: resolvedRoute.location.path,
          params: to.params,
          query: to.query,
          hash: to.hash,
        });
      }
      return next();
    };

    const onFailure = (error?: Error | unknown) => {
      if (store && store.state) {
        const { initializationStatus } = store.state;
        if (initializationStatus === "failure" || initializationStatus === null) {
          return next();
        }
      }
      return next(error as any);
    };

    const options: ApiCallOptions = {
      action: initImpl,
      onFailure,
    };
    await store.dispatch(new ApiCall(options));
  });
};

export const auth = (router: Router) => {
  router.beforeEach(async (to, _from, next) => {
    try {
      if (!(await routeRequiresAuth(to))) {
        return next();
      }
      const { accountService } = store.state;
      if (!accountService) {
        if (store.state.initializationStatus === "failure") {
          return next();
        }
        return next(false);
      }

      const isAuthed = accountService().IsAuthed;
      if (isAuthed) {
        const redirect = resolveRedirect(to);
        if (!redirect) {
          // window.location.href change, don't call next() and let browser do navigation
          return;
        }
        if (to !== redirect) {
          return next(redirect);
        }
        return next();
      }
      return redirectToLoginPage(to, _from, next);
    } catch (error: any) {
      return next(error);
    }
  });
};

type RouteRequiresAuth = (() => Promise<boolean> | boolean) | boolean | undefined;

function isRouteRequiresAuthMeta(meta: RouteMeta): meta is { requiresAuth: RouteRequiresAuth } {
  return meta.requiresAuth !== undefined;
}

const redirectToLoginPage: NavigationGuard = (to, _from, next) => next({
  name: wellKnownRoutes.login.name,
  query: {
    [redirectParamName]: to.query[redirectParamName] || to.fullPath.split("#")[0],
  },
  hash: to.hash,
});

async function routeRequiresAuth(to: Route): Promise<boolean> {
  if (!to.meta) return true;

  if (!isRouteRequiresAuthMeta(to.meta)) return true;

  const { requiresAuth }: { requiresAuth: RouteRequiresAuth } = to.meta;
  if (requiresAuth === undefined) return true;
  if (typeof requiresAuth === "function") return requiresAuth();
  return requiresAuth;
}
