import {
  FilesystemNodeDeletable,
  FilesystemNodeTypes,
  FilesystemNodeShortcut,
  FilesystemNodeWorkspace,
} from "@/common/fs";
import { RenameWorkspaceInfo, ManageUsersInviteModel, FolderCreateModel } from "@/services/models/filesystem";
import { ChangeProjectInfo } from "@/services/models/project";
import { Payload, PayloadMap } from "@/store/typed";

export enum ActionTypes {
  Navigate = "[Filesystem] Navigate",
  Fetch = "[Filesystem] Fetch",
  NavigationRestore = "[Filesystem] Navigation Restore",
  FolderCreate = "[Filesystem] Create Folder",
  FolderRename = "[Filesystem] Rename Folder",
  WorkspacesLoad = "[Filesystem] Workspaces Load",
  WorkspacesRefresh = "[Filesystem] Workspaces Refresh",
  ProjectLoad = "[Filesystem] Project Load",
  ProjectRename = "[Filesystem] Project Rename",
  ProjectUpdate = "[Filesystem] Project Update",
  ProjectToggleFeedback = "[Filesystem] Project Toggle Feedback",
  ProjectToggleUserOnly = "[Filesystem] Project Toggle UserOnly",
  ProjectShare = "[Filesystem] Project Share",
  WorkspaceRename = "[Filesystem] Workspace Rename",
  WorkspaceArchive = "[Filesystem] Workspace Archive",
  FavoriteWorkspace = "[Filesystem] Favorite Workspace",
  InvitationRespond = "[Filesystem] Invitation Respond",
  NodeRemove = "[Filesystem] Nodes Delete",
  UpdateFilesystemOwner = "[Filesystem] Update Filesystem Owner",
  FetchCurrent = "[Filesystem] FetchCurrent",
  FirstPageLoad = "[Filesystem] First Page Load",
}

export class Navigate implements Payload {
  public readonly type = ActionTypes.Navigate;
  constructor(public node: FilesystemNodeTypes) { }
}

export class Fetch implements Payload {
  public readonly type = ActionTypes.Fetch;
  constructor(public node: FilesystemNodeTypes) { }
}

export class FetchCurrent implements Payload {
  public readonly type = ActionTypes.FetchCurrent;
}

export class NavigationRestore implements Payload {
  public readonly type = ActionTypes.NavigationRestore;
  constructor(
    public id: string,
    public idType: "folder" | "shortcut" | "workspace" = "folder",
    public silent: boolean = false,
  ) { }
}

export class ProjectLoad implements Payload {
  public readonly type = ActionTypes.ProjectLoad;
  constructor(public shortcut: string) { }
}

export class ProjectUpdate implements Payload {
  public readonly type = ActionTypes.ProjectUpdate;
  constructor(public shortcut: string, public projectInfo: Partial<ChangeProjectInfo>) { }
}

export class ProjectRename implements Payload {
  public readonly type = ActionTypes.ProjectRename;
  constructor(public shortcut: string, public name: string) { }
}

export class ProjectToggleFeedback implements Payload {
  public readonly type = ActionTypes.ProjectToggleFeedback;
  constructor(public shortcut: string, public enabled: boolean) { }
}

export class ProjectToggleUserOnly implements Payload {
  public readonly type = ActionTypes.ProjectToggleUserOnly;
  constructor(public shortcut: string, public isUserOnly: boolean) { }
}

export class ProjectShare implements Payload {
  public readonly type = ActionTypes.ProjectShare;
  constructor(
    public workspace: FilesystemNodeWorkspace,
    public node: FilesystemNodeShortcut,
    public usersInvite: ManageUsersInviteModel,
    public recaptchaToken: string | undefined,
  ) { }
}

export class FolderCreate implements Payload {
  public static readonly type = ActionTypes.FolderCreate;
  public readonly type = FolderCreate.type;
  constructor(public folderCreateModel: FolderCreateModel) { }
}

export class FolderRename implements Payload {
  public static readonly type = ActionTypes.FolderRename;
  public readonly type = FolderRename.type;
  constructor(public folderId: string, public name: string) { }
}

export class WorkspacesLoad implements Payload {
  public static readonly type = ActionTypes.WorkspacesLoad;
  public readonly type = WorkspacesLoad.type;
}

export class WorkspacesRefresh implements Payload {
  public static readonly type = ActionTypes.WorkspacesRefresh;
  public readonly type = WorkspacesRefresh.type;
}

export class WorkspaceRename implements Payload {
  public static readonly type = ActionTypes.WorkspaceRename;
  public readonly type = WorkspaceRename.type;
  constructor(public model: RenameWorkspaceInfo) { }
}

export class WorkspaceArchive implements Payload {
  public static readonly type = ActionTypes.WorkspaceArchive;
  public readonly type = WorkspaceArchive.type;
  constructor(public id: string, public archive: boolean) { }
}

export class FavoriteWorkspace implements Payload {
  public static readonly type = ActionTypes.FavoriteWorkspace;
  public readonly type = FavoriteWorkspace.type;
  constructor(public id: string, public favorite: boolean) { }
}

export class InvitationRespond implements Payload {
  public static readonly type = ActionTypes.InvitationRespond;
  public readonly type = InvitationRespond.type;
  constructor(public id: string, public accept: boolean) { }
}

export class NodeRemove implements Payload {
  public static readonly type = ActionTypes.NodeRemove;
  public readonly type = NodeRemove.type;
  constructor(public nodes: FilesystemNodeDeletable[], public parentFolder: string) { }
}

export class UpdateFilesystemOwner implements Payload {
  public static readonly type = ActionTypes.UpdateFilesystemOwner;
  public readonly type = UpdateFilesystemOwner.type;
  constructor(public filesystemId: string, public email: string) { }
}

export class FirstPageLoad implements Payload {
  public readonly type = ActionTypes.FirstPageLoad;
  constructor(public shortcut: string) { }
}

export interface ActionPayloadMap extends PayloadMap<ActionTypes> {
  [ActionTypes.Navigate]: Navigate;
  [ActionTypes.Fetch]: Fetch;
  [ActionTypes.NavigationRestore]: NavigationRestore;
  [ActionTypes.ProjectLoad]: ProjectLoad;
  [ActionTypes.ProjectUpdate]: ProjectUpdate;
  [ActionTypes.ProjectRename]: ProjectRename;
  [ActionTypes.ProjectToggleFeedback]: ProjectToggleFeedback;
  [ActionTypes.ProjectToggleUserOnly]: ProjectToggleUserOnly;
  [ActionTypes.ProjectShare]: ProjectShare;
  [ActionTypes.FolderCreate]: FolderCreate;
  [ActionTypes.FolderRename]: FolderRename;
  [ActionTypes.WorkspacesLoad]: WorkspacesLoad;
  [ActionTypes.WorkspacesRefresh]: WorkspacesRefresh;
  [ActionTypes.WorkspaceRename]: WorkspaceRename;
  [ActionTypes.WorkspaceArchive]: WorkspaceArchive;
  [ActionTypes.FavoriteWorkspace]: FavoriteWorkspace;
  [ActionTypes.InvitationRespond]: InvitationRespond;
  [ActionTypes.NodeRemove]: NodeRemove;
  [ActionTypes.UpdateFilesystemOwner]: UpdateFilesystemOwner;
  [ActionTypes.FetchCurrent]: FetchCurrent;
  [ActionTypes.FirstPageLoad]: FirstPageLoad;
}
