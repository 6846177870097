
import {
  format, formatRelative, formatDistance, isValid, formatDistanceStrict,
} from "date-fns";
import Vue, { VNodeData } from "vue";

import { jsDateFromAspDateString } from "@/common/lib";

const defaults = {
  dateFormat: "PPP",
  dateTimeFormat: "p EEEE, MMMM do",
  timeAfterDateFormat: "EEEE, MMMM do p",
  tooltipFormat: "PPpp",
};

interface Props {
  value: Date | string;
  format: string;
  tooltipFormat: string;
  dateTimeFormat: string;
  time: boolean;
  timeAfterDate: boolean;
}

const componentProps = {
  value: {
    type: [Date, String],
    required: true,
  },

  format: {
    type: String,
    default: defaults.dateFormat,
  },

  tooltipFormat: {
    type: String,
    default: defaults.tooltipFormat,
  },

  dateTimeFormat: {
    type: String,
    default: defaults.dateTimeFormat,
  },

  time: {
    type: Boolean,
    default: false,
  },

  timeAfterDate: {
    type: Boolean,
    default: false,
  },

  relative: {
    type: Boolean,
    default: false,
  },

  distance: {
    type: Boolean,
    default: false,
  },

  strict: {
    type: Boolean,
    default: false,
  },

  addSuffix: {
    type: Boolean,
    default: false,
  },
};

export default Vue.extend({
  functional: true,
  props: componentProps,

  render(h, context) {
    const { props, data } = context;
    const date = normalize(props.value);
    const formatters = determineFormatters(props);
    let text = "";
    let tooltip = "";
    if (date) {
      if (props.relative) {
        text = formatRelative(date, new Date());
      } else if (props.distance) {
        const formatDistanceFn = props.strict ? formatDistanceStrict : formatDistance;
        text = formatDistanceFn(date, new Date(), {
          addSuffix: props.addSuffix,
        });
        if (text.includes("second")) {
          text = "just now";
        } else {
          text = text.replace("minute", "min");
        }
      } else {
        text = format(date, formatters.text);
      }

      tooltip = format(date, formatters.tooltip);
    } else {
      text = "Invalid Date";
    }
    const nodeData: VNodeData = {
      class: ["ax-date", data.staticClass],
      attrs: {
        title: tooltip,
      },
      domProps: {
        innerHTML: text,
      },
    };
    return h("span", nodeData);
  },
});

const determineFormatters = (props: Props) => {
  const {
    time, timeAfterDate, format: dateFormat, tooltipFormat, dateTimeFormat,
  } = props;

  return {
    text: time ? (timeAfterDate ? defaults.timeAfterDateFormat : dateTimeFormat) : dateFormat,
    tooltip: tooltipFormat,
  };
};

const normalize = (value: Date | string) => {
  if (value instanceof Date) {
    return value;
  }
  return convertToDate(value);
};

const convertToDate = (value: string) => {
  const date = new Date(value);
  try {
    return isValid(date) ? date : jsDateFromAspDateString(value);
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
