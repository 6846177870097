
import Vue from "vue";
import { Location } from "vue-router";

import { accountServiceUnavailable } from "@/common/axshare/messages";
import { PasswordValidator } from "@/common/axshare/user";
import { createCustomEvent } from "@/common/lib/events";
import { ROUTES } from "@/router";
import AccountService from "@/services/account.service";
import { AxShare } from "@/store/state";

import AxButton from "@/components/AxButton.vue";
import AxForm from "@/components/AxForm.vue";
import AxFormErrors from "@/components/AxFormErrors.vue";
import AxFormGroup from "@/components/AxFormGroup.vue";
import AxInput from "@/components/AxInput.vue";
import { Submit } from "@/components/types/AxForm";

interface Model {
  email: string;
  password: string;
  staySignedIn: boolean;
}

export default Vue.extend({
  components: {
    AxButton,
    AxForm,
    AxFormGroup,
    AxInput,
    AxFormErrors,
  },

  props: {
    requestId: {
      type: String,
      required: true,
    },
    machineId: {
      type: String,
      required: false,
      default: "",
    },
    rpVersion: {
      type: String,
      required: true,
    },
  },

  data() {
    const passwordValidator = new PasswordValidator(false);
    return {
      model: {
        email: "",
        password: "",
        staySignedIn: true,
      },
      errors: [] as string[],
      passwordValidator,
    };
  },

  computed: {
    accountService(): AccountService | undefined {
      const { accountService } = this.$store.state as AxShare;
      if (accountService) {
        return accountService();
      }
      return undefined;
    },

    signinRoute(): Location {
      return { name: ROUTES["rp.signin"].name, query: this.$route.query };
    },
  },

  methods: {
    addError(errorMessage: string) {
      this.errors.push(errorMessage);
    },

    async submit({ values }: Submit<Model>) {
      const { accountService } = this;
      if (!accountService) {
        throw new Error(accountServiceUnavailable);
      }

      const { email, password } = values;
      this.errors = [];

      if (!this.passwordValidator.isValid) {
        this.errors.push(this.passwordValidator.errorMessage());
        return;
      }

      const response = await accountService.rpSignUp(email, password, this.requestId);
      if (response) {
        // dispatch custom event for tracking purposes
        const event = createCustomEvent("rp-signup-success", { email });
        document.dispatchEvent(event);

        await this.$router.push({
          name: ROUTES["rp.redirect"].name,
          params: {
            token: response,
          },
          query: {
            r: this.requestId,
            m: this.machineId,
            rp: this.rpVersion,
          },
        });
      } else {
        await this.$router.push({
          name: ROUTES["rp.error"].name,
        });
      }
    },
  },
});
