
import Vue from "vue";

import WelcomeLayout from "@/layouts/WelcomeLayout.vue";
import { ROUTES } from "@/router";
import { requestInvite } from "@/services/fs.service";

import AxButton from "@/components/AxButton.vue";
import AxForm from "@/components/AxForm.vue";
import AxIcon from "@/components/AxIcon.vue";
import AxAxureLogo from "@/components/icons/AxAxureLogo.vue";

export default Vue.extend({
  components: {
    AxAxureLogo,
    AxIcon,
    AxForm,
    AxButton,
    WelcomeLayout,
  },

  props: {
    workspaceId: {
      type: String,
      required: true,
    },

    shortcut: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      homeRoute: { name: ROUTES.home.name },
    };
  },

  mounted() {
    this.updateQueryParams();
  },

  methods: {
    async requestAccessToWorkspace() {
      try {
        const response = await requestInvite(this.workspaceId, this.shortcut);

        await this.$router.push({
          name: ROUTES.workspaceAccessRequested.name,
          params: {
            workspaceName: encodeURIComponent(response.WorkspaceName),
          },
        });
      } catch {
        await this.$router.push({
          name: ROUTES.somethingNotQuiteRight.name,
        });
      }
    },

    updateQueryParams() {
      const { retry } = this.$route.query;
      if (!retry) {
        const query = { ...this.$route.query, retry: String(true) };
        this.$router.replace({ query });
      }
    },
  },
});
