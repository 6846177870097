
import Vue from "vue";

import WelcomeLayout from "@/layouts/WelcomeLayout.vue";
import { ROUTES } from "@/router";

import AxButton from "@/components/AxButton.vue";

export default Vue.extend({
  components: {
    AxButton,
    WelcomeLayout,
  },

  data() {
    return {
      fsRoute: { name: ROUTES.fs.name },
    };
  },
});
