import { Payload } from "vuex";

import { Action, Artboard, Asset, Master, Platform, ExpoStaticContent } from "@shared/expo";

import { ProjectConfig } from "@/common/expo/interfaces";
import { UploadBgImg } from "@/services/expo.service";

import { PayloadMap } from "../typed";

export enum MutationTypes {
  ProjectSetup = "[Expo] Project Setup",
  ProjectIsLoadingSet = "[Expo] Project Is Loading Set",
  ProjectContentSet = "[Expo] Project Content Set",
  ProjectContentClear = "[Expo] Project Content Clear",
  ProjectZoomSet = "[Expo] Project Zoom Set",
  ProjectPlatformUpdate = "[Expo] Project Platform Update",
  ProjectPlatformBgImgUpload = "[Expo] Project Platform Background Image Upload",
  ProjectPlatformBgImgDelete = "[Expo] Project Platform Background Image Delete",
  ProjectUpdateLastModifiedDate = "[Expo] Project Update Last Modified Date",
  ProjectUpdateLastSavedDate = "[Expo] Project Update Last Saved Date",

  ArtboardSelect = "[Expo] Artboard Select",
  ArtboardSelectAll = "[Expo] Artboard Select All",
  ArtboardSelectToggle = "[Expo] Artboard Select Toggle",
  ArtboardSelectSpan = "[Expo] Artboard Select Span",
  ArtboardDeselect = "[Expo] Artboard Deselect",
  ArtboardDeselectAll = "[Expo} Artboard Deselect All",
  ArtboardSelectSingle = "[Expo] Artboard Select Single",
  ArtboardOrderUpdated = "[Expo] Artboard Order Updated",

  ArtboardActionMenuToggle = "[Expo] Artboard Action Menu Toggle",
  ArtboardAddMaster = "[Expo] Artboard Add Master",
  ArtboardRemoveMaster = "[Expo] Artboard Remove Master",

  ArtboardAdd = "[Expo] Artboard Add",
  ArtboardRemove = "[Expo] Artboard Remove",
  ArtboardActionAdd = "[Expo] Artboard Action Add",
  ArtboardActionUpdate = "[Expo] Artboard Action Update",
  ArtboardActionRemove = "[Expo] Artboard Action Remove",
  // MasterActionAdd = "[Expo] Master Action Add",
  MasterActionUpdate = "[Expo] Master Action Update",
  MasterActionRemove = "[Expo] Master Action Remove",

  ArtboardActionHotspotPositionSet = "[Expo] Artboard Action Hotspot Position Set",
  MasterActionHotspotPositionSet = "[Expo] Master Action Hotspot Position Set",

  ProjectMasterCreate = "[Expo] Project Master Create",
  ProjectMasterRename = "[Expo] Project Master Rename",
  ProjectMasterCopy = "[Expo] Project Master Copy",
  ProjectMasterDelete = "[Expo] Project Master Delete",

  ArtboardSetHeaderFooterHeight = "[Expo] Artboard Set Header Footer Height",
}

export class ProjectSetup implements Payload {
  public readonly type = MutationTypes.ProjectSetup;
  constructor(public setup: ProjectConfig) {}
}

export class ProjectIsLoadingSet implements Payload {
  public readonly type = MutationTypes.ProjectIsLoadingSet;
  constructor(public shortcut: string, public value: boolean) {}
}

export class ProjectContentSet implements Payload {
  public readonly type = MutationTypes.ProjectContentSet;
  constructor(public shortcut: string, public content: ExpoStaticContent) {}
}

export class ProjectContentClear implements Payload {
  public readonly type = MutationTypes.ProjectContentClear;
  constructor(public shortcut: string) {}
}

export class ProjectZoomSet implements Payload {
  public readonly type = MutationTypes.ProjectZoomSet;
  constructor(public shortcut: string, public zoom: number) {}
}

export class ProjectPlatformUpdate implements Payload {
  public readonly type = MutationTypes.ProjectPlatformUpdate;
  constructor(public shortcut: string, public platform: Platform) {}
}

export class ProjectPlatformBgImgUpload implements Payload {
  public readonly type = MutationTypes.ProjectPlatformBgImgUpload;
  constructor(public shortcut: string, public response: UploadBgImg) {}
}

export class ProjectPlatformBgImgDelete implements Payload {
  public readonly type = MutationTypes.ProjectPlatformBgImgDelete;
  constructor(public shortcut: string, public response: UploadBgImg) {}
}

export class ProjectUpdateLastModifiedDate implements Payload {
  public readonly type = MutationTypes.ProjectUpdateLastModifiedDate;
  constructor(public readonly shortcut: string) {}
}

export class ProjectUpdateLastSavedDate implements Payload {
  public readonly type = MutationTypes.ProjectUpdateLastSavedDate;
  constructor(public readonly shortcut: string) {}
}

export class ArtboardSelect implements Payload {
  public readonly type = MutationTypes.ArtboardSelect;
  constructor(public readonly shortcut: string, public readonly artboardId: string) {}
}

export class ArtboardSelectAll implements Payload {
  public readonly type = MutationTypes.ArtboardSelectAll;
  constructor(public readonly shortcut: string) {}
}

export class ArtboardDeselect implements Payload {
  public readonly type = MutationTypes.ArtboardDeselect;
  constructor(public readonly shortcut: string, public readonly artboardId: string) {}
}

export class ArtboardDeselectAll implements Payload {
  public readonly type = MutationTypes.ArtboardDeselectAll;
  constructor(public readonly shortcut: string) {}
}

export class ArtboardSelectToggle implements Payload {
  public readonly type = MutationTypes.ArtboardSelectToggle;
  constructor(public readonly shortcut: string, public readonly artboardId: string) {}
}

export class ArtboardSelectSingle implements Payload {
  public readonly type = MutationTypes.ArtboardSelectSingle;
  constructor(public readonly shortcut: string, public readonly artboardId: string) {}
}

export class ArtboardSelectSpan implements Payload {
  public readonly type = MutationTypes.ArtboardSelectSpan;
  constructor(public readonly shortcut: string, public readonly artboardId: string, public readonly extend: boolean) {}
}

export class ArtboardOrderUpdated implements Payload {
  public readonly type = MutationTypes.ArtboardOrderUpdated;
  constructor(public readonly shortcut: string, public readonly artboardIds: string[]) {}
}

export class ArtboardActionMenuToggle implements Payload {
  public readonly type = MutationTypes.ArtboardActionMenuToggle;
  constructor(
    public readonly shortcut: string,
    public readonly artboardId: string,
    public readonly actionId: string,
    public readonly isOpened: boolean
  ) {}
}

export class ArtboardAddMaster implements Payload {
  public readonly type = MutationTypes.ArtboardAddMaster;
  constructor(public readonly shortcut: string, public readonly artboardId: string, public readonly masterId: string) {}
}

export class ArtboardRemoveMaster implements Payload {
  public readonly type = MutationTypes.ArtboardRemoveMaster;
  constructor(public readonly shortcut: string, public readonly artboardId: string, public readonly masterId: string) {}
}

export class ArtboardAdd implements Payload {
  public readonly type = MutationTypes.ArtboardAdd;
  constructor(
    public readonly shortcut: string,
    public readonly artboard: Artboard,
    public readonly asset: Asset,
    public readonly url: string
  ) {}
}

export class ArtboardRemove implements Payload {
  public readonly type = MutationTypes.ArtboardRemove;
  constructor(public readonly shortcut: string, public readonly artboardId: string) {}
}

export class ArtboardActionAdd implements Payload {
  public readonly type = MutationTypes.ArtboardActionAdd;
  constructor(public readonly shortcut: string, public readonly artboardId: string, public readonly action: Action) {}
}

export class ArtboardActionUpdate implements Payload {
  public readonly type = MutationTypes.ArtboardActionUpdate;
  constructor(
    public readonly shortcut: string,
    public readonly artboardId: string,
    public readonly actionId: string,
    public readonly originalAction: Action,
    public readonly action: Action
  ) {}
}

export class ArtboardActionRemove implements Payload {
  public readonly type = MutationTypes.ArtboardActionRemove;
  constructor(public readonly shortcut: string, public readonly artboard: Artboard, public readonly action: Action) {}
}

export class ArtboardActionHotspotPositionSet implements Payload {
  public readonly type = MutationTypes.ArtboardActionHotspotPositionSet;
  constructor(
    public readonly shortcut: string,
    public readonly artboardId: string,
    public readonly actionId: string,
    public readonly position: {
      x: number;
      y: number;
      height: number;
      width: number;
    }
  ) {}
}

// export class MasterActionAdd implements Payload {
//   public readonly type = MutationTypes.MasterActionAdd;
//   constructor(public readonly masterId: string, public readonly action: Action) {}
// }

export class MasterActionUpdate implements Payload {
  public readonly type = MutationTypes.MasterActionUpdate;
  constructor(
    public readonly shortcut: string,
    public readonly artboardId: string,
    public readonly masterId: string,
    public readonly actionId: string,
    public readonly originalAction: Action,
    public readonly action: Action
  ) {}
}

export class MasterActionRemove implements Payload {
  public readonly type = MutationTypes.MasterActionRemove;
  constructor(public readonly shortcut: string, public readonly master: Master, public readonly action: Action) {}
}

export class MasterActionHotspotPositionSet implements Payload {
  public readonly type = MutationTypes.MasterActionHotspotPositionSet;
  constructor(
    public readonly shortcut: string,
    public readonly masterId: string,
    public readonly actionId: string,
    public readonly position: {
      x: number;
      y: number;
      height: number;
      width: number;
    },
    public readonly relativeToBottom: number | undefined
  ) {}
}

export class ProjectMasterCreate implements Payload {
  public readonly type = MutationTypes.ProjectMasterCreate;
  constructor(
    public readonly shortcut: string,
    public readonly master: Master,
    public readonly artboard: Artboard,
    public readonly callback: (master: Master) => void
  ) {}
}

export class ProjectMasterRename implements Payload {
  public readonly type = MutationTypes.ProjectMasterRename;
  constructor(public readonly shortcut: string, public readonly master: Master, public readonly name: string) {}
}

export class ProjectMasterCopy implements Payload {
  public readonly type = MutationTypes.ProjectMasterCopy;
  constructor(
    public readonly shortcut: string,
    public readonly master: Master,
    public readonly artboard: Artboard,
    public readonly callback: (master: Master) => void
  ) {}
}

export class ProjectMasterDelete implements Payload {
  public readonly type = MutationTypes.ProjectMasterDelete;
  constructor(public readonly shortcut: string, public readonly master: Master) {}
}

export class ArtboardSetHeaderFooterHeight implements Payload {
  public readonly type = MutationTypes.ArtboardSetHeaderFooterHeight;
  constructor(
    public readonly shortcut: string,
    public readonly artboard: Artboard,
    public readonly height: number,
    public readonly isHeader: boolean
  ) {}
}

export interface MutationPayloadMap extends PayloadMap<MutationTypes> {
  [MutationTypes.ProjectSetup]: ProjectSetup;
  [MutationTypes.ProjectIsLoadingSet]: ProjectIsLoadingSet;
  [MutationTypes.ProjectContentSet]: ProjectContentSet;
  [MutationTypes.ProjectContentClear]: ProjectContentClear;
  [MutationTypes.ProjectZoomSet]: ProjectZoomSet;
  [MutationTypes.ProjectPlatformUpdate]: ProjectPlatformUpdate;
  [MutationTypes.ProjectPlatformBgImgUpload]: ProjectPlatformBgImgUpload;
  [MutationTypes.ProjectPlatformBgImgDelete]: ProjectPlatformBgImgDelete;
  [MutationTypes.ProjectUpdateLastModifiedDate]: ProjectUpdateLastModifiedDate;
  [MutationTypes.ProjectUpdateLastSavedDate]: ProjectUpdateLastSavedDate;
  [MutationTypes.ArtboardSelect]: ArtboardSelect;
  [MutationTypes.ArtboardSelectToggle]: ArtboardSelectToggle;
  [MutationTypes.ArtboardSelectSpan]: ArtboardSelectSpan;
  [MutationTypes.ArtboardSelectAll]: ArtboardSelectAll;
  [MutationTypes.ArtboardDeselect]: ArtboardDeselect;
  [MutationTypes.ArtboardDeselectAll]: ArtboardDeselectAll;
  [MutationTypes.ArtboardSelectSingle]: ArtboardSelectSingle;
  [MutationTypes.ArtboardOrderUpdated]: ArtboardOrderUpdated;
  [MutationTypes.ArtboardActionMenuToggle]: ArtboardActionMenuToggle;
  [MutationTypes.ArtboardAddMaster]: ArtboardAddMaster;
  [MutationTypes.ArtboardRemoveMaster]: ArtboardRemoveMaster;
  [MutationTypes.ArtboardAdd]: ArtboardAdd;
  [MutationTypes.ArtboardRemove]: ArtboardRemove;
  [MutationTypes.ArtboardActionAdd]: ArtboardActionAdd;
  [MutationTypes.ArtboardActionUpdate]: ArtboardActionUpdate;
  [MutationTypes.ArtboardActionRemove]: ArtboardActionRemove;
  [MutationTypes.ArtboardActionHotspotPositionSet]: ArtboardActionHotspotPositionSet;
  // [MutationTypes.MasterActionAdd]: MasterActionAdd;
  [MutationTypes.MasterActionUpdate]: MasterActionUpdate;
  [MutationTypes.MasterActionRemove]: MasterActionRemove;
  [MutationTypes.MasterActionHotspotPositionSet]: MasterActionHotspotPositionSet;
  [MutationTypes.ProjectMasterCreate]: ProjectMasterCreate;
  [MutationTypes.ProjectMasterRename]: ProjectMasterRename;
  [MutationTypes.ProjectMasterCopy]: ProjectMasterCopy;
  [MutationTypes.ProjectMasterDelete]: ProjectMasterDelete;
  [MutationTypes.ArtboardSetHeaderFooterHeight]: ArtboardSetHeaderFooterHeight;
}
