
import Vue from "vue";
import { Location } from "vue-router";

import { ROUTES } from "@/router";

import AxIcon from "@/components/AxIcon.vue";
import AxAxureLogo from "@/components/icons/AxAxureLogo.vue";

export default Vue.extend({
  components: {
    AxAxureLogo,
    AxIcon,
  },

  computed: {
    isRecentActive(): boolean {
      return this.$route.name === ROUTES["fs.recents"].name;
    },

    recentsRoute(): Location {
      const recentRouteName = ROUTES["fs.recents"].name;
      return {
        name: recentRouteName,
        query: {
          refresh: this.isRecentActive ? "true" : "",
        },
      };
    },
  },
});
