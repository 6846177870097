import Vue from "vue";

import { openExternal } from "@/common/desktop/links";
import { isElectron } from "@/common/environment";

export const desktopExternalLinks = Vue.extend({
  created() {
    if (isElectron) {
      document.addEventListener("click", this.handleClick, false);
    }
  },

  destroyed() {
    if (isElectron) {
      document.removeEventListener("click", this.handleClick);
    }
  },

  methods: {
    handleClick(event: MouseEvent) {
      const target = event.target;
      if (!(target instanceof HTMLElement)) return;
      const link = this.findLink(target);
      if (!link) return;

      const isExternal = location.hostname !== link.hostname;
      if (isExternal && !event.defaultPrevented) {
        event.stopPropagation();
        event.preventDefault();
        openExternal(link.href);
      }
    },

    findLink(target: HTMLElement): HTMLAnchorElement | undefined {
      let node: HTMLElement | undefined = target;
      while (node) {
        if (node instanceof HTMLAnchorElement) {
          return node;
        }
        node = node.parentNode as HTMLElement;
      }
    },
  },
});
