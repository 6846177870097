
import Vue from "vue";

import { SsoId } from "@shared/models";

import { accountServiceUnavailable } from "@/common/axshare/messages";
import AccountService from "@/services/account.service";

import AxButton from "@/components/AxButton.vue";
import AxForm from "@/components/AxForm.vue";
import { prop } from "@/components/utils";

export default Vue.extend({
  components: {
    AxButton,
    AxForm,
  },

  props: {
    email: {
      type: String,
      required: true,
    },
    accountService: prop<AccountService>({
      required: true,
    }),
  },

  data() {
    return {
      message: "",
    };
  },

  methods: {
    async submit() {
      const { accountService } = this;
      if (!accountService) {
        throw new Error(accountServiceUnavailable);
      }

      this.$emit("submit");
      this.message = "";
      const response = await accountService.forgotPassword(this.email, SsoId.AxShare.toString());
      this.message = response.message;
      this.$emit("success", this.message);
    },

    onError(error: string) {
      this.$emit("error", error);
    },
  },
});
