
import Vue from "vue";

import AxForm from "@/components/AxForm.vue";
import AxFormErrors from "@/components/AxFormErrors.vue";
import AxInput from "@/components/AxInput.vue";
import { Submit } from "@/components/types/AxForm";

interface Editable {
  value: string;
}
export default Vue.extend({
  components: {
    AxForm,
    AxInput,
    AxFormErrors,
  },

  props: {
    value: {
      type: String,
      required: true,
    },

    submitOnBlur: {
      type: Boolean,
      default: true,
    },

    submit: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      original: this.value,
      active: false,
      editableHeight: "auto",
      editableWidth: "auto",
    };
  },

  computed: {
    form(): Editable {
      return {
        value: this.value,
      };
    },

    editableStyles(): Partial<CSSStyleDeclaration> {
      return { height: this.editableHeight, width: this.editableWidth };
    },
  },

  watch: {
    value(newValue) {
      this.original = newValue;
    },
  },

  methods: {
    activate() {
      this.saveDimensions();
      this.active = true;
      this.$nextTick(() => {
        const input = this.$refs.input as any;
        if (input && input.select) {
          input.select();
        }
      });
    },

    deactivate() {
      this.active = false;
    },

    saveDimensions() {
      const editableElement = this.$refs.editable as HTMLElement;
      if (editableElement) {
        this.editableHeight = `${editableElement.getBoundingClientRect().height}px`;
        this.editableWidth = `${editableElement.getBoundingClientRect().width}px`;
      }
    },

    async submitForm({ values }: Submit<Editable>) {
      if (!this.active) return;

      if (this.original !== values.value) {
        if (this.submit) {
          await this.submit(values.value);
        }
        this.$emit("input", values.value);
      }
      this.deactivate();
    },
  },
});
