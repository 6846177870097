import VueGTM from "@gtm-support/vue2-gtm";
import Vue from "vue";

import { asError } from "@/common/lib";
import router from "@/router";

const gtmTrackingId = process.env.VUE_APP_GTM_TRACKING_ID;

export default () => {
  if (!gtmTrackingId) return;

  try {
    Vue.use(VueGTM, {
      id: gtmTrackingId,
      enabled: process.env.NODE_ENV === "production" && !!gtmTrackingId,
      vueRouter: router,
    });
  } catch (err) {
    console.error(asError(err).message);
  }
};
