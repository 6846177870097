import { Payload } from "vuex";

import { ExtendedSsoResponse } from "@shared/models";
import { UserProfile } from "@/services/models/account";
import { PayloadMap } from "@/store/typed";

export enum MutationTypes {
  SetInfo = "[User] Set Info",
  SetProfile = "[User] Set Profile",
}

export class SetInfo implements Payload {
  public readonly type = MutationTypes.SetInfo;
  constructor(public info: ExtendedSsoResponse) {}
}

export class SetProfile implements Payload {
  public readonly type = MutationTypes.SetProfile;
  constructor(public profile: UserProfile) {}
}

export interface MutationPayloadMap extends PayloadMap<MutationTypes> {
  [MutationTypes.SetInfo]: SetInfo;
  [MutationTypes.SetProfile]: SetProfile;
}
