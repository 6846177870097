import { RouteConfig } from "vue-router";

import { ROUTES } from "@/router";
import store from "@/store";
import { AxShare } from "@/store/state";

const Configure = () => import(/* webpackChunkName: "setup" */ "@/pages/Setup.vue");

export type Keys = "setup";

const routes: { readonly [R in Keys]: RouteConfig } = {
  setup: {
    path: "/setup",
    name: "setup",
    component: Configure,
    beforeEnter(_, __, next) {
      const { axShareConfig } = store.state as AxShare;
      const appConfigured: boolean = store.getters.appConfigured;

      if (!!axShareConfig && axShareConfig.AxShareOnPrem && !appConfigured) {
        next();
      } else {
        next(ROUTES.home);
      }
    },
    meta: {
      requiresAuth(): boolean {
        const appConfigured: boolean = store.getters.appConfigured;
        return appConfigured;
      },
    },
  },
};

const config = [
  {
    ...routes.setup,
  },
];

export default {
  routes,
  config,
};
