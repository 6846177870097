
import Vue from "vue";

import { formatArray } from "@/common/lib";
import { getFileExtension } from "@/common/lib/string";
import { ShortcutType } from "@/services/models";
import { AxShare } from "@/store/state";

import AxButton from "@/components/AxButton.vue";
import AxFileUpload from "@/components/AxFileUpload.vue";
import AxProjectFileUploadIndicator from "@/components/AxProjectFileUploadIndicator.vue";
import AxProjectIcon from "@/components/AxProjectIcon.vue";

const bytesInMegabyte = 1024 * 1024;

const fileTypesToShortcutType: { [key: string]: ShortcutType | undefined } = {
  ".rp": ShortcutType.Rp,
  ".rplib": ShortcutType.Lib,
};
const imgFileTypes = [".png", ".jpg", ".gif"];

export default Vue.extend({
  components: {
    AxButton,
    AxFileUpload,
    AxProjectFileUploadIndicator,
    AxProjectIcon,
  },

  props: {
    value: {
      type: Object,
      required: false,
      default: null,
    },

    uploadProgress: {
      type: Object,
      required: false,
      default: null,
    },

    uploadMessage: {
      type: String,
      default: "Drop .RP file here or",
    },

    isRpUploader: {
      type: Boolean,
      default: true,
    },

    autofocus: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    let maxFileSize = 0;
    const { axShareConfig } = this.$store.state as AxShare;
    if (axShareConfig) {
      // axShareConfig's max file size value is in bytes, and vue-transmit's is in MB
      // conversion is required
      maxFileSize = axShareConfig.MaxFileSize / bytesInMegabyte;
    }

    const acceptedFileTypes = this.isRpUploader ? Object.keys(fileTypesToShortcutType) : imgFileTypes;
    const acceptedFileTypeFormatted = formatArray(acceptedFileTypes);

    // prettier-ignore
    const fileTooLargeMessage = "Upload error: The selected file is too large ({{ fileSize }}MB). Maximum file size is {{ maxFileSize }}MB.";
    const fileIncorrectTypeMessage = `Upload error: The selected file has incorrect type. Valid file extensions are ${acceptedFileTypeFormatted}.`;

    return {
      errors: [] as string[],
      fileOptions: {
        acceptedFileTypes,
        paramName: "fileData",
        maxFiles: 1,
        maxFileSize,
        fileSizeBase: 1024,
        uploadAreaClasses: "ax-project-file-picker__upload-area",
        dictFileTooBig: fileTooLargeMessage,
        dictInvalidFileType: fileIncorrectTypeMessage,
      },
    };
  },

  computed: {
    file: {
      get(): any | null {
        return this.value;
      },

      set(value: any) {
        this.$emit("file-accepted");
        this.$emit("input", value);
      },
    },
  },

  mounted() {
    if (this.autofocus) {
      this.onFocus();
    }
  },

  methods: {
    filesProps(props: any) {
      const file = props.acceptedFiles[0];
      return {
        file,
        type: this.getTypeFromFilename(file),
      };
    },

    getTypeFromFilename(file: any) {
      const fallback = ShortcutType.Rp;
      if (!file || !file.name) return fallback;

      const extension = getFileExtension(file.name.toLowerCase());
      return (extension && fileTypesToShortcutType[extension]) || fallback;
    },

    error(_: any, reason: string) {
      this.errors = [reason];
      this.$emit("file-error", this.errors);
    },

    cancelUpload() {
      this.$emit("cancel-upload");
    },

    onFocus() {
      const chooseBtn = (this.$refs.chooseBtn as any).$el as HTMLElement;

      if (chooseBtn && document.activeElement !== chooseBtn) {
        this.$nextTick(() => chooseBtn.focus());
      }
    },
  },
});
