import axios, { AxiosInstance } from "axios";

import { LicenseInfoResponse, RpErrorTypes } from "@/generated/models";
import { objectToFormData } from "@/services/utils/formData";

import { addAuthorizationHeader, withNoCacheHeaders } from "./utils/axios";

function createServer(host: string, token: () => string | undefined) {
  const server = axios.create({
    baseURL: host,
    withCredentials: true,
    transformRequest: [
      (data, headers) => {
        addAuthorizationHeader(headers, token());
        return data;
      },
    ],
    ...withNoCacheHeaders,
  });

  return server;
}

// we need this because the liensing/portal server has different capitalization rules from cloud.
export type CustomRpResponseObject = {
  success: Boolean,
  Payload: LicenseInfoResponse,
  ErrorType: RpErrorTypes,
  errorMessage: string
}

export default class LicensingService {
  private licensingServer: AxiosInstance;

  constructor(private readonly licensingHost: string, authToken: string) {
    this.licensingServer = createServer(licensingHost, () => authToken);
  }

  public async getLicenseData(machineId: string, redemptionKey?: string, skipTrialCheck?: Boolean) {
    const formData = objectToFormData({
      code: machineId,
      redemptionKey,
      skipTrialCheck: skipTrialCheck ?? false,
    });
    const val = await this.licensingServer.post<CustomRpResponseObject>("/licensing/active", formData);
    return val.data;
  }
}
