import { RouteConfig } from "vue-router";

const SsoCompleted = () => import("@/pages/SsoCompleted.vue");

export type Keys = "sso-popup.completed";

const routes: { readonly [R in Keys]: RouteConfig } = {
  "sso-popup.completed": {
    path: "/sso-popup/completed",
    name: "sso-popup.completed",
    component: SsoCompleted,
  },
};

const config = [
  {
    ...routes["sso-popup.completed"],
  },
];

export default {
  routes,
  config,
};
