
import Vue from "vue";

import { getUserName } from "@/common/axshare";
import { FilesystemNodeWorkspace, FilesystemNodeInvitation } from "@/common/fs";

import { FilesystemNotificationStateJs } from "../generated/models";
import { FavoriteWorkspace } from "../store/fs/actionTypes";
import { AxShare } from "../store/state";

import { prop } from "./utils";

import AxButton from "@/components/AxButton.vue";
import AxForm from "@/components/AxForm.vue";
import AxIcon from "@/components/AxIcon.vue";
import AxNavigationLinkFs from "@/components/AxNavigationLinkFs.vue";
import AxTooltip from "@/components/AxTooltip.vue";
import AxUnreadMentionsIndicator from "@/components/AxUnreadMentionsIndicator.vue";
import AxWorkspaceIcon from "@/components/AxWorkspaceIcon.vue";
import AxStar from "@/components/icons/AxStar.vue";

export default Vue.extend({
  components: {
    AxButton,
    AxForm,
    AxIcon,
    AxWorkspaceIcon,
    AxNavigationLinkFs,
    AxTooltip,
    AxUnreadMentionsIndicator,
    AxStar,
  },

  props: {
    workspace: prop<FilesystemNodeWorkspace>({
      required: false,
    }),

    invitation: prop<FilesystemNodeInvitation>({
      required: false,
    }),

    canFavorite: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isInvitation(): boolean {
      return !!this.invitation;
    },

    node(): FilesystemNodeWorkspace | FilesystemNodeInvitation {
      return this.invitation || this.workspace;
    },

    isSubInstance(): boolean {
      const { axShareConfig } = this.$store.state as AxShare;
      return axShareConfig !== null && axShareConfig.IsSubInstance;
    },

    isSubInstanceAdmin(): boolean {
      return this.$store.getters.isSubInstanceAdmin;
    },

    isAdminUser(): boolean {
      return this.$store.getters.isAdminUser;
    },

    isOnPrem(): boolean {
      const { axShareConfig } = this.$store.state as AxShare;
      return axShareConfig !== null && axShareConfig.AxShareOnPrem;
    },

    notificationState(): FilesystemNotificationStateJs | undefined {
      const notificationState = this.workspace?.listingNode?.NotificationState;
      return notificationState;
    },

    hasNewComments(): boolean {
      return !!this.notificationState && this.notificationState.HasNewComments;
    },

    unreadMentions(): number {
      return this.notificationState ? this.notificationState.UnreadMentionsCount : 0;
    },

    hasUnreadMentions(): boolean {
      return this.unreadMentions > 0;
    },

    workspaceClass(): Record<string, boolean> {
      return {
        "nav-text": true,
        "new-comments": this.hasNewComments || this.hasUnreadMentions,
        "invitation-node": this.isInvitation,
      };
    },

    iconName(): string {
      return this.workspace && this.workspace.isDefault ? "lock" : "workspace";
    },

    favoriteTitle(): string {
      return this.workspace.isFavorite ? "Remove from favorites" : "Add to favorites";
    },

    workspaceText(): string {
      const workspaceName = this.node.name;
      const maxTextLength = 33;
      if (workspaceName.length <= maxTextLength) return workspaceName;

      const truncatedText = `${workspaceName.substring(0, maxTextLength - 3)}...`;
      return truncatedText;
    },

    showTooltip(): boolean {
      if (this.isInvitation) return false;

      const isAdminOnSubinstance = this.isSubInstance && this.isSubInstanceAdmin;
      const isAdminOnPrem = this.isOnPrem && this.isAdminUser;

      if (isAdminOnSubinstance || isAdminOnPrem) {
        return !this.workspace.isOwner;
      }

      return false;
    },

    tooltipProps(): any {
      return {
        tooltipPlacement: "right",
        arrow: true,
        title: `Owner: ${this.ownerName}`,
      };
    },

    ownerName(): string {
      if (!this.workspace.owner) {
        return this.workspace.ownerEmail;
      }

      return getUserName(this.workspace.owner) || this.workspace.ownerEmail || "";
    },

    shared(): boolean {
      return this.workspace && this.workspace.users && this.workspace.users.length > 0;
    },
  },

  methods: {
    async setFavoriteWs() {
      const eventNamesPrefix = this.workspace.isFavorite ? "unfavorite" : "favorite";
      this.$emit(`${eventNamesPrefix}-started`, this.workspace);
      await this.$store.dispatch(new FavoriteWorkspace(this.workspace.id, !this.workspace.isFavorite));
      this.$emit(`${eventNamesPrefix}-finished`, this.workspace);
    },
  },
});
