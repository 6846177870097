export default {
  sitemap: {
    id: "sitemapHost",
    gid: 1,
  },
  notes: {
    id: "pageNotesHost",
    gid: 2,
  },
  discussions: {
    id: "feedbackHost",
    gid: 4,
  },
  handoff: {
    id: "handoffHost",
    gid: 5,
  },
};
