import { render, staticRenderFns } from "./AxCreateProjectSketch.vue?vue&type=template&id=6736e478&scoped=true&"
import script from "./AxCreateProjectSketch.vue?vue&type=script&lang=ts&"
export * from "./AxCreateProjectSketch.vue?vue&type=script&lang=ts&"
import style0 from "./AxCreateProjectSketch.vue?vue&type=style&index=0&id=6736e478&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6736e478",
  null
  
)

export default component.exports