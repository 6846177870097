
import Vue from "vue";

import { FilesystemNodeTypes, getNodeOptions, NodeOptions } from "@/common/fs";

import AxButton from "@/components/AxButton.vue";
import AxButtonGroup from "@/components/AxButtonGroup.vue";
import AxFilesystemNodeCreate from "@/components/AxFilesystemNodeCreate.vue";
import AxShortcutFilesystemActions, { ShortcutActionsOptions } from "@/components/AxShortcutFilesystemActions.vue";
import { arrayProp } from "@/components/utils";

export default Vue.extend({
  components: {
    AxFilesystemNodeCreate,
    AxButtonGroup,
    AxButton,
    AxShortcutFilesystemActions,
  },

  props: {
    id: {
      type: String,
      required: true,
    },

    selected: arrayProp<FilesystemNodeTypes>({
      required: false,
      default: () => [],
    }),
  },

  data() {
    return {
      moving: false,
      deleting: false,
    };
  },

  computed: {
    options(): NodeOptions {
      return getNodeOptions(this.$store.getters.current.node);
    },

    shortcutActionsOptions(): ShortcutActionsOptions {
      return {
        move: {
          show: this.moving,
          submit: this.moveSubmit,
          cancel: this.moveCancel,
        },
        delete: {
          show: this.deleting,
          submit: this.deleteSubmit,
          cancel: this.deleteCancel,
        },
        clone: { show: false },
        rename: { show: false },
        upload: { show: false },
        folderId: this.options.folderId,
        items: this.selected,
      };
    },
  },

  methods: {
    moveSubmit() {
      this.moving = false;
      this.clearSelection();
    },

    moveCancel() {
      this.moving = false;
    },

    deleteSubmit() {
      this.deleting = false;
      this.clearSelection();
    },

    deleteCancel() {
      this.deleting = false;
    },

    refresh() {
      this.$emit("refresh");
    },

    clearSelection() {
      this.$emit("input", []);
    },
  },
});
