import { FilesystemJs, SsoResponse, MigrateToAcbResult } from "@/generated/models";
import { MigrationSteps } from "@/store/migration/state";
import { Payload, PayloadMap } from "@/store/typed";

export enum MutationTypes {
  MigrateWorkspaces = "[Migration] Migrate Workspaces",
  Restart = "[Migration] Restart",
  SetStep = "[Migration] Set Step",
  SetFailedStep = "[Migration] Set Failed Step",
  SetSessionToken = "[Migration] Set Session Token",
  SelectSubInstance = "[Migration] Select Sub Instance",
  GoBack = "[Migration] Go Back",
  GoForward = "[Migration] Go Forward",
  SelectWorkspaces = "[Migration] Select Workspaces",
  SelectUsers = "[Migration] Select Users",
  ConfirmMigration = "[Migration] Confirm Migration",
  SetMigrationResult = "[Migration] Set Migration Result",
}

export class MigrateWorkspaces implements Payload {
  public readonly type = MutationTypes.MigrateWorkspaces;
  constructor(public migrate: boolean) { }
}

export class SetStep implements Payload {
  public readonly type = MutationTypes.SetStep;
  constructor(public step: MigrationSteps) { }
}

export class SetFailedStep implements Payload {
  public readonly type = MutationTypes.SetFailedStep;
  constructor(public step: MigrationSteps) { }
}

export class SelectSubInstance implements Payload {
  public readonly type = MutationTypes.SelectSubInstance;
  constructor(public domainPrefix: string) { }
}

export class SetSessionToken implements Payload {
  public readonly type = MutationTypes.SetSessionToken;
  constructor(public migrationId: string, public token: string) { }
}

export class GoBack implements Payload {
  public readonly type = MutationTypes.GoBack;
}

export class GoForward implements Payload {
  public readonly type = MutationTypes.GoForward;
}

export class SelectWorkspaces implements Payload {
  public readonly type = MutationTypes.SelectWorkspaces;
  constructor(public workspaces: FilesystemJs[]) { }
}

export class SelectUsers implements Payload {
  public readonly type = MutationTypes.SelectUsers;
  constructor(public users: SsoResponse[], public assignSeats: boolean) { }
}

export class ConfirmMigration implements Payload {
  public readonly type = MutationTypes.ConfirmMigration;
  constructor() { }
}

export class SetMigrationResult implements Payload {
  public readonly type = MutationTypes.SetMigrationResult;
  constructor(public result: MigrateToAcbResult) { }
}

export class Restart implements Payload {
  public readonly type = MutationTypes.Restart;
  constructor() { }
}

export interface MutationPayloadMap extends PayloadMap<MutationTypes> {
  [MutationTypes.MigrateWorkspaces]: MigrateWorkspaces;
  [MutationTypes.SetStep]: SetStep;
  [MutationTypes.SetFailedStep]: SetFailedStep;
  [MutationTypes.SetSessionToken]: SetSessionToken;
  [MutationTypes.GoBack]: GoBack;
  [MutationTypes.GoForward]: GoForward;
  [MutationTypes.SelectWorkspaces]: SelectWorkspaces;
  [MutationTypes.SelectUsers]: SelectUsers;
  [MutationTypes.ConfirmMigration]: ConfirmMigration;
  [MutationTypes.SelectSubInstance]: SelectSubInstance;
  [MutationTypes.SetMigrationResult]: SetMigrationResult;
  [MutationTypes.Restart]: Restart;
}
