import { isSafari } from "@/common/lib";

export interface Storage {
  get(key: string): string | undefined;
  set(key: string, value: string): void;
  delete(key: string): void;
}

export class InMemoryStorage implements Storage {
  private storage: Record<string, string> = {};

  public get(key: string): string | undefined {
    return this.storage[key];
  }

  public set(key: string, value: string): void {
    this.storage[key] = value;
  }

  public delete(key: string): void {
    delete this.storage[key];
  }
}

export class LocalStorage implements Storage {
  public get(key: string): string | undefined {
    const value = localStorage.getItem(key);
    if (typeof value === "string") return value;
    return undefined;
  }

  public set(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  public delete(key: string): void {
    localStorage.removeItem(key);
  }
}

export const BrowserStorage: new () => Storage = !isSafari ? InMemoryStorage : LocalStorage;
