
import { Validator } from "lakmus";
import Vue from "vue";
import { PropValidator } from "vue/types/options";

import { FilesystemNodeShortcut } from "@/common/fs";
import { ProjectRename } from "@/store/fs/actionTypes";

import AxButton from "@/components/AxButton.vue";
import AxForm from "@/components/AxForm.vue";
import AxFormGroup from "@/components/AxFormGroup.vue";
import AxInput from "@/components/AxInput.vue";
import { Submit } from "@/components/types/AxForm";

interface RenameForm {
  name: string;
}

const item: PropValidator<FilesystemNodeShortcut> = {
  type: Object,
  required: true,
};
const props = {
  item,
};

class RenameFormValidator extends Validator<RenameForm> {
  constructor() {
    super();

    this.ruleFor(x => x.name)
      .notEmpty()
      .withMessage("Please enter project name.");
  }
}
export const renameFormValidator = new RenameFormValidator();

export default Vue.extend({
  components: {
    AxForm,
    AxFormGroup,
    AxInput,
    AxButton,
  },

  props: {
    ...props,
  },
  data() {
    const form: RenameForm = { name: this.item.name };
    return {
      form,
      validator: renameFormValidator,
    };
  },
  methods: {
    async submit({ values }: Submit<RenameForm>) {
      await this.$store.dispatch(new ProjectRename(this.item.id, values.name));
      this.$emit("submit");
    },
  },
});
