
import Vue from "vue";
import { Location } from "vue-router";

import { SharedFilesystemMappingJs, SsoResponse } from "@shared/models";

import { getUserName } from "@/common/axshare";
import { ROUTES } from "@/router";

import AxButton from "@/components/AxButton.vue";
import AxIcon from "@/components/AxIcon.vue";
import AxList from "@/components/AxList.vue";
import AxListItem from "@/components/AxListItem.vue";
import AxMenu from "@/components/AxMenu.vue";
import AxPerfectScrollbar from "@/components/AxPerfectScrollbar.vue";
import AxTooltip from "@/components/AxTooltip.vue";
import AxUserAvatar from "@/components/AxUserAvatar.vue";
import AxUserAvatarStack from "@/components/AxUserAvatarStack.vue";
import { arrayProp } from "@/components/utils";

export default Vue.extend({
  components: {
    AxButton,
    AxIcon,
    AxList,
    AxListItem,
    AxMenu,
    AxPerfectScrollbar,
    AxTooltip,
    AxUserAvatar,
    AxUserAvatarStack,
  },

  props: {
    items: arrayProp<SharedFilesystemMappingJs>(),

    notStackedLength: {
      type: Number,
      default: 0,
    },

    stackLength: {
      type: Number,
      default: 3,
    },

    inversed: {
      type: Boolean,
      default: false,
    },

    stackExpandable: {
      type: Boolean,
      default: true,
    },

    stackInversed: {
      type: Boolean,
      default: false,
    },

    avatarSize: {
      type: String,
      default: "24px",
    },

    rootFolderId: {
      type: String,
      default: "",
    },

    canInvite: {
      type: Boolean,
      default: false,
    },

    inviteOthers: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      menuOpened: false,
    };
  },

  computed: {
    component(): string {
      return this.canInvite ? "AxButton" : "div";
    },

    notStacked(): SharedFilesystemMappingJs[] {
      const from = 0;
      const to = this.notStackedLength;
      const sliced = this.items.slice(from, to);
      return this.inversed ? sliced.reverse() : sliced;
    },

    stacked(): SharedFilesystemMappingJs[] {
      const from = this.notStacked.length;
      const to = from + this.stackLength;
      const sliced = this.items.slice(from, to);
      return this.inversed || this.stackInversed ? sliced.reverse() : sliced;
    },

    remaining(): SharedFilesystemMappingJs[] {
      const from = this.notStacked.length + this.stacked.length;
      const sliced = this.items.slice(from);
      return this.inversed ? sliced.reverse() : sliced;
    },

    inviteUsersButtonStyle(): Partial<CSSStyleDeclaration> {
      return {
        width: this.avatarSize,
        height: this.avatarSize,
        fontSize: this.avatarSize,
      };
    },

    userInvite(): Location {
      return {
        name: ROUTES["fs.node.userinvite"].name,
        params: { id: this.rootFolderId },
      };
    },
  },

  methods: {
    displayName(item: SharedFilesystemMappingJs) {
      return `${item.user.bio} ${item.user.nickname} ${item.user.userEmail}`;
    },

    avatarProps(item: SharedFilesystemMappingJs) {
      return {
        hideTitle: true,
        user: item.user,
        w: this.avatarSize,
        h: this.avatarSize,
      };
    },

    getUserNameOrEmail(user: SsoResponse): string {
      return getUserName(user) || user.userEmail;
    },
  },
});
