
import Vue from "vue";
import { Location } from "vue-router";
import { PropValidator } from "vue/types/options";

import { isWorkspaceNode, FilesystemNodeWorkspace, WorkspaceRights } from "@/common/fs";
import { directive as onClickAway } from "@/mixins/ClickAway";
import { ROUTES } from "@/router";
import { fsRouterLocation } from "@/router/fs";
import { setUserOnly } from "@/services/fs.service";
import { WorkspaceRename, WorkspacesRefresh } from "@/store/fs/actionTypes";
import { AxShare } from "@/store/state";

import AxButton from "@/components/AxButton.vue";
import AxButtonGroup from "@/components/AxButtonGroup.vue";
import AxChangeOwner from "@/components/AxChangeOwner.vue";
import AxDialog from "@/components/AxDialog.vue";
import AxDropdownButton from "@/components/AxDropdownButton.vue";
import AxEditable from "@/components/AxEditable.vue";
import AxFilesystemArchive from "@/components/AxFilesystemArchive.vue";
import AxFilesystemNodeDelete from "@/components/AxFilesystemNodeDelete.vue";
import AxFilesystemViewerActionsBase from "@/components/AxFilesystemViewerActionsBase.vue";
import AxForm from "@/components/AxForm.vue";
import AxHelp from "@/components/AxHelp.vue";
import AxIcon from "@/components/AxIcon.vue";
import AxList from "@/components/AxList.vue";
import AxListItem from "@/components/AxListItem.vue";
import AxManageUsersJoin from "@/components/AxManageUsers/AxManageUsersJoin.vue";
import AxSharedWithWorkspace from "@/components/AxSharedWithWorkspace.vue";
import AxToggleButton from "@/components/AxToggleButton.vue";
import AxWorkspaceIcon from "@/components/AxWorkspaceIcon.vue";
import { Submit } from "@/components/types/AxForm";

const itemProp: PropValidator<FilesystemNodeWorkspace> = {
  type: Object,
  required: true,
  validator(item) {
    return isWorkspaceNode(item);
  },
};

const props = {
  item: itemProp,
};

export default Vue.extend({
  directives: { onClickAway },
  components: {
    AxButton,
    AxButtonGroup,
    AxDialog,
    AxDropdownButton,
    AxEditable,
    AxIcon,
    AxList,
    AxListItem,
    AxFilesystemNodeDelete,
    AxFilesystemViewerActionsBase,
    AxSharedWithWorkspace,
    AxChangeOwner,
    AxManageUsersJoin,
    AxForm,
    AxHelp,
    AxToggleButton,
    AxWorkspaceIcon,
    AxFilesystemArchive,
  },

  props,

  data() {
    return {
      deletingWorkspace: false,
      changingOwnerWorkspace: false,
      joiningWorkspace: false,
      menuOpened: false,
      archivingWorkspace: false,
    };
  },

  computed: {
    inputClasses(): string {
      let className = "input-checkbox";
      if (this.item.userOnly) className += " checked";
      return className;
    },

    viewOnly(): boolean {
      return this.item.viewOnly;
    },

    shared(): boolean {
      return this.item.users && this.item.users.length > 0;
    },

    isDefaultWorkspace(): boolean {
      return this.item.isDefault;
    },

    rights(): WorkspaceRights {
      return this.$store.getters.workspaceRights(this.item);
    },

    workspaceLeave(): Location {
      return {
        name: ROUTES["fs.node.userleave"].name,
        params: { id: this.item.rootFolderId },
      };
    },

    securityConfigure(): Location {
      return {
        name: ROUTES["fs.node.configure"].name,
        params: { id: this.item.rootFolderId },
      };
    },

    anyManageUserAction(): boolean {
      return this.rights.usersInvite || this.rights.userLeave;
    },

    isLimitAccessAvailable(): boolean {
      return this.rights.securityConfigure && (this.isOnPrem || this.isSubInstance);
    },

    isOnPrem(): boolean {
      const { axShareConfig } = this.$store.state as AxShare;
      return axShareConfig !== null && axShareConfig.AxShareOnPrem;
    },

    isSubInstance(): boolean {
      const { axShareConfig } = this.$store.state as AxShare;
      return axShareConfig !== null && axShareConfig.IsSubInstance;
    },

    archiveText(): string {
      return this.item.isArchived ? "Unarchive" : "Archive";
    },

    userInviteRoute(): Location {
      return {
        name: ROUTES["fs.node.userinvite"].name,
        params: { id: this.item.rootFolderId },
      };
    },
  },

  methods: {
    async limitAccessChanged({ values }: Submit<FilesystemNodeWorkspace>) {
      const { isSubInstance, item } = this;

      if (isSubInstance) {
        await setUserOnly(item.id, values.userOnly);
        await this.$store.dispatch(new WorkspacesRefresh());
      }
    },

    toggleMenuBtn(_openedMenu: boolean) {
      this.menuOpened = _openedMenu;
    },

    closeMenu() {
      this.menuOpened = false;
    },

    onClickAway() {
      if (this.menuOpened) {
        this.closeMenu();
      }
    },

    async deleteSubmit() {
      this.deletingWorkspace = false;
      const defaultWorkspace: FilesystemNodeWorkspace = this.$store.getters.defaultWorkspace;
      await this.$router.push(
        fsRouterLocation(defaultWorkspace),
      );
    },

    deleting() {
      this.deletingWorkspace = true;
      this.closeMenu();
    },

    deleteCancel() {
      this.deletingWorkspace = false;
    },

    changeOwner() {
      this.changingOwnerWorkspace = true;
      this.closeMenu();
    },

    onOwnerChanged() {
      this.changingOwnerWorkspace = false;
    },

    joining() {
      this.joiningWorkspace = true;
      this.closeMenu();
    },

    joinCancel() {
      this.joiningWorkspace = false;
    },

    async renameSubmit(name: string) {
      await this.$store.dispatch(new WorkspaceRename({ Name: name, FilesystemId: this.item.id }));
    },

    archiving() {
      this.archivingWorkspace = true;
      this.closeMenu();
    },

    archiveSubmit() {
      this.archivingWorkspace = false;
    },

    archiveCancel() {
      this.archivingWorkspace = false;
    },
  },
});
