
import Vue from "vue";

export default Vue.extend({
  props: {
    backgroundColor: {
      type: String,
      default: "#b1eaef",
    },

    spinnerColor: {
      type: String,
      default: "#00b9ca",
    },

    progress: {
      type: Number,
      default: 0,
    },

    indeterminate: {
      type: Boolean,
      default: false,
    },

    short: {
      type: Boolean,
      default: false,
    },

    height: {
      type: String,
      default: "3px",
    },
  },

  data() {
    return {
      // random animation delay allows multiple spinners work not synchronously
      randomAnimationDelay: `${Math.random()}s`,
    };
  },

  computed: {
    spinnerClasses(): Record<string, boolean> {
      return {
        indeterminate: this.indeterminate,
        "indeterminate--short": this.indeterminate && this.short,
        "progress-bar": !this.indeterminate,
      };
    },

    spinnerStyles(): Partial<CSSStyleDeclaration> {
      const styles: Partial<CSSStyleDeclaration> = {};
      if (this.spinnerColor) styles.backgroundColor = this.spinnerColor;
      if (this.indeterminate) {
        styles.animationDelay = this.randomAnimationDelay;
      } else {
        styles.width = `${this.progress}%`;
      }
      return styles;
    },
  },
});
