import { NotificationErrorType } from "@/services/models/notification";

const accountServiceUnavailable =
  "Couldn't connect to the account service. Please, check your connection and try again.";
const enterpriseLicenseCheckFailed =
  "Your account's license needs to be verified. To continue, please publish an Axure RP project from Axure RP Enterprise or contact your system administrator.";
const betaFeaturesDisabled =
  "This feature is currently in beta and disabled on your instance of Axure Cloud for Business. Please contact your system administrator to enable beta features.";

export { enterpriseLicenseCheckFailed, accountServiceUnavailable, betaFeaturesDisabled };

export const NotificationErrorMessages = {
  [NotificationErrorType.InvalidData]:
    "Invalid Slack add/edit request, please retry. If problem persists, please contact us at support@axure.com.",
  [NotificationErrorType.InvalidDataTeams]:
    "Invalid Microsoft Teams add/edit request, please retry. If problem persists, please contact us at support@axure.com.",
  [NotificationErrorType.ExpiredToken]: "Expired Slack request, please retry.",
  [NotificationErrorType.ExpiredTokenTeams]: "Expired Microsoft Teams request, please retry.",
  [NotificationErrorType.SlackIssue]:
    "Issue encountered during request, please retry. If problem persists, please contact us at support@axure.com.",
  [NotificationErrorType.SlackAlreadyExist]: "Can not add Slack Notification. Slack Notification already exists.",
  [NotificationErrorType.Unknown]: "Unknown error. If problem persists, please contact us at support@axure.com.",
};
