import {
  ListingJs, ShortcutNotificationStateJs, ExtendedProjectPage, FolderRowJs,
} from "@shared/models";

import { FilesystemNodeTypes } from "@/common/fs";
import { Payload, PayloadMap } from "@/store/typed";

import { AxSearchData } from "./state";

export enum MutationTypes {
  Fetching = "[Filesystem] Fetching",
  Navigating = "[Filesystem] Navigating",
  SetContents = "[Filesystem] Set Contents",
  SetParentNode = "[Filesystem] Set Parent Node",
  Clear = "[Filesystem] Clear",
  NodeRemember = "[Filesystem] Remember Node",
  NodeRemove = "[Filesystem] Remove Node",
  NodeRemoveContents = "[Filesystem] Remove Node Contents",
  SetListingNode = "[Filesystem] Set Listing Node",
  SetViewOnly = "[Filesystem] Set View Only",
  ProjectRename = "[Filesystem] Project Rename",
  ProjectToggleFeedback = "[Filesystem] Project Toggle Feedback",
  ProjectToggleUserOnly = "[Filesystem] Project Toggle UserOnly",
  WorkspaceRename = "[Filesystem] Workspace Rename",
  FolderAdd = "[Filesystem] Folder Add",
  FolderRename = "[Filesystem] Folder Rename",
  SetSearchData = "[Filesystem] Set Search Data",
  RecentsLoad = "[Filesystem] Recents Load",
  SetProjectNotificationState = "[Filesystem] Set Project Notification State",
  WorkspaceArchive = "[Filesystem] Workspace Archive",
  FavoriteWorkspace = "[Filesystem] Favorite Workspace",
  SetFirstPage = "[Filesystem] Set First Page"
}

export class Fetching implements Payload {
  public readonly type = MutationTypes.Fetching;
  constructor(public id: string, public inProgress: boolean) { }
}

export class Navigating implements Payload {
  public readonly type = MutationTypes.Navigating;
  constructor(
    public id: string,
    public status: {
      inProgress: boolean;
      isError?: boolean;
      error?: Error | unknown;
    },
  ) { }
}

export class SetContents implements Payload {
  public readonly type = MutationTypes.SetContents;
  constructor(public node: FilesystemNodeTypes, public contents: ListingJs) { }
}

export class SetViewOnly implements Payload {
  public readonly type = MutationTypes.SetViewOnly;
  constructor(public node: FilesystemNodeTypes, public viewOnly: boolean) { }
}

export class Clear implements Payload {
  public readonly type = MutationTypes.Clear;
  constructor(public id: string) { }
}

export class NodeRemember implements Payload {
  public readonly type = MutationTypes.NodeRemember;
  constructor(public node: FilesystemNodeTypes) { }
}

export class SetParentNode implements Payload {
  public readonly type = MutationTypes.SetParentNode;
  constructor(public id: string, public parent: FilesystemNodeTypes | undefined) { }
}

export class NodeRemove implements Payload {
  public readonly type = MutationTypes.NodeRemove;
  constructor(public id: string) { }
}

export class NodeRemoveContents implements Payload {
  public readonly type = MutationTypes.NodeRemoveContents;
  constructor(public parentFolder: string, public id: string) { }
}

export class SetListingNode implements Payload {
  public readonly type = MutationTypes.SetListingNode;
  constructor(public id: string, public listingNode: any) { }
}

export class ProjectRename implements Payload {
  public readonly type = MutationTypes.ProjectRename;
  constructor(public id: string, public name: string) { }
}

export class ProjectToggleFeedback implements Payload {
  public readonly type = MutationTypes.ProjectToggleFeedback;
  constructor(public id: string, public enabled: boolean) { }
}

export class ProjectToggleUserOnly implements Payload {
  public readonly type = MutationTypes.ProjectToggleUserOnly;
  constructor(public id: string, public isUserOnly: boolean) { }
}

export class WorkspaceRename implements Payload {
  public readonly type = MutationTypes.WorkspaceRename;
  constructor(public id: string, public name: string) { }
}

export class WorkspaceArchive implements Payload {
  public readonly type = MutationTypes.WorkspaceArchive;
  constructor(public id: string, public archive: boolean) { }
}

export class FavoriteWorkspace implements Payload {
  public readonly type = MutationTypes.FavoriteWorkspace;
  constructor(public id: string, public favorite: boolean) { }
}

export class FolderAdd implements Payload {
  public readonly type = MutationTypes.FolderAdd;
  constructor(public node: FilesystemNodeTypes, public folderRowJs: FolderRowJs) { }
}

export class FolderRename implements Payload {
  public readonly type = MutationTypes.FolderRename;
  constructor(public id: string, public name: string) { }
}

export class SetSearchData implements Payload {
  public readonly type = MutationTypes.SetSearchData;
  constructor(public data: Partial<AxSearchData>) { }
}

export class RecentsLoad implements Payload {
  public readonly type = MutationTypes.RecentsLoad;
  constructor(public inProgress: boolean) { }
}

export class SetProjectNotificationState implements Payload {
  public readonly type = MutationTypes.SetProjectNotificationState;
  constructor(public id: string, public notificationState: Partial<ShortcutNotificationStateJs>) { }
}

export class SetFirstPage implements Payload {
  public readonly type = MutationTypes.SetFirstPage;
  constructor(public nodeId: string, public page: ExtendedProjectPage) { }
}

export interface MutationPayloadMap extends PayloadMap<MutationTypes> {
  [MutationTypes.Fetching]: Fetching;
  [MutationTypes.Navigating]: Navigating;
  [MutationTypes.SetContents]: SetContents;
  [MutationTypes.SetViewOnly]: SetViewOnly;
  [MutationTypes.Clear]: Clear;
  [MutationTypes.NodeRemember]: NodeRemember;
  [MutationTypes.SetParentNode]: SetParentNode;
  [MutationTypes.NodeRemove]: NodeRemove;
  [MutationTypes.NodeRemoveContents]: NodeRemoveContents;
  [MutationTypes.SetListingNode]: SetListingNode;
  [MutationTypes.ProjectRename]: ProjectRename;
  [MutationTypes.ProjectToggleFeedback]: ProjectToggleFeedback;
  [MutationTypes.ProjectToggleUserOnly]: ProjectToggleUserOnly;
  [MutationTypes.WorkspaceRename]: WorkspaceRename;
  [MutationTypes.FolderAdd]: FolderAdd;
  [MutationTypes.FolderRename]: FolderRename;
  [MutationTypes.SetSearchData]: SetSearchData;
  [MutationTypes.RecentsLoad]: RecentsLoad;
  [MutationTypes.SetProjectNotificationState]: SetProjectNotificationState;
  [MutationTypes.WorkspaceArchive]: WorkspaceArchive;
  [MutationTypes.FavoriteWorkspace]: FavoriteWorkspace;
  [MutationTypes.SetFirstPage]: SetFirstPage;
}
