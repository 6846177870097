import {
  FilesystemNode,
  FilesystemNodeClonable,
  FilesystemNodeShortcut,
  FilesystemNodeType,
  FilesystemNodeTypes,
  MentionType,
  MentionTypeName,
} from "@/common/fs";
import {
  FilesystemNodeWorkspace, WorkspaceRights, FilesystemNodeFolder, FilesystemNodeRoot,
} from "@/common/fs/types";
import { SsoResponse, ShortcutNotificationStateJs, FolderNotificationStateJs } from "@/generated/models";
import { DirectoryTreeNode, ShortcutType } from "@/services/models";

import { orderBy } from "../lib";

import { FilesystemSearchItem } from "@/components/types/AxSearch";

export function isRootNode(node: any): node is FilesystemNodeRoot {
  return !!node && node.type === FilesystemNodeType.Root;
}

export function isWorkspaceNode(node: any): node is FilesystemNodeWorkspace {
  return !!node && node.type === FilesystemNodeType.Workspace && !!node.id;
}

export function isShortcutNode(node: any): node is FilesystemNodeShortcut {
  return !!node && node.type === FilesystemNodeType.Shortcut && !!node.shortcut;
}

export function isFilesystemFolderNode(node: any): node is FilesystemNodeFolder {
  return !!node && !node.isSearchResult && node.type === FilesystemNodeType.Folder;
}

export function isShortcutSearchNode(node: any): node is FilesystemSearchItem {
  return !!node && node.isSearchResult;
}

export function isCloneableNode(node: FilesystemNode): node is FilesystemNodeClonable {
  if (isShortcutNode(node)) {
    const cloneableShortcutTypes: ShortcutType[] = [ShortcutType.Rp, ShortcutType.Lib];
    return cloneableShortcutTypes.some(type => node.shortcutType === type);
  }
  return false;
}

export function sortDirectoryTree(treeItems: DirectoryTreeNode[]) {
  return orderBy(
    treeItems,
    item => ({ value: item.defaultWorkspace || false, descending: true }),
    item => ({ value: item.isFavorite || false, descending: true }),
    item => ({ value: item.name }),
  );
}

export interface NodeOptions {
  folderId: string;
  canMove: boolean;
  canDelete: boolean;
}

export function getNodeOptions(node: FilesystemNodeTypes | undefined): NodeOptions {
  const options: NodeOptions = {
    folderId: "",
    canDelete: false,
    canMove: false,
  };
  if (!node) {
    return options;
  }

  if (node.type === FilesystemNodeType.Folder) {
    options.canDelete = node.viewOnly === false;
    options.canMove = node.viewOnly === false;
    options.folderId = node.id;
  }
  if (node.type === FilesystemNodeType.Workspace) {
    options.canDelete = node.viewOnly === false;
    options.canMove = node.viewOnly === false;
    options.folderId = node.rootFolderId;
  }
  return options;
}

export interface WorkspaceRightsContext {
  userInfo: SsoResponse;
  isSubInstance: boolean;
  hasAuthorRights: boolean;
  isAdminUser: boolean;
}

export function computeWorkspaceRights(
  workspace: FilesystemNodeWorkspace,
  env: WorkspaceRightsContext,
): WorkspaceRights {
  const {
    isDefault, viewOnly, isOwner, sharedWith,
  } = workspace;
  const {
    isSubInstance, hasAuthorRights, isAdminUser, userInfo,
  } = env;

  // Users can leave any workspace except their private default
  // Admins can leave only those workspaces that they explicitly joined
  const joinedWorkspace = isOwner || sharedWith.some(item => userInfo && userInfo.userId === item.user.userId);
  const canLeave = joinedWorkspace && !isDefault;

  if (isDefault || (isSubInstance && !hasAuthorRights)) {
    return {
      usersManage: false,
      usersInvite: false,
      usersRemove: false,
      userLeave: canLeave,
      securityConfigure: false,
      workspaceDelete: false,
      workspaceRename: false,
      workspaceJoin: false,
      workspaceArchive: false,
    };
  }
  const canBeShared = sharedWith && sharedWith.some(item => !item.isViewer);
  return {
    usersManage: !isDefault,
    usersInvite: !viewOnly && (!isSubInstance || (isSubInstance && hasAuthorRights)),
    usersRemove: !!isOwner || isAdminUser,
    securityConfigure: (isOwner || isAdminUser) && !isDefault,
    workspaceDelete: (isOwner || isAdminUser) && !isDefault,
    workspaceRename: !viewOnly && !isDefault && (!isSubInstance || (isSubInstance && hasAuthorRights)),
    userLeave: canLeave && (isSubInstance || !isOwner || canBeShared),
    workspaceJoin: !isOwner && !isDefault && !joinedWorkspace && isAdminUser,
    workspaceArchive: !viewOnly && !isDefault && (!isSubInstance || (isSubInstance && hasAuthorRights)),
  };
}

export function getUnreadCommentMentions(
  notificationState: ShortcutNotificationStateJs | FolderNotificationStateJs | undefined,
): number {
  return getUnreadMentions(notificationState, [MentionType.Issue, MentionType.IssueComment]);
}

export function getUnreadPublishNotesMentions(
  notificationState: ShortcutNotificationStateJs | FolderNotificationStateJs | undefined,
): number {
  return getUnreadMentions(notificationState, MentionType.PublishNote);
}

export function getUnreadMentions(
  notificationState: ShortcutNotificationStateJs | FolderNotificationStateJs | undefined,
  mentionTypes: MentionTypeName | MentionTypeName[],
): number {
  let unreadMentionsCount = 0;
  if (notificationState && notificationState.UnreadMentionsCount) {
    const unreadMentions = notificationState.UnreadMentionsCount;
    const types = Array.isArray(mentionTypes) ? [...mentionTypes] : [mentionTypes];

    for (const mentionType of types) {
      if (unreadMentions[mentionType]) unreadMentionsCount += unreadMentions[mentionType];
    }
  }

  return unreadMentionsCount;
}
