import { GetterTree } from "vuex";

import { AxShareCurrentUserInfo } from "@shared/models";

import { AccessLevels } from "@/services/models/account";
import { AxShareAdmin } from "@/store/admin/state";
import { AxShare } from "@/store/state";

const getterTree: GetterTree<AxShareAdmin, AxShare> = {
  currentAdminUser(state): AxShareCurrentUserInfo | undefined {
    return state.userInfo;
  },

  isAdminUser(_state, getters): boolean {
    const currentUser: AxShareCurrentUserInfo | undefined = getters.currentAdminUser;
    return (
      currentUser !== undefined
      && (currentUser.AccessLevel === AccessLevels.SuperAdmin || currentUser.AccessLevel === AccessLevels.Admin)
    );
  },

  isSuperAdminUser(_state, getters): boolean {
    const currentUser: AxShareCurrentUserInfo | undefined = getters.currentAdminUser;
    return currentUser !== undefined && currentUser.AccessLevel === AccessLevels.SuperAdmin;
  },

  isReviewerUser(_state, getters): boolean {
    const currentUser: AxShareCurrentUserInfo | undefined = getters.currentAdminUser;
    return currentUser !== undefined && currentUser.AccessLevel === AccessLevels.Reviewer;
  },

  isAuthorUser(_state, getters): boolean {
    const currentUser: AxShareCurrentUserInfo | undefined = getters.currentAdminUser;
    return currentUser !== undefined && currentUser.AccessLevel === AccessLevels.Author;
  },

  isTechnicalAdminUser(_state, getters): boolean {
    const currentUser: AxShareCurrentUserInfo | undefined = getters.currentAdminUser;
    return currentUser !== undefined && currentUser.AccessLevel === AccessLevels.TechnicalAdmin;
  },

  isSubInstanceAdmin(_state, getters, rootState): boolean {
    return rootState.axShareConfig && rootState.axShareConfig.IsSubInstance && getters.isAdminUser;
  },

  isSubInstanceTechnicalAdmin(_state, getters, rootState): boolean {
    return rootState.axShareConfig && rootState.axShareConfig.IsSubInstance && getters.isTechnicalAdminUser;
  },

  isSubInstanceReviewer(_state, getters, rootState): boolean {
    return rootState.axShareConfig && rootState.axShareConfig.IsSubInstance && getters.isReviewerUser;
  },

  isSubInstanceAuthor(_state, getters, rootState): boolean {
    return rootState.axShareConfig && rootState.axShareConfig.IsSubInstance && getters.isAuthorUser;
  },

  hasAuthorRights(_state, getters): boolean {
    const currentUser: AxShareCurrentUserInfo | undefined = getters.currentAdminUser;
    return currentUser !== undefined && currentUser.AccessLevel <= AccessLevels.Author;
  },
};

export default getterTree;
