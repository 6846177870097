import {
  HubConnectionBuilder, HubConnection, HubConnectionState, LogLevel, IRetryPolicy,
} from "@microsoft/signalr";

import { isDevelopment } from "@/common/environment";
import { retryUntilTruthy, randomIntegerBetween } from "@/common/lib";
import store from "@/store";
import { AxShare } from "@/store/state";

export type SignalRHubListener = (...args: any[]) => void;
export type SignalRHubListenerOf<T = any> = (data: T) => void;
export type SignalRHubListeners = Record<string, SignalRHubListener | SignalRHubListenerOf>;

export type HubName = "notifications" | "workspaces" | "projects";

const retryPolicyFactory = (): IRetryPolicy => {
  const intervals = [
    [0, 10],
    [20, 40],
    [60, 90],
    [120, 180],
  ].map(([from, to]) => randomIntegerBetween(from * 1000, to * 1000));
  const retryIntervals = [...intervals, null];

  return {
    nextRetryDelayInMilliseconds(retryContext): number | null {
      return retryIntervals[retryContext.previousRetryCount];
    },
  };
};

interface HubConnectionMetadata {
  hasOnReconnectedHandler: boolean;
}

class PushNotificationsService {
  private readonly hubConnections: Record<string, HubConnection | undefined> = {};
  private readonly hubConnectionMetadata: WeakMap<HubConnection, HubConnectionMetadata> = new WeakMap();
  private readonly activeHubListeners: Record<string, number> = {};
  private readonly groupsMembership: Map<string, Set<string>> = new Map();

  private get ApiUrl(): string {
    return store.getters.apiUrl;
  }

  private get Enabled(): boolean {
    const { axShareConfig } = store.state as AxShare;
    const enablePushNotifications = !!axShareConfig && axShareConfig.EnablePushNotifications === true;
    return enablePushNotifications;
  }

  public async disconnect() {
    const disconnectFromHubTasks = Object.keys(this.hubConnections).map(this.disconnectFromHub);
    await Promise.all(disconnectFromHubTasks);
  }

  public async addHubListeners(hubName: HubName, listeners: SignalRHubListeners) {
    const connection = await this.ensureHubStarted(hubName);
    if (connection) {
      for (const methodName in listeners) {
        if (Object.prototype.hasOwnProperty.call(listeners, methodName)) {
          const listener = listeners[methodName];
          connection.on(methodName, listener);
          this.activeHubListeners[hubName]++;
        }
      }
    }
  }

  public async removeHubListeners(hubName: HubName, listeners: SignalRHubListeners) {
    const connection = this.hubConnections[hubName];
    if (connection) {
      for (const methodName in listeners) {
        if (Object.prototype.hasOwnProperty.call(listeners, methodName)) {
          const listener = listeners[methodName];
          connection.off(methodName, listener);
          this.activeHubListeners[hubName]--;
        }
      }

      if (this.activeHubListeners[hubName] <= 0) {
        await this.disconnectFromHub(hubName);
      }
    }
  }

  public async addToGroup(hubName: HubName, groupName: string) {
    const groupNameNormalized = groupName.toLowerCase();
    const connection = await this.ensureHubStarted(hubName);
    if (connection) {
      await connection.send("AddToGroup", groupNameNormalized);

      let groups = this.groupsMembership.get(hubName);
      if (!groups) {
        groups = new Set();
        this.groupsMembership.set(hubName, groups);
      }
      groups.add(groupNameNormalized);
    }
  }

  public async removeFromGroup(hubName: HubName, groupName: string) {
    const groupNameNormalized = groupName.toLowerCase();
    // don't have to ensure that hub's connected
    // when client disconnects its group membership will be revoked
    const connection = this.hubConnections[hubName];

    if (connection && connection.state === HubConnectionState.Connected) {
      await connection.send("RemoveFromGroup", groupNameNormalized);

      const groups = this.groupsMembership.get(hubName);
      if (groups) {
        groups.delete(groupNameNormalized);
      }
    }
  }

  private async ensureHubStarted(hubName: HubName): Promise<HubConnection | undefined> {
    if (!this.Enabled) return undefined;

    let connection = this.hubConnections[hubName];

    if (!connection) {
      // Use access token to be able to identify user
      connection = new HubConnectionBuilder()
        .withUrl(`${this.ApiUrl}/hubs/${hubName}`, {
          withCredentials: true,
        })
        .withAutomaticReconnect(retryPolicyFactory())
        .configureLogging(isDevelopment ? LogLevel.Information : LogLevel.Warning)
        .build();
      this.hubConnections[hubName] = connection;
      this.activeHubListeners[hubName] = 0;
    }

    if (connection.state === HubConnectionState.Disconnected) {
      try {
        await connection.start();
      } catch (error) {
        // failed to connect to SignalR hub
        // it's possible that server is actively refusing new connections
        // in that case we're handling this gracefully, as if push notifications are disabled completely
        return;
      }
    }

    const connected = await this.connected(connection);
    if (connected) {
      const metadata = this.getConnectionMetadata(connected);
      if (!metadata.hasOnReconnectedHandler) {
        connected.onreconnected(connectionId => this.restoreGroupsMembership(hubName, connectionId, connected));
        metadata.hasOnReconnectedHandler = true;
      }
      return connected;
    }
  }

  private getConnectionMetadata(connection: HubConnection) {
    let metadata = this.hubConnectionMetadata.get(connection);
    if (metadata) return metadata;

    metadata = {
      hasOnReconnectedHandler: false,
    };
    this.hubConnectionMetadata.set(connection, metadata);
    return metadata;
  }

  private async restoreGroupsMembership(
    hubName: HubName,
    connectionId: string | undefined,
    connection: HubConnection,
  ): Promise<void> {
    if (connectionId && connection.state === HubConnectionState.Connected) {
      const groups = this.groupsMembership.get(hubName);
      if (groups) {
        for (const group of groups) {
          // eslint-disable-next-line no-await-in-loop
          await this.addToGroup(hubName, group);
        }
      }
    }
  }

  private async disconnectFromHub(hubName: string) {
    const connection = this.hubConnections[hubName];
    if (connection) {
      if (connection.state === HubConnectionState.Connecting) {
        // tried to immediately close connection
        // give it little timeout to finish connecting and then close connection
        await this.connected(connection);
      }
      if (connection.state === HubConnectionState.Connected) {
        await connection.stop();
      }
      this.hubConnections[hubName] = undefined;
      delete this.hubConnections[hubName];
    }
  }

  private async connected(connection: HubConnection | undefined): Promise<HubConnection | undefined> {
    // giving some time until connection is fully ready
    const retries = 50;
    const timeout = 50;
    return retryUntilTruthy(
      () => {
        if (connection && connection.state === HubConnectionState.Connected) {
          return connection;
        }
        return undefined;
      },
      retries,
      timeout,
    );
  }
}

export const pushNotificationsService = new PushNotificationsService();
