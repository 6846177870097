
import Vue from "vue";

import { AxShare } from "@/store/state";

const AxWorkspacesMigrationDialog = () => import("./AxWorkspacesMigrationDialog.vue");

export default Vue.extend({
  components: {
    AxWorkspacesMigrationDialog,
  },

  computed: {
    allowWorkspacesMigrate(): boolean {
      return this.$store.getters.allowWorkspacesMigrate;
    },

    inProgress(): boolean {
      const { migration } = this.$store.state as AxShare;
      return migration.inProgress;
    },

    migrationId(): string {
      const { migration } = this.$store.state as AxShare;
      return migration.migrationId;
    },
  },
});
