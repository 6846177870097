import { DirectiveOptions, VNode, VNodeDirective } from "vue";

const overlayClass = "_vue_clickaway_overlay";
const HANDLER = "_vue_clickaway_handler";
interface ClickAwayHTMLElement extends HTMLElement {
  [HANDLER]?: {
    handler: (e: MouseEvent) => void;
    target: HTMLElement;
  };
}

type Options =
  | ((e: MouseEvent) => void)
  | {
      handler: (e: MouseEvent) => void;
      closeConditional?: (e: MouseEvent) => boolean;
      include?: () => Element[];
      overlay: boolean;
    };

function closeConditionalDefault(_e: MouseEvent) {
  return true;
}

function validate(handler: CallableFunction | null, binding: VNodeDirective): handler is CallableFunction {
  if (typeof handler === "function") return true;
  if (process.env.NODE_ENV !== "production") {
    console.warn(`v-${binding.name}="${binding.expression}" expects a function value, got ${handler}`);
  }
  return false;
}

function getOptions(binding: VNodeDirective) {
  const options: Options = binding.value;
  let callback: ((e: MouseEvent) => void) | null = null;
  let overlay = false;
  let closeConditional = closeConditionalDefault;
  let include: () => Element[] = () => [];
  if (typeof options === "function") {
    callback = options;
  }
  if (typeof options === "object") {
    callback = options.handler;
    if (options.closeConditional && typeof options.closeConditional === "function") {
      closeConditional = options.closeConditional;
    }
    if (options.include && typeof options.include === "function") {
      include = options.include;
    }
    if (typeof options.overlay === "boolean") {
      overlay = options.overlay;
    }
  }

  return { callback, overlay, closeConditional, include };
}

function bind(_element: HTMLElement, binding: VNodeDirective, _vnode: VNode) {
  const options = getOptions(binding);
  validate(options.callback, binding);
}

function unbind(element: HTMLElement) {
  const el = element as ClickAwayHTMLElement;
  const bindedHandler = el[HANDLER];
  if (bindedHandler) {
    const { handler, target } = bindedHandler;
    target.removeEventListener("click", handler, {
      capture: true,
    });
    if (target.classList.contains(overlayClass)) {
      target.remove();
    }
    delete el[HANDLER];
  }
}

function inserted(element: HTMLElement, binding: VNodeDirective, vnode: VNode) {
  const el = element as ClickAwayHTMLElement;

  const vm = vnode.context;
  const { callback, overlay, closeConditional, include } = getOptions(binding);

  // @NOTE: Vue binds directives in microtasks, while UI events are dispatched
  //        in macrotasks. This causes the listener to be set up before
  //        the "origin" click event (the event that lead to the binding of
  //        the directive) arrives at the document root. To work around that,
  //        we ignore events until the end of the "initial" macrotask.
  let initialMacrotaskEnded = false;
  setTimeout(() => {
    initialMacrotaskEnded = true;
  }, 0);

  el[HANDLER] = {
    handler(ev: MouseEvent) {
      if (!callback) return;
      const e = ev as any;
      const path = e.path || (e.composedPath ? e.composedPath() : undefined);
      if (initialMacrotaskEnded) {
        const isActive = closeConditional;
        if (!isActive(e)) return;

        const includeNodes = include().reduce(
          (acc, curr) => {
            acc.push(...curr.getElementsByTagName("*"));
            return acc;
          },
          [] as Element[]
        );
        const clickedInEls =
          (path && path.indexOf(el) >= 0) ||
          (ev.target && ev.target instanceof Element && (el.contains(ev.target) || includeNodes.includes(ev.target)));
        if (!clickedInEls) {
          return callback.call(vm, ev);
        }
      }
    },
    target: resolveTarget(overlay, vnode),
  };

  const bindedHandler = el[HANDLER];
  if (bindedHandler) {
    const { handler, target } = bindedHandler;
    target.addEventListener("click", handler, {
      passive: true,
      capture: true,
    });
  }
}

export const directive: DirectiveOptions = {
  bind,
  inserted,
  update(el, binding, vnode) {
    if (binding.value === binding.oldValue) return;
    bind(el, binding, vnode);
  },
  unbind,
};

export const mixin = {
  directives: { onClickaway: directive },
};

function resolveTarget(createOverlay: boolean, binding: VNode): HTMLElement {
  const target = ((binding.context && binding.context.$root.$el) ||
    document.querySelector("#app") ||
    document.documentElement) as HTMLElement;
  if (!createOverlay) return target;

  const element = binding.elm;
  if (!element) return target;

  const parent = element.parentElement;
  if (!parent) return target;

  const overlay = document.createElement("div");
  overlay.classList.add(overlayClass);
  parent.appendChild(overlay);
  return overlay;
}
