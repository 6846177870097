import {
  resolveAccountServiceHost, resolveApiBaseUrl, setApiBaseUrl, getApiCallId,
} from "@/common/axshare/api";
import { persistentStorage } from "@/common/axshare/persistent";
import { equalsIgnoringCase } from "@/common/lib";
import { reconfigure } from "@/services/server";
import { getConfig, getVersion } from "@/services/user.service";
import { AxShare } from "@/store/state";
import { GetUserInfo, GetUserProfile } from "@/store/user/actionTypes";

import {
  ActionPayloadMap,
  ActionTypes,
  Authenticate,
  Initialize,
  LoadConfig,
  SetupAccountService,
  ApiCall,
  ApiCallOptions,
  Logout,
} from "./actionTypes";
import { GetAdminInfo } from "./admin/actionTypes";
import { asyncAction, AsyncActionState } from "./async-action";
import * as mutations from "./mutationTypes";
import { ActionTree } from "./typed";

const actions: ActionTree<AxShare, AxShare, ActionPayloadMap> = {
  async [ActionTypes.Initialize]({ state, dispatch, commit }, {
    force, apiUrl, authToken, useGet,
  }) {
    if (force) {
      commit(new mutations.Reset());
    }

    const canInitialize = force || state.initializationStatus !== "success";
    if (canInitialize) {
      setApiBaseUrl(apiUrl);
      reconfigure(resolveApiBaseUrl(state.axShareConfig));
      try {
        await dispatch(new LoadConfig(force));
        await dispatch(new SetupAccountService(force, authToken, useGet));
        commit(new mutations.InitializationStatus("success"));
        return state.axShareConfig;
      } catch (error) {
        commit(new mutations.InitializationStatus("failure", error));
        throw error;
      }
    }
  },

  async [ActionTypes.ApiCall]({ state, commit }, { options }) {
    const { action, id, onFailure } = options;
    let apiCallId = id;
    if (!apiCallId) {
      apiCallId = getApiCallId();
      commit(new mutations.ApiCallAdd(apiCallId, action, onFailure));
    }

    let result;
    try {
      commit(new mutations.ApiCallSetStatus(apiCallId, AsyncActionState.Fetching));
      result = await action();
      commit(new mutations.ApiCallSetStatus(apiCallId, AsyncActionState.Success));
    } catch (error) {
      commit(new mutations.ApiCallSetStatus(apiCallId, AsyncActionState.Failure));
      if (onFailure) {
        await onFailure(error);
      } else {
        throw error;
      }
    } finally {
      const apiCall = state.apiCalls[apiCallId];
      if (apiCall && apiCall.status === AsyncActionState.Success) {
        commit(new mutations.ApiCallRemove(apiCallId));
      }
    }
    return result;
  },

  async [ActionTypes.ApiCallRetry]({ state, commit, dispatch }, { apiCallId }) {
    const apiCall = state.apiCalls[apiCallId];
    if (!apiCall) return;
    const { action, onFailure } = apiCall;
    commit(new mutations.ApiCallRetry(apiCallId));
    const options: ApiCallOptions = {
      id: apiCallId,
      action,
      onFailure,
    };
    await dispatch(new ApiCall(options));
  },

  async [ActionTypes.ApiCallClear]({ commit }) {
    commit(new mutations.ApiCallClear());
  },

  async [ActionTypes.LoadConfig](context, { force }) {
    const { state } = context;
    if (state.axShareConfig != null && !force) return;
    return asyncAction(
      context,
      ActionTypes.LoadConfig,
      () => getConfig(),
      response => new mutations.ConfigSet(response),
    );
  },

  async [ActionTypes.SetupAccountService]({
    state, getters, commit, dispatch,
  }, { force, authToken, useGet }) {
    if (state.axShareConfig == null) return;
    if (state.accountService != null && !force) return;
    if (!getters.appConfigured) return;
    const accountServiceHost = resolveAccountServiceHost(state.axShareConfig);
    const axshareHost = resolveApiBaseUrl(state.axShareConfig);
    commit(new mutations.SetupAccountService(accountServiceHost, axshareHost, authToken, useGet));
    await dispatch(new Authenticate());
  },

  async [ActionTypes.Authenticate]({ state, dispatch }) {
    if (state.axShareConfig == null) return;
    if (state.accountService == null) return;

    if (state.accountService) {
      try {
        const accountService = state.accountService();
        const isAuthed = await accountService.auth();
        const afterAuthActions = [];
        afterAuthActions.push(dispatch(new GetAdminInfo()));

        if (isAuthed) {
          afterAuthActions.push(dispatch(new GetUserInfo()));
          afterAuthActions.push(dispatch(new GetUserProfile()));
        }

        await Promise.all(afterAuthActions);

        if (isAuthed) {
          // assert that userId match in userInfo and accountService
          // ensure that the same user is signed in to Accounts and Cloud
          // see A9-2850 for more
          const userInfoUserId = state.user?.userInfo?.userId;
          const accountServiceUserId = accountService.UserId;
          if (!equalsIgnoringCase(userInfoUserId, accountServiceUserId)) {
            await dispatch(new Logout());
            throw new Error("Incorrect login status. User mismatch between Cloud and Accounts occurred.");
          }
        }
      } catch (error) {
        console.warn(`Authentication against account service has failed: ${error}`);
        // uncomment logout redirect after clarifying Safari behavior
        // const logoutUrl = `${AxShareHostSecureUrl}/user/logout`;
        // window.location.href = logoutUrl;
      }
    }
  },

  async [ActionTypes.Logout]({ state, commit, dispatch }, { onSuccess, onFailure }) {
    const { accountService } = state;
    if (accountService) {
      try {
        await accountService().logout();
        if (onSuccess) {
          await onSuccess();
        }
        await dispatch(new Initialize(true));
      } catch (error) {
        if (onFailure) {
          await onFailure();
        }
        throw error;
      }
    } else {
      commit(new mutations.Reset());
    }
  },

  async [ActionTypes.LoadApiVersionInfo]({ commit }) {
    const version = await getVersion();
    commit(new mutations.ApiVersionSet(version));
    persistentStorage.setApiVersionInfo(version);
  },
};

export default actions;
