
import Vue from "vue";
import { PropValidator } from "vue/types/options";

import { FilesystemNodeTypes } from "@/common/fs";
import AxFilesystemViewerActionsBase from "@/components/AxFilesystemViewerActionsBase.vue";

const itemProp: PropValidator<FilesystemNodeTypes> = {
  type: Object,
  required: true,
};
const props = {
  item: itemProp,
};

export default Vue.extend({
  components: {
    AxFilesystemViewerActionsBase,
  },
  props,
});
