
import Vue from "vue";
import { Location } from "vue-router";
import { PropValidator } from "vue/types/options";

import {
  isShortcutNode,
  isFilesystemFolderNode,
  FilesystemNodeShortcut,
  FilesystemNodeFolder,
  FilesystemNodeType,
  getUnreadCommentMentions,
} from "@/common/fs";
import { ROUTES } from "@/router";

import AxButton from "@/components/AxButton.vue";
import AxIcon from "@/components/AxIcon.vue";
import AxTooltip from "@/components/AxTooltip.vue";
import AxUnreadMentionsIndicator from "@/components/AxUnreadMentionsIndicator.vue";
import { markShortcutAsRead } from "@/components/standalone/services/issue.service";

const projectProp: PropValidator<FilesystemNodeShortcut | FilesystemNodeFolder> = {
  type: Object,
  required: true,
  validator(item) {
    return isShortcutNode(item) || isFilesystemFolderNode(item);
  },
};

export default Vue.extend({
  components: {
    AxUnreadMentionsIndicator,
    AxButton,
    AxIcon,
    AxTooltip,
  },

  props: {
    project: projectProp,
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    navigationDisabled(): boolean {
      if (this.disabled) return true;
      if (this.project.type === FilesystemNodeType.Shortcut) return !this.project.feedbackEnabled;
      if (this.project.type === FilesystemNodeType.Folder && this.project.listingNode) {
        return !this.project.listingNode.NotificationState?.NewCommentShortcutFeedbackEnabled;
      }

      return true;
    },

    hasNewComments(): boolean {
      return !!this.project.listingNode && this.project.listingNode.NotificationState?.HasNewComments;
    },

    hasUnreadCommentsMentions(): boolean {
      if (!this.project.listingNode) return false;
      const commentsMentionsCount = getUnreadCommentMentions(this.project.listingNode.NotificationState);
      return commentsMentionsCount > 0;
    },

    commentsClasses(): any {
      const classes = [
        {
          "new-comments": this.hasNewComments || this.hasUnreadCommentsMentions,
        },
        "comments-text",
      ];

      return classes;
    },

    commentsCountMessage(): string {
      if (!this.project.listingNode) {
        return "";
      }

      const totalCount = this.project.listingNode?.NotificationState?.TotalCommentsCount ?? 0;

      // \xa0 - unbreakable space symbol
      let message = `${totalCount}\xa0comment`;
      if (totalCount !== 1) message += "s";

      if (this.project.listingNode.NotificationState?.NewCommentsCount) {
        message += `, ${this.project.listingNode.NotificationState.NewCommentsCount}\xa0new`;
      }

      return message;
    },

    viewCommentsRoute(): Location {
      let shortcut = "";
      if (this.project.type === FilesystemNodeType.Shortcut) {
        shortcut = this.project.shortcut;
      } else if (this.project.type === FilesystemNodeType.Folder && this.project.listingNode) {
        shortcut = this.project.listingNode.NotificationState?.NewCommentShortcut;
      }

      return {
        name: ROUTES["project.comments"].name,
        params: { shortcut: shortcut || this.project.id },
      };
    },
  },

  methods: {
    async markAsRead() {
      await markShortcutAsRead(this.project.id);
    },
  },
});
