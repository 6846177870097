import { MutationPayloadMap, MutationTypes } from "@/store/settings/mutationTypes";
import { AxShareSettings } from "@/store/settings/state";
import { MutationTree } from "@/store/typed";

const mutations: MutationTree<AxShareSettings, MutationPayloadMap> = {
  [MutationTypes.SetShowAllWorkspaces](state, { show }) {
    state.showAllWorkspaces = show;
  },
  [MutationTypes.SetSavedWorkspaceId](state, { id }) {
    state.savedWorkspaceId = id;
  },

  [MutationTypes.SetShowArchived](state, { show }) {
    state.showArchived = show;
  },
};

export default mutations;
