
import Vue from "vue";

import { AxShare } from "@/store/state";

export default Vue.extend({
  computed: {
    isSubInstance(): boolean {
      const { axShareConfig } = this.$store.state as AxShare;
      return axShareConfig !== null && axShareConfig.IsSubInstance;
    },
  },
});
