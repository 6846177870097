import { isProduction } from "@/common/environment";
import { getUserInfo, refreshAuth } from "@/services/user.service";
import { asyncAction } from "@/store/async-action";
import { AxShare } from "@/store/state";
import { ActionTree } from "@/store/typed";
import { ActionPayloadMap, ActionTypes, SetUserTimeZone } from "@/store/user/actionTypes";
import { SetInfo, SetProfile } from "@/store/user/mutationsTypes";
import { AxShareUser } from "@/store/user/state";

const actions: ActionTree<AxShareUser, AxShare, ActionPayloadMap> = {
  [ActionTypes.GetUserInfo](context) {
    const appConfigured: boolean = context.rootGetters.appConfigured;

    const { dispatch } = context;

    if (appConfigured) {
      return asyncAction(
        context,
        ActionTypes.GetUserInfo,
        async () => {
          let userInfoResponse = await getUserInfo();

          if (userInfoResponse) {
            if (!userInfoResponse.localTimeZone) {
              try {
                await dispatch(new SetUserTimeZone());
                userInfoResponse = await getUserInfo();
              } catch (e) {
                console.error("Error setting time zone");
                console.error(e);
              }
            }

            context.commit(new SetInfo(userInfoResponse));
          }
        },
      );
    }
  },

  [ActionTypes.GetUserProfile](context) {
    if (context.rootState.accountService) {
      const appConfigured: boolean = context.rootGetters.appConfigured;

      if (appConfigured) {
        const accountService = context.rootState.accountService;

        return asyncAction(
          context,
          ActionTypes.GetUserProfile,
          async () => accountService().getUserProfile(),
          response => new SetProfile(response),
        );
      }
    }
  },

  [ActionTypes.ChangeAccountInfo](context, { model }) {
    if (context.rootState.accountService) {
      const accountService = context.rootState.accountService;
      return asyncAction(context, ActionTypes.ChangeAccountInfo, async () => {
        const authToken = await accountService().changeAccountInfo(model);

        // update cookies with app server
        if (authToken) {
          await refreshAuth(authToken);
        }

        // temporarily follow redirect only in production mode
        const followRedirect = isProduction;
        await getUserInfo(followRedirect);
      });
    }
  },

  async [ActionTypes.SetUserTimeZone](context, { timeZone }) {
    if (context.rootState.accountService) {
      await context.rootState.accountService().setUserTimeZone(timeZone);
    }
  },
};

export default actions;
