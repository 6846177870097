export function round(value: number, precision = 0) {
  const multiplier = Math.pow(10, precision);
  return Math.round(value * multiplier) / multiplier;
}

export function randomBetween(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

export function randomIntegerBetween(min: number, max: number) {
  const rMin = Math.ceil(min);
  const rMax = Math.floor(max);
  return Math.floor(Math.random() * (rMax - rMin)) + rMin;
}
