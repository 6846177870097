import { Payload } from "vuex";

import { NotificationJs, NotificationMuteItemJs } from "@/generated/models";
import { PayloadMap } from "@/store/typed";

export enum MutationTypes {
  AddNotification = "[Notifications] Add Notification",
  AddNotificationMuteItem = "[Notifications] Add Notification Mute Item",
  EditNotification = "[Notifications] Edit Notification",
  SetUserSubscriptions = "[Notifications] Set User Subscriptions",
  SetUserGuestSubscriptions = "[Notifications] Set User Guest Subscriptions",
  SetNotificationMuteItems = "[Notifications] Set Notification Mute Items",
  DeleteUserSubscription = "[Notifications] Delete User Subscription",
  DeleteUserGuestSubscription = "[Notifications] Delete User Guest Subscription",
  DeleteNotificationMuteItem = "[Notifications] Delete Notification Mute Item",
}

export class AddNotification implements Payload {
  public readonly type = MutationTypes.AddNotification;
  constructor(public notification: NotificationJs) {}
}

export class AddNotificationMuteItem implements Payload {
  public readonly type = MutationTypes.AddNotificationMuteItem;
  constructor(public notification: NotificationMuteItemJs) {}
}

export class EditNotification implements Payload {
  public readonly type = MutationTypes.EditNotification;
  constructor(public notification: NotificationJs) {}
}

export class SetUserSubscriptions implements Payload {
  public readonly type = MutationTypes.SetUserSubscriptions;
  constructor(public notifications: NotificationJs[]) {}
}

export class SetUserGuestSubscriptions implements Payload {
  public readonly type = MutationTypes.SetUserGuestSubscriptions;
  constructor(public notifications: NotificationJs[]) {}
}

export class SetNotificationMuteItems implements Payload {
  public readonly type = MutationTypes.SetNotificationMuteItems;
  constructor(public notifications: NotificationMuteItemJs[]) {}
}

export class DeleteUserSubscription implements Payload {
  public readonly type = MutationTypes.DeleteUserSubscription;
  constructor(public notificationId: string) {}
}

export class DeleteUserGuestSubscription implements Payload {
  public readonly type = MutationTypes.DeleteUserGuestSubscription;
  constructor(public notificationId: string) {}
}

export class DeleteNotificationMuteItem implements Payload {
  public readonly type = MutationTypes.DeleteNotificationMuteItem;
  constructor(public notificationId: string) {}
}

export interface MutationPayloadMap extends PayloadMap<MutationTypes> {
  [MutationTypes.AddNotification]: AddNotification;
  [MutationTypes.AddNotificationMuteItem]: AddNotificationMuteItem;
  [MutationTypes.EditNotification]: EditNotification;
  [MutationTypes.SetUserSubscriptions]: SetUserSubscriptions;
  [MutationTypes.SetUserGuestSubscriptions]: SetUserGuestSubscriptions;
  [MutationTypes.SetNotificationMuteItems]: SetNotificationMuteItems;
  [MutationTypes.DeleteUserSubscription]: DeleteUserSubscription;
  [MutationTypes.DeleteUserGuestSubscription]: DeleteUserGuestSubscription;
  [MutationTypes.DeleteNotificationMuteItem]: DeleteNotificationMuteItem;
}
