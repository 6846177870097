import { compare } from "compare-versions";
import { add } from "date-fns";

import { persistentStorage } from "@/common/axshare/persistent";

export class VersionCheck {
  private clientVersion?: string;
  private acknowledgedAt?: number;

  constructor(
    private readonly serverVersion: string,
    private readonly serverReleaseDate: Date,
    private readonly outdatedAfter: Duration,
  ) {
    let storedVersion = persistentStorage.getLastVersion();

    if (storedVersion && !storedVersion.version) {
      // in previous implementation clientVersion could be empty,
      // so we keep backwards compatibility by setting it to server version and reloading state
      this.clientVersion = serverVersion;
      this.sync();
      storedVersion = persistentStorage.getLastVersion();
    }

    if (storedVersion) {
      this.clientVersion = storedVersion.version;
      if (compare(serverVersion, this.clientVersion, ">")) {
        this.acknowledgedAt = undefined;
        this.sync();
      } else if (compare(serverVersion, this.clientVersion, "<")) {
        // version rollback occurred - rollback client version and set it as acknowledged
        this.acknowledgedAt = Date.now();
        this.sync();
      } else {
        this.acknowledgedAt = storedVersion.acknowledgedAt;
        this.serverReleaseDate = new Date(storedVersion.releaseDate);
      }
    } else {
      this.sync();
    }
  }

  private hasNewVersion(): boolean {
    return !this.clientVersion || compare(this.serverVersion, this.clientVersion, ">");
  }

  private sync() {
    persistentStorage.setLastVersion({
      version: this.serverVersion,
      releaseDate: this.serverReleaseDate.getTime(),
      acknowledgedAt: this.acknowledgedAt,
    });
  }

  public notifyAboutNewVersion(now: Date): boolean {
    if (!this.hasNewVersion()) {
      if (this.acknowledgedAt) {
        return false;
      }
    }

    const serverReleased = new Date(this.serverReleaseDate);
    const outdatedDate = add(serverReleased, this.outdatedAfter);
    return outdatedDate > new Date(now);
  }

  public acknowledge(): void {
    this.clientVersion = this.serverVersion;
    this.acknowledgedAt = Date.now();
    this.sync();
  }
}
