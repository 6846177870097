
import Vue from "vue";

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "AxToggleButton",
    },
    colorChecked: {
      type: String,
      default: "#C7E5F5",
    },
    colorUnchecked: {
      type: String,
      default: "#a0aec0",
    },
    large: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      toggled: !!this.value,
    };
  },

  computed: {
    className(): Record<string, boolean> {
      return {
        toggled: this.toggled,
        disabled: this.disabled,
      };
    },
    toggleColor(): String {
      if (this.toggled) {
        return this.colorChecked;
      }

      return this.colorUnchecked;
    },
  },

  watch: {
    value(value) {
      this.toggled = !!value;
    },
  },

  methods: {
    toggle(event: any) {
      this.toggled = !this.toggled;
      this.$emit("input", this.toggled);
      this.$emit("change", {
        value: this.toggled,
        srcEvent: event,
      });
    },
  },
});
