
import Vue from "vue";

import { AnalyticsClickEvents, isSketchProject } from "@/common/axshare";
import { enterpriseLicenseCheckFailed } from "@/common/axshare/messages";
import { FilesystemNodeWithContents, FilesystemNodeShortcut } from "@/common/fs";
import { orderBy, noop } from "@/common/lib";
import { RichText } from "@/generated/models";
import { AxShare } from "@/store/state";
import { ArtboardUploadBatch } from "@/store/uploads/state";

import { createPublishNote } from "../services/project.service";

import AxButton from "@/components/AxButton.vue";
import AxDropzone from "@/components/AxDropzone.vue";
import AxIcon from "@/components/AxIcon.vue";
import AxPublishNotes from "@/components/AxPublishNotes.vue";
import AssetUploader from "@/components/expo/AssetUploader.vue";

interface IUploaderFile {
  id: string;
  name: string;
}

type AssetUploader = Vue & {
  files: Record<string, IUploaderFile>;
  batchId: string;
  triggerBrowse(): void;
  addFile(file: File): void;
  processFiles(): void;
  clear(): void;
  removeFile(file: IUploaderFile): boolean;
  removeFileById(id: string): boolean;
};

export default Vue.extend({
  components: {
    AxDropzone,
    AxIcon,
    AssetUploader,
    AxButton,
    AxPublishNotes,
  },

  data() {
    return {
      activatedKey: 0,
      close: noop,
      AnalyticsClickEvents,
      publishNote: undefined as RichText | undefined,
      uploading: {} as Record<string, boolean | undefined>,
    };
  },

  computed: {
    disableUpload(): boolean {
      return this.$store.getters.enterpriseLicenseCheckFailed;
    },

    disableUploadMessage(): string {
      return enterpriseLicenseCheckFailed;
    },

    batches(): ArtboardUploadBatch[] {
      const { uploads } = this.$store.state as AxShare;
      return Object.values(uploads.batches).filter(Boolean) as ArtboardUploadBatch[];
    },

    batchesInProgress(): ArtboardUploadBatch[] {
      const batchesInProgress = this.$store.getters.batchesInProgress as ArtboardUploadBatch[];
      return batchesInProgress;
    },

    currentProject(): FilesystemNodeShortcut | undefined {
      const { node }: FilesystemNodeWithContents = this.$store.getters.current;
      if (isSketchProject(node)) {
        return node;
      }
      return undefined;
    },

    projects(): FilesystemNodeShortcut[] {
      const { batchesInProgress, currentProject } = this;

      const projects: FilesystemNodeShortcut[] = [];
      if (currentProject) {
        projects.push(currentProject);
      }

      for (const batch of batchesInProgress) {
        // skip batch for current project, as it's covered above
        if (currentProject && batch.shortcut === currentProject.shortcut) {
          continue;
        }
        const node = this.$store.getters.getFsNode(batch.shortcut);
        if (isSketchProject(node)) {
          projects.push(node);
        }
      }

      return projects;
    },

    queuedFiles(): IUploaderFile[] {
      const assetUploader = this.getAssetUploader();
      if (assetUploader) {
        const batchId = assetUploader.batchId;
        return this.$store.getters.batchNotStartedArtboards(batchId);
      }
      return [];
    },

    hasFiles(): boolean {
      return this.queuedFiles.length > 0;
    },

    currentUserId(): string {
      const { user } = this.$store.state as AxShare;
      if (user.userInfo) {
        return user.userInfo.userId;
      }
      return "";
    },

    currentUserToken(): string {
      const { accountService } = this.$store.state;
      if (accountService) {
        return accountService().AuthToken;
      }
      return "";
    },

    shortcut(): string | undefined {
      if (this.currentProject) return this.currentProject.shortcut;
      return undefined;
    },
  },

  activated() {
    const assetUploader = this.getAssetUploader();
    if (assetUploader && typeof assetUploader.clear === "function") {
      assetUploader.clear();
    }
  },

  methods: {
    getAssetUploader(): AssetUploader | undefined {
      if (this.currentProject) {
        const assetUploader: AssetUploader | undefined = this.$refs[
          `assetUploader_${this.currentProject.shortcut}`
        ] as any;
        if (Array.isArray(assetUploader)) {
          return assetUploader[0];
        }
      }
      return undefined;
    },

    triggerBrowse(close?: () => void) {
      if (this.currentProject) {
        if (close) {
          this.close = close;
        }
        const assetUploader = this.getAssetUploader();
        if (assetUploader && typeof assetUploader.triggerBrowse === "function") {
          assetUploader.triggerBrowse();
        }
      }
    },

    addFiles(files: File[]) {
      const assetUploader = this.getAssetUploader();
      if (assetUploader && typeof assetUploader.addFile === "function") {
        orderBy(files, file => file.name).map(assetUploader.addFile);
      }
    },

    async uploadAssets() {
      if (!this.currentProject) {
        return;
      }
      const projectId = this.currentProject.id;
      try {
        Vue.set(this.uploading, projectId, true);
        await this.$nextTick();
        const assetUploader = this.getAssetUploader();
        if (assetUploader && typeof assetUploader.processFiles === "function") {
          await createPublishNote(projectId, this.publishNote ? JSON.stringify(this.publishNote) : "");
          this.publishNote = undefined;
          this.activatedKey++;

          assetUploader.processFiles();
        }
        if (this.close) {
          this.close();
        }
      } finally {
        Vue.set(this.uploading, projectId, false);
      }
    },

    removeFile(file: IUploaderFile) {
      const assetUploader = this.getAssetUploader();
      if (assetUploader && typeof assetUploader.removeFileById === "function") {
        assetUploader.removeFileById(file.id);
      }
    },

    filesAdded(id: string) {
      Vue.set(this.uploading, id, false);
    },

    isUploading(id: string) {
      return !!this.uploading[id];
    },
  },
});
