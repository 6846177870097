
import Vue from "vue";

import AxIcon from "@/components/AxIcon.vue";

export default Vue.extend({
  props: {
    components: {
      type: Object,
      default() {
        return {
          AxIcon,
        };
      },
    },
    sorting: {
      type: String,
      default: "none",
    },
  },
});
