import {
  FilesystemJs,
  FolderRowJs,
  InvitationJs,
  SharedFilesystemMappingJs,
  ShortcutJs,
  SsoResponse,
  ShortcutNotificationStateJs,
} from "@shared/models";

import { ShortcutType } from "@/services/models";

export enum FilesystemNodeType {
  Root = "root",
  Workspace = "workspace",
  Folder = "folder",
  Shortcut = "shortcut",
  Invitation = "invitation",
  Recents = "recents",
  Search = "search",
}

const enum ShortcutStateEnum {
  Empty = 0,
  GenerationFailed = 1,
  Generating = 2,
  Deleted = 3,
  Ok = 4,
}

export type ShortcutStateName = keyof typeof ShortcutStateEnum;
export type ShortcutStateNames = { readonly [P in ShortcutStateName]: P };

export const ShortcutState: ShortcutStateNames = {
  Empty: "Empty",
  GenerationFailed: "GenerationFailed",
  Generating: "Generating",
  Deleted: "Deleted",
  Ok: "Ok",
};
export const ShortcutStates = Object.values(ShortcutState);

export interface FilesystemNodeRoot extends FilesystemNode {
  readonly type: FilesystemNodeType.Root;
}

export interface FilesystemNodeRecents extends FilesystemNode {
  readonly type: FilesystemNodeType.Recents;
}

export interface FilesystemNodeSearch extends FilesystemNode {
  readonly type: FilesystemNodeType.Search;
}

export interface FilesystemNodeWorkspace extends FilesystemNode {
  readonly type: FilesystemNodeType.Workspace;
  rootFolderId: string;
  isDefault: boolean;
  listingNode: FilesystemJs | undefined;
  sharedWith: SharedFilesystemMappingJs[];
  invited: SharedFilesystemMappingJs[];
  users: SharedFilesystemMappingJs[];
  viewOnly: boolean;
  isOwner: boolean;
  owner?: SsoResponse;
  ownerEmail: string;
  userOnly: boolean;
  httpsOnly: boolean;
  isArchived: boolean;
  isFavorite: boolean;
}

export interface FilesystemNodeInvitation extends FilesystemNode {
  readonly type: FilesystemNodeType.Invitation;
  owner: SsoResponse;
  sender: SsoResponse;
  message: string;
  listingNode: InvitationJs | undefined;
}

export interface FilesystemNodeFolder extends FilesystemNode {
  readonly type: FilesystemNodeType.Folder;
  listingNode: FolderRowJs | undefined;
  viewOnly: boolean;
  updated?: Date;
}

export interface FilesystemNodeShortcut extends FilesystemNode {
  readonly type: FilesystemNodeType.Shortcut;
  listingNode: ShortcutJs | undefined;
  shortcutType: ShortcutType;
  prototypeUrl: string;
  shortcut: string;
  isExpoProject: boolean;
  viewOnly: boolean;
  updated?: Date;
  generated?: Date;
  shortcutState: ShortcutStateName;
  feedbackEnabled: boolean;
  isUserOnly: boolean;
  hasPassword: boolean;
  isUserOnlyWorkspace: boolean;
  notificationState: ShortcutNotificationStateJs;
}

export type FilesystemNodeTypes =
  | FilesystemNodeRoot
  | FilesystemNodeFolder
  | FilesystemNodeShortcut
  | FilesystemNodeWorkspace
  | FilesystemNodeInvitation
  | FilesystemNodeRecents
  | FilesystemNodeSearch;

export type FilesystemNodeMovable = FilesystemNodeFolder | FilesystemNodeShortcut;
export type FilesystemNodeDeletable = FilesystemNodeFolder | FilesystemNodeShortcut | FilesystemNodeWorkspace;
export type FilesystemNodeClonable = FilesystemNodeShortcut;

export interface FilesystemNode {
  type: FilesystemNodeType;
  id: string;
  name: string;
  parent: FilesystemNodeTypes | undefined;
  listingNode: any;
  created: Date;
  totalItems: number;
}

export interface FilesystemNodeWithContents {
  node: FilesystemNodeTypes;
  contents: FilesystemNodeTypes[];
}

export interface WorkspaceRights {
  usersManage: boolean;
  usersInvite: boolean;
  usersRemove: boolean;
  securityConfigure: boolean;
  workspaceDelete: boolean;
  workspaceRename: boolean;
  workspaceArchive: boolean;
  userLeave: boolean;
  workspaceJoin: boolean;
}

export interface CreateWorkspaceModel {
  workspaceName: string;
  userViewOnly: boolean;
}

const enum MentionSourceTypeEnum {
  Issue = 0,
  IssueComment = 1,
  PublishNote = 2,
}

export type MentionTypeName = keyof typeof MentionSourceTypeEnum;
export type MentionTypeNames = { readonly [P in MentionTypeName]: P };

export const MentionType: MentionTypeNames = {
  Issue: "Issue",
  IssueComment: "IssueComment",
  PublishNote: "PublishNote",
};
export const MentionTypes = Object.values(MentionType);

export type ProjectTypes = "rp" | "sketch" | "image";
export const ProjectType: Record<string, ProjectTypes> = {
  rp: "rp",
  sketch: "sketch",
  image: "image",
};
