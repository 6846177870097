
import Vue from "vue";
import { PropOptions } from "vue/types/options";

import {
  FilesystemNodeType, FilesystemNodeTypes, ProjectType, ProjectTypes,
} from "@/common/fs";
import adminRouter from "@/router/admin";
import { fsRouterLocation } from "@/router/fs";
import { AxShare } from "@/store/state";

import AxButton from "@/components/AxButton.vue";
import AxCreateProjectRp from "@/components/AxCreateProjectRp.vue";
import AxCreateProjectSketch from "@/components/AxCreateProjectSketch.vue";

const nodeProp: PropOptions<FilesystemNodeTypes> = {
  type: Object,
  required: true,
};

export default Vue.extend({
  components: {
    AxButton,
    AxCreateProjectRp,
    AxCreateProjectSketch,
  },

  props: {
    node: nodeProp,
  },

  data() {
    const data = {
      compact: true,
      ProjectType,
      projectType: "",
      adminAccountsSettingsRoute: adminRouter.routes["admin.accounts"],
    };

    return data;
  },

  computed: {
    canPublish(): boolean {
      if (!this.isSubInstance) {
        return true;
      }

      return !this.$store.getters.enterpriseLicenseCheckFailed;
    },

    folderId(): string {
      if (this.node.type === FilesystemNodeType.Workspace) return this.node.rootFolderId;
      return this.node.id;
    },

    isSubInstance(): boolean {
      const { axShareConfig } = this.$store.state as AxShare;
      return axShareConfig !== null && axShareConfig.IsSubInstance;
    },

    isSubInstanceAdmin(): boolean {
      return this.$store.getters.isSubInstanceAdmin;
    },
  },

  methods: {
    modeChanged(projectType: ProjectTypes, compact: boolean) {
      this.projectType = projectType;
      this.compact = compact;
      this.$emit("modeChanged", { compact, projectType });
    },

    async submit() {
      this.cancel(true);
    },

    cancel(refresh: boolean = false) {
      this.$router.push(fsRouterLocation(this.folderId, { refresh: { contents: refresh } }));
    },
  },
});
