
import Vue from "vue";
import { PropOptions } from "vue/types/options";

import { SsoResponse } from "@shared/models";

import { getUserName } from "@/common/axshare";
import { UserProfile } from "@/services/models/account";

import AxIcon from "@/components/AxIcon.vue";
import AxUserAvatar from "@/components/AxUserAvatar.vue";

const userProp: PropOptions<SsoResponse> = {
  type: Object,
  required: true,
};

const profileProp: PropOptions<UserProfile> = {
  type: Object,
  required: true,
};

const props = {
  user: userProp,
  profile: profileProp,
};

export default Vue.extend({
  components: {
    AxUserAvatar,
    AxIcon,
  },

  props: {
    showAvatar: {
      type: Boolean,
      default: true,
    },

    showUsername: {
      type: Boolean,
      default: true,
    },

    usernameClasses: {
      type: String,
      default: "",
    },

    showNotificationBadge: {
      type: Boolean,
      default: false,
    },

    w: {
      type: String,
      default: "24px",
    },

    h: {
      type: String,
      default: "24px",
    },

    ...props,
  },

  computed: {
    userName(): string {
      return getUserName(this.profile) || this.user.userEmail || "";
    },

    profileImg(): string {
      let profileImg = this.profile.Img || "";
      if (!profileImg) {
        profileImg = this.user.profileImageUrl || "";
      }
      return profileImg;
    },
  },
});
