import { Hotspot } from "@shared/expo";

export function normalizeHotspotMeasurements(hs: Hotspot) {
  // Rounds or floors the val based on traditional rounding
  function floorRound(val: number) {
    const integer = Math.floor(val);
    const decimal = val % 1;

    if (decimal < 0.5) {
      return integer;
    }
    return Math.round(val);
  }

  const normalized: Hotspot = {
    Dimensions: {
      Height: floorRound(hs.Dimensions.Height),
      Width: floorRound(hs.Dimensions.Width),
    },
    Start: {
      X: floorRound(hs.Start.X),
      Y: floorRound(hs.Start.Y),
    },
    End: {
      X: floorRound(hs.End.X),
      Y: floorRound(hs.End.Y),
    },
  };

  return normalized;
}

export function createUniqueTag(): string {
  const tag = Math.random()
    .toString()
    .substring(2)
    + Math.random()
      .toString()
      .substring(2)
    + Math.random()
      .toString()
      .substring(2)
    + Math.random()
      .toString()
      .substring(2);
  return tag;
}

export async function hashPassword(toHash: string, salt: string = "axure") {
  const msgToHash = `${salt}${toHash}`;

  if (crypto && crypto.subtle && crypto.subtle.digest) {
    const msgUint8 = new TextEncoder().encode(msgToHash); // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest("SHA-512", msgUint8); // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, "0")).join(""); // convert bytes to hex string
    return hashHex;
  }

  const { default: { sha512 } } = await import("sha.js");
  // eslint-disable-next-line new-cap
  return new sha512().update(msgToHash).digest("hex");
}

/* eslint-disable no-bitwise */
export function simpleHash(toHash: string): number {
  let hash = 0;
  if (toHash.length === 0) {
    return hash;
  }
  for (let i = 0; i < toHash.length; i++) {
    const char = toHash.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash &= hash;
  }
  return hash;
}
/* eslint-enable no-bitwise */

export function addParamsToFragment(hash: string, params: string) {
  const fragment = hash ? `${hash.substring(1)}&${params}` : params;
  const separatedParams = fragment.split("&");
  const uniqueParams = [...new Set(separatedParams)];
  return uniqueParams.join("&");
}

export function getIframeQueryParams(startPage: string, useContentRedirect: boolean, inspectMode: boolean) {
  const queryParams: { [key: string]: string } = {
    cl: "1", // add 'cl' parameter to iframeSrc for recognize request as in-app mode
    id: startPage,
  };

  if (inspectMode) queryParams.hand = String(true);
  if (useContentRedirect) queryParams.contentRedirect = "1";

  if (window.location.search) {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split("=");
      queryParams[pair[0]] = pair[1];
    }
  }
  return queryParams;
}

export function combineQueryWithHash(query: { [key: string]: any }, hash: string) {
  const queryParams = { ...query };
  const vars = hash.substring(1).split("&");
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    queryParams[pair[0]] = pair[1];
  }
  return queryParams;
}
