
import Vue from "vue";

import { FilesystemNodeType, FilesystemNodeTypes } from "@/common/fs";
import { fsRouterLocation } from "@/router/fs";

import AxCreateFolder from "@/components/AxCreateFolder.vue";
import AxDialog from "@/components/AxDialog.vue";

export default Vue.extend({
  components: {
    AxCreateFolder,
    AxDialog,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      show: true,
    };
  },

  computed: {
    node(): FilesystemNodeTypes {
      return this.$store.getters.getNodeByFolderId(this.id);
    },

    folderId(): string {
      if (this.node.type === FilesystemNodeType.Workspace) return this.node.rootFolderId;
      return this.node.id;
    },
  },

  methods: {
    async submit() {
      this.cancel();
    },

    cancel(refresh: boolean = false) {
      this.$router.push(fsRouterLocation(this.id, { refresh: { contents: refresh } }));
    },
  },
});
