import Vue from "vue";

import { AxShare } from "@/store/state";

export const appStatus = Vue.extend({
  computed: {
    appStatus() {
      const { initializationStatus: status } = this.$store.state as AxShare;
      return status;
    },

    appStatusInitialized(): boolean {
      return !!this.appStatus;
    },

    appStatusFailure(): boolean {
      return this.appStatus === "failure";
    },

    appStatusSuccess(): boolean {
      return this.appStatus === "success";
    },
  },
});
