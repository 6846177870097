

import Vue from "vue";

import { RpErrorTypes } from "@/generated/models";
import LicensingService from "@/services/licensing.service";
import { AxShare } from "@/store/state";

import AxButton from "@/components/AxButton.vue";
import AxCopyToClipboard from "@/components/AxCopyToClipboard.vue";
import AxForm from "@/components/AxForm.vue";
import AxFormErrors from "@/components/AxFormErrors.vue";
import AxFormGroup from "@/components/AxFormGroup.vue";
import AxFormSuccess from "@/components/AxFormSuccess.vue";
import AxInput from "@/components/AxInput.vue";

export default Vue.extend({
  components: {
    AxButton,
    AxForm,
    AxFormGroup,
    AxInput,
    AxFormErrors,
    AxFormSuccess,
    AxCopyToClipboard,
  },
  props: {
    requestId: {
      type: String,
      required: true,
    },
    machineId: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      default: "",
    },
    redemptionKey: {
      type: String,
      default: "",
    },
    rpVersion: {
      type: String,
      required: true,
    },
  },
  data() {
    const protocol = this.rpVersion === "10" ? "axurerp10" : "axurerp";
    const desktopLink = `${protocol}://signin?requestId=${this.requestId}`;
    return {
      model: {
        redemptionKey: this.redemptionKey,
      },
      desktopLink,
      errors: [] as string[],
      successMessages: [] as string[],
      licenseKey: "",
    };
  },
  computed: {
    showLicenseKey(): Boolean { return Boolean(this.licenseKey); },
    buttonClass(): string {
      return this.machineId ? "key-button-10 key-submit-button" : "key-button-9 key-submit-button";
    },
  },
  methods: {
    setStatusMessages({ success = [], errors = [] }: { success?: string | string[]; errors?: string | string[] }) {
      const successMessages = Array.isArray(success) ? success : [success];
      const errorMessages = Array.isArray(errors) ? errors : [errors];
      this.successMessages = successMessages;
      this.errors = errorMessages;
    },

    addError(errorMessage: string) {
      this.errors.push(errorMessage);
    },

    clearStatusMessages() {
      this.setStatusMessages({ success: [], errors: [] });
    },

    async submit() {
      const { axShareConfig, accountService } = this.$store.state as AxShare;
      const licensingHost = axShareConfig?.LicensingServiceHost;
      if (licensingHost) {
        this.clearStatusMessages();
        const licensingService = new LicensingService(licensingHost, this.token);
        const licData = (await licensingService.getLicenseData(this.machineId, this.model.redemptionKey, true));

        if (licData.success) {
          this.licenseKey = licData.Payload.BestLicense.key;
          if (accountService) {
            await accountService().cacheLicenseResponse(this.requestId, JSON.stringify(licData));
          }
          (this.$refs.link as HTMLElement).click();
        } else if (!licData.success && licData.ErrorType === RpErrorTypes.RedemptionKeyNotFound) {
          this.addError(licData.errorMessage);
        }
      }
    },
  },
});

