
import Vue, { PropOptions } from "vue";

import {
  isRp6ProjectAndBelow, isRp7Project, isRp8Project, isRp9Project, isRp9ProjectWithThumbnails, isRp10Project,
} from "@/common/axshare";
import { FilesystemNodeShortcut } from "@/common/fs";
import { ShortcutType } from "@/services/models";

import AxIcon from "@/components/AxIcon.vue";

const projectTypeIcons: { [key: number]: string | undefined } = {
  [ShortcutType.Rp]: "rp",
  [ShortcutType.Lib]: "library",
  [ShortcutType.TeamLib]: "teamlib",
  [ShortcutType.TeamRp]: "teamrp",
};

const projectProp: PropOptions<FilesystemNodeShortcut> = {
  type: Object,
  required: false,
};

export default Vue.extend({
  components: {
    AxIcon,
  },

  props: {
    type: {
      type: Number,
      required: true,
    },

    state: {
      type: String,
      required: false,
      default: "",
    },

    small: {
      type: Boolean,
      default: false,
    },

    large: {
      type: Boolean,
      default: false,
    },

    version: {
      type: Number,
      required: false,
      default: undefined,
    },

    iconClasses: {
      type: String,
      default: "",
    },

    project: projectProp,
  },

  computed: {
    icon(): any {
      return projectTypeIcons[this.type] || projectTypeIcons[ShortcutType.Rp];
    },

    size(): string {
      if (this.small) return "sm";
      if (this.large) return "lg";
      return "sm";
    },

    classes(): Record<string, boolean> {
      return {
        small: this.small,
        large: this.large,
        "rp-6": this.isRp6AndBelow,
        "rp-7": this.isRp7,
        "rp-8": this.isRp8,
        "rp-9": this.isRp9,
        "rp-10": this.isRp10,
      };
    },

    name(): string {
      if (this.isRp10) {
        return `project-${this.icon}-10-${this.size}`;
      }
      if (this.isRp9 || this.isRp9WithThumbnails) {
        return `project-${this.icon}-9-${this.size}`;
      }
      if (this.isRp8) {
        return `project-${this.icon}-8-${this.size}`;
      }
      if (this.isRp7) {
        return `project-${this.icon}-7-${this.size}`;
      }
      if (this.isRp6AndBelow) {
        return `project-${this.icon}-6-${this.size}`;
      }
      return `project-${this.icon}-${this.size}`;
    },

    isRp6AndBelow(): boolean {
      return isRp6ProjectAndBelow(
        { project: this.project, resourceDirVersion: this.version, shortcutState: this.state },
      );
    },

    isRp7(): boolean {
      return isRp7Project({ project: this.project, resourceDirVersion: this.version });
    },

    isRp8(): boolean {
      return isRp8Project({ project: this.project, resourceDirVersion: this.version });
    },

    isRp9(): boolean {
      return isRp9Project({ project: this.project, resourceDirVersion: this.version });
    },

    isRp9WithThumbnails(): boolean {
      return isRp9ProjectWithThumbnails({ project: this.project, resourceDirVersion: this.version });
    },

    isRp10(): boolean {
      return isRp10Project({ project: this.project, resourceDirVersion: this.version });
    },
  },
});
