import actions from "@/store/project/actions";
import getters from "@/store/project/getters";
import mutations from "@/store/project/mutations";
import state from "@/store/project/state";

export default {
  state,
  actions,
  mutations,
  getters,
};
