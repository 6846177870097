
import Vue from "vue";

import AxIcon from "@/components/AxIcon.vue";

export default Vue.extend({
  components: {
    AxIcon,
  },

  props: {
    shared: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: "workspace",
    },
  },
});
