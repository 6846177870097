import { GetterTree } from "vuex";

import { AxShare } from "@/store/state";
import {
  AxShareUploads,
  ArtboardUploadInfo,
  ArtboardUploadStatusCompleted,
  ArtboardUploadBatch,
  ArtboardUploadStatus,
} from "@/store/uploads/state";

const getterTree: GetterTree<AxShareUploads, AxShare> = {
  batchIsCompleted(state) {
    return (batchId: string): boolean => {
      const batch = state.batches[batchId];
      if (!batch) return true;
      const artboards = Object.values(batch.contents).filter(Boolean) as ArtboardUploadInfo[];
      const allCompleted = artboards.every(batchArtboardIsCompleted);
      return allCompleted;
    };
  },

  batchUploadCompletedOrDismissed(state, getters) {
    return (batchId: string): boolean => {
      const batch = state.batches[batchId];
      if (!batch) return true;
      if (batch.dismissed) return true;
      return getters.batchIsCompleted(batchId);
    };
  },

  batchNotStartedArtboards(state) {
    return (batchId: string): ArtboardUploadInfo[] => {
      const batch = state.batches[batchId];
      if (!batch) return [];
      const artboards = Object.values(batch.contents).filter(Boolean) as ArtboardUploadInfo[];
      return artboards.filter(batchArtboardNotStarted);
    };
  },

  batchesInProgress(state) {
    const batches = Object.values(state.batches).filter(Boolean) as ArtboardUploadBatch[];
    const batchesInProgress = batches.filter(batch => !batchIsCompleted(batch));
    return batchesInProgress;
  },
};

export function batchIsCompleted(batch: ArtboardUploadBatch) {
  return Object.values(batch.contents).every(artboard => !artboard || batchArtboardIsCompleted(artboard));
}

export function batchArtboardIsCompleted({ status }: ArtboardUploadInfo) {
  return ArtboardUploadStatusCompleted.includes(status);
}

export function batchArtboardNotStarted({ status }: ArtboardUploadInfo) {
  return status === ArtboardUploadStatus.NotStarted;
}

export default getterTree;
