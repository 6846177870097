
import Vue from "vue";

import { prototypeUrls, PrototypeUrls } from "@/common/axshare";
import { FilesystemNodeType } from "@/common/fs";
import { arrayToMap } from "@/common/lib";
import { SearchResult, StringChunk } from "@/common/search/SearchResult";
import { ShortcutType } from "@/services/models";
import { AxShare } from "@/store/state";

import AxDataTableNoData from "@/components/AxDataTableNoData.vue";
import AxFilesystemTable from "@/components/AxFilesystemTable.vue";
import AxHeaderSearchResultLink from "@/components/AxHeaderSearchResultLink.vue";
import AxSearchResultModified from "@/components/AxSearchResultModified.vue";
import AxShortcutDropdownButton from "@/components/AxShortcutDropdownButton.vue";
import AxShortcutPublicLink from "@/components/AxShortcutPublicLink.vue";
import { Header } from "@/components/types/AxDataTable";
import { FilesystemSearchItem } from "@/components/types/AxSearch";
import { arrayProp } from "@/components/utils";

export default Vue.extend({
  components: {
    AxDataTableNoData,
    AxFilesystemTable,
    AxHeaderSearchResultLink,
    AxShortcutDropdownButton,
    AxSearchResultModified,
    AxShortcutPublicLink,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    results: arrayProp<SearchResult<FilesystemSearchItem>>({
      required: false,
      default: () => [],
    }),

    query: {
      type: String,
      default: "",
    },

    headerOffsetTop: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      deletedItems: {} as Record<string, boolean>,
      clonedItems: {} as Record<string, boolean>,
      movedItems: {} as Record<string, boolean>,
      renamedItems: {} as Record<string, boolean>,
    };
  },

  computed: {
    items(): (FilesystemSearchItem & { shortcutType?: ShortcutType })[] {
      return this.results.map(r => ({
        ...r.item,
        shortcutType: r.item.subtype,
      }));
    },

    searchResults() {
      return arrayToMap(this.results, searchResult => searchResult.item.id);
    },

    headers(): Header[] {
      return [
        {
          text: `${this.results.length.toString()} results for text "${this.query}"`,
          value: "name",
        },
        {
          text: "ID",
          value: "id",
          sortable: false,
        },
        {
          text: "Modified",
          value: "updated",
          width: "15%",
        },
        {
          // actions
          text: "",
          sortable: false,
          width: "10%",
        },
      ];
    },
  },

  methods: {
    isProject(item: FilesystemSearchItem): boolean {
      return item.type === FilesystemNodeType.Shortcut;
    },
    showShortcutDropdown(item: FilesystemSearchItem) {
      return item.type === FilesystemNodeType.Shortcut && item.viewOnly === false;
    },
    prototypeUrls(item: FilesystemSearchItem): PrototypeUrls {
      const { axShareConfig } = this.$store.state as AxShare;
      return prototypeUrls(axShareConfig, item.prototypeUrl || "", item.id);
    },

    searchResult(item: FilesystemSearchItem): SearchResult<FilesystemSearchItem> | undefined {
      return this.searchResults[item.id];
    },

    idChunks(item: FilesystemSearchItem): StringChunk[] {
      const result = this.searchResult(item);
      if (!result) {
        return [];
      }
      return result.getStringChunks("id") || [];
    },

    itemDeleted(item: FilesystemSearchItem) {
      this.$set(this.deletedItems, item.id, true);
    },

    isItemDeleted(item: FilesystemSearchItem) {
      return !!this.deletedItems[item.id];
    },

    itemCloned(item: FilesystemSearchItem) {
      this.$set(this.clonedItems, item.id, true);
    },

    itemMoved(item: FilesystemSearchItem) {
      this.$set(this.movedItems, item.id, true);
    },

    itemRenamed(item: FilesystemSearchItem) {
      this.$set(this.renamedItems, item.id, true);
    },

    itemModified() {
      this.$emit("modified");
    },
  },
});
