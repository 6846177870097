
import Vue from "vue";
import { PropOptions } from "vue/types/options";

import { getObjectValueByPath } from "@/common/lib";

import AxIcon from "@/components/AxIcon.vue";

const optionsProp: PropOptions = {
  type: [Array, Function],
  default: () => [],
};

export default Vue.extend({
  components: {
    AxIcon,
  },

  props: {
    options: optionsProp,

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    value: {
      type: [Object, String, Number, Boolean],
      default: "",
    },

    defaultValue: {
      type: [Object, String, Number, Boolean],
      default: undefined,
    },

    optionKey: {
      type: String,
      default: "value",
    },

    optionValue: {
      type: String,
      default: "",
    },

    optionText: {
      type: String,
      default: "text",
    },

    optionDisabled: {
      type: [String, Boolean],
      default: "disabled",
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    compact: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      optionsLoading: true,
      optionItems: this.options,
      isOpen: false,
    };
  },

  computed: {
    innerValue: {
      get(): any {
        if (this.value || this.value === 0 || this.value === false) {
          return this.value;
        }
        return "";
      },

      set(value: any): any {
        return this.$emit("input", value);
      },
    },

    labelText(): string {
      return this.label || this.placeholder;
    },

    defaultOptionText(): string {
      return this.placeholder || this.label;
    },
  },

  watch: {
    options: {
      async handler() {
        await this.resolveOptions();
      },
      immediate: true,
    },
  },

  methods: {
    keyHandler(event: any) {
      const { keyCode } = event;
      const enterKeyCode = 13;
      const escKeyCode = 27;
      const spaceKeyCode = 32;

      if (keyCode === escKeyCode) {
        this.isOpen = false;
      } else if (keyCode === enterKeyCode) {
        this.toggle();
      } else if (keyCode === spaceKeyCode) {
        this.isOpen = true;
      }
    },

    blurHandler() {
      if (this.isOpen) {
        this.toggle();
      }
    },

    toggle() {
      this.isOpen = !this.isOpen;
    },

    async resolveOptions() {
      this.optionsLoading = true;
      this.optionItems = typeof this.optionItems === "function" ? await this.optionItems() : this.options;
      if (this.defaultValue && this.optionItems && this.optionItems.length) {
        for (let i = 0; i <= this.optionItems.length - 1; i++) {
          if (this.optionItems[i][this.optionValue] === this.defaultValue) {
            this.innerValue = this.optionItems[i][this.optionValue];
            break;
          }
        }
      }
      this.optionsLoading = false;
    },

    getOptionKey(option: any) {
      return getObjectValueByPath(option, this.optionKey);
    },

    getOptionValue(option: any) {
      if (this.optionValue) {
        return getObjectValueByPath(option, this.optionValue);
      }
      return option;
    },

    getOptionText(option: any) {
      if (this.optionText) {
        return getObjectValueByPath(option, this.optionText);
      }
      return option;
    },

    isOptionDisabled(option: any) {
      if (typeof this.optionDisabled === "boolean") {
        return this.optionDisabled;
      }

      return !!getObjectValueByPath(option, this.optionDisabled);
    },
  },
});
