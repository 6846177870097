import { Validator } from "lakmus";

import { IssueCommentJs } from "@/generated/models";
import { ManageUsersModel } from "@/services/models/account";
import { CreateExpoProjectModel } from "@/services/models/expo";
import { FolderCreateModel } from "@/services/models/filesystem";
import { ProjectCreateModel } from "@/services/models/project";
import { ShortcutPluginMapping } from "@/services/models/sites";

import { CreateWorkspaceModel } from "./types";

class FolderCreateModelValidatorInner extends Validator<FolderCreateModel> {
  constructor() {
    super();

    this.ruleFor(x => x.Name)
      .notEmpty()
      .withMessage("Please enter Folder name.");
  }
}
export const FolderCreateModelValidator = new FolderCreateModelValidatorInner();

class CreateWorkspaceModelValidatorInner extends Validator<CreateWorkspaceModel> {
  constructor() {
    super();

    this.ruleFor(x => x.workspaceName)
      .notEmpty()
      .withMessage("Please enter Workspace name.");
  }
}
export const CreateWorkspaceModelValidator = new CreateWorkspaceModelValidatorInner();

class ManageUsersInviteModelValidatorInner extends Validator<ManageUsersModel> {
  constructor() {
    super();

    this.ruleFor(x => x.usersInvite)
      .must(value => !!value && !!value.userEmails && value.userEmails.length > 0)
      .withMessage("Please specify at least one Email.");
  }
}
export const ManageUsersInviteModelValidator = new ManageUsersInviteModelValidatorInner();

class ProjectCreateModelValidatorInner extends Validator<ProjectCreateModel> {
  constructor() {
    super();

    this.ruleFor(x => x.Name)
      .notEmpty()
      .withMessage("Please enter Project name.");
  }
}
export const ProjectCreateModelValidator = new ProjectCreateModelValidatorInner();

class CreateExpoProjectModelValidatorInner extends Validator<CreateExpoProjectModel> {
  constructor() {
    super();

    this.ruleFor(x => x.Name)
      .notEmpty()
      .withMessage("Please enter Project name.");
  }
}
export const CreateExpoProjectModelValidator = new CreateExpoProjectModelValidatorInner();

class PluginModelValidatorInner extends Validator<ShortcutPluginMapping> {
  constructor() {
    super();

    this.ruleFor(x => x.PluginName)
      .notEmpty()
      .withMessage("Please enter Plugin name.");

    this.ruleFor(x => x.Content)
      .notEmpty()
      .withMessage("Please enter Plugin content.");
  }
}
export const PluginModelValidator = new PluginModelValidatorInner();

class CommentModelValidatorInner extends Validator<IssueCommentJs> {
  constructor() {
    super();

    this.ruleFor(x => x.commentText)
      .notEmpty()
      .withMessage("Please enter text.");
  }
}
export const CommentModelValidator = new CommentModelValidatorInner();
