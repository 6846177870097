
import Vue from "vue";
import { PropOptions } from "vue/types/options";

import { SsoResponse } from "@shared/models";

import { ManageUserModel, ManageUsersModel } from "@/services/models/account";

import AxAutocomplete from "@/components/AxAutocomplete.vue";
import AxButton from "@/components/AxButton.vue";
import AxList from "@/components/AxList.vue";
import AxListItem from "@/components/AxListItem.vue";
import { arrayProp } from "@/components/utils";

const itemsProp: PropOptions<ManageUsersModel> = {
  type: Object,
  required: true,
};

export default Vue.extend({
  components: {
    AxAutocomplete,
    AxButton,
    AxList,
    AxListItem,
  },

  props: {
    items: itemsProp,

    activatorRef: {
      type: [HTMLElement, Object, Function],
      required: false,
      default: undefined,
    },

    value: arrayProp<string>({
      default: () => [],
    }),

    placeholder: {
      type: String,
      default: "Enter Email addresses",
    },

    label: {
      type: String,
      default: "Enter Email addresses",
    },

    disableLabel: {
      type: Boolean,
      default: false,
    },

    inputClass: {
      type: String,
      default: "",
    },

    inputAttrs: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    inputEvents: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    autofocus: {
      type: Boolean,
      default: false,
    },

    menuOpened: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      searchFields: ["user.userEmail", "user.nickname"],
    };
  },

  computed: {
    autocompleteOptions(): ManageUserModel[] {
      return this.items.users.filter(u => !this.value.includes(u.user.userEmail));
    },
  },

  watch: {
    value(value) {
      this.$emit("update:input-started", value.length > 0);
    },
  },

  methods: {
    isInvited(user: SsoResponse): boolean {
      return this.isInvitedEmail(user.userEmail);
    },

    isInvitedEmail(email: string): boolean {
      const { items } = this;
      return !!(
        items.usersInvite
        && items.usersInvite.userEmails
        && items.usersInvite.userEmails.indexOf(email) > -1
      );
    },

    focus() {
      const autocomplete = (this.$refs.autocomplete as any);
      if (autocomplete && typeof autocomplete.focus === "function") {
        autocomplete.focus();
      }
    },

    updateValue(selected: string[] | undefined) {
      if (selected) {
        const newEmail = selected.filter(s => !this.value.includes(s));
        if (newEmail.some(this.isInvitedEmail)) return;
        this.$emit("input", selected);
      }
    },
  },
});
