import axios from "axios";
import qs from "qs";

import { resolveApiBaseUrl } from "@/common/axshare/api";
import { isIE } from "@/common/lib";

import {
  addAuthorizationHeader,
  transformRequestDefaults,
  withNoCacheHeaders,
  timestampInterceptor,
} from "./utils/axios";

let getAuthToken: () => string | undefined = () => "";

export const server = axios.create({
  baseURL: resolveApiBaseUrl(),
  withCredentials: true,
  paramsSerializer: {
    serialize: params => qs.stringify(params, { arrayFormat: "repeat" }),
  },
  transformRequest: [
    ...transformRequestDefaults,
    (data, headers) => {
      addAuthorizationHeader(headers, getAuthToken());
      return data;
    },
  ],
  ...(isIE ? withNoCacheHeaders : undefined),
});

if (isIE) {
  // add cache-breaking timestamp for each and every request made in IE
  server.interceptors.request.use(timestampInterceptor());
}

export function setAuthTokenAccessor(tokenAccessor: () => string | undefined) {
  getAuthToken = tokenAccessor;
}

export function reconfigure(url: string) {
  server.defaults.baseURL = url;
}

export default {
  server,
};
