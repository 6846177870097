
import Vue, { VueConstructor } from "vue";

import { shortcutLinkMixin } from "@/mixins/ShortcutLink";

type Mixins = InstanceType<typeof shortcutLinkMixin>;

export default (Vue as VueConstructor<Vue & Mixins>).extend({
  mixins: [shortcutLinkMixin],
});
