import axios, { AxiosInstance } from "axios";
import { stringify } from "qs";

import {
  ListJs, ResponseObject, SsoResponse, ResetPasswordValidateJs, TimeZoneJs,
} from "@shared/models";

import { authStorage } from "@/common/axshare/auth";
import { isElectronRenderer, isElectronMain, isDesktopLocal } from "@/common/environment";
import {
  delay, isIE, testSameOrigin, isIframe,
} from "@/common/lib";
import { Main, Renderer } from "@/desktop/events";
import { AxShareHostsConfig } from "@/services/models/config";
import { objectToFormData } from "@/services/utils/formData";

import { exec, Options } from "./api";
import { ChangeAccountInfoModel, DoLoginModel, UserProfile } from "./models/account";
import { ApiResponseObject } from "./models/responseObject";
import {
  addAuthorizationHeader,
  transformRequestDefaults,
  withNoCacheHeaders,
  timestampInterceptor,
} from "./utils/axios";

interface ChangeAccountInfoResponse extends ApiResponseObject {
  authToken: string;
}

function createServer(host: string, token: () => string | undefined) {
  const server = axios.create({
    baseURL: host,
    withCredentials: true,
    transformRequest: [
      ...transformRequestDefaults,
      (data, headers) => {
        addAuthorizationHeader(headers, token());
        return data;
      },
    ],
    ...withNoCacheHeaders,
  });

  if (isIE) {
    server.interceptors.request.use(timestampInterceptor());
  }

  return server;
}

export default class AccountService {
  private isAuthed = false;
  private isGuest = false;
  private userId?: string;
  private useHttpGet;
  private accountServer: AxiosInstance;
  private appServer: AxiosInstance;

  public get IsAuthed() {
    return this.isAuthed;
  }

  public get AuthToken() {
    return authStorage.Token;
  }

  public get UserId() {
    if (!this.IsAuthed) return undefined;
    return this.userId;
  }

  private get getOrPost() {
    // keep backwards compatibility with previous version of OnPrem servers
    // those servers may not support POST requests for account operations
    return this.useHttpGet ? this.get : this.post;
  }

  constructor(
    private readonly accountServiceHost: string,
    private readonly axShareHost: string,
    authToken?: string,
    useGet?: boolean,
  ) {
    this.useHttpGet = useGet;
    this.accountServer = createServer(accountServiceHost, () => this.AuthToken);
    this.appServer = createServer(axShareHost, () => this.AuthToken);
    if (authToken) {
      authStorage.Token = authToken;
    }
  }

  public async authGetSso() {
    let authSuccess = false;
    let sso;
    try {
      sso = await this.getOrPost<SsoResponse>("/user/auth");

      if (sso.authToken) {
        await this.setAuth(sso.authToken, sso.userId);
        authSuccess = true;
      }
    } catch (err) {
      authSuccess = false;
      if (isElectronRenderer && !isDesktopLocal) {
        await mainProcessLogout();
      }
      if (isElectronMain) {
        // throw further up, so this can be logged in main process
        throw err;
      }
    } finally {
      this.isAuthed = authSuccess;
    }
    return sso;
  }

  public async auth() {
    await this.authGetSso();
    return this.isAuthed;
  }

  public async guestAuth() {
    const sso = await this.postAppServer<SsoResponse>("/user/guestAuth");
    await this.setAuth(sso.authToken, sso.userId);
    this.isGuest = true;
    this.isAuthed = true;
    return sso;
  }

  public async login(model: DoLoginModel, opts?: Options) {
    const loginResponse = await this.doLoginRequest(model, opts);
    await this.setAuth(loginResponse.authToken, loginResponse.userId);
    return loginResponse;
  }

  public async guestLogin(email: string) {
    const formData = objectToFormData({ email });
    const sso = await this.postAppServer<SsoResponse>("/user/guestLogin", formData);
    await this.setAuth(sso.authToken, sso.userId);
    this.isGuest = true;
    this.isAuthed = true;
    return sso;
  }

  public async doLoginRequest(model: DoLoginModel, opts?: Options) {
    const formData = objectToFormData(model);
    const loginResponse = await this.getOrPost<SsoResponse>("/user/dologin", formData, opts);
    return loginResponse;
  }

  public async forgotPassword(email: string, target: string) {
    const formData = objectToFormData({ email, target });
    return this.getOrPost<ResponseObject>("/user/forgotpassword", formData);
  }

  public async resetPassword(email: string, token: string, newPassword: string, target: string) {
    const formData = objectToFormData({
      email,
      token,
      newPassword,
      target,
    });
    return this.getOrPost<ResponseObject>("/user/resetpasswordspa", formData);
  }

  public async resetPasswordValidateToken(token: string) {
    const formData = objectToFormData({
      token,
    });
    return this.post<ResetPasswordValidateJs>("/user/resetPasswordValidateToken", formData);
  }

  public async signUp(email: string, password: string, callerId?: string) {
    const formData = objectToFormData({ email, password, callerId });
    return this.getOrPost<ResponseObject>("/user/create", formData, {
      ignoreResponseRedirectUrl: true,
    });
  }

  public async rpSignUp(email: string, password: string, rpRequestId: string, callerId?: string) {
    const formData = objectToFormData({
      email,
      password,
      callerId,
      rpRequestId,
    });
    return this.getOrPost<string>("/user/create", formData, {
      ignoreResponseRedirectUrl: true,
    });
  }

  public async logout() {
    try {
      try {
        const logoutPath = "/user/logout?isAjax=true";
        const logoutOptions = {
          ignoreResponseRedirectUrl: true,
        };
        const logoutTasks = [];
        const logoutAtAccounts = this.getOrPost(logoutPath, logoutOptions);
        logoutTasks.push(logoutAtAccounts);

        // also make request to logout from AxShareHost, as cookie is HttpOnly and can't be cleared via JS
        if (!testSameOrigin(this.accountServiceHost, this.axShareHost)) {
          const logoutAtApp = this.getOrPost(`${this.axShareHost}${logoutPath}`, logoutOptions);
          logoutTasks.push(logoutAtApp);
        }

        if (this.isGuest) {
          const logoutAtAppGuest = this.postAppServer("/user/guestLogout");
          logoutTasks.push(logoutAtAppGuest);
        }

        await Promise.all(logoutTasks);
      } catch (e) {
        // silently skip
        console.warn(e);
      }

      authStorage.clearToken();
      this.userId = undefined;
      this.isGuest = false;
      this.isAuthed = false;

      if (isElectronRenderer) {
        await mainProcessLogout();
      }
    } catch (e) {
      console.warn(e);
    }
  }

  public getUserProfile() {
    return this.get<UserProfile>("/user/getUserProfile");
  }

  public async changeAccountInfo(model: ChangeAccountInfoModel) {
    const userId = this.UserId;
    if (userId) {
      const formData = objectToFormData(model);
      const response = await this.getOrPost<ChangeAccountInfoResponse>("/user/changeAccountInfo", formData);

      if (response.authToken) {
        await this.setAuth(response.authToken, userId);
        return response.authToken;
      }
    }
  }

  public async updateUserProfileName(name: string) {
    const formData = objectToFormData({ name });
    return this.getOrPost<ResponseObject>("/user/updateUserProfileName", formData);
  }

  public async getTimeZones() {
    return this.get<ListJs<TimeZoneJs>>("/user/getTimeZones");
  }

  public async setUserTimeZone(timeZoneId?: string) {
    const formData: FormData = this.getTimeZoneFormData(timeZoneId);

    return this.post<ResponseObject>("/user/setLocalTimeZone", formData);
  }

  public async setGuestUserTimeZone(timeZoneId?: string) {
    const formData = this.getTimeZoneFormData(timeZoneId);
    return this.postAppServer<ResponseObject>("/user/setGuestLocalTimeZone", formData);
  }

  public async updateUserProfileBio(bio: string) {
    const params = stringify({ bio });
    const url = `/user/updateUserProfileBio?${params}`;
    return this.getOrPost<ResponseObject>(url, { ignoreResponseRedirectUrl: true });
  }

  public async uploadUserProfileImg(fileToUpload: File) {
    const formData = objectToFormData({ fileToUpload });
    const url = "/user/uploadUserProfileImg";
    return this.post<ResponseObject>(url, formData);
  }

  public async deleteUserProfileImg() {
    const url = "/user/deleteUserProfileImg";
    return this.getOrPost<ResponseObject>(url, { ignoreResponseRedirectUrl: true });
  }

  public async cacheLicenseResponse(requestId: string, response: string) {
    const url = "/user/cacheLicenseResponse";
    return this.post(url, objectToFormData({
      requestId, response,
    }));
  }

  private get<T>(url: string, optsOrFormData?: FormData | Options, opts?: Options): Promise<T> {
    const { formData, options } = this.unwrapArgs(optsOrFormData, opts);

    if (formData) {
      // eslint-disable-next-line no-param-reassign
      url = this.appendFormDataToQueryString(url, formData);
    }

    return exec<T>(this.accountServer.get(url), options);
  }

  private post<T>(url: string, optsOrFormData?: FormData | Options, opts?: Options): Promise<T> {
    const { formData, options } = this.unwrapArgs(optsOrFormData, opts);
    return exec<T>(this.accountServer.post(url, formData), options);
  }

  private postAppServer<T>(url: string, optsOrFormData?: FormData | Options, opts?: Options): Promise<T> {
    const { formData, options } = this.unwrapArgs(optsOrFormData, opts);
    return exec<T>(this.appServer.post(url, formData), options);
  }

  private async setAuth(authToken: string, userId: string) {
    authStorage.Token = authToken;
    this.userId = userId;
    await this.setDesktopAuth(authToken);
  }

  private getTimeZoneFormData(timeZoneId?: string): FormData {
    const timeZone = timeZoneId || Intl.DateTimeFormat().resolvedOptions().timeZone;
    const baseUtcOffsetMinutes = -(new Date().getTimezoneOffset());

    return objectToFormData({ timeZone, baseUtcOffsetMinutes });
  }

  private async setDesktopAuth(token: string) {
    if (isElectronRenderer) {
      try {
        const config: AxShareHostsConfig = {
          AccountServiceSecureUrl: this.accountServiceHost,
          AxShareHostSecureUrl: this.axShareHost,
          authToken: token,
        };

        await mainProcessLogin(config);
      } catch (e) {
        // passing auth into to main process may fail if inside iframe, like in-app prototype
        // in this case swallow error if inside iframe,
        // otherwise rethrow
        if (!isIframe()) {
          throw e;
        }
      }
    }
  }

  private unwrapArgs(
    optsOrFormData: FormData | Options | undefined,
    opts: Options | undefined,
  ): { formData?: FormData; options?: Options; } {
    // eslint-disable-next-line no-nested-ternary
    return optsOrFormData
      ? optsOrFormData instanceof FormData
        ? { formData: optsOrFormData, options: opts }
        : { formData: undefined, options: optsOrFormData }
      : { formData: undefined, options: undefined };
  }

  private appendFormDataToQueryString(url: string, formData: FormData) {
    // eslint-disable-next-line prefer-const
    let [path, query] = url.split("?");

    const formObject: Record<string, any> = {};
    formData.forEach((value, key) => { formObject[key] = value; });
    const params = stringify(formObject);
    query = query ? query + params : params;

    return `${path}?${query}`;
  }
}

function mainProcessLogin(config: AxShareHostsConfig) {
  return new Promise<Electron.IpcRendererEvent | void>(resolve => {
    if (!isElectronRenderer) resolve();

    window.AxureCloudNative.ipc.once(Renderer.LoginHandled, resolve);
    window.AxureCloudNative.ipc.send(Main.Login, config);

    // for backwards compatibility when main process won't send Renderer.LoginHandled
    // give a moment to logout to complete, and then resolve promise
    delay(2000).then(() => resolve());
  });
}

function mainProcessLogout() {
  return new Promise<Electron.IpcRendererEvent | void>(resolve => {
    if (!isElectronRenderer) resolve();

    window.AxureCloudNative.ipc.once(Renderer.LogoutHandled, resolve);
    window.AxureCloudNative.ipc.send(Main.Logout);

    // for backwards compatibility when main process won't send Renderer.LogoutHandled
    // give a moment to logout to complete, and then resolve promise
    delay(2000).then(() => resolve());
  });
}
