
import Vue from "vue";
import { PropValidator } from "vue/types/options";

import { FilesystemNodeClonable } from "@/common/fs";
import { asError } from "@/common/lib";
import { cloneShortcut } from "@/services/project.service";

import AxButton from "@/components/AxButton.vue";
import AxForm from "@/components/AxForm.vue";
import AxReCaptcha from "@/components/AxReCaptcha.vue";
import { Submit } from "@/components/types/AxForm";

const item: PropValidator<FilesystemNodeClonable> = {
  type: Object,
  required: true,
};
const props = {
  item,
};

export default Vue.extend({
  components: {
    AxButton,
    AxForm,
    AxReCaptcha,
  },

  props: {
    folder: {
      type: String,
      required: true,
    },

    ...props,
  },

  data() {
    return {
      selected: null,
      captchaReady: false,
      submitting: false,
      errors: [] as string[],
    };
  },

  methods: {
    onCaptchaReady(ready: boolean) {
      this.captchaReady = ready;
    },

    async submit(_: Submit<any>) {
      this.errors = [];
      this.submitting = true;
      await (this.$refs.recaptcha as any).execute();
    },

    async recaptchaCallback(recaptchaToken: string | undefined = undefined) {
      try {
        await cloneShortcut(this.item.id, this.folder, recaptchaToken);
        this.$emit("submit");
        this.submitting = false;
      } catch (error) {
        this.errors.push(asError(error).message);
      } finally {
        this.submitting = false;
      }
    },
  },
});
