import { Payload } from "vuex";

import { AxNotificationOptions, AxMentionedNotificationOptions } from "@/common/notifications/AxNotificationOptions";
import { AddNotificationModel, EditNotificationModel, NotificationMuteItemModel } from "@/services/models/notification";

import { PayloadMap } from "../typed";

export enum ActionTypes {
  AddNotification = "Add Notification",
  AddNotificationMuteItem = "Add Notification Mute Item",
  EditNotification = "Edit Notification",
  GetUserSubscriptions = "Get User Subscriptions",
  GetUserGuestSubscriptions = "Get User Guest Subscriptions",
  GetNotificationMuteItems = "Get Notification Mute Items",
  DeleteUserSubscription = "Delete User Subscription",
  DeleteUserGuestSubscription = "Delete User Guest Subscription",
  DeleteNotificationMuteItem = "Delete Notification Mute Item",
  DeleteLegacyEmailSubscriptions = "Delete Legacy Email Subscriptions",
  ShowDesktopNotification = "Show Desktop Notification",
  ShowMentionedDesktopNotification = "Show Mentioned Desktop Notification",
  ShowWorkspaceInvitedDesktopNotification = "Show Workspace Invited Desktop Notification",
}

export class AddNotification implements Payload {
  public readonly type = ActionTypes.AddNotification;
  constructor(public readonly model: AddNotificationModel) {}
}

export class AddNotificationMuteItem implements Payload {
  public readonly type = ActionTypes.AddNotificationMuteItem;
  constructor(public readonly model: NotificationMuteItemModel) {}
}

export class EditNotification implements Payload {
  public readonly type = ActionTypes.EditNotification;
  constructor(public readonly model: EditNotificationModel) {}
}

export class GetUserSubscriptions implements Payload {
  public readonly type = ActionTypes.GetUserSubscriptions;
}

export class GetUserGuestSubscriptions implements Payload {
  public readonly type = ActionTypes.GetUserGuestSubscriptions;
}

export class GetNotificationMuteItems implements Payload {
  public readonly type = ActionTypes.GetNotificationMuteItems;
}

export class DeleteUserSubscription implements Payload {
  public readonly type = ActionTypes.DeleteUserSubscription;
  constructor(public readonly notificationId: string) {}
}

export class DeleteUserGuestSubscription implements Payload {
  public readonly type = ActionTypes.DeleteUserGuestSubscription;
  constructor(public readonly notificationId: string) {}
}

export class DeleteNotificationMuteItem implements Payload {
  public readonly type = ActionTypes.DeleteNotificationMuteItem;
  constructor(public readonly notificationId: string) {}
}

export class DeleteLegacyEmailSubscriptions implements Payload {
  public readonly type = ActionTypes.DeleteLegacyEmailSubscriptions;
}

export class ShowDesktopNotification implements Payload {
  public readonly type = ActionTypes.ShowDesktopNotification;
  constructor(public readonly options: AxNotificationOptions) {}
}

export class ShowMentionedDesktopNotification implements Payload {
  public readonly type = ActionTypes.ShowMentionedDesktopNotification;
  constructor(public readonly options: AxMentionedNotificationOptions) {}
}

export class ShowWorkspaceInvitedDesktopNotification implements Payload {
  public readonly type = ActionTypes.ShowWorkspaceInvitedDesktopNotification;
  constructor(public readonly options: AxMentionedNotificationOptions) {}
}

export interface ActionPayloadMap extends PayloadMap<ActionTypes> {
  [ActionTypes.AddNotification]: AddNotification;
  [ActionTypes.AddNotificationMuteItem]: AddNotificationMuteItem;
  [ActionTypes.EditNotification]: EditNotification;
  [ActionTypes.GetUserSubscriptions]: GetUserSubscriptions;
  [ActionTypes.GetUserGuestSubscriptions]: GetUserGuestSubscriptions;
  [ActionTypes.GetNotificationMuteItems]: GetNotificationMuteItems;
  [ActionTypes.DeleteUserSubscription]: DeleteUserSubscription;
  [ActionTypes.DeleteUserGuestSubscription]: DeleteUserGuestSubscription;
  [ActionTypes.DeleteNotificationMuteItem]: DeleteNotificationMuteItem;
  [ActionTypes.DeleteLegacyEmailSubscriptions]: DeleteLegacyEmailSubscriptions;
  [ActionTypes.ShowDesktopNotification]: ShowDesktopNotification;
  [ActionTypes.ShowMentionedDesktopNotification]: ShowMentionedDesktopNotification;
  [ActionTypes.ShowWorkspaceInvitedDesktopNotification]: ShowWorkspaceInvitedDesktopNotification;
}
