//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export const enum FileType {
  Jpg = 0,
  Png = 1,
  Gif = 2,
  Axvg = 3
}
export interface Interaction
{
  Type: InteractionType;
  Animation: Animation;
}
export interface Action extends IdObject
{
  Name: string;
  Hotspot: Hotspot;
  Type: ActionType;
  Setting: ActionSetting;
  ParentId: string;
  IsMaster: boolean;
  RelativeToBottom?: number;
}
export interface NavigationAction extends ActionSetting
{
  TargetArtboardId: string;
}
export interface ScrollPositionAction extends ActionSetting
{
  Location: Point;
  IsSmoothScroll: boolean;
}
export interface ExternalUrlAction extends ActionSetting
{
  Url: string;
}
export interface ActionSetting
{
  Interaction: Interaction;
  Type: ActionType;
}
export interface ActionSettingConverter
{
}
export interface DebugConverter
{
}
export interface Animation
{
  Out: AnimationSetting;
  In: AnimationSetting;
}
export interface AnimationSetting
{
  Easing: EasingType;
  Animation: AnimationType;
  Duration: number;
}
export interface ArtboardInfo
{
  ArtboardId: string;
  ActionPoolId: string;
  CurrRevision: number;
  Name: string;
  IsModified: boolean;
  IsArchived: boolean;
  IsDeleted: boolean;
}
export interface ActionPool extends IdObject
{
  ArtboardId: string;
  Actions: { [key:string]: Action };
}
export interface Artboard extends IdObject
{
  S3_BUCKET: string;
  AssetId: string;
  SubAssetIds: string[];
  AssetInfo: { [key:string]: Asset };
  MasterIds: string[];
  Name: string;
  NextArtboardId: string;
  HeaderHeight: number;
  FooterHeight: number;
  HasHeader: boolean;
  HasFooter: boolean;
  IsArchived: boolean;
  IsHandoffCapable: boolean;
  Asset: Asset;
  SubAssets: { [key:string]: Asset };
  ActionPool: ActionPool;
  CurrRevision: number;
}
export interface AssetInfo
{
  AssetId: string;
  IsModified: boolean;
  IsDeleted: boolean;
}
export interface ArtboardAssetManifest
{
  ArtboardAsset: Asset;
  SubAssets: Asset[];
}
export interface Asset extends IdObject
{
  OriginalFileName: string;
  FileName: string;
  ThumbFileName: string;
  FilePath: string;
  Type: FileType;
  Dimensions: Dimensions;
  LayerId: string;
  Scale: number;
}
export interface Hotspot
{
  Start: Point;
  End: Point;
  Dimensions: Dimensions;
}
export interface Point
{
  X: number;
  Y: number;
}
export interface IdObject
{
  Id: string;
  CreatedOn: string;
  ModifiedOn?: string;
  DeletedOn?: string;
  IsModified: boolean;
  IsDeleted: boolean;
}
export interface SyncManifest
{
  Shortcut: string;
  Artboards: SyncArtboard[];
}
export interface SyncArtboard
{
  Id: string;
  Name: string;
  Asset: SyncAsset;
  SubAssets: SyncAsset[];
}
export interface SyncAsset
{
  LayerId: string;
  Name: string;
  FileName: string;
  Scale: number;
}
export interface Master extends IdObject
{
  Name: string;
  Actions: { [key:string]: Action };
}
export interface Project extends IdObject
{
  ShortcutKey: string;
  ProjectName: string;
  Platform: Platform;
  MasterIds: string[];
  ArtboardIds: string[];
  ArtboardInfoMap: { [key:string]: ArtboardInfo };
  Zoom: number;
}
export interface ExpoRevision
{
  ArtboardId: string;
  CurrentRevisionNumber: number;
  RevisionTimestamp: { [key:number]: string };
  ModifiedOn?: string;
  CreatedOn: string;
  IsModified: boolean;
}
export interface ExpoJson
{
}
export interface ExpoStaticContent
{
  project: Project;
  masters: { [key:string]: Master };
  artboards: { [key:string]: Artboard };
  assets: { [key:string]: Asset };
  images: { [key:string]: string };
  thumbnails: { [key:string]: string };
  bgImgLink: string;
}
export interface AddHotspotRequest
{
  tag: string;
  artboardId?: string;
  masterId?: string;
  hotspot: Hotspot;
  type: ActionType;
  setting: ActionSetting;
  relativeToBottom?: number;
}
export interface ArchiveArtboardRequest
{
  artboardId: string;
}
export interface CreateMasterRequest
{
  name: string;
  artboardId: string;
}
export interface DeleteArtboardRequest
{
  artboardId: string;
}
export interface DeleteArtboardsRequest
{
  artboardIds: string[];
}
export interface DeleteHotspotRequest
{
  isMaster: boolean;
  sourceId: string;
  actionId: string;
}
export interface EditHotspotRequest
{
  sourceId: string;
  targetId: string;
  actionId: string;
  hotspot: Hotspot;
  type: ActionType;
  setting: ActionSetting;
  relativeToBottom?: number;
  isSourceMaster: boolean;
  isTargetMaster: boolean;
}
export interface ModifyMasterRequest
{
  artboardId: string;
  masterId: string;
  name: string;
}
export interface ReorderArtboardRequest
{
  targetAbId: string;
  tailAbId: string;
  fullResequence: boolean;
}
export interface SetHeaderFooterHeightRequest
{
  artboardId: string;
  height: number;
  isHeader: boolean;
}
export interface SetZoomRequest
{
  zoom: number;
}
export interface UpdatePlatformRequest
{
  platform: Platform;
}
export const enum ActionType {
  SetPanelState = 0,
  PreviousScreen = 1,
  NextScreen = 2,
  PreviousHistoryScreen = 3,
  PointOnScreen = 4,
  ExternalUrl = 5
}
export const enum InteractionType {
  Click = 0,
  DoubleClick = 1,
  SwipeRight = 2,
  SwipeLeft = 3,
  SwipeUp = 4,
  SwipeDown = 5
}
export const enum AnimationType {
  None = 0,
  Swing = 1,
  Linear = 2,
  EaseInCubic = 3,
  EaseOutCubic = 4,
  EaseInOutCubic = 5,
  Bounce = 6,
  Elastic = 7
}
export const enum EasingType {
  None = 0,
  Fade = 1,
  PushRight = 2,
  PushLeft = 3,
  SlideRight = 4,
  SlideLeft = 5,
  SlideUp = 6,
  SlideDown = 7,
  FlipRight = 8,
  FlipLeft = 9,
  FlipUp = 10,
  FlipDown = 11
}
export const enum DeviceType {
  iPhone8 = 0,
  iPhone8Plus = 1,
  iPhoneSE = 2,
  iPhoneX = 3,
  iPhone11Pro = 4,
  iPhone12Pro = 5,
  iPhone12ProMax = 6,
  iPhone13Pro = 7,
  iPhone13ProMax = 8,
  iPadMini7_9 = 9,
  iPadMini8_3 = 10,
  iPad10_2 = 11,
  iPadPro11 = 12,
  IPadPro12_9 = 13,
  GalaxyS8 = 14,
  Pixel2 = 15,
  Pixel2XL = 16,
  Mobile = 17,
  Tablet9 = 18,
  Tablet7 = 19,
  Custom = 20,
  Web = 21,
  Auto = 22,
  iPhone11 = 23,
  GalaxyS9 = 24,
  GalaxyS10 = 25,
  Pixel3 = 26,
  Pixel3XL = 27,
  Pixel4 = 28,
  Pixel5 = 29,
  Pixel4XL = 30
}
export const enum ForegroundColor {
  Light = 0,
  Dark = 1
}
export const enum PlatformType {
  Web = 0,
  Mobile = 1,
  MobileApple = 2,
  MobileGoogle = 3,
  MobileSamsung = 4
}
export const enum Orientation {
  Portrait = 0,
  Landscape = 1
}
export const enum PageAlign {
  Left = 0,
  Center = 1
}
export const enum HorizontalAlign {
  Left = 0,
  Center = 1,
  Right = 2
}
export const enum VerticalAlign {
  Top = 0,
  Middle = 1,
  Bottom = 2
}
export const enum ImageRepeat {
  NoRepeat = 0,
  Repeat = 1,
  RepeatX = 2,
  RepeatY = 3,
  StretchToCover = 4,
  StretchToContain = 5,
  StretchHorizontalAndVertical = 6
}
export interface MobilePlatform extends Platform
{
  HasStatusBar: boolean;
  StatusBarHeight: number;
  ForegroundColor: ForegroundColor;
  ShowScreensBelowStatusBar: boolean;
}
export interface PlatformInfo
{
}
export interface PlatformConverter
{
}
export interface Dimensions
{
  Width: number;
  Height: number;
}
export interface PlatformSettings
{
  Orientation: Orientation;
  Dimensions: Dimensions;
}
export interface Platform
{
  Type: PlatformType;
  Device: DeviceType;
  Settings: PlatformSettings;
  BackgroundColor: string;
  IsUsingBackgroundColor: boolean;
}
export interface WebPlatform extends Platform
{
  PageAlignment: PageAlign;
  HasBackgroundImage: boolean;
  BackgroundImageExt: string;
  BackgroundImageName: string;
  BackgroundImageWidth: number;
  BackgroundImageHeight: number;
  HorizontalAlignment: HorizontalAlign;
  VerticalAlignment: VerticalAlign;
  ImageRepeat: ImageRepeat;
}
export interface DeviceDropDownOption
{
  Value: number;
  Text: string;
  DeviceName: string;
  Device: DeviceType;
  Dimensions?: Dimensions;
}
