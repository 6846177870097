import { AxShareConfigModel } from "@shared/models";

import { isDesktopLocal } from "@/common/environment";
import { delay } from "@/common/lib";

let apiBaseUrl = "";
let accountServiceUrl = "";
let apiCallsCounter: number = 0;

// slight delay after write operation to avoid next stale read
export const dualDbDelay = (ms?: number) => delay(ms ?? 50);

export function setApiBaseUrl(url: string) {
  apiBaseUrl = url;
}

export function setAccountServiceHost(url: string) {
  accountServiceUrl = url;
}

export const resolveApiBaseUrl = (config?: AxShareConfigModel | null): string => {
  if (config) {
    return config.MatchProtocolMode ? config.AxShareHostUrl : config.AxShareHostSecureUrl;
  }
  if (apiBaseUrl) return apiBaseUrl;
  return (isDesktopLocal ? process.env.VUE_APP_DESKTOP_AXSHARE_API_URL : process.env.VUE_APP_AXSHARE_API_URL) || "";
};

export const resolveAccountServiceHost = (config?: AxShareConfigModel | null): string => {
  if (config) {
    return config.MatchProtocolMode ? config.AccountServiceUrl : config.AccountServiceSecureUrl;
  }
  if (accountServiceUrl) return accountServiceUrl;
  return process.env.VUE_APP_AXSHARE_ACCOUNT_SERVICE_URL || "";
};

export function getApiCallId() {
  apiCallsCounter++;
  return apiCallsCounter.toString();
}
