
import Vue, {
  VNode, VNodeChildren, VNodeChildrenArrayContents, VNodeData,
} from "vue";
import { PropValidator } from "vue/types/options";
import { ScopedSlotChildren } from "vue/types/vnode";

import { getObjectValueByPath, objectsSort } from "@/common/lib";

import AxButton from "@/components/AxButton.vue";
import AxDataTableCellCheckbox from "@/components/AxDataTableCellCheckbox.vue";
import AxSorter from "@/components/icons/AxSorter.vue";
import { Header } from "@/components/types/AxDataTable";

export interface Pagination {
  descending?: boolean;
  page: number;
  rowsPerPage: number;
  sortBy?: string;
  totalItems?: number;
}

interface RowPerPageItemValue {
  text: string;
  value: number;
}

export type RowPerPageItem = Array<number | RowPerPageItemValue>;

export default Vue.extend({
  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    value: {
      type: Array,
      required: false,
      default(): any[] {
        return [];
      },
    } as PropValidator<any[]>,

    items: {
      type: Array,
      required: true,
      default(): any[] {
        return [];
      },
    } as PropValidator<any[]>,

    headers: {
      type: Array,
      default(): Header[] {
        return [];
      },
    } as PropValidator<Header[]>,

    rowsPerPageItems: {
      type: Array,
      default() {
        return [
          {
            text: "All",
            value: -1,
          },
          5,
          10,
          25,
        ];
      },
    } as PropValidator<RowPerPageItem>,

    itemKey: {
      type: String,
      default: "id",
    },

    headerText: {
      type: String,
      default: "text",
    },

    selectColumn: {
      type: Boolean,
      default: false,
    },

    selectAll: {
      type: Boolean,
      default: false,
    },

    customSort: {
      type: Function,
      default: objectsSort,
    } as PropValidator<typeof objectsSort>,

    fixedHeader: {
      type: Boolean,
      default: false,
    },

    fixedActionsHeader: {
      type: Boolean,
      default: false,
    },

    fixedHeaderStyles: {
      type: Object,
      default: () => ({}),
    },

    pagination: {
      type: Object,
      required: false,
      default: undefined,
    } as PropValidator<Pagination>,

    totalItems: {
      type: Number,
      default: null,
    },

    disableInitialSort: {
      type: Boolean,
      default: false,
    },

    hideActionsFooter: {
      type: Boolean,
      default: true,
    },

    hideHeaders: {
      type: Boolean,
      default: false,
    },

    responsive: {
      type: Boolean,
      default: true,
    },

    transitionItems: {
      type: String,
      default: "",
    },
  },

  data() {
    const defaultPagination: Pagination = {
      descending: false,
      page: 1,
      rowsPerPage: -1,
      sortBy: undefined,
      totalItems: 0,
    };
    return {
      defaultPagination,
    };
  },

  computed: {
    computedPagination(): Pagination {
      return this.hasPagination ? { ...this.defaultPagination, ...this.pagination } : this.defaultPagination;
    },

    hasPagination(): boolean {
      const pagination = this.pagination || {};
      return Object.keys(pagination).length > 0;
    },

    filteredItems(): any[] {
      return this.filteredItemsImpl();
    },

    headerColumns(): number {
      const { headers } = this;
      return headers.length + (this.hasSelectAll || this.hasSelectColumn ? 1 : 0);
    },

    tableClasses(): Record<string, boolean> {
      const { fixedHeader } = this;
      return {
        "ax-table--fixed-header": fixedHeader,
      };
    },

    hasSelectColumn(): boolean {
      return this.selectColumn !== undefined && this.selectColumn !== false;
    },

    hasSelectAll(): boolean {
      return this.selectAll !== undefined && this.selectAll !== false;
    },

    selected(): Record<string, boolean> {
      const selected: { [key: string]: boolean } = {};
      for (const value of this.value) {
        const key = getObjectValueByPath(value, this.itemKey) as string;
        selected[key] = true;
      }
      return selected;
    },

    everyItem(): boolean {
      return this.filteredItems.length !== 0 && this.filteredItems.every(i => this.isSelected(i));
    },

    someItems(): boolean {
      return this.filteredItems.some(i => this.isSelected(i));
    },

    itemsLength(): number {
      return this.totalItems || this.items.length;
    },

    getPage(): number {
      const { rowsPerPage } = this.computedPagination;

      return rowsPerPage;
    },

    pageStart(): number {
      return this.getPage === -1 ? 0 : (this.computedPagination.page - 1) * this.getPage;
    },

    pageStop(): number {
      return this.getPage === -1 ? this.itemsLength : this.computedPagination.page * this.getPage;
    },

    indeterminate(): boolean {
      return this.hasSelectAll && this.someItems && !this.everyItem;
    },
  },

  created() {
    const firstSortable = this.headers.find(h => h.sortable === true);
    this.defaultPagination.sortBy = !this.disableInitialSort && firstSortable ? firstSortable.value : undefined;
    this.initPagination();
  },

  methods: {
    sort(index: string | undefined) {
      const { sortBy, descending } = this.computedPagination;
      if (sortBy === null) {
        this.updatePagination({ sortBy: index, descending: false });
      } else if (sortBy === index && !descending) {
        this.updatePagination({ descending: true });
      } else if (sortBy !== index) {
        this.updatePagination({ sortBy: index, descending: false });
      } else {
        this.updatePagination({ sortBy: undefined, descending: undefined });
      }
    },

    isSelected(item: any): boolean {
      return !!this.selected[getObjectValueByPath(item, this.itemKey)];
    },

    initPagination() {
      if (!this.rowsPerPageItems.length) {
        console.warn("The prop 'rows-per-page-items' can not be empty", this);
      } else {
        const rowsDefault = this.rowsPerPageItems[0];
        this.defaultPagination.rowsPerPage = typeof rowsDefault === "number" ? rowsDefault : rowsDefault.value;
      }

      this.defaultPagination.totalItems = this.items.length;

      this.updatePagination({ ...this.defaultPagination, ...this.pagination });
    },

    updatePagination(val: Partial<Pagination>) {
      const pagination: Pagination = this.hasPagination ? this.pagination : this.defaultPagination;
      const updatedPagination: Pagination = { ...pagination, ...val };
      this.$emit("update:pagination", updatedPagination);

      if (!this.hasPagination) {
        this.defaultPagination = updatedPagination;
      }
    },

    filteredItemsImpl(): any[] {
      if (this.totalItems) return this.items;

      let items = this.items.slice();
      const { computedPagination } = this;
      if (computedPagination.sortBy) {
        items = this.customSort(items, computedPagination.sortBy, computedPagination.descending);
      }
      return this.hasPagination ? items.slice(this.pageStart, this.pageStop) : items;
    },

    genTHead() {
      if (this.hideHeaders) return undefined;
      let children = [];
      const data: VNodeData = {};
      const row = this.headers.map(o => this.genHeader(o));
      if (this.hasSelectAll) {
        const checkbox = this.$createElement(AxDataTableCellCheckbox, {
          props: {
            value: this.everyItem,
            indeterminate: this.indeterminate,
          },
          on: { input: this.toggle },
        });
        row.unshift(this.$createElement("th", { class: "ax-data-table-checkbox always-show" }, [checkbox]));
      } else if (this.hasSelectColumn) {
        row.unshift(this.$createElement("th", { class: "ax-data-table-checkbox" }, []));
      }

      children = [
        this.genTR(row, {
          class: {
            selected: this.someItems,
          },
        }),
        // this.genTProgress(),
      ];

      if (this.fixedHeader) {
        data.style = this.fixedHeaderStyles;
      }

      return this.$createElement("thead", data, [children]);
    },

    genTProgress() {
      if (this.loading === false) return undefined;

      const col = this.$createElement(
        "th",
        {
          attrs: {
            colspan: this.headerColumns,
          },
        },
        this.genProgress(),
      );

      return this.genTR([col], {
        staticClass: "ax-data-table__progress",
      });
    },

    genProgress() {
      return this.$scopedSlots.progress && this.$scopedSlots.progress({}); // || this.genProgressDefault();
    },

    genProgressDefault() {
      const progressBar = this.$createElement("div", {
        class: ["progress-bar", "progress-bar-striped", "progress-bar-animated", "w-100"],
      });
      return this.$createElement(
        "div",
        {
          class: ["progress", "w-100"],
          attrs: { style: "height: 5px" },
        },
        [progressBar],
      );
    },

    genHeader(header: Header): VNode {
      const array = [
        this.$scopedSlots.headerCell ? this.$scopedSlots.headerCell({ header }) : (header[this.headerText] as string),
      ];
      const { data, children } = this.genHeaderData(header, array);
      return this.$createElement("th", data, children);
    },

    genHeaderData(header: Header, children: VNodeChildrenArrayContents) {
      const classes: string[] = [];
      const data: VNodeData = {
        key: header[this.headerText] as string,
        attrs: {
          scope: "col",
        },

        style: this.genHeaderStyles(header),
        class: classes,
      };

      if (header.sortable == null || header.sortable) {
        this.genHeaderSortingData(header, children, data, classes);
      }

      return { data, children };
    },

    genHeaderStyles(header: Header) {
      let styles: Partial<CSSStyleDeclaration> = {};
      if (header.width) {
        const headerWidthStyles = { flexBasis: header.width, flexGrow: "0" };
        styles = { ...styles, ...headerWidthStyles };
      }
      return styles;
    },

    genHeaderSortingData(header: Header, children: VNodeChildrenArrayContents, data: VNodeData, classes: string[]) {
      if (!("value" in header)) {
        console.warn("Headers must have a value property that corresponds to a value in the v-model array", this);
      }

      data.attrs = {
        ...data.attrs,
        tabIndex: 0,
      };
      data.on = {
        click: () => {
          this.sort(header.value);
        },

        keydown: (e: KeyboardEvent) => {
          const spaceKeyCode = 32;
          if (e.keyCode === spaceKeyCode) {
            e.preventDefault();
            this.sort(header.value);
          }
        },
      };

      classes.push("sortable");
      if (this.isSorted(header)) {
        classes.push("active");
      }
      const sorter = this.genSorter(header);
      children.push(sorter);
    },

    genSorter(header: Header): VNode {
      const data: VNodeData = {
        class: "sort-icon",
      };
      if (this.isSorted(header)) {
        data.props = { ...data.props, sorting: this.computedPagination.descending ? "desc" : "asc" };
      }
      return this.$createElement(AxSorter, data);
    },

    isSorted(header: Header) {
      return this.computedPagination.sortBy === header.value;
    },

    genTBody() {
      const children = this.genItems();
      if (this.transitionItems) {
        return this.$createElement(
          "transition-group",
          {
            props: {
              name: this.transitionItems,
              tag: "tbody",
            },
          },
          children,
        );
      }
      return this.$createElement("tbody", children);
    },

    genTFoot(): VNode | string {
      if (!this.$scopedSlots.footer) {
        return "";
      }

      const footer = this.$scopedSlots.footer({});
      const row = this.hasTag(footer, "td") ? this.genTR([footer]) : footer;

      return this.$createElement("tfoot", [row]);
    },

    genActionsHeader(): VNode | string {
      if (this.$scopedSlots.actionsHeader) {
        return this.$createElement(
          "div",
          {
            class: this.fixedActionsHeader ? "ax-data-table__actions-header-fixed" : "ax-data-table__actions-header",
          },
          [this.$scopedSlots.actionsHeader({})],
        );
      }
      return "";
    },

    genActionsFooter(): VNode | string {
      if (this.hideActionsFooter) {
        return "";
      }

      return this.$createElement("div", { class: "ax-data-table__actions-footer" }, [
        this.genPagination(),
        this.genPrevBtn(),
        this.genNextBtn(),
      ]);
    },

    genPagination(): VNode {
      let pagination: VNode | VNodeChildren = "–";

      if (this.itemsLength) {
        const stop = this.itemsLength < this.pageStop || this.pageStop < 0 ? this.itemsLength : this.pageStop;

        pagination = this.$scopedSlots.pageText
          ? this.$scopedSlots.pageText({
            pageStart: this.pageStart + 1,
            pageStop: stop,
            itemsLength: this.itemsLength,
          })
          : `${this.pageStart + 1}-${stop} of ${this.itemsLength}`;
      }

      return this.$createElement("div", { class: "ax-data-table__pagination" }, [pagination]);
    },

    genPrevBtn() {
      return this.$createElement(
        AxButton,
        {
          props: {
            disabled: this.computedPagination.page === 1,
          },
          on: {
            click: () => {
              const { page } = this.computedPagination;
              this.updatePagination({ page: page - 1 });
            },
          },
        },
        ["Prev"],
      );
    },

    genNextBtn() {
      const pagination = this.computedPagination;
      const disabled = pagination.rowsPerPage < 0 || pagination.page * pagination.rowsPerPage >= this.itemsLength || this.pageStop < 0;

      return this.$createElement(
        AxButton,
        {
          props: {
            disabled,
          },
          on: {
            click: () => {
              const { page } = this.computedPagination;
              this.updatePagination({ page: page + 1 });
            },
          },
        },
        ["Next"],
      );
    },

    genItems() {
      if (!this.items.length && !this.loading) {
        const noData = (this.$scopedSlots.noData && this.$scopedSlots.noData({})) || "No items to display.";
        return [this.genEmptyItems(noData)];
      }

      return this.genFilteredItems();
    },

    genFilteredItems(): VNodeChildren {
      if (!this.$scopedSlots.items) {
        return undefined;
      }

      const rows = [];
      for (let index = 0, len = this.filteredItems.length; index < len; ++index) {
        const item = this.filteredItems[index];
        const props = this.createProps(item, index);
        const row = this.$scopedSlots.items(props);
        if (!row) continue;
        rows.push(
          this.hasTag(row, "td")
            ? this.setTdStyles(
              this.genTR([row], {
                key: this.getKey(props.item) || index,
                class: { selected: this.isSelected(item) },
              }),
            )
            : this.setRowTdStyles(row),
        );
      }
      return rows;
    },

    setRowTdStyles(row: ScopedSlotChildren) {
      if (!row) return row;
      if (typeof row === "string") return row;
      if (!Array.isArray(row)) return row;
      for (const r of row) {
        if (!r || typeof r === "string" || typeof r === "boolean" || Array.isArray(r)) continue;
        if (r.tag !== undefined) {
          this.setTdStyles(r);
        }
      }
      return row;
    },

    setTdStyles(row: VNode) {
      const totalHeaders = this.headers.length;
      if (!row.children) return row;

      const cellOffset = this.hasSelectAll || this.hasSelectColumn ? 1 : 0;
      const children = row.children.filter(cell => cell.tag === "td");
      for (let i = 0; i < totalHeaders; i++) {
        const header = this.headers[i];
        const cell = children[i + cellOffset];
        if (!cell || !header) continue;
        if (cell.tag !== "td") continue;

        const data = cell.data || {};
        let headerStyles;
        if (data.style && typeof data.style === "object") {
          headerStyles = { ...data.style, ...this.genHeaderStyles(header) };
        } else {
          headerStyles = this.genHeaderStyles(header);
        }

        data.style = headerStyles;
        cell.data = data;
      }
      return row;
    },

    createProps(item: any, index: number) {
      const keyProp = this.itemKey;
      const itemKey = getObjectValueByPath(item, keyProp);
      const props = { item, index, key: itemKey };

      Object.defineProperty(props, "selected", {
        get: () => this.isSelected(item),
        set: value => {
          if (itemKey == null) {
            console.warn(`"${keyProp}" attribute must be defined for item`, this);
          }

          let selected = this.value.slice();
          if (value) selected.push(item);
          else {
            selected = selected.filter(i => getObjectValueByPath(i, keyProp) !== itemKey);
          }
          this.$emit("input", selected);
        },
      });

      return props;
    },

    genEmptyItems(content: VNodeChildren) {
      return this.genTR([
        this.$createElement(
          "th",
          {
            attrs: { colspan: this.headerColumns },
          },
          content,
        ),
      ], {
        class: "ax-no-data-row",
      });
    },

    hasTag(elements: any, tag: string): boolean {
      return Array.isArray(elements) && elements.find(e => e.tag === tag);
    },

    genTR(children: VNodeChildren, data?: VNodeData) {
      return this.$createElement("tr", data, children);
    },

    toggle(value: boolean) {
      const selected = { ...this.selected };
      for (const filteredItem of this.filteredItems) {
        const key = getObjectValueByPath(filteredItem, this.itemKey);
        selected[key] = value;
      }

      this.$emit(
        "input",
        this.items.filter(i => {
          const key = getObjectValueByPath(i, this.itemKey);
          return selected[key];
        }),
      );
    },

    getKey(item: any): any | null {
      return this.itemKey ? getObjectValueByPath(item, this.itemKey) : null;
    },
  },

  render(h): VNode {
    let table = h("table", { class: this.tableClasses }, [this.genTHead(), this.genTBody(), this.genTFoot()]);

    if (this.responsive) {
      table = h("div", { class: "ax-data-table__overflow" }, [table]);
    }

    return h("div", { class: "ax-data-table" }, [this.genActionsHeader(), table, this.genActionsFooter()]);
  },
});
