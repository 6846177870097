
import Vue from "vue";

import { AxShare } from "@/store/state";

export default Vue.extend({
  computed: {
    isFullScreen(): boolean {
      const { desktop } = this.$store.state as AxShare;
      return desktop.desktopParams.isFullScreen;
    },
  },
});
