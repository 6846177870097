
import Vue from "vue";

import AxButton from "@/components/AxButton.vue";

export default Vue.extend({
  components: {
    AxButton,
  },

  props: {
    retry: {
      type: Function,
      default: undefined,
    },
  },
});
