
import Vue from "vue";

import LicensingService from "@/services/licensing.service";
import { AxShare } from "@/store/state";

import AxCopyToClipboard from "@/components/AxCopyToClipboard.vue";

export default Vue.extend({
  components: { AxCopyToClipboard },
  props: {
    requestId: {
      type: String,
      required: true,
    },
    machineId: {
      type: String,
      required: false,
      default: "",
    },
    token: {
      type: String,
      required: true,
    },
    rpVersion: {
      type: String,
      required: true,
    },
  },
  data() {
    const protocol = this.rpVersion === "10" ? "axurerp10" : "axurerp";
    const desktopLink = `${protocol}://signin?requestId=${this.requestId}&authToken=${this.token}`;

    return {
      desktopLink,
      keyData: "",
      fetchedLicense: false,
      keyTypeText: "",
    };
  },
  async mounted() {
    if (this.machineId) {
      const { axShareConfig } = this.$store.state as AxShare;
      const licensingHost = axShareConfig?.LicensingServiceHost;
      if (licensingHost) {
        try {
          const now = new Date();
          const licensingService = new LicensingService(licensingHost, this.token);
          const data = await licensingService.getLicenseData(this.machineId);
          const response = data.Payload;
          let keyData = response.BestLicense?.key;
          if (keyData) {
            const expireString = response.BestLicense.expireDate;
            const expire = expireString ? new Date(expireString) : now;

            if (expire > now) {
            // subscription
              this.keyTypeText = "subscription";
              this.keyData = keyData;
            }
          } else if (response.CurrentTrial) {
            keyData = response.CurrentTrial.TrialKey;
            const expireString = response.CurrentTrial.ExpireDate;
            const expire = new Date(expireString);

            if (keyData && expire > now) {
              this.keyTypeText = "trial";
              this.keyData = keyData;
            }
          }
          this.fetchedLicense = true;
        } catch (error) {
          console.error(error);
          // suppress licensing host errors.
        }
      }
    }

    // Firefox has a wierd issue, when clicking on element prevents some requests
    setTimeout(() => { (this.$refs.link as HTMLElement).click(); }, 1000);
  },
});
