
import Vue from "vue";
import { Location } from "vue-router";

import { ROUTES } from "@/router";
import { ArtboardUploadStatus, ArtboardUploadInfo } from "@/store/uploads/state";

import AxButton from "@/components/AxButton.vue";
import AxIcon from "@/components/AxIcon.vue";
import AxProgressBar from "@/components/AxProgressBar.vue";
import { prop } from "@/components/utils";

const itemProp = prop<ArtboardUploadInfo>({
  required: true,
});

export default Vue.extend({
  components: {
    AxButton,
    AxIcon,
    AxProgressBar,
  },

  props: {
    item: itemProp,
  },

  data() {
    return {
      removed: false,
    };
  },

  computed: {
    hidden(): boolean {
      return this.canceled || this.removed;
    },

    progressBarOptions(): any {
      const options: Record<string, any> = {
        height: "3px",
        indeterminate: this.indeterminate,
        progress: this.item.progress || 0,
      };
      if (this.item.status === ArtboardUploadStatus.Error) {
        options.spinnerColor = "#f90078";
        options.progress = 100;
      }
      return options;
    },

    name(): string {
      return this.item.name;
    },

    exporting(): boolean {
      return this.item.status === ArtboardUploadStatus.Exporting;
    },

    inProgress(): boolean {
      const status = this.item.status;
      return status === ArtboardUploadStatus.Exporting || status === ArtboardUploadStatus.InProgress;
    },

    success(): boolean {
      return this.item.status === ArtboardUploadStatus.Success;
    },

    error(): boolean {
      return this.item.status === ArtboardUploadStatus.Error;
    },

    canceled(): boolean {
      return this.item.status === ArtboardUploadStatus.Canceled;
    },

    completed(): boolean {
      return this.success || this.error;
    },

    processing(): boolean {
      return this.inProgress && this.item.progress === 100;
    },

    indeterminate(): boolean {
      return this.inProgress && (this.item.progress === 0 || this.item.progress === 100);
    },

    cancellable(): boolean {
      return !this.canceled && this.inProgress && this.item.progress < 100 && this.item.cancel !== undefined;
    },

    errorMessage(): string | undefined {
      return this.item.errorMessage;
    },

    linkToArtboardInspect(): Location | undefined {
      if (!this.item.serverArtboardId) return undefined;

      return {
        name: ROUTES["expo.inspect"].name,
        params: {
          shortcut: this.item.shortcut,
          screen: this.item.serverArtboardId,
        },
      };
    },

    component(): string {
      return this.success && this.linkToArtboardInspect ? "AxButton" : "div";
    },
  },

  methods: {
    removeUpload() {
      this.removed = true;
    },

    async cancelUpload() {
      if (this.item.cancel) {
        await this.item.cancel();
      }
    },
  },
});
