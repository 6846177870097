
import Vue from "vue";
import { PropValidator } from "vue/types/options";

import { isWorkspaceNode, FilesystemNodeWorkspace } from "@/common/fs";
import { WorkspaceArchive } from "@/store/fs/actionTypes";

import AxButton from "@/components/AxButton.vue";
import AxForm from "@/components/AxForm.vue";
import AxFormGroup from "@/components/AxFormGroup.vue";
import { Submit } from "@/components/types/AxForm";

const itemProp: PropValidator<FilesystemNodeWorkspace> = {
  type: Object,
  required: true,
  validator(item) {
    return isWorkspaceNode(item);
  },
};

export default Vue.extend({
  components: {
    AxForm,
    AxFormGroup,
    AxButton,
  },

  props: {
    workspace: itemProp,
  },

  computed: {},

  methods: {
    async submit(_: Submit<any>) {
      await this.$store.dispatch(new WorkspaceArchive(this.workspace.id, !this.workspace.isArchived));
      this.$emit("submit");
    },
  },
});
