import { GetterTree } from "vuex";

import { NotificationJs, NotificationMuteItemJs } from "@shared/models";

import { NotificationStore } from "@/store/notifications/state";
import { AxShare } from "@/store/state";

const getterTree: GetterTree<NotificationStore, AxShare> = {
  notifications(state): NotificationJs[] {
    return Object.values(state.notifications);
  },

  guestSubscriptions(state): NotificationJs[] {
    return Object.values(state.guestSubscriptions);
  },

  notificationMuteItems(state): NotificationMuteItemJs[] {
    return Object.values(state.notificationMuteItems);
  },
};

export default getterTree;
