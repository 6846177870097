function isObject(value: any): value is { [key: string]: any } {
  return value === Object(value);
}

function isArray(value: any): value is any[] {
  return Array.isArray(value);
}

function isDate(value: any): value is Date {
  return value instanceof Date;
}

function isBlob(value: any) {
  return value && typeof value.size === "number" && typeof value.type === "string" && typeof value.slice === "function";
}

function isFile(value: any): value is File {
  return (
    isBlob(value) &&
    (typeof value.lastModifiedDate === "object" || typeof value.lastModified === "number") &&
    typeof value.name === "string"
  );
}

interface Options {
  indices?: boolean;
}

export function objectToFormData(
  object: any,
  options: Options = { indices: true },
  formData: FormData = new FormData(),
  keyPrefix?: string
) {
  if (object === undefined || object === null) {
    return formData;
  }

  if (isArray(object)) {
    object.forEach((value, index) => {
      const currentIndex = options.indices ? index : "";
      const key = `${keyPrefix}[${currentIndex}]`;
      objectToFormData(value, options, formData, key);
    });
  } else if (isDate(object)) {
    if (!keyPrefix) return formData;
    formData.append(keyPrefix, object.toISOString());
  } else if (isObject(object) && !isFile(object) && !isBlob(object)) {
    Object.keys(object).forEach(prop => {
      const value = object[prop];

      if (isArray(value)) {
        while (prop.length > 2 && prop.lastIndexOf("[]") === prop.length - 2) {
          // eslint-disable-next-line no-param-reassign
          prop = prop.substring(0, prop.length - 2);
        }
      }

      const key = keyPrefix ? `${keyPrefix}[${prop}]` : prop;

      objectToFormData(value, options, formData, key);
    });
  } else {
    if (!keyPrefix) return formData;
    formData.append(keyPrefix, object);
  }

  return formData;
}
