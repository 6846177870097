
import Vue from "vue";
import { Location } from "vue-router";

import { FilesystemNodeType } from "@/common/fs";
import { ROUTES } from "@/router";
import { fsRouterLocation } from "@/router/fs";

import AxHeaderSearchResult from "@/components/AxHeaderSearchResult.vue";

export default Vue.extend({
  components: {
    AxHeaderSearchResult,
  },

  props: {
    result: {
      type: Object,
      default: null,
    },
    query: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isViewOnly(): boolean {
      if (!this.isProject) {
        return false;
      }
      return !!this.result.item.viewOnly;
    },

    isProject(): boolean {
      return this.result.item.type === FilesystemNodeType.Shortcut;
    },

    route(): Location {
      const { result } = this;
      switch (result.item.type) {
        case FilesystemNodeType.Shortcut:
          return this.routeOverview;
        case FilesystemNodeType.Workspace:
        case FilesystemNodeType.Folder:
          return this.fsLocation;
        default:
          return this.fsLocation;
      }
    },

    routeOverview(): Location {
      const { result } = this;
      return {
        name: ROUTES["project.overview"].name,
        params: { shortcut: result.item.id },
      };
    },

    fsLocation(): Location {
      const { result } = this;
      if (result.item.type === FilesystemNodeType.Folder && result.item.workspaceId) {
        return fsRouterLocation(result.item, { workspaceId: result.item.workspaceId });
      }
      return fsRouterLocation(result.item);
    },
  },
});
