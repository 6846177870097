import Vue from "vue";

import { resolveApiBaseUrl } from "@/common/axshare/api";
import AccountService from "@/services/account.service";
import { reconfigure, setAuthTokenAccessor } from "@/services/server";
import { AsyncActionState } from "@/store/async-action";
import { MutationPayloadMap, MutationTypes } from "@/store/mutationTypes";
import { AxShare, ApiCall, initialState } from "@/store/state";

import { MutationTree } from "./typed";

const mutations: MutationTree<AxShare, MutationPayloadMap> = {
  [MutationTypes.Reset](state) {
    const current = state as Record<string, any>;
    const initial = initialState() as Record<string, any>;
    Object.keys(initial).forEach(key => {
      Vue.set(current, key, initial[key]);
    });
    reconfigure(resolveApiBaseUrl(state.axShareConfig));
  },

  [MutationTypes.QueryAction](state, { action, state: actionState }) {
    Vue.set(state.actions, action, actionState);
  },

  [MutationTypes.ConfigSet](state, { config }) {
    state.axShareConfig = config;
    reconfigure(resolveApiBaseUrl(state.axShareConfig));
  },

  [MutationTypes.SetupAccountService](state, {
    accountServiceHost, axShareHost, authToken, useGet,
  }) {
    const accountService = new AccountService(accountServiceHost, axShareHost, authToken, useGet);
    state.accountService = () => accountService;
    setAuthTokenAccessor(() => accountService.AuthToken);
  },

  [MutationTypes.InitializationStatus](state, { status, error }) {
    state.initializationStatus = status;
    state.initializationError = error;
  },

  [MutationTypes.SetNavigationError](state, { error }) {
    state.navigationError = error;
  },

  [MutationTypes.ApiCallAdd](state, { apiCallId, action, onFailure }) {
    const apiCall: ApiCall = {
      action,
      onFailure,
      status: AsyncActionState.NotStarted,
      retry: 0,
    };
    Vue.set(state.apiCalls, apiCallId, apiCall);
  },

  [MutationTypes.ApiCallRemove](state, { apiCallId }) {
    Vue.delete(state.apiCalls, apiCallId);
  },

  [MutationTypes.ApiCallClear](state) {
    Object.keys(state.apiCalls).forEach(key => {
      Vue.delete(state.apiCalls, key);
    });
  },

  [MutationTypes.ApiCallSetStatus](state, { apiCallId, status }) {
    const apiCall = state.apiCalls[apiCallId];
    if (apiCall) {
      apiCall.status = status;
    }
  },

  [MutationTypes.ApiCallRetry](state, { apiCallId }) {
    const apiCall = state.apiCalls[apiCallId];
    if (apiCall) {
      apiCall.retry++;
    }
  },

  [MutationTypes.ApiVersionSet](state, { apiVersionInfo }) {
    state.apiVersionInfo = apiVersionInfo;
  },
};

export default mutations;
