
import Vue, { VNode } from "vue";

import { ProjectLoad } from "@/store/fs/actionTypes";

export default Vue.extend({
  props: {
    shortcut: {
      type: String,
      required: true,
    },

    interval: {
      type: Number,
      required: false,
      default: 10000,
    },
  },

  data() {
    return {
      pollingInterval: null as number | null,
      isDestroyed: false,
    };
  },

  async created() {
    await this.$store.dispatch(new ProjectLoad(this.shortcut));
    if (!this.isDestroyed) {
      this.pollingInterval = window.setInterval(
        () => this.$store.dispatch(new ProjectLoad(this.shortcut)),
        this.interval,
      );
    }
  },

  beforeDestroy() {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
    }
    this.isDestroyed = true;
  },

  render(h): VNode {
    return h("span", this.$scopedSlots.default && this.$scopedSlots.default({}));
  },
});
