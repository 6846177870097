
import Vue from "vue";

import AxIcon from "@/components/AxIcon.vue";

export default Vue.extend({
  components: {
    AxIcon,
  },
});
