export const createCustomEvent = (eventName: string, eventDetail: any) => {
  let event;
  if (typeof CustomEvent === "function") {
    event = new CustomEvent(eventName, { detail: eventDetail });
  } else {
    // Deprecated fallback for IE
    event = document.createEvent("CustomEvent");
    event.initCustomEvent(eventName, true, true, eventDetail);
  }

  return event;
};
