
import Vue from "vue";

import AxButton from "@/components/AxButton.vue";
import AxIcon from "@/components/AxIcon.vue";
import AxInput from "@/components/AxInput.vue";

export default Vue.extend({
  components: {
    AxInput,
    AxIcon,
    AxButton,
  },

  props: {
    value: {
      type: String,
      default: "",
    },

    inputClass: {
      type: String,
      default: "",
    },

    collapsible: {
      type: Boolean,
      default: false,
    },

    keepPlaceholder: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onInput(e?: any): void {
      this.$emit("input", e);
    },

    onBlur(e?: any): void {
      this.$emit("blur", e);
      if (this.collapsible) {
        this.$emit("open", false);
      }
    },

    onFocus(e?: any): void {
      this.$emit("focus", e);
      if (this.collapsible) {
        this.$emit("open", true);
      }
    },
  },
});
