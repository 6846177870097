
import Vue from "vue";

import { FilesystemNodeTypes, ProjectType, ProjectTypes } from "@/common/fs";
import CreateProject from "@/layouts/CreateProject.vue";
import { fsRouterLocation } from "@/router/fs";

import AxCreateProject from "@/components/AxCreateProject.vue";
import AxDialog from "@/components/AxDialog.vue";
import AxIcon from "@/components/AxIcon.vue";
import AxPanel from "@/components/AxPanel.vue";

interface CreateProjectOptions {
  compact: boolean,
  projectType: ProjectTypes
}

export default Vue.extend({
  components: {
    AxDialog,
    AxCreateProject,
    CreateProject,
    AxPanel,
    AxIcon,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      compact: true,
      ProjectType,
      projectType: "",
      show: true,
    };
  },

  computed: {
    node(): FilesystemNodeTypes {
      return this.$store.getters.getNodeByFolderId(this.id);
    },
  },

  methods: {
    modeChanged({ compact, projectType } : CreateProjectOptions) {
      this.projectType = projectType;
      this.compact = compact;
    },

    submit() {
      this.cancel(true);
    },

    cancel(refresh: boolean = false) {
      this.$router.push(fsRouterLocation(this.id, { refresh: { contents: refresh } }));
    },
  },
});
