
import Vue, { VueConstructor } from "vue";
import { PropValidator } from "vue/types/options";

import {
  FilesystemNodeType, FilesystemNodeTypes,
} from "@/common/fs";

import AxFilesystemViewerActions from "./AxFilesystemViewerActions.vue";
import AxFilesystemViewerActionsFolder from "./AxFilesystemViewerActionsFolder.vue";
import AxFilesystemViewerActionsWorkspace from "./AxFilesystemViewerActionsWorkspace.vue";

export default Vue.extend({
  components: {
    AxFilesystemViewerActions,
    AxFilesystemViewerActionsFolder,
    AxFilesystemViewerActionsWorkspace,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    /* eslint-disable vue/require-default-prop */
    node: {
      type: Object,
      default: null,
    } as PropValidator<FilesystemNodeTypes>,
  },

  data() {
    return {
      FilesystemNodeType,
    };
  },

  computed: {
    actionsComponent(): VueConstructor<Vue> | null {
      const node = this.node;
      let actionsComponent = AxFilesystemViewerActions;
      if (node.type === FilesystemNodeType.Folder) {
        actionsComponent = AxFilesystemViewerActionsFolder;
      }
      if (node.type === FilesystemNodeType.Workspace) {
        actionsComponent = AxFilesystemViewerActionsWorkspace;
      }
      return actionsComponent;
    },
  },
});
