export function isError(err: unknown): err is Error {
  return err instanceof Error;
}

export function asError(err: unknown): Error {
  if (isError(err)) {
    return err;
  }

  if (typeof err === "string") {
    return { message: err, name: typeof err };
  }

  if (err !== undefined) {
    return { message: (err as any).toString(), name: typeof err };
  }

  return new Error();
}
