
import Vue from "vue";
import VueRouter from "vue-router";

import { AxShareConfigModel, UserMentionedArgs } from "@shared/models";

import { prototypeUrls } from "@/common/axshare";
import { MentionType, FilesystemNodeWorkspace, FilesystemNodeShortcut } from "@/common/fs";
import { AxMentionedNotificationOptions } from "@/common/notifications/AxNotificationOptions";
import { hasDesktopNotificationsPermission } from "@/common/notifications/permissions";
import { ROUTES } from "@/router";
import { getUserGuestSubscriptions } from "@/services/notification.service";
import { SignalRHubListeners } from "@/services/push.notifications.service";
import { Fetch } from "@/store/fs/actionTypes";
import { ShowMentionedDesktopNotification } from "@/store/notifications/actionTypes";

import AxPushNotificationsBase from "@/components/AxPushNotifications/AxPushNotificationsBase";

export default Vue.extend({
  components: {
    AxPushNotificationsBase,
  },
  computed: {
    listeners(): SignalRHubListeners {
      return {
        userMentioned: async (payload: UserMentionedArgs) => {
          const shortcut = payload.Shortcut.toLowerCase();

          if (payload.MentionType === MentionType.PublishNote) {
            // Refresh the whole workspace to be able to display actual mentions state immediately (including shortcut workspace and parent folders)
            const workspace: FilesystemNodeWorkspace | undefined = this.$store.getters.findWorkspace(shortcut);
            if (workspace) await this.$store.dispatch(new Fetch(workspace));
          }

          if (await hasDesktopNotificationsPermission()) {
            const notificationOptions: AxMentionedNotificationOptions = {
              authorName: payload.AuthorName,
              authorEmail: payload.AuthorEmail,
              body: payload.Message,
              tag: payload.Id,
              authorProfileImage: payload.AuthorProfileImage,
              onclick: async (notification: Notification) => {
                // google chrome notifications are not closed by click on MacOS
                // so it should be closed manually
                if (notification) {
                  notification.close();
                }

                try {
                  if (payload.MentionType === MentionType.Issue || payload.MentionType === MentionType.IssueComment) {
                    // open the public link instead of the cloud preview page for shortcuts with guest subscription
                    const subscriptions = await getUserGuestSubscriptions();
                    if (subscriptions.Items.some(x => x.ShortcutKey.toLowerCase() === shortcut.toLowerCase())) {
                      const axShareConfig: AxShareConfigModel | undefined = this.$store.state.axShareConfig;
                      if (axShareConfig) {
                        const urls = prototypeUrls(axShareConfig, { id: shortcut } as FilesystemNodeShortcut);
                        window.location.href = `${urls.full}#issueCode=${payload.IssueCode}&type=issue`;
                      }
                      return;
                    }
                    await this.$router.push({
                      name: ROUTES["expo.preview"].name,
                      params: { shortcut, screen: payload.PageId },
                      query: { comment: payload.IssueCode },
                    });
                  } else if (payload.MentionType === MentionType.PublishNote) {
                    await this.$router.push({
                      name: ROUTES["project.history"].name,
                      params: { shortcut },
                    });
                  }
                } catch (error) {
                  if (!VueRouter.isNavigationFailure(error)) {
                    throw error;
                  }
                }
              },
            };

            this.$store.dispatch(new ShowMentionedDesktopNotification(notificationOptions));
          }
        },
      };
    },
  },
});
