import {
  FilesystemJs, FolderRowJs, InvitationJs, ShortcutJs,
} from "@shared/models";

import { jsDateFromAspDateString as toJsDate } from "@/common/lib";
import { BreadcrumbJson } from "@/services/models";

import {
  FilesystemNodeFolder,
  FilesystemNodeInvitation,
  FilesystemNodeShortcut,
  FilesystemNodeType,
  FilesystemNodeTypes,
  FilesystemNodeWorkspace,
  ShortcutStateName,
} from "./types";

function getSharedUsers(fs: FilesystemJs) {
  return fs.SharedUsers2 || [];
}

function getInvitedUsers(fs: FilesystemJs) {
  return fs.InvitedUsers2 || [];
}

export function toWorkspaceNode(item: FilesystemJs, parent: FilesystemNodeTypes): FilesystemNodeWorkspace {
  const sharedWith = getSharedUsers(item);
  const invited = getInvitedUsers(item);
  return {
    ...common(item),
    id: item.FilesystemId,
    name: item.DisplayName,
    type: FilesystemNodeType.Workspace,
    parent,
    isDefault: item.IsDefaultFs,
    rootFolderId: "",
    listingNode: item,
    viewOnly: item.ViewerFs,
    isOwner: !item.Owner,
    sharedWith,
    invited,
    users: [...sharedWith, ...invited],
    owner: item.OwnerSso,
    ownerEmail: item.Owner,
    userOnly: item.UserViewOnly,
    httpsOnly: item.HttpsOnly,
    isArchived: item.IsArchived,
    isFavorite: item.IsFavorite,
  };
}

export function toInvitationNode(item: InvitationJs, parent: FilesystemNodeTypes): FilesystemNodeInvitation {
  return {
    ...common(item),
    parent,
    id: item.FilesystemId,
    name: item.FilesystemName,
    type: FilesystemNodeType.Invitation,
    owner: item.Owner2,
    sender: item.Sender,
    message: item.Message,
    listingNode: item,
  };
}

export function toFolderNode(item: FolderRowJs, parent: FilesystemNodeTypes): FilesystemNodeFolder {
  return {
    ...common(item),
    id: item.FolderId,
    name: item.FolderName,
    type: FilesystemNodeType.Folder,
    viewOnly: false,
    parent,
    listingNode: item,
    updated: item.ModifiedOn && item.ModifiedOn.Value ? toJsDate(item.ModifiedOn.Value) : toJsDate(item.CreatedOn),
  };
}

export function toShortcutNode(item: ShortcutJs, parent: FilesystemNodeTypes): FilesystemNodeShortcut {
  const shortcut = item.Shortcut.toLowerCase();
  return {
    ...common(item),
    id: shortcut,
    name: item.Name,
    type: FilesystemNodeType.Shortcut,
    parent,
    prototypeUrl: item.PrototypeUrl,
    shortcut,
    shortcutType: item.ShortcutType,
    shortcutState: item.ShortcutState as ShortcutStateName,
    isExpoProject: item.IsExpoProject,
    listingNode: item,
    viewOnly: item.InFsViewerFolder,
    feedbackEnabled: item.FeedbackEnabled,
    isUserOnly: item.IsUserOnly,
    hasPassword: item.HasPassword,
    updated: item.ModifiedOn ? toJsDate(item.ModifiedOn) : toJsDate(item.CreatedOn),
    generated: item.TargetPrototypeGenerationTimestamp ? toJsDate(item.TargetPrototypeGenerationTimestamp) : undefined,
    isUserOnlyWorkspace: item.IsUserOnlyWorkspace,
    notificationState: item.NotificationState,
  };
}

export function common(item: { CreatedOn: string }) {
  return {
    created: toJsDate(item.CreatedOn),
    listingNode: item,
    totalItems: 10,
    sharedWith: [],
  };
}

export function breadcrumbToWorkspaceNode(
  breadcrumb: BreadcrumbJson,
  existing: FilesystemNodeTypes | undefined,
): FilesystemNodeWorkspace {
  const mapped = {
    id: breadcrumb.id,
    name: breadcrumb.name,
    rootFolderId: breadcrumb.fsRootFolderId,
    isDefault: breadcrumb.isDefaultFs,
  };
  if (existing && existing.type === FilesystemNodeType.Workspace) {
    return {
      ...existing,
      ...mapped,
    };
  }
  return {
    ...fromBreadcrumb(breadcrumb),
    ...mapped,
    type: FilesystemNodeType.Workspace,
    viewOnly: false,
    userOnly: false,
    httpsOnly: false,
    isOwner: false,
    sharedWith: [],
    invited: [],
    users: [],
    owner: undefined,
    ownerEmail: "",
    isArchived: false,
    isFavorite: false,
  };
}

export function breadcrumbToFolderNode(
  breadcrumb: BreadcrumbJson,
  existing: FilesystemNodeTypes | undefined,
): FilesystemNodeFolder {
  const mapped = {
    id: breadcrumb.id,
    name: breadcrumb.name,
  };

  if (existing && existing.type === FilesystemNodeType.Folder) {
    return {
      ...existing,
      ...mapped,
    };
  }

  return {
    ...fromBreadcrumb(breadcrumb),
    ...mapped,
    type: FilesystemNodeType.Folder,
    viewOnly: false,
  };
}

function fromBreadcrumb(breadcrumb: BreadcrumbJson) {
  return {
    id: breadcrumb.id,
    name: breadcrumb.name,
    listingNode: undefined,
    parent: undefined,
    totalItems: 0,
    created: new Date(),
  };
}
