
import Vue from "vue";

import { isElectron } from "@/common/environment";
import { FilesystemNodeRecents, FilesystemNodeType, FilesystemNodeTypes } from "@/common/fs";
import { ROUTES } from "@/router";

import AxNavigationBreadcrumbs from "./AxNavigationBreadcrumbs.vue";

export default Vue.extend({
  components: {
    AxNavigationBreadcrumbs,
  },

  computed: {
    breadcrumbs(): FilesystemNodeTypes [] {
      const breadcrumbs: FilesystemNodeTypes [] = [...this.$store.getters.filesystemPath];
      if (this.isRecents) {
        const recentsNode = {
          id: "recent",
          name: "Recents",
          type: FilesystemNodeType.Recents,
        } as FilesystemNodeRecents;
        breadcrumbs.push(recentsNode);
      }
      return breadcrumbs;
    },

    visible(): boolean {
      return this.breadcrumbs.length > 1;
    },

    isElectron(): boolean {
      return isElectron;
    },

    isRecents(): boolean {
      return this.$route.name === ROUTES["fs.recents"].name;
    },
  },
});
