
import Vue from "vue";

import { DESKTOP_APP_DOWNLOAD_URL } from "@/common/axshare/links";
import { wellKnownRoutes } from "@/common/axshare/routing";
import { isElectron } from "@/common/environment";
import WelcomeLayout from "@/layouts/WelcomeLayout.vue";
import { AxShare } from "@/store/state";

import AxButton from "@/components/AxButton.vue";
import AxCloudInfoDescription from "@/components/AxCloudInfoDescription.vue";

export default Vue.extend({
  components: {
    AxButton,
    WelcomeLayout,
    AxCloudInfoDescription,
  },

  data() {
    return {
      desktopUrl: DESKTOP_APP_DOWNLOAD_URL,
      homeRoute: !isElectron
        ? {
          name: wellKnownRoutes.home.name,
        }
        : undefined,
    };
  },

  computed: {
    forBusiness(): boolean {
      const { axShareConfig } = this.$store.state as AxShare;
      return axShareConfig !== null && axShareConfig.IsSubInstance && !axShareConfig.AxShareOnPrem;
    },
  },
});
