import actions from "@/store/expo/actions";
import getters from "@/store/expo/getters";
import mutations from "@/store/expo/mutations";
import state from "@/store/expo/state";

export default {
  state,
  actions,
  mutations,
  getters,
};
