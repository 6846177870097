
import Vue from "vue";

import { CommentsChangedArgs } from "@shared/models";

import { FilesystemNodeWorkspace } from "@/common/fs";
import { equalsIgnoringCase } from "@/common/lib";
import { Fetch } from "@/store/fs/actionTypes";

import AxPushNotificationsWorkspace from "@/components/AxPushNotifications/AxPushNotificationsWorkspace.vue";

export default Vue.extend({
  components: {
    AxPushNotificationsWorkspace,
  },

  computed: {
    workspaces(): FilesystemNodeWorkspace[] {
      return Object.values(this.$store.getters.workspaces);
    },
  },

  methods: {
    async workspaceCommentsChanged(payload: CommentsChangedArgs, workspace: FilesystemNodeWorkspace) {
      if (equalsIgnoringCase(payload.FilesystemId, workspace.id)) {
        await this.$store.dispatch(new Fetch(workspace));
      }
    },
  },
});
