
import debounce from "lodash.debounce";
import Vue from "vue";

import { FilesystemNodeType } from "@/common/fs";
import { Search } from "@/common/search/Search";
import { SearchResult } from "@/common/search/SearchResult";
import { getUserSearchData } from "@/services/fs.service";
import { ApiCall, ApiCallOptions } from "@/store/actionTypes";
import { SetSearchData } from "@/store/fs/mutationTypes";
import { AxSearchData } from "@/store/fs/state";
import { AxShare } from "@/store/state";

import AxFilesystemSearch from "@/components/AxFilesystemSearch.vue";
import AxInputSearch from "@/components/AxInputSearch.vue";
import AxMenu from "@/components/AxMenu.vue";
import { FilesystemSearchItem } from "@/components/types/AxSearch";

const debounceInput = debounce(
  (searchQuery: string, fn: (str: string) => void) => {
    fn(searchQuery);
  },
  500,
  { maxWait: 1000 },
);

type ItemField = keyof FilesystemSearchItem;
type SearchFunction = (item: FilesystemSearchItem) => boolean;

export default Vue.extend({
  components: {
    AxInputSearch,
    AxFilesystemSearch,
    AxMenu,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const searchList: FilesystemSearchItem[] = [];
    const searchFields = new Map<ItemField, SearchFunction>([
      ["name", () => true],
      ["id", (item: FilesystemSearchItem) => item.type === FilesystemNodeType.Shortcut],
    ]);
    const searchEngine: Search<FilesystemSearchItem> = new Search(searchList, searchFields);
    return {
      loading: false,
      searchSessionStarted: false,
      searchListOutdated: false,
      searchList,
      searchEngine,
      searchFields,
      opened: false,
    };
  },

  computed: {
    dataReloadRequired(): boolean {
      // reload search data required if list outdated and search query is not empty
      return !this.loading && this.searchListOutdated && this.searchText.length > 0;
    },

    searchResults(): Array<SearchResult<FilesystemSearchItem>> {
      const { searchText } = this;
      if (searchText && searchText.length > 0) {
        return this.searchEngine.doSearch(searchText);
      }
      return [];
    },

    searchText(): string {
      const { fs } = this.$store.state as AxShare;
      return fs.searchData.query;
    },

    searchData(): AxSearchData {
      const { fs } = this.$store.state as AxShare;
      return fs.searchData;
    },

    isSearching(): boolean {
      const { query } = this.searchData;
      return query.length > 0;
    },
  },

  watch: {
    dataReloadRequired(value) {
      if (value) {
        this.getSearchTree();
      }
    },

    searchResults(value) {
      this.$store.commit(new SetSearchData({ results: value }));
    },

    "$route.query": function (newQuery: Record<string, string>, oldQuery: Record<string, string>) {
      if (!newQuery.searchQuery && oldQuery.searchQuery) {
        this.$store.commit(new SetSearchData({ query: "" }));
        // close search session if route changed
        this.toggleSearchSessionStarted(false);
      }
    },
  },

  created() {
    this.$store.commit(new SetSearchData({ query: "" }));
    this.setSearchInRoute("");
  },

  destroyed() {
    this.$store.commit(new SetSearchData({ query: "" }));
    this.setSearchInRoute("");
  },

  methods: {
    searchQueryChanged(query: string) {
      debounceInput(query, debouncedQuery => {
        this.setSearchInRoute(debouncedQuery);
        this.$store.commit(new SetSearchData({ query: debouncedQuery }));
      });
    },

    setSearchInRoute(search: string) {
      let queryParams;
      let shouldNavigate = false;

      const { searchQuery: currentSearch } = this.$route.query;
      if (search === currentSearch || (!search && !currentSearch)) {
        // same or empty search query, do nothing
        return;
      }

      if (search) {
        shouldNavigate = true;
        queryParams = { ...this.$route.query, searchQuery: search };
      } else {
        // remove 'searchQuery' parameter
        const { searchQuery, ...rest } = this.$route.query;
        if (searchQuery) {
          shouldNavigate = true;
        }
        queryParams = rest;
      }
      if (shouldNavigate) {
        this.$router.replace({ query: queryParams });
      }
    },

    toggleFocus(value: boolean) {
      if (value) {
        // input focus
        if (!this.searchSessionStarted) {
          // start search session
          this.toggleSearchSessionStarted(true);
        }
      } else if (!this.searchText) {
        // input blur
        // if query empty then to end search session
        this.toggleSearchSessionStarted(false);
      }
    },

    toggleSearchSessionStarted(value: boolean) {
      // check if search session has just started
      if (value && value !== this.searchSessionStarted) {
        this.searchListOutdated = true;
      }
      this.searchSessionStarted = value;
    },

    async getSearchTree() {
      const getSearchTreeImpl = async () => {
        this.loading = true;
        this.$store.commit(new SetSearchData({ isLoading: true }));

        try {
          this.searchList = await getUserSearchData();
          this.searchEngine = new Search(this.searchList, this.searchFields);
        } finally {
          this.searchListOutdated = false;
          this.$store.commit(new SetSearchData({ isLoading: false }));
          this.loading = false;
        }
      };
      const options: ApiCallOptions = {
        action: getSearchTreeImpl,
      };
      this.$store.dispatch(new ApiCall(options));
    },
  },
});
