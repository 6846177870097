
import Vue, { VueConstructor } from "vue";

import { appStatus } from "@/mixins/AppStatus";
import { Initialize } from "@/store/actionTypes";
import { AxShare } from "@/store/state";

import AxAppFailure from "@/components/AxAppFailure.vue";
import AxAppSplashScreen from "@/components/AxAppSplashScreen.vue";
import AxDelayed from "@/components/AxDelayed.vue";
import AxOnline from "@/components/AxOnline.vue";

type Mixins = InstanceType<typeof appStatus>;

export default (Vue as VueConstructor<Vue & Mixins>).extend({
  components: {
    AxAppFailure,
    AxAppSplashScreen,
    AxDelayed,
    AxOnline,
  },

  mixins: [appStatus],

  computed: {
    componentMatched(): boolean {
      return this.$route.matched.length > 0;
    },

    initializationError(): Error | undefined | unknown {
      const { initializationError } = this.$store.state as AxShare;
      return initializationError;
    },

    navigationError(): Error | undefined | unknown {
      const { navigationError } = this.$store.state as AxShare;
      return navigationError;
    },
  },

  methods: {
    async onlineStatusChanged(isOnline: boolean) {
      if (isOnline && (!this.appStatusInitialized || this.appStatusFailure)) {
        // try to recover from uninitialized app state, if it was due-to networking issue
        await this.$store.dispatch(new Initialize(true));
      }
    },
  },
});
