import { Payload } from "vuex";
import { DesktopParams } from "@/desktop/types";
import { PayloadMap } from "@/store/typed";

export enum MutationTypes {
  SetDesktopParams = "[Desktop] Set Desktop Params",
}

export class SetDesktopParams implements Payload {
  public readonly type = MutationTypes.SetDesktopParams;
  constructor(public desktopParams: Partial<DesktopParams> | undefined) {}
}

export interface MutationPayloadMap extends PayloadMap<MutationTypes> {
  [MutationTypes.SetDesktopParams]: SetDesktopParams;
}
