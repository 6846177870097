import axios, { AxiosRequestConfig } from "axios";
import qs from "qs";

import { resolveApiBaseUrl } from "@/common/axshare/api";
import { isIE } from "@/common/lib";
import { isFormData } from "@/common/lib/guards";
import {
  addAuthorizationHeader, timestampInterceptor, transformRequestDefaults, withNoCacheHeaders,
} from "@/services/utils/axios";

let getAuthToken: () => string | undefined = () => "";
let getVToken: () => string | undefined = () => "";
let cloudMode = false;

const createServer = (
  baseUrl: string,
) => {
  const server = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: "repeat" }),
    },
    transformRequest: [
      ...transformRequestDefaults,
      (data, headers) => {
        addAuthorizationHeader(headers, getAuthToken());
        addTokens(data, getAuthToken(), getVToken());
        return data;
      },
    ],
    ...(isIE ? withNoCacheHeaders : undefined),
  });

  server.interceptors.request.use(includeTokens);

  return server;
};

function addTokens(data: any, authToken: string | undefined, vToken: string | undefined) {
  if (isFormData(data)) {
    if (authToken) data.append("utoken", authToken);
    if (vToken) data.append("token", vToken);
  }
}

function includeTokens(config_: AxiosRequestConfig) {
  if (config_.method?.toLowerCase() === "get") {
    config_.params = config_.params || {};
    const vtoken = getVToken();
    const inApp = cloudMode;
    if (vtoken && !inApp) config_.params.token = vtoken;
  }

  return config_;
}

export const server = createServer(resolveApiBaseUrl());

if (isIE) {
  // add cache-breaking timestamp for each and every request made in IE
  server.interceptors.request.use(timestampInterceptor());
}

export function setVTokenAccessor(tokenAccessor: () => string | undefined) {
  getVToken = tokenAccessor;
}

export function setAuthTokenAccessor(tokenAccessor: () => string | undefined) {
  getAuthToken = tokenAccessor;
}

export function setCloudMode(isCloudMode: boolean) {
  cloudMode = isCloudMode;
}

export function reconfigure(url: string) {
  server.defaults.baseURL = url;
}

export default {
  server,
};
