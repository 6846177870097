import actions from "@/store/admin/actions";
import getters from "@/store/admin/getters";
import mutations from "@/store/admin/mutations";
import state from "@/store/admin/state";

export default {
  state,
  actions,
  mutations,
  getters,
};
