
import Vue from "vue";

import { FolderCreateModelValidator } from "@/common/fs";
import { FolderCreateModel } from "@/services/models/filesystem";
import { FolderCreate } from "@/store/fs/actionTypes";

import AxButton from "@/components/AxButton.vue";
import AxForm from "@/components/AxForm.vue";
import AxFormGroup from "@/components/AxFormGroup.vue";
import AxInput from "@/components/AxInput.vue";
import { Submit } from "@/components/types/AxForm";

export default Vue.extend({
  components: {
    AxButton,
    AxForm,
    AxFormGroup,
    AxInput,
  },

  props: {
    parentFolder: {
      type: String,
      required: true,
    },
  },

  data() {
    const model: FolderCreateModel = {
      Name: "",
      FolderId: this.parentFolder,
      currentFolderId: this.parentFolder,
    };

    return {
      model,
      validator: FolderCreateModelValidator,
    };
  },

  methods: {
    async submit({ values }: Submit<FolderCreateModel>) {
      await this.$store.dispatch(new FolderCreate(values));
      this.$emit("submit");
    },
  },
});
