import { NotificationJs } from "@shared/models";

export enum NotificationMethod {
  Email = 0,
  Slack = 1,
  MsTeams = 3,
}

export enum NotificationSubRate {
  Instant,
  Daily,
}

export enum EntryType {
  All,
  Filesystem,
  Shortcut,
  Folder,
}

// Hipchat no longer supported, was 2
export enum NotificationTypes {
  Email = 0,
  Slack = 1,
  MsTeams = 3,
}

export interface AddNotificationModel {
  subscriptionRate: string;
  targetId?: string;
  targetType: string;
  isSlack: boolean;
  msTeamsUrl?: string;
  isMsTeams?: boolean;
}

export interface EditNotificationModel {
  NotificationId: string;
  newTargetId?: string;
  newTargetType: string;
  newSubRate: string;
  newMsTeamsUrl?: string;
}

export interface NotificationJsActions {
  notification: NotificationJs;
  activeItem?: any;
}

export enum NotificationErrorType {
  InvalidData,
  InvalidDataTeams,
  ExpiredToken,
  ExpiredTokenTeams,
  SlackIssue,
  SlackAlreadyExist,
  Unknown,
}

export enum ProjectCommentsTypes {
  All,
  Some,
  None,
}

export interface NotificationSettingModel {
  notificationType?: NotificationTypes;
  projectCommentsType?: ProjectCommentsTypes;
}

export enum NotificationMuteItemTypes {
  Workspace,
  Project,
}

export interface NotificationMuteItemModel {
  notificationType?: NotificationTypes;
  targetId?: string;
  targetType?: NotificationMuteItemTypes;
}
