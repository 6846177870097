export interface AxShareSettings {
  showAllWorkspaces: boolean;
  savedWorkspaceId?: string;
  showArchived: boolean;
}

const state = (): AxShareSettings => ({
  showAllWorkspaces: false,
  savedWorkspaceId: undefined,
  showArchived: false,
});

export default state;
