import { ExtendedSsoResponse } from "@shared/models";
import { UserProfile } from "@/services/models/account";

export interface AxShareUser {
  userInfo?: ExtendedSsoResponse;
  profile?: UserProfile;
}

const state = (): AxShareUser => ({
  userInfo: undefined,
  profile: undefined,
});

export default state;
