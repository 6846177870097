import Vue from "vue";

import { MutationTree } from "@/store/typed";
import { batchIsCompleted } from "@/store/uploads/getters";
import { MutationPayloadMap, MutationTypes } from "@/store/uploads/mutationTypes";
import { AxShareUploads, ArtboardUploadBatch, ArtboardUploadInfo, ArtboardUploadStatus } from "@/store/uploads/state";

const mutations: MutationTree<AxShareUploads, MutationPayloadMap> = {
  [MutationTypes.NewUpload](state, { uploadData: { source, batchId, shortcut, projectName } }) {
    // remove all existing batches
    state.batches = {};

    const batch: ArtboardUploadBatch = {
      source,
      id: batchId,
      shortcut,
      projectName,
      contents: {},
      created: new Date(),
      dismissed: false,
    };
    Vue.set(state.batches, batchId, batch);
  },

  [MutationTypes.DismissUpload](state, { batchId }) {
    const batch = state.batches[batchId];
    if (!batch) return;
    batch.dismissed = true;
    cleanupBatch(state, batch);
  },

  [MutationTypes.AddToBatch](state, { uploadData: { batchId, artboardId, artboardName, shortcut, cancel } }) {
    const batch = state.batches[batchId];
    if (batch) {
      const artboard: ArtboardUploadInfo = {
        id: artboardId,
        name: artboardName,
        shortcut,
        cancel,
        serverArtboardId: "",
        status: ArtboardUploadStatus.NotStarted,
        progress: 0,
      };
      Vue.set(batch.contents, artboardId, artboard);
    }
  },

  [MutationTypes.RemoveFromBatch](state, { batchId, artboardId }) {
    const batch = state.batches[batchId];
    if (!batch) return;
    const artboard = batch.contents[artboardId];
    if (!artboard) return;
    Vue.delete(batch.contents, artboardId);
  },

  [MutationTypes.UpdateProgress](state, { uploadData: { batchId, artboardId, progress, status, cancel } }) {
    const batch = state.batches[batchId];
    if (!batch) return;
    const artboard = batch.contents[artboardId];
    if (artboard) {
      artboard.status = status !== undefined ? status : ArtboardUploadStatus.InProgress;
      artboard.progress = progress;
      artboard.cancel = cancel || artboard.cancel;
    }
  },

  [MutationTypes.UploadSuccess](state, { uploadData: { batchId, artboardId, serverArtboardId } }) {
    const batch = state.batches[batchId];
    if (!batch) return;
    const artboard = batch.contents[artboardId];
    if (artboard) {
      artboard.progress = 100;
      artboard.status = ArtboardUploadStatus.Success;
      artboard.serverArtboardId = serverArtboardId;
    }
    cleanupBatch(state, batch);
  },

  [MutationTypes.UploadError](state, { uploadData: { batchId, artboardId, errorMessage } }) {
    const batch = state.batches[batchId];
    if (!batch) return;
    const artboard = batch.contents[artboardId];
    if (artboard) {
      artboard.status = ArtboardUploadStatus.Error;
      artboard.errorMessage = errorMessage;
    }
    cleanupBatch(state, batch);
  },

  [MutationTypes.UploadCancel](state, { uploadData: { batchId, artboardId } }) {
    const batch = state.batches[batchId];
    if (!batch) return;
    const artboard = batch.contents[artboardId];
    if (artboard) {
      artboard.status = ArtboardUploadStatus.Canceled;
    }
    cleanupBatch(state, batch);
  },
};

function cleanupBatch(state: AxShareUploads, batch: ArtboardUploadBatch) {
  if (batch.dismissed && batchIsCompleted(batch)) {
    Vue.delete(state.batches, batch.id);
  }
}

export default mutations;
