import Vue from "vue";
import Vuex from "vuex";

import actions from "@/store/actions";
import admin from "@/store/admin";
import desktop from "@/store/desktop";
import expo from "@/store/expo";
import fs from "@/store/fs";
import getters from "@/store/getters";
import migration from "@/store/migration";
import mutations from "@/store/mutations";
import notifications from "@/store/notifications";
import project from "@/store/project";
import settings from "@/store/settings";
import state from "@/store/state";
import uploads from "@/store/uploads";
import user from "@/store/user";

import { desktopBridge, vuexPersist } from "./plugins";

Vue.use(Vuex);

export default new Vuex.Store({
  state: state(),
  actions,
  mutations,
  getters,
  modules: {
    fs,
    project,
    expo,
    user,
    admin,
    desktop,
    settings,
    uploads,
    notifications,
    migration,
  },
  strict: process.env.NODE_ENV !== "production",
  plugins: [desktopBridge, vuexPersist.plugin],
});
