
import Vue from "vue";
import { PropOptions } from "vue/types/options";

import { CommentsChangedArgs, WorkspaceActivityEventArgs } from "@shared/models";

import { isWorkspaceNode, FilesystemNodeWorkspace } from "@/common/fs";
import { pushNotificationsService } from "@/services/push.notifications.service";

import AxPushNotificationsBase from "@/components/AxPushNotifications/AxPushNotificationsBase";

const hubName = "workspaces";

const workspace: PropOptions<FilesystemNodeWorkspace> = {
  type: Object,
  required: true,
  validator(item) {
    return isWorkspaceNode(item);
  },
};

export default Vue.extend({
  components: {
    AxPushNotificationsBase,
  },

  props: {
    workspace,

    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      hubName,
      listeners: {
        commentsChanged: (payload: CommentsChangedArgs) => {
          this.$emit("comments-changed", payload);
        },

        activityEvent: (payload: WorkspaceActivityEventArgs) => {
          this.$emit("activity-event", payload);
        },
      },
    };
  },

  async mounted() {
    if (this.readonly) return;
    // join group only after child component finished mounting
    // this is required, because AxPushNotificationsBase keeps track of hub connection
    // and to join hub group, connection is required first
    this.$nextTick(async () => {
      await pushNotificationsService.addToGroup(hubName, this.workspace.id);
    });
  },

  async beforeDestroy() {
    if (this.readonly) return;
    await pushNotificationsService.removeFromGroup(hubName, this.workspace.id);
  },
});
