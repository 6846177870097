import { AccountRowJs, AxShareCurrentUserInfo, AxShareSubInstanceInfo } from "@shared/models";
import { Payload } from "vuex";
import { PayloadMap } from "@/store/typed";

export enum MutationTypes {
  SetAdminUserInfo = "[Admin] Set User Info",
  SetAdminSubInstanceInfo = "[Admin] Set SubInstance Info",
  SetAdminSubInstanceAccounts = "[Admin] Set SubInstance Accounts",
}

export class SetAdminUserInfo implements Payload {
  public readonly type = MutationTypes.SetAdminUserInfo;
  constructor(public userInfo: AxShareCurrentUserInfo | undefined) {}
}

export class SetAdminSubInstanceInfo implements Payload {
  public readonly type = MutationTypes.SetAdminSubInstanceInfo;
  constructor(public subInstanceInfo: AxShareSubInstanceInfo | undefined) {}
}

export class SetAdminSubInstanceAccounts implements Payload {
  public readonly type = MutationTypes.SetAdminSubInstanceAccounts;
  constructor(public accounts: AccountRowJs[] | undefined) {}
}

export interface MutationPayloadMap extends PayloadMap<MutationTypes> {
  [MutationTypes.SetAdminUserInfo]: SetAdminUserInfo;
  [MutationTypes.SetAdminSubInstanceInfo]: SetAdminSubInstanceInfo;
  [MutationTypes.SetAdminSubInstanceAccounts]: SetAdminSubInstanceAccounts;
}
