
import Vue, { PropOptions } from "vue";

import AxList from "@/components/AxList.vue";
import AxListItem from "@/components/AxListItem.vue";

interface Option<T = any> {
  label: string;
  value: T;
  children?: Option<T>[];
}

const optionsProp: PropOptions<Option[]> = {
  type: Array,
  required: false,
  default: () => ([]),
};

export default Vue.extend({
  name: "AxFilesystemTreeProjectsList",
  components: {
    AxList,
    AxListItem,
  },

  props: {
    options: optionsProp,

    activeIndex: {
      type: Number,
      required: false,
      default: 0,
    },

    startIndex: {
      type: Number,
      required: false,
      default: 0,
    },

    activeOption: {
      type: Object,
      required: false,
      default: undefined,
    },

    selectedOption: {
      type: Object,
      required: false,
      default: undefined,
    },

    handlers: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
});
