
import Vue, { VueConstructor } from "vue";

import { isElectron } from "@/common/environment";
import { appStatus } from "@/mixins/AppStatus";
import { desktopClasses } from "@/mixins/DesktopClasses";
import { desktopExternalLinks } from "@/mixins/DesktopExternalLinks";
import AppView from "@/pages/AppView.vue";
import { AxShare } from "@/store/state";

import AxAppFailure from "@/components/AxAppFailure.vue";
import AxVersionTracker from "@/components/AxVersionTracker.vue";

const AxTitleBar = () => import("@/components/AxTitleBar.vue");

type Mixins = InstanceType<typeof desktopExternalLinks> &
InstanceType<typeof desktopClasses> &
InstanceType<typeof appStatus>;

export default (Vue as VueConstructor<Vue & Mixins>).extend({
  components: {
    AxAppFailure,
    AppView,
    AxTitleBar,
    AxVersionTracker,
  },

  mixins: [desktopExternalLinks, desktopClasses, appStatus],

  metaInfo: {
    title: "Axure Cloud",
  },

  data() {
    return { isElectron };
  },

  computed: {
    appConfigured(): boolean {
      return this.$store.getters.appConfigured && this.appStatusSuccess;
    },

    navigationError(): Error | unknown {
      const { navigationError } = this.$store.state as AxShare;
      return navigationError;
    },
  },

  created() {
    window.addEventListener("unhandledrejection", this.handleUnhandledRejection);
  },

  destroyed() {
    window.removeEventListener("unhandledrejection", this.handleUnhandledRejection);
  },

  methods: {
    handleUnhandledRejection(rejection: PromiseRejectionEvent) {
      if (rejection.reason === "We are currently performing maintenance, please retry shortly.") {
        // temp fix: silent RO mode error
        rejection.preventDefault();
      }
    },
  },
});
