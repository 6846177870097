import { GetterTree } from "vuex";

import { AxShare } from "@/store/state";
import { AxShareUser } from "@/store/user/state";

const getterTree: GetterTree<AxShareUser, AxShare> = {
  enterpriseLicenseCheckFailed(state, _, rootState) {
    const { axShareConfig } = rootState;
    const { userInfo } = state;
    if (!axShareConfig || !userInfo) return false;

    const { IsSubInstance: isSubInstance } = axShareConfig;
    return isSubInstance && userInfo.isRpEnterprise && !userInfo.isWhitelisted && !userInfo.isTrialActive;
  },
};

export default getterTree;
