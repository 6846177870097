import { persistentStorage } from "@/common/axshare/persistent";
import { isElectron } from "@/common/environment";
import { hasNotifications, isSafari, browserVersion } from "@/common/lib/browser";

export async function hasDesktopNotificationsPermission(): Promise<boolean> {
  if (!hasNotifications) {
    return false;
  }

  const version = browserVersion ?? "0";
  const versionWithCorrectPermissions = "13.1.0";

  if (isSafari && compareVersion(version, versionWithCorrectPermissions) === -1) {
    return hasDesktopNotificationsPermissionForOldSafari();
  }

  if (Notification.permission.toLowerCase() !== "granted") {
    return false;
  }

  const permissionFromStorage = persistentStorage.getDesktopNotificationsEnabled();

  if (permissionFromStorage !== undefined) {
    return permissionFromStorage;
  }

  // electron always has "granted" permission
  // for electron if notifications option is not set we consider it turned on
  if (isElectron) {
    return true;
  }

  // for browser if notifications option is not set we consider it turned off
  // browser will ask permission when option is turned on manually
  return false;
}

export async function requestNotificationsPermission(): Promise<string> {
  if (isSafari) {
    // Safari doesn't return promise - it takes callback instead
    return new Promise((resolve, reject) => {
      try {
        Notification.requestPermission(result => resolve(result.toString()));
      } catch (err) {
        reject(err);
      }
    });
  }

  return Notification.requestPermission();
}

async function hasDesktopNotificationsPermissionForOldSafari(): Promise<boolean> {
  const browserPermission = Notification.permission.toString();

  if (browserPermission === "denied") {
    return false;
  }

  const permissionFromStorage = persistentStorage.getDesktopNotificationsEnabled();

  if (browserPermission === "granted" && permissionFromStorage !== undefined) {
    return permissionFromStorage;
  }

  // old safari browser doesn't show correct permission from Notification.permission
  // requestPermission is performed in order to get correct permission
  if (browserPermission === "default" && permissionFromStorage === true) {
    const correctPermission = await requestNotificationsPermission();

    return correctPermission === "granted";
  }

  return false;
}

function compareVersion(v1:string, v2:string) {
  const v1Values = v1.split(".");
  const v2Values = v2.split(".");
  const k = Math.min(v1Values.length, v2Values.length);
  for (let i = 0; i < k; ++ i) {
      const v1Value = parseInt(v1Values[i], 10);
      const v2Value = parseInt(v2Values[i], 10);
      if (v1Value > v2Value) return 1;
      if (v1Value < v2Value) return -1;
  }

  if (v1Values.length === v2Values.length ) {
    return 0;
  }

  return v1Values.length < v2Values.length ? -1 : 1;
}
