
import Vue from "vue";

import { FilesystemNodeWorkspace } from "@/common/fs";
import { fsRouterLocation } from "@/router/fs";

import AxConfigureWorkspaceSecurity from "@/components/AxConfigureWorkspaceSecurity.vue";
import AxDialog from "@/components/AxDialog.vue";

export default Vue.extend({
  components: {
    AxConfigureWorkspaceSecurity,
    AxDialog,
  },

  props: {
    workspaceId: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      show: true,
      persistent: false,
    };
  },

  computed: {
    workspace(): FilesystemNodeWorkspace {
      return this.$store.getters.getFsNode(this.workspaceId);
    },
  },

  methods: {
    async submit() {
      this.cancel(true);
    },

    cancel(refresh: boolean = false) {
      this.$router.push(fsRouterLocation(this.id, { refresh: { contents: refresh } }));
    },

    setPersistent(persistent: boolean) {
      this.persistent = persistent;
    },
  },
});
