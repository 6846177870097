
import Vue from "vue";
import { PropOptions } from "vue/types/options";

import {
  isRpProject, isRpProjectType, isSketchProject, isSketchProjectType,
} from "@/common/axshare";
import { FilesystemNodeShortcut, isShortcutNode, ShortcutStateName } from "@/common/fs";
import { ShortcutType } from "@/services/models";

import AxIcon from "@/components/AxIcon.vue";
import AxProjectIcon from "@/components/AxProjectIcon.vue";

const projectProp: PropOptions<FilesystemNodeShortcut> = {
  type: Object,
  required: false,
  validator: item => isShortcutNode(item),
};
const typeProp: PropOptions<ShortcutType> = {
  type: Number,
  required: false,
};
const props = {
  project: projectProp,
  type: typeProp,
};

export default Vue.extend({
  components: {
    AxIcon,
    AxProjectIcon,
  },

  props: {
    small: {
      type: Boolean,
      default: false,
    },

    iconClasses: {
      type: String,
      default: "",
    },

    state: {
      type: String,
      default: "",
    },

    stretch: {
      type: Boolean,
      default: false,
    },

    version: {
      type: Number,
      required: false,
      default: undefined,
    },

    ...props,
  },

  data() {
    return {
      // eslint-disable-next-line global-require
      fallback: require("@/assets/project-rp8.png"),
    };
  },

  computed: {
    classes(): Record<string, boolean> {
      return {
        "w-100": this.stretch,
        "ax-project-thumbnail__small": this.small,
        "ax-project-thumbnail__large": !this.small,
      };
    },

    shortcutType(): ShortcutType {
      const { project, type } = this;
      if (project && project.listingNode) {
        return project.listingNode.ShortcutType;
      }

      return type;
    },

    shortcutState(): ShortcutStateName {
      const { project, state } = this;
      if (project) {
        return project.shortcutState;
      }

      return state as ShortcutStateName;
    },

    projectResourceDirVersion(): number {
      const { project, version } = this;
      return (project && project.listingNode && project.listingNode.ResourceDirVersion) || version;
    },

    isRp(): boolean {
      const { project, type } = this;
      if (project) {
        return isRpProject(project);
      }

      return isRpProjectType(type);
    },

    isSketch(): boolean {
      const { project, type } = this;
      if (project) {
        return isSketchProject(project);
      }

      return isSketchProjectType(type);
    },
  },
});
