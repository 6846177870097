
import Vue from "vue";

export default Vue.extend({
  props: {
    color: {
      type: String,
      default: null,
    },

    short: {
      type: Boolean,
      default: false,
    },

    dark: {
      type: Boolean,
      default: false,
    },

    light: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    colorValue(): string {
      if (this.color) return this.color;
      if (this.dark) return "#444";
      if (this.light) return "#e3e3e3";
      return "#e3e3e3";
    },
  },
});
