import { BrowserStorage, Storage } from "@/common/axshare/storage";

export class AuthStorage {
  private authTokenKey = "authToken";
  private store: Storage;

  constructor(store: Storage) {
    this.store = store;
  }

  get Token() {
    return this.store.get(this.authTokenKey);
  }

  set Token(token: string | undefined) {
    if (token) {
      this.store.set(this.authTokenKey, token);
    } else {
      this.clearToken();
    }
  }

  public clearToken() {
    this.store.delete(this.authTokenKey);
  }
}

const storage =
  process.env.VUE_APP_IS_ELECTRON_MAIN === "true" ? new (require("@/desktop/storage")).default() : new BrowserStorage();

export const authStorage = new AuthStorage(storage);
