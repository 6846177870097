
import Vue from "vue";

import {
  FilesystemNodeShortcut,
  FilesystemNodeType,
  FilesystemNodeTypes,
  getUnreadPublishNotesMentions,
} from "@/common/fs";

import { Pagination } from "@/components/AxDataTable.vue";
import AxDataTableNoData from "@/components/AxDataTableNoData.vue";
import AxFilesystemNodeLink from "@/components/AxFilesystemNodeLink.vue";
import AxFilesystemTable from "@/components/AxFilesystemTable.vue";
import AxShortcutCommentsCount from "@/components/AxShortcutCommentsCount.vue";
import AxShortcutDropdownButton from "@/components/AxShortcutDropdownButton.vue";
import AxShortcutModified from "@/components/AxShortcutModified.vue";
import AxShortcutPublicLink from "@/components/AxShortcutPublicLink.vue";
import AxUnreadMentionsIndicator from "@/components/AxUnreadMentionsIndicator.vue";
import { arrayProp } from "@/components/utils";

export default Vue.extend({
  components: {
    AxDataTableNoData,
    AxFilesystemTable,
    AxShortcutDropdownButton,
    AxShortcutModified,
    AxShortcutPublicLink,
    AxFilesystemNodeLink,
    AxShortcutCommentsCount,
    AxUnreadMentionsIndicator,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    items: arrayProp<FilesystemNodeTypes>({
      required: false,
      default: () => [],
    }),

    headerOffsetTop: {
      type: Number,
      default: 0,
    },
  },

  data() {
    const pagination: Partial<Pagination> = {
      sortBy: undefined,
      descending: true,
    };
    return {
      pagination,
      FilesystemNodeType,
      deletedItems: {} as Record<string, boolean>,
    };
  },

  methods: {
    project(item: FilesystemNodeShortcut) {
      return this.$store.getters.getFsNode(item.id) || item;
    },

    itemDeleted(item: FilesystemNodeShortcut) {
      this.$set(this.deletedItems, item.id, true);
    },

    isItemDeleted(item: FilesystemNodeShortcut) {
      return !!this.deletedItems[item.id];
    },

    showShortcutDropdown(item: FilesystemNodeTypes) {
      return item.type === FilesystemNodeType.Shortcut && item.viewOnly === false;
    },

    hasUnreadPublishNotesMentions(item: FilesystemNodeTypes) {
      if (!item.listingNode) return false;
      const mentionsCount = getUnreadPublishNotesMentions(item.listingNode.NotificationState);
      return mentionsCount > 0;
    },
  },
});
