
import Vue from "vue";

import { WorkspaceInvitationDeletedArgs, WorkspaceInvitedArgs } from "@shared/models";

import { FilesystemNodeWorkspace } from "@/common/fs";
import { AxMentionedNotificationOptions } from "@/common/notifications/AxNotificationOptions";
import { hasDesktopNotificationsPermission } from "@/common/notifications/permissions";
import { ROUTES } from "@/router";
import { SignalRHubListeners } from "@/services/push.notifications.service";
import { WorkspacesRefresh } from "@/store/fs/actionTypes";
import { NodeRemove } from "@/store/fs/mutationTypes";
import { ShowWorkspaceInvitedDesktopNotification } from "@/store/notifications/actionTypes";

import AxPushNotificationsBase from "@/components/AxPushNotifications/AxPushNotificationsBase";

export default Vue.extend({
  components: {
    AxPushNotificationsBase,
  },

  computed: {
    listeners(): SignalRHubListeners {
      return {
        workspaceInvited: async (payload: WorkspaceInvitedArgs) => {
          await this.$store.dispatch(new WorkspacesRefresh());

          if (await hasDesktopNotificationsPermission()) {
            const notificationOptions: AxMentionedNotificationOptions = {
              authorEmail: payload.OwnerEmail,
              authorName: payload.OwnerName,
              body: payload.MentionComment,
              tag: payload.Id,
              authorProfileImage: payload.OwnerProfileImage,
              onclick: async (notification: Notification) => {
                // google chrome notifications are not closed by click on MacOS
                // so it should be closed manually
                if (notification) {
                  notification.close();
                }

                await this.$router.push({
                  name: ROUTES["fs.workspace.invited"].name,
                  params: { workspaceId: payload.FilesystemId },
                });
              },
            };

            await this.$store.dispatch(new ShowWorkspaceInvitedDesktopNotification(notificationOptions));
          }
        },

        workspaceInvitationDeleted: async (payload: WorkspaceInvitationDeletedArgs) => {
          let exitFromCurrentWorkspace = false;
          const { shortcut } = this.$route.params;
          if (shortcut) {
            const workspace: FilesystemNodeWorkspace | undefined = this.$store.getters.findWorkspace(shortcut);
            if (workspace && workspace.id === payload.FilesystemId) exitFromCurrentWorkspace = true;
          }

          this.$store.commit(new NodeRemove(payload.FilesystemId));
          const currentPath = this.$router.currentRoute.path;
          if (currentPath.toLowerCase().indexOf(payload.FilesystemId.toLowerCase()) >= 0 || exitFromCurrentWorkspace) {
            await this.$router.push({
              name: ROUTES.fs.name,
            });
          }
        },
      };
    },
  },
});
