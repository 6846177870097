
import Vue from "vue";

const AxDialogManager = Vue.extend({
  data() {
    const modals: Vue[] = [];
    return {
      modals,
      baseZIndex: 2000,
    };
  },

  watch: {
    modals(newVal, _oldVal) {
      this.updateModals(newVal || []);
    },
  },

  methods: {
    // Public methods
    registerModal(modal: Vue | any) {
      // Register the modal if not already registered
      if (modal && this.modals.indexOf(modal) === -1) {
        // Add modal to modals array
        this.modals.push(modal);
        modal.$once("hook:beforeDestroy", () => {
          this.unregisterModal(modal);
        });
      }
    },

    unregisterModal(modal: Vue | any) {
      const index = this.modals.indexOf(modal);
      if (index > -1) {
        // Remove modal from modals array
        this.modals.splice(index, 1);
        // Reset the modal's data
        // eslint-disable-next-line no-underscore-dangle
        if (!(modal._isBeingDestroyed || modal._isDestroyed)) {
          this.resetModal(modal);
        }
      }
    },

    getBaseZIndex() {
      return this.baseZIndex;
    },

    // Private methods
    updateModals(modals: Vue[]) {
      const baseZIndex = this.getBaseZIndex();
      modals.forEach((modal, index) => {
        const dialog = modal as any;
        // We update data values on each modal
        dialog.zIndex = baseZIndex + 2 * index;
        dialog.isTop = index === this.modals.length - 1;
      });
    },

    resetModal(modal: any) {
      if (modal) {
        modal.zIndex = this.getBaseZIndex();
        modal.isTop = true;
      }
    },
  },
});

export default new AxDialogManager();
