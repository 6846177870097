
import Vue, { VNode } from "vue";

import { delay } from "@/common/lib/promise";

export default Vue.extend({
  props: {
    delay: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      completed: false,
      cancelled: false,
    };
  },

  computed: {
    fulfilled(): boolean {
      return this.completed && !this.cancelled;
    },
  },

  async created() {
    await delay(this.delay);
    this.completed = true;
  },

  destroyed() {
    if (!this.completed) {
      this.cancelled = true;
    }
  },

  render(h): VNode {
    return this.fulfilled && this.$scopedSlots.default ? ((this.$scopedSlots.default({}) as unknown) as VNode) : h("");
  },
});
