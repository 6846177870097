export const BridgeEvents = {
  bridgeReady: "bridgeReady",
  ready: "ready",
  playerMessageCenterEvent: "playerMessageCenterEvent",
  inspectorToggled: "inspectorToggled",
  documentConfiguration: "documentConfiguration",
  previewGenerationComplete: "generationComplete",
  pluginCreated: "pluginCreated",
  updateIsPanZoomEnabled: "updateIsPanZoomEnabled",
};

export const AppEvents = {
  starting: "AppEvents.starting",
};

export const commands = {
  addHandoff: "addHandoff",
  getPageData: "getPageData",
  getDocumentConfiguration: "getDocumentConfiguration",
  inspectorEnable: "inspectorEnable",
  inspectorDisable: "inspectorDisable",
  inspectorShow: "inspectorShow",
  inspectorHide: "inspectorHide",
  keyboardEvent: "keyboardEvent",
  selectAdaptiveView: "selectAdaptiveView",
  setZoom: "setZoom",
  pluginToggle: "pluginToggle",
  hidePlayerControls: "hidePlayerControls",
  hidePinsOverlay: "hidePinsOverlay",
  selectPage: "selectPage",
  resizePlayerContent: "resizePlayerContent",
  selectScaleOption: "selectScaleOption",
  selectOverlayOption: "selectOverlayOption",

  triggerUpdateIsPanZoomEnabled: "updateIsPanZoomEnabled",

  generateLocalExpoPreview: "generateLocalExpoPreview",

  reload: "reload",
  refreshViewPort: "refreshViewPort",

  navigateToIssue: "navigateToIssue",
  updateNotificationState: "updateNotificationState",

  exitCommentMode: "exitCommentMode",
  reset: "reset",
};

export const KeyboardEvents = {
  ctrlOrCmdPlus: "ctrlOrCmdPlus",
  ctrlOrCmdMinus: "ctrlOrCmdMinus",
  ctrlOrCmdZero: "ctrlOrCmdZero",
  spaceBarUp: "spaceBarUp",
  spaceBarDown: "spaceBarDown",
  comma: "comma",
  period: "period",
  esc: "esc",
  comment: "comment",
};
