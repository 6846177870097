import {
  Modifier,
} from "@popperjs/core";

const sameWidth: Modifier<"sameWidth", any> = {
  name: "sameWidth",
  enabled: true,
  fn({ state }) {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect({ state }) {
    const rect = state.elements.reference.getBoundingClientRect();
    state.elements.popper.style.width = `${rect.width}px`;
  },
  phase: "beforeWrite",
  requires: ["computeStyles"],
};

export { sameWidth };
