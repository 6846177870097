
import Vue from "vue";
import { PropValidator } from "vue/types/options";

import { FilesystemNodeFolder, isFilesystemFolderNode } from "@/common/fs";
import { FolderRename } from "@/store/fs/actionTypes";

import AxEditable from "@/components/AxEditable.vue";
import AxFilesystemViewerActionsBase from "@/components/AxFilesystemViewerActionsBase.vue";
import AxIcon from "@/components/AxIcon.vue";
import AxFolder from "@/components/icons/AxFolder.vue";

const itemProp: PropValidator<FilesystemNodeFolder> = {
  type: Object,
  required: true,
  validator(item) {
    return isFilesystemFolderNode(item);
  },
};
const props = {
  item: itemProp,
};

export default Vue.extend({
  components: {
    AxEditable,
    AxFilesystemViewerActionsBase,
    AxFolder,
    AxIcon,
  },

  props,

  computed: {
    viewOnly(): boolean {
      return this.item.viewOnly;
    },

    rights(): { folderRename: boolean } {
      return {
        folderRename: !this.viewOnly,
      };
    },
  },

  methods: {
    async renameSubmit(name: string) {
      await this.$store.dispatch(new FolderRename(this.item.id, name));
    },
  },
});
