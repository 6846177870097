
import Vue from "vue";

import { AxShare } from "@/store/state";

export default Vue.extend({
  computed: {
    readOnlyErrorMessage(): string {
      const { axShareConfig } = this.$store.state as AxShare;
      return axShareConfig ? axShareConfig.ReadOnlyMessage : "";
    },
  },
});
