import { trimEmailDomain } from "@/common/lib";
import { SsoResponse, UserProfileJs } from "@/generated/models";
import { UserProfile } from "@/services/models/account";

function hasName(profile: any): profile is { Name: string } {
  return profile && typeof profile.Name === "string";
}

function hasUsername(profile: any): profile is { username: string } {
  return profile && typeof profile.username === "string";
}

function hasEmail(profile: any): profile is { userEmail: string } {
  return profile && typeof profile.userEmail === "string";
}

interface User {
  Name?: string;
  nickname?: string;
}

export function getUserName(profile: UserProfile | SsoResponse | UserProfileJs | undefined, fallback = "") {
  if (!profile) return fallback;
  /* eslint-disable */
  const name = hasName(profile) ? profile.Name :
    hasUsername(profile) ? profile.username :
      profile.nickname;
  /* eslint-enable */

  if (!name) {
    if (!fallback && hasEmail(profile)) {
      // fill empty fallback to userEmail
      // eslint-disable-next-line no-param-reassign
      fallback = profile.userEmail;
    }
    return trimEmailDomain(fallback);
  }
  return name;
}

class PasswordValidationRule {
  constructor(message: string, private validator: (password: string) => boolean) {
    this.message = message;
  }

  public state = false;
  public message: string;
  public validate(password: string) {
    this.state = this.validator(password);
  }
}

export class PasswordValidator {
  constructor(useExtraRules: boolean = false) {
    this.useExtraRules = useExtraRules;
  }

  public useExtraRules: boolean;
  private minLength = 8;
  private upperRegex = /[A-Z]/;
  private lowerRegex = /[a-z]/;
  private numberRegex = /[0-9]/;
  private errorMessages: string[] = [];

  public rules: PasswordValidationRule[] = [
    new PasswordValidationRule(`${this.minLength} characters`, password => password.trim().length >= this.minLength),
    new PasswordValidationRule("one uppercase letter", password => this.upperRegex.test(password)),
    new PasswordValidationRule("one lowercase letter", password => this.lowerRegex.test(password)),
    new PasswordValidationRule("one number", password => this.numberRegex.test(password)),
  ];

  public get isValid(): boolean {
    return this.errorMessages.length === 0;
  }

  public validate(password: string) {
    this.errorMessages = [];

    for (let i = 0; i < this.rules.length; i++) {
      const rule = this.rules[i];
      rule.validate(password);

      if (!rule.state) this.errorMessages.push(rule.message);
      if (!this.useExtraRules) break;
    }
  }

  public errorMessage(): string {
    if (this.isValid) return "";

    let errorMessage = "Your password must contain at least";
    this.errorMessages.forEach(error => {
      errorMessage += ` ${error},`;
    });

    // replacing last comma with dot
    errorMessage = `${errorMessage.substring(0, errorMessage.length - 1)}.`;

    return errorMessage;
  }
}
