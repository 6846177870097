
import Vue from "vue";

import { FilesystemNodeShortcut, FilesystemNodeType } from "@/common/fs";

import AxShortcutModified from "@/components/AxShortcutModified.vue";

export default Vue.extend({
  components: {
    AxShortcutModified,
  },

  props: {
    item: {
      type: Object,
      default: null,
    },
  },

  computed: {
    canShowModifiedDate(): boolean {
      return this.item.type === FilesystemNodeType.Shortcut || this.item.type === FilesystemNodeType.Folder;
    },

    project(): FilesystemNodeShortcut {
      return this.$store.getters.getFsNode(this.item.id);
    },
  },
});
