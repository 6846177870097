import {
  AxShareConfigModel,
  ExtendedSsoResponse,
  VersionInfoResponse,
  ValidateRequestJs,
} from "@shared/models";

import { exec } from "@/services/api";
import { server } from "@/services/server";
import { withNoCacheHeaders } from "@/services/utils/axios";
import { objectToFormData } from "@/services/utils/formData";

export function getConfig() {
  return exec<AxShareConfigModel>(server.get("/user/getconfig", withNoCacheHeaders));
}

export function getConfigFromDomain(domain: string) {
  return exec<AxShareConfigModel>(
    server.get("/user/getconfig", {
      ...withNoCacheHeaders,
      baseURL: domain,
    }),
  );
}

export function getUserInfo(followRedirect = true) {
  return exec<ExtendedSsoResponse>(server.get("/user/getuserinfo"), {
    ignoreResponseRedirectUrl: !followRedirect,
  });
}

export function getVersion() {
  return exec<VersionInfoResponse>(server.get("/user/getversion"));
}

export function validateRpRequestId(requestId: string) {
  return exec<ValidateRequestJs>(server.get("/user/validaterequestid", { params: { requestId } }));
}

export function refreshAuth(token: string) {
  const formData = objectToFormData({
    token,
  });
  return exec(server.post("/user/refreshAuth", formData));
}
