
import Vue from "vue";

import { FilesystemNodeType, FilesystemNodeTypes } from "@/common/fs";

import AxIcon from "@/components/AxIcon.vue";
import AxNavigationLinkFs from "@/components/AxNavigationLinkFs.vue";
import AxProjectThumbnail from "@/components/AxProjectThumbnail.vue";
import AxFolder from "@/components/icons/AxFolder.vue";
import { prop } from "@/components/utils";

export default Vue.extend({
  components: {
    AxIcon,
    AxFolder,
    AxProjectThumbnail,
    AxNavigationLinkFs,
  },

  props: {
    node: prop<FilesystemNodeTypes>({
      required: true,
    }),
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      FilesystemNodeType,
    };
  },
});
