import Vue from "vue";
import { PropOptions } from "vue/types/options";

import { AccountRowJs, SharedFilesystemMappingJs, SsoResponse } from "@shared/models";

import { FilesystemNodeWorkspace } from "@/common/fs";
import { orderBy } from "@/common/lib";
import { AxShare } from "@/store/state";

const workspaceProp: PropOptions<FilesystemNodeWorkspace> = {
  type: Object,
  required: true,
};

export const workspaceMembersMixin = Vue.extend({
  props: {
    workspace: workspaceProp,
  },

  computed: {
    isSubInstance(): boolean {
      const { axShareConfig } = this.$store.state as AxShare;
      return axShareConfig !== null && axShareConfig.IsSubInstance;
    },

    sharedWith(): SharedFilesystemMappingJs[] {
      let users = this.workspace ? this.workspace.users : [];
      const sharedWith: SharedFilesystemMappingJs[] = [];

      const { yourself, iAmTheOwner, didJoinWorkspace } = this;

      const owner = this.owner;
      if (owner) {
        sharedWith.push(owner);
        users = users.filter(u => u.user.userId !== owner.user.userId);
      }

      if (yourself && !iAmTheOwner && didJoinWorkspace) {
        sharedWith.push(yourself);
        users = users.filter(u => u.user.userId !== yourself.user.userId);
      }

      sharedWith.push(...users);

      // Users list should be in following order:
      //  - Owner
      //  - Current user (if not owner)
      //  - Admins
      //  - Authors
      //  - Viewers in workspace
      //  - Reviewers instance-wide
      //  - alphabetic by email

      const ordered = orderBy(
        sharedWith,
        u => ({ value: owner?.user.userId === u.user.userId, descending: true }),
        u => ({ value: u.user.userId === yourself?.user.userId, descending: true }),
        u => ({ value: !!u.isAdmin, descending: true }),
        u => ({ value: u.isViewer === false, descending: true }),
        u => this.getLevel(u.user) || 0,
        u => u.user.userEmail,
      );

      return ordered;
    },

    yourself(): SharedFilesystemMappingJs | undefined {
      const { user } = this.$store.state as AxShare;
      if (user.userInfo) {
        const profileImageUrl = user.userInfo.profileImageUrl || (user.profile ? user.profile.Img : "");
        const yourself: SharedFilesystemMappingJs = {
          user: {
            ...user.userInfo,
            profileImageUrl,
          },

          isViewer: this.workspace ? this.workspace.viewOnly : false,
        };
        return yourself;
      }
      return undefined;
    },

    owner(): SharedFilesystemMappingJs | undefined {
      if (!this.workspace) return undefined;
      if (this.workspace.isOwner) return this.yourself;
      if (this.workspace.owner) {
        return {
          user: this.workspace.owner,
          isViewer: false,
        };
      }
      return undefined;
    },

    didJoinWorkspace(): boolean {
      if (!this.workspace) return false;
      if (this.workspace.isOwner) return true;
      const me = this.yourself;
      if (me && this.workspace.users.some(d => d.user.userId === me.user.userId)) {
        return true;
      }
      return false;
    },

    iAmTheOwner(): boolean {
      if (this.yourself && this.owner) {
        return this.yourself.user === this.owner.user;
      }
      return false;
    },

    subInstanceAccounts(): AccountRowJs[] {
      if (this.isSubInstance) {
        const { admin } = this.$store.state as AxShare;
        if (admin && admin.subInstanceAccounts) return admin.subInstanceAccounts;
      }
      return [];
    },
  },

  methods: {
    getLevel(user: SsoResponse): number | undefined {
      const { isSubInstance } = this;
      if (isSubInstance) {
        const account = this.subInstanceAccounts.find(item => item.Id === user.userId);
        if (account) return account.Level;
      }
    },
  },
});
