
import Vue, { VNodeData } from "vue";
import { mergeData } from "vue-functional-data-merge";

export default Vue.extend({
  functional: true,
  render(h, ctx) {
    const data: VNodeData = {
      staticClass: "ax-form-group",
    };
    return h("div", mergeData(ctx.data, data), ctx.slots().default);
  },
});
