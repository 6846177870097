
import Vue from "vue";

import { FilesystemFolderInfo } from "@shared/models";

import { CreateWorkspaceModel, CreateWorkspaceModelValidator, FilesystemNodeType } from "@/common/fs";
import { asError } from "@/common/lib";
import { fsRouterLocation } from "@/router/fs";
import { shareFileSystem, workspaceCreate } from "@/services/fs.service";
import { ManageUsersInviteModel } from "@/services/models/filesystem";
import { AxShare } from "@/store/state";

import AxButton from "@/components/AxButton.vue";
import AxCheckbox from "@/components/AxCheckbox.vue";
import AxDialog from "@/components/AxDialog.vue";
import AxForm from "@/components/AxForm.vue";
import AxFormGroup from "@/components/AxFormGroup.vue";
import AxHelp from "@/components/AxHelp.vue";
import AxInput from "@/components/AxInput.vue";
import AxManageUsersInvite from "@/components/AxManageUsers/AxManageUsersInvite.vue";
import AxToggleButton from "@/components/AxToggleButton.vue";
import { Submit } from "@/components/types/AxForm";

export default Vue.extend({
  components: {
    AxForm,
    AxFormGroup,
    AxButton,
    AxDialog,
    AxInput,
    AxCheckbox,
    AxManageUsersInvite,
    AxToggleButton,
    AxHelp,
  },

  data() {
    const model: CreateWorkspaceModel = {
      workspaceName: "",
      userViewOnly: false,
    };

    const usersInvite: ManageUsersInviteModel = {
      userEmails: [],
      viewerOnly: false,
    };

    return {
      usersInvite,
      dialog: true,
      fSFolderInfo: undefined as FilesystemFolderInfo | undefined,
      model,
      validator: CreateWorkspaceModelValidator,
      buttonColor: "primary",
    };
  },

  computed: {
    isLimitAccessAvailable(): boolean {
      return this.isOnPrem || this.isSubInstance;
    },

    isOnPrem(): boolean {
      const { axShareConfig } = this.$store.state as AxShare;
      return axShareConfig !== null && axShareConfig.AxShareOnPrem;
    },

    isSubInstance(): boolean {
      const { axShareConfig } = this.$store.state as AxShare;
      return axShareConfig !== null && axShareConfig.IsSubInstance;
    },
  },

  methods: {
    changed(usersInvite: ManageUsersInviteModel) {
      this.usersInvite = usersInvite;
    },

    async submit({ values }: Submit<CreateWorkspaceModel>) {
      const { usersInvite } = this;
      if (!this.fSFolderInfo) {
        this.fSFolderInfo = await workspaceCreate(values.workspaceName, values.userViewOnly);
      }

      if (this.fSFolderInfo) {
        const { FilesystemInfo, RootFolderId } = this.fSFolderInfo;
        if (usersInvite.userEmails && usersInvite.userEmails.length > 0) {
          const model: ManageUsersInviteModel = {
            userEmails: Array.isArray(usersInvite.userEmails)
              ? usersInvite.userEmails.join(",")
              : usersInvite.userEmails,
            inviteMessage: usersInvite.inviteMessage,
            viewerOnly: usersInvite.viewerOnly,
            currentFolderId: RootFolderId,
            filesystemId: FilesystemInfo.FilesystemId,
          };
          try {
            await shareFileSystem(model);
          } catch (error) {
            const message = asError(error).message;
            throw new Error(`Workspace has been created, but inviting users has failed: ${message}`);
          }
        }
        await this.$router.replace(
          fsRouterLocation({
            id: FilesystemInfo.FilesystemId,
            type: FilesystemNodeType.Workspace,
          }),
        );
      }
      this.dialog = false;
    },

    cancel() {
      this.$emit("closed");
    },
  },
});
