import { render, staticRenderFns } from "./WorkspaceAccessRequested.vue?vue&type=template&id=7b687108&scoped=true&"
import script from "./WorkspaceAccessRequested.vue?vue&type=script&lang=ts&"
export * from "./WorkspaceAccessRequested.vue?vue&type=script&lang=ts&"
import style0 from "./WorkspaceAccessRequested.vue?vue&type=style&index=0&id=7b687108&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b687108",
  null
  
)

export default component.exports