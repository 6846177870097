
import Vue from "vue";

import { FilesystemNodeType } from "@/common/fs";
import { SearchResult, StringChunk } from "@/common/search/SearchResult";

import { prop } from "./utils";

import AxIcon from "@/components/AxIcon.vue";
import AxProjectThumbnail from "@/components/AxProjectThumbnail.vue";
import AxFolder from "@/components/icons/AxFolder.vue";
import { FilesystemSearchItem } from "@/components/types/AxSearch";

export default Vue.extend({
  components: {
    AxIcon,
    AxProjectThumbnail,
    AxFolder,
  },

  props: {
    result: prop<SearchResult<FilesystemSearchItem>>({
      required: true,
      default: null,
    }),
    query: {
      type: String,
      default: "",
    },
  },

  computed: {
    chunks(): StringChunk[] {
      const result = this.result;
      const chunks = result.getStringChunks("name");
      return chunks || [];
    },

    isProject(): boolean {
      return this.result.item.type === FilesystemNodeType.Shortcut;
    },

    projectType(): number {
      return this.result.item.subtype !== undefined ? this.result.item.subtype : NaN;
    },

    shortcutState(): string | undefined {
      return this.result.item.shortcutState;
    },

    projectResourceDirVersion(): number {
      return this.result.item.resourceDirVersion !== undefined ? this.result.item.resourceDirVersion : NaN;
    },

    isFolder(): boolean {
      return this.result.item.type === FilesystemNodeType.Folder;
    },

    icon(): string {
      switch (this.result.item.type) {
        case FilesystemNodeType.Workspace:
          return "workspace";
        case FilesystemNodeType.Folder:
          return "folder";
        default:
          return "axure_page";
      }
    },
  },
});
