import {
  Action, ActionType, Artboard, ScrollPositionAction,
} from "@shared/expo";

import { isSavedAction } from "@/common/expo/actions";
import { ProjectConfig } from "@/common/expo/interfaces";
import { FilesystemNodeShortcut } from "@/common/fs";
import * as api from "@/services/expo.service";
import * as mutations from "@/store/expo/mutationTypes";
import { ExpoStore } from "@/store/expo/state";
import { AxShare } from "@/store/state";
import { ActionTree } from "@/store/typed";

import { ActionPayloadMap, ActionTypes, ProjectContentFetch } from "./actionTypes";

// Helper function to perform request to add/edit hotspot
async function saveAction(shortcut: string, artboard: Artboard, originalAction: Action, action: Action) {
  const saved = isSavedAction(action);

  // Fix for point on screen action when users delete an input and immediately saves
  if (action.Type === ActionType.PointOnScreen) {
    const ptOnScreenAction = action.Setting as ScrollPositionAction;
    const x = typeof ptOnScreenAction.Location.X;
    const y = typeof ptOnScreenAction.Location.Y;
    let tempStr = "";
    let tempVal = 0;

    if (x !== "number") {
      tempStr = `${ptOnScreenAction.Location.X}`;
      tempVal = parseInt(tempStr, 10);
      ptOnScreenAction.Location.X = Number.isNaN(tempVal) ? 0 : tempVal;
    }
    if (y !== "number") {
      tempStr = `${ptOnScreenAction.Location.Y}`;
      tempVal = parseInt(tempStr, 10);
      ptOnScreenAction.Location.Y = Number.isNaN(tempVal) ? 0 : tempVal;
    }
  }

  const result = saved
    ? await api.hotspotEdit(shortcut, originalAction, action)
    : await api.hotspotAdd(shortcut, artboard, action);

  return result;
}

const actionsTree: ActionTree<ExpoStore, AxShare, ActionPayloadMap> = {
  async [ActionTypes.SetupProject]({
    state, commit, dispatch, rootGetters,
  }, { options }) {
    const {
      mode, shortcut, archiveMode, resourceFolder, resourceAxureFolder, platform, bgImgUrl,
    } = options;
    // TODO: Optimize
    // Trigger call to load and setup project.
    // NOTE: As of now, the state shortcut is correct but the project objects (artboards, assets, ...) are wrong.
    // We need to find a better way to catch this discrepancy between states, loading between two different projects
    // and force a new content fetch and set.
    const currentProject = state.projects[shortcut.toLowerCase()];
    if (
      !currentProject.isProjectLoading
      && !currentProject.isProjectLoaded
      // || (route as any).from.name === "project.overview"
    ) {
      // Mark project is loading
      commit(new mutations.ProjectIsLoadingSet(shortcut, true));

      // Store general project details.
      commit(
        new mutations.ProjectSetup({
          lastModified: getLastModified(rootGetters, shortcut),
          mode,
          shortcut,
          archiveMode,
          resourceFolder,
          resourceAxureFolder,
          platform,
          bgImgUrl,
          artboardsOrder: currentProject.artboardsOrder,
          hasArtboards: currentProject.hasArtboards,
        }),
      );

      // Retrieve all expo content and store into vuex
      await dispatch(new ProjectContentFetch(shortcut));
    }
  },

  async [ActionTypes.ProjectFetchExpo]({
    state, dispatch, commit, rootGetters,
  }, { shortcutId }) {
    // eslint-disable-next-line no-param-reassign
    shortcutId = shortcutId.toLowerCase();
    const content = await api.getProject(shortcutId, true);
    if (content) {
      const currentProject = state.projects[shortcutId];
      const lastModified = getLastModified(rootGetters, shortcutId);
      if (currentProject) {
        if (currentProject.lastModifiedDate < lastModified) {
          await dispatch(new ProjectContentFetch(shortcutId));
        }
      }
      const config: ProjectConfig = {
        mode: "expo",
        shortcut: shortcutId,
        resourceFolder: "",
        resourceAxureFolder: "",
        platform: content.project.Platform,
        archiveMode: false,
        bgImgUrl: content.bgImgUrl,
        artboardsOrder: content.project.ArtboardIds,
        hasArtboards: content.project.ArtboardIds.length > 0,
        lastModified: getLastModified(rootGetters, shortcutId),
      };
      commit(new mutations.ProjectSetup(config));
    }
  },

  async [ActionTypes.ProjectContentFetch]({ state, commit }, { shortcut }) {
    const project = state.projects[shortcut];
    let archiveMode = false;
    if (project) {
      archiveMode = project.archiveMode;
    }
    const content = await api.getStaticContent(shortcut, archiveMode);
    commit(new mutations.ProjectContentSet(shortcut, content));
    commit(new mutations.ProjectZoomSet(shortcut, content.project.Zoom));
  },

  async [ActionTypes.ProjectZoomSave]({ state }, { shortcut, zoom }) {
    const project = state.projects[shortcut];
    if (!project) return;

    return api.saveProjectZoom(project.shortcut, zoom);
  },

  async [ActionTypes.ProjectPlatformUpdate]({ state, dispatch, commit }, { shortcut, platform }) {
    const project = state.projects[shortcut];
    if (!project) return;

    if (platform !== undefined) {
      const result = await api.updatePlatform(project.shortcut, platform);
      await dispatch(new ProjectContentFetch(shortcut));
      commit(new mutations.ProjectUpdateLastSavedDate(shortcut));
      return result;
    }
  },

  async [ActionTypes.ProjectPlatformBackgroundImageUpload]({ commit }, { shortcut, response }) {
    // const result = api.uploadBackgroundImage(state.shortcut, form);
    commit(new mutations.ProjectPlatformBgImgUpload(shortcut, response));
    commit(new mutations.ProjectUpdateLastSavedDate(shortcut));
  },

  async [ActionTypes.ProjectPlatformBackgroundImageDelete]({ commit }, { shortcut }) {
    const result = await api.deleteBackgroundImage(shortcut);
    commit(new mutations.ProjectPlatformBgImgDelete(shortcut, result));
    commit(new mutations.ProjectUpdateLastSavedDate(shortcut));
  },

  async [ActionTypes.ArtboardRemove]({ commit }, { shortcut, artboardId }) {
    commit(new mutations.ArtboardRemove(shortcut, artboardId));
    const result = await api.artboardDelete(shortcut, artboardId);
    commit(new mutations.ProjectUpdateLastSavedDate(shortcut));
    return result;
  },

  async [ActionTypes.ArtboardArchive]({ commit }, { shortcut, artboardId }) {
    commit(new mutations.ArtboardRemove(shortcut, artboardId));
    const result = await api.artboardArchive(shortcut, artboardId);
    commit(new mutations.ProjectUpdateLastSavedDate(shortcut));
    return result;
  },

  async [ActionTypes.ArtboardUnarchive]({ commit }, { shortcut, artboardId }) {
    commit(new mutations.ArtboardRemove(shortcut, artboardId));
    const result = await api.artboardUnarchive(shortcut, artboardId);
    commit(new mutations.ProjectUpdateLastSavedDate(shortcut));
    return result;
  },

  async [ActionTypes.ArtboardRemoveSelection]({ state, commit }, { shortcut }) {
    const project = state.projects[shortcut];
    if (!project) return;

    const selectedArtboards = Object.keys(project.selectedArtboards);

    for (const artboardId of selectedArtboards) {
      commit(new mutations.ArtboardRemove(shortcut, artboardId));
    }

    await api.artboardsDelete(project.shortcut, selectedArtboards);

    commit(new mutations.ProjectUpdateLastModifiedDate(shortcut));
    commit(new mutations.ProjectUpdateLastSavedDate(shortcut));
  },

  async [ActionTypes.ArtboardArchiveSelection]({ state, commit }, { shortcut }) {
    const project = state.projects[shortcut];
    if (!project) return;

    const selectedArtboards = Object.keys(project.selectedArtboards);
    for (const artboardId of selectedArtboards) {
      commit(new mutations.ArtboardRemove(shortcut, artboardId));
    }

    for (const artboardId of selectedArtboards) {
      // eslint-disable-next-line no-await-in-loop
      await api.artboardArchive(project.shortcut, artboardId);
    }
    commit(new mutations.ProjectUpdateLastSavedDate(shortcut));
  },

  async [ActionTypes.ArtboardUnarchiveSelection]({ state, commit }, { shortcut }) {
    const project = state.projects[shortcut];
    if (!project) return;

    const selectedArtboards = Object.keys(project.selectedArtboards);
    for (const artboardId of selectedArtboards) {
      commit(new mutations.ArtboardRemove(shortcut, artboardId));
    }

    for (const artboardId of selectedArtboards) {
      // eslint-disable-next-line no-await-in-loop
      await api.artboardUnarchive(project.shortcut, artboardId);
    }
    commit(new mutations.ProjectUpdateLastSavedDate(shortcut));
  },

  async [ActionTypes.ArtboardReorder]({ commit }, { shortcut, artboardId, tailArtboardId }) {
    const result = await api.artboardReorder(shortcut, artboardId, tailArtboardId);
    commit(new mutations.ProjectUpdateLastSavedDate(shortcut));
    return result;
  },

  async [ActionTypes.ArtboardAdd]({ commit }, {
    shortcut, artboard, asset, url,
  }) {
    commit(new mutations.ArtboardAdd(shortcut, artboard, asset, url));
    commit(new mutations.ProjectUpdateLastSavedDate(shortcut));
  },

  async [ActionTypes.ArtboardAddMaster]({ commit }, { shortcut, artboardId, masterId }) {
    const result = await api.addRemoveArtboardMaster(shortcut, artboardId, masterId, true);
    commit(new mutations.ProjectUpdateLastSavedDate(shortcut));
    return result;
  },

  async [ActionTypes.ArtboardRemoveMaster]({ commit }, { shortcut, artboardId, masterId }) {
    const result = await api.addRemoveArtboardMaster(shortcut, artboardId, masterId);
    commit(new mutations.ProjectUpdateLastSavedDate(shortcut));
    return result;
  },

  async [ActionTypes.ArtboardActionSave]({ commit }, {
    shortcut, artboard, originalAction, action,
  }) {
    // store temporary id if action wasn't saved before
    const actionId = action.Id;
    const response = await saveAction(shortcut, artboard, originalAction, action);

    if (response.action.IsMaster) {
      commit(
        new mutations.MasterActionUpdate(
          shortcut,
          artboard.Id,
          response.action.ParentId,
          actionId,
          originalAction,
          response.action,
        ),
      );
    } else {
      commit(new mutations.ArtboardActionUpdate(shortcut, artboard.Id, actionId, originalAction, response.action));
    }
    commit(new mutations.ProjectUpdateLastSavedDate(shortcut));
  },

  async [ActionTypes.ArtboardActionRemove]({ commit }, { shortcut, artboard, action }) {
    commit(new mutations.ArtboardActionRemove(shortcut, artboard, action));

    const saved = isSavedAction(action);
    if (saved) {
      await api.hotspotDelete(shortcut, action.IsMaster, action.ParentId, action.Id);
    }
    commit(new mutations.ProjectUpdateLastSavedDate(shortcut));
  },

  async [ActionTypes.MasterActionSave]({ commit }, {
    shortcut, artboard, master, originalAction, action,
  }) {
    // store temporary id if action wasn't saved before
    const actionId = action.Id;
    const response = await saveAction(shortcut, artboard, originalAction, action);

    commit(
      new mutations.MasterActionUpdate(shortcut, artboard.Id, master.Id, actionId, originalAction, response.action),
    );
    commit(new mutations.ProjectUpdateLastSavedDate(shortcut));
  },

  async [ActionTypes.MasterActionRemove]({ commit }, { shortcut, master, action }) {
    commit(new mutations.MasterActionRemove(shortcut, master, action));

    const saved = isSavedAction(action);
    if (saved) {
      await api.hotspotDelete(shortcut, action.IsMaster, action.ParentId, action.Id);
    }
    commit(new mutations.ProjectUpdateLastSavedDate(shortcut));
  },

  async [ActionTypes.ProjectMasterCreate]({ commit }, { shortcut, bundle }) {
    const result = await api.masterCreate(shortcut, bundle.name, bundle.artboard.Id);
    commit(new mutations.ProjectMasterCreate(shortcut, result.Master, bundle.artboard, bundle.callback));
    commit(new mutations.ProjectUpdateLastSavedDate(shortcut));
  },

  async [ActionTypes.ProjectMasterRename]({ commit }, { shortcut, master, name }) {
    commit(new mutations.ProjectMasterRename(shortcut, master, name));
    await api.masterRename(shortcut, master.Id, name);
    commit(new mutations.ProjectUpdateLastSavedDate(shortcut));
  },

  async [ActionTypes.ProjectMasterCopy]({ commit }, {
    shortcut, master, artboard, callback,
  }) {
    const result = await api.masterCopy(shortcut, artboard.Id, master.Id);
    commit(new mutations.ProjectMasterCopy(shortcut, result.Master, artboard, callback));
  },

  async [ActionTypes.ProjectMasterDelete]({ commit }, { shortcut, master }) {
    commit(new mutations.ProjectMasterDelete(shortcut, master));
    await api.masterDelete(shortcut, master.Id);
  },

  async [ActionTypes.ArtboardSetHeaderFooterHeight]({ commit }, {
    shortcut, artboard, height, isHeader,
  }) {
    await api.setHeaderFooterHeight(shortcut, artboard, height, isHeader);
    commit(new mutations.ArtboardSetHeaderFooterHeight(shortcut, artboard, height, isHeader));
    commit(new mutations.ProjectUpdateLastSavedDate(shortcut));
  },
};

function getLastModified(rootGetters: any, shortcutId: string) {
  let lastModified = 0;
  const fsNode: FilesystemNodeShortcut = rootGetters.getFsNode(shortcutId);
  if (fsNode && fsNode.updated) {
    lastModified = fsNode.updated.getTime();
  }
  return lastModified;
}

export default actionsTree;
