
import Vue from "vue";
import { PropOptions } from "vue/types/options";

import { SsoResponse, AccountRowJs } from "@shared/models";

import { FilesystemNodeWorkspace } from "@/common/fs";
import { getSubinstanceAccounts } from "@/services/fs.service";
import { ManageUsersModel, ManageUserModel, AccessLevels } from "@/services/models/account";
import { UpdateFilesystemOwner, Fetch } from "@/store/fs/actionTypes";
import { AxShare } from "@/store/state";
import { AxShareUser } from "@/store/user/state";

import AxButton from "@/components/AxButton.vue";
import AxForm from "@/components/AxForm.vue";
import AxFormGroup from "@/components/AxFormGroup.vue";
import AxInput from "@/components/AxInput.vue";
import AxManageUsersSelect from "@/components/AxManageUsers/AxManageUsersSelect.vue";

const workspaceProp: PropOptions<FilesystemNodeWorkspace> = {
  type: Object,
  required: true,
};

const props = {
  workspace: workspaceProp,
  buttonColor: {
    type: String,
    default: "primary",
  },
  buttonValue: {
    type: String,
    default: "Update",
  },
};

export default Vue.extend({
  components: {
    AxButton,
    AxForm,
    AxFormGroup,
    AxManageUsersSelect,
    AxInput,
  },

  props,

  data() {
    const model: Partial<ManageUsersModel> = {
      users: [],
    };

    return {
      model,
      owner: "",
    };
  },

  computed: {
    placeholder(): string | undefined {
      return this.owner ? this.owner : undefined;
    },

    isSubInstance(): boolean {
      const { axShareConfig } = this.$store.state as AxShare;
      return axShareConfig !== null && axShareConfig.IsSubInstance;
    },

    currentUser(): AxShareUser {
      const { user } = this.$store.state as AxShare;
      return user;
    },

    isSubInstanceAuthor(): boolean {
      return this.$store.getters.isSubInstanceAuthor;
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      const { isOwner, ownerEmail, users } = this.workspace;
      const { userInfo } = this.currentUser;
      const { isSubInstance } = this;

      let filteredUsers: SsoResponse[] = [];

      if (isSubInstance) {
        const accounts = await getSubinstanceAccounts();
        filteredUsers = accounts.Items.filter(
          item => !item.IsDeactivated
            && item.Email !== ownerEmail
            && !(isOwner && userInfo && userInfo.userEmail === item.Email)
            && (item.Level === AccessLevels.Author
              || item.Level === AccessLevels.Admin
              || item.Level === AccessLevels.SuperAdmin),
        ).map(item => this.getUser(item) as SsoResponse);
      } else {
        filteredUsers = users.filter(item => item.user.userEmail !== ownerEmail).map(item => item.user);
      }

      this.model.users = filteredUsers.map(item => ({
        user: item,
      })) as ManageUserModel[];
    },

    getUser(account: AccountRowJs) {
      const user: Partial<SsoResponse> = {
        userId: account.Id,
        userEmail: account.Email,
        nickname: account.Nickname,
      };
      return user;
    },

    async submit() {
      if (this.owner) {
        await this.$store.dispatch(new UpdateFilesystemOwner(this.workspace.id, this.owner));
        await this.$store.dispatch(new Fetch(this.workspace));
        this.$emit("owner-changed");
      }
    },

    select(event: { selected: { user: SsoResponse }, deactivate: () => any }) {
      this.owner = event.selected.user.userEmail;
      event.deactivate();
    },
  },
});
