
import Vue from "vue";
import { Location } from "vue-router";

import { ROUTES } from "@/router";
import { ArtboardUploadInfo, ArtboardUploadBatch } from "@/store/uploads/state";

import { prop } from "./utils";

import AxArtboardUploadsItem from "@/components/AxArtboardUploadsItem.vue";
import AxButton from "@/components/AxButton.vue";
import AxIcon from "@/components/AxIcon.vue";
import AxPageNotification from "@/components/AxPageNotification.vue";

export default Vue.extend({
  components: {
    AxArtboardUploadsItem,
    AxButton,
    AxIcon,
    AxPageNotification,
  },

  props: {
    batch: prop<ArtboardUploadBatch>({
      required: true,
    }),
  },

  data() {
    return {
      show: true,
    };
  },

  computed: {
    projectName(): string {
      if (this.batch && this.batch.projectName) {
        return `to "${this.batch.projectName}"`;
      }
      return "";
    },

    uploadItems(): Record<string, ArtboardUploadInfo> {
      if (!this.batch) return {};
      return this.batch.contents;
    },

    toProjectOverview(): Location | undefined {
      if (!this.batch) return undefined;
      return { name: ROUTES["project.overview"].name, params: { shortcut: this.batch.shortcut } };
    },

    complete(): boolean {
      if (this.batch) {
        return this.$store.getters.batchIsCompleted(this.batch.id);
      }
      return false;
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },
  },
});
