
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      dragging: false,
    };
  },

  methods: {
    handleDragStart(e: DragEvent) {
      this.$emit("drag-start", e);
    },
    handleDragEnd(e: DragEvent) {
      this.dragging = false;
      this.$emit("drag-end", e);
    },
    handleDragEnter(e: DragEvent) {
      this.dragging = true;
      this.$emit("drag-enter", e);
    },
    handleDragOver(e: DragEvent) {
      this.dragging = true;
      let effect;
      if (e.dataTransfer) {
        try {
          // Handle browser bug
          effect = e.dataTransfer.effectAllowed;
        } catch (error) {
          // ignore
        }
        e.dataTransfer.dropEffect = effect === "move" || effect === "linkMove" ? "move" : "copy";
      }

      this.$emit("drag-over", e);
    },
    handleDragLeave(e: DragEvent) {
      this.dragging = false;
      this.$emit("drag-leave", e);
    },
    handleDrop(e: DragEvent) {
      this.dragging = false;
      if (!e.dataTransfer) {
        return;
      }
      const files = Array.from(e.dataTransfer.files);
      this.$emit("drop", files, e);
    },
  },
});
