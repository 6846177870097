
import Vue from "vue";

import { SetShowAllWorkspaces } from "@/store/settings/mutationTypes";
import { AxShare } from "@/store/state";

import AxHelp from "@/components/AxHelp.vue";
import AxToggleButton from "@/components/AxToggleButton.vue";

export default Vue.extend({
  components: {
    AxToggleButton,
    AxHelp,
  },

  props: {
    persist: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      local: false,
    };
  },

  computed: {
    showAllWorkspaces(): boolean {
      if (this.persist) {
        const { settings } = this.$store.state as AxShare;
        return settings && settings.showAllWorkspaces;
      }
      return this.local;
    },

    isSubInstanceAdmin(): boolean {
      return this.$store.getters.isSubInstanceAdmin;
    },
  },

  created() {
    this.$emit("input", this.showAllWorkspaces);
  },

  methods: {
    setShowAllWorkspaces(showAll: boolean) {
      if (this.persist) {
        this.$store.commit(new SetShowAllWorkspaces(showAll));
      } else {
        this.local = showAll;
      }
      this.$emit("input", this.showAllWorkspaces);
    },
  },
});
