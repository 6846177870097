import Vue, { VNode } from "vue";

import { pushNotificationsService, HubName, SignalRHubListeners } from "@/services/push.notifications.service";

import { prop } from "@/components/utils";

export default Vue.extend({
  props: {
    hub: {
      type: String,
      required: true,
    },

    listeners: prop<SignalRHubListeners>({
      type: Object,
      default: {},
    }),
  },

  async created() {
    await pushNotificationsService.addHubListeners(this.hub as HubName, this.listeners);
  },

  async beforeDestroy() {
    await pushNotificationsService.removeHubListeners(this.hub as HubName, this.listeners);
  },

  render(h): VNode {
    return h();
  },
});
