
import Vue from "vue";

import { DbConnectionError } from "@/common/axshare/errors";

import { prop } from "@/components/utils";

export default Vue.extend({
  props: {
    error: prop(DbConnectionError, {
      required: true,
    }),
  },

  data() {
    return {
      showError: false,
    };
  },

  computed: {
    errorMessage(): string {
      return this.error ? this.error.message : "";
    },

    detailsMessage(): string {
      return this.showError ? "Hide Details" : "Show Details";
    },
  },

  methods: {
    onDetailsClicked() {
      this.showError = !this.showError;
    },
  },
});
