
import Vue from "vue";

import WelcomeLayout from "@/layouts/WelcomeLayout.vue";

import AxIcon from "@/components/AxIcon.vue";

export default Vue.extend({
  components: {
    WelcomeLayout,
    AxIcon,

  },
});
