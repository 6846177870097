
import Vue from "vue";

export default Vue.extend({
  props: {
    dividerTop: {
      type: Boolean,
      default: false,
    },

    dividerBottom: {
      type: Boolean,
      default: false,
    },
  },
});
