
import Vue from "vue";

import { isElectron } from "@/common/environment";
import { orderByDescending } from "@/common/lib";
import { AxShare } from "@/store/state";
import { DismissUpload } from "@/store/uploads/mutationTypes";
import { ArtboardUploadBatch, ArtboardUploadStatus } from "@/store/uploads/state";

import AxArtboardUploadsPicker from "@/components/AxArtboardUploadsPicker.vue";
import AxArtboardUploadsProgress from "@/components/AxArtboardUploadsProgress.vue";

const AxArtboardUploadsDesktopTracker = () => import("@/components/AxArtboardUploadsDesktopTracker.vue");

export default Vue.extend({
  components: {
    AxArtboardUploadsDesktopTracker,
    AxArtboardUploadsPicker,
    AxArtboardUploadsProgress,
  },

  data() {
    return {
      isElectron,
    };
  },

  computed: {
    batch(): ArtboardUploadBatch | undefined {
      const { uploads } = this.$store.state as AxShare;
      const batches = Object.values(uploads.batches).filter(
        batch => batch && !batch.dismissed
      ) as ArtboardUploadBatch[];
      const lastBatch = orderByDescending(batches, batch => batch.created)[0];
      return lastBatch;
    },
    started(): boolean {
      if (this.batch) {
        const contents = Object.values(this.batch.contents);
        for (const item of contents) {
          if (item.status !== ArtboardUploadStatus.NotStarted) return true;
        }
      }
      return false;
    },
  },

  methods: {
    dismissBatch(id: string) {
      this.$store.commit(new DismissUpload(id));
    },
  },
});
