
import Vue, { VNode } from "vue";

export default Vue.extend({
  props: {
    interval: {
      type: Number,
      required: true,
    },

    handler: {
      type: Function,
      required: true,
    },

    immediate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      retryInterval: undefined as number | undefined,
    };
  },

  async created() {
    if (this.immediate) {
      await this.runHandler();
    }
    this.retryInterval = window.setInterval(this.runHandler, this.interval);
  },

  beforeDestroy() {
    clearInterval(this.retryInterval);
  },

  methods: {
    async runHandler() {
      try {
        await this.handler();
      } catch (e) {
        this.$emit("error", e);
      }
    },
  },

  render(h): VNode {
    return h();
  },
});
