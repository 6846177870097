import { AxNotificationOptions, AxMentionedNotificationOptions } from "@/common/notifications/AxNotificationOptions";
import { badgeDrawer } from "@/common/notifications/BadgeDrawer";
import { hasDesktopNotificationsPermission } from "@/common/notifications/permissions";
import {
  addNotification,
  addNotificationMuteItem,
  deleteLegacyEmailSubscriptions,
  deleteNotificationMuteItem,
  deleteSubscription, editNotification, getNotificationMuteItems, getUserGuestSubscriptions, getUserSubscriptions,
} from "@/services/notification.service";
import { NotificationStore } from "@/store/notifications/state";
import { AxShare } from "@/store/state";

import { ActionTree } from "../typed";

import {
  ActionPayloadMap, ActionTypes, ShowDesktopNotification,
} from "./actionTypes";
import * as mutations from "./mutationTypes";

const actions: ActionTree<NotificationStore, AxShare, ActionPayloadMap> = {

  async [ActionTypes.AddNotification](context, { model }) {
    const notification = await addNotification(model);
    context.commit(new mutations.AddNotification(notification));
  },

  async [ActionTypes.AddNotificationMuteItem](context, { model }) {
    const notificationMuteItem = await addNotificationMuteItem(model);
    context.commit(new mutations.AddNotificationMuteItem(notificationMuteItem));
  },

  async [ActionTypes.EditNotification](context, { model }) {
    const notification = await editNotification(model);
    context.commit(new mutations.EditNotification(notification));
  },

  async [ActionTypes.GetUserSubscriptions](context) {
    const userSubscriptions = await getUserSubscriptions();
    if (userSubscriptions) {
      context.commit(new mutations.SetUserSubscriptions(userSubscriptions.Items));
    }
  },

  async [ActionTypes.GetUserGuestSubscriptions](context) {
    const userGuestSubscriptions = await getUserGuestSubscriptions();
    if (userGuestSubscriptions) {
      context.commit(new mutations.SetUserGuestSubscriptions(userGuestSubscriptions.Items));
    }
  },

  async [ActionTypes.GetNotificationMuteItems](context) {
    const notificationMuteItemsJs = await getNotificationMuteItems();
    if (notificationMuteItemsJs) {
      context.commit(new mutations.SetNotificationMuteItems(notificationMuteItemsJs.Items));
    }
  },

  async [ActionTypes.DeleteUserSubscription](context, { notificationId }) {
    const response = await deleteSubscription(notificationId);
    if (response && response.success) {
      context.commit(new mutations.DeleteUserSubscription(notificationId));
    }
  },

  async [ActionTypes.DeleteUserGuestSubscription](context, { notificationId }) {
    const response = await deleteSubscription(notificationId);
    if (response && response.success) {
      context.commit(new mutations.DeleteUserGuestSubscription(notificationId));
    }
  },

  async [ActionTypes.DeleteNotificationMuteItem](context, { notificationId }) {
    const response = await deleteNotificationMuteItem(notificationId);
    if (response && response.success) {
      context.commit(new mutations.DeleteNotificationMuteItem(notificationId));
    }
  },

  async [ActionTypes.DeleteLegacyEmailSubscriptions](context) {
    const response = await deleteLegacyEmailSubscriptions();
    if (response && response.success) {
      const { notifications } = context.state;
      context.commit(new mutations.SetUserSubscriptions(Object.values(notifications)
        .filter(x => x.SlackChannel || x.MsTeamsUrl)));
    }
  },

  async [ActionTypes.ShowDesktopNotification](_context, { options }) {
    let iconUrl: string | undefined;

    const title = options.title ? options.title : "Axure";

    if (options.iconUrl) {
      iconUrl = options.iconUrl;
    }

    if (!(await hasDesktopNotificationsPermission())) {
      return;
    }

    try {
      const notification = new Notification(title, {
        body: options.body,
        icon: iconUrl,
        tag: options.tag,
        renotify: true,
      });

      if (options.onclick) {
        notification.onclick = () => {
          if (options.onclick) {
            options.onclick(notification);
          }
        };
      }
    } catch (e) {
      console.error("Failed to display desktop notification", e);
    }
  },
  async [ActionTypes.ShowMentionedDesktopNotification](context, { options }) {
    const title = `New message from ${options.authorName || options.authorEmail}`;
    const notificationOptions: AxNotificationOptions = getNotificationOptions(title, options);

    await context.dispatch(new ShowDesktopNotification(notificationOptions));
  },

  async [ActionTypes.ShowWorkspaceInvitedDesktopNotification](context, { options }) {
    const title = `New workspace invitation from ${options.authorName || options.authorEmail}`;
    const notificationOptions: AxNotificationOptions = getNotificationOptions(title, options);

    await context.dispatch(new ShowDesktopNotification(notificationOptions));
  },
};

function getNotificationOptions(title: string, options: AxMentionedNotificationOptions): AxNotificationOptions {
  const notificationOptions: AxNotificationOptions = {
    title,
    body: options.body || "",
    tag: options.tag,
    onclick: options.onclick,
  };

  if (options.authorProfileImage) {
    notificationOptions.iconUrl = options.authorProfileImage;
  } else {
    const iconText = options.authorName ? options.authorName[0].toUpperCase() : options.authorEmail[0].toUpperCase();
    const base64Data = badgeDrawer.drawBadgeAsBase64({
      text: iconText,
      backgroundColor: "#00a2d8",
      font: "40px Source Sans Pro,sans-serif",
      radius: 40,
      textOffsetTop: 5,
    });

    notificationOptions.iconUrl = base64Data;
  }

  return notificationOptions;
}

export default actions;
