
import Vue from "vue";

export default Vue.extend({
  props: {
    color: {
      type: String,
      default: "#47b469",
    },

    pot: {
      type: String,
      default: "#fff",
    },

    border: {
      type: String,
      default: "#333",
    },
  },
});
