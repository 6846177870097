import { RouteConfig } from "vue-router";

import { AxShareConfigModel } from "@shared/models";

import router from "@/router";

export enum RouteStatus {
  NotConfigured,
  Reconfigured,
  Ok,
}

export let routesConfigured = false;
export let routeStatus: RouteStatus = RouteStatus.NotConfigured;

interface LazyRoute {
  fetch: () => Promise<RouteConfig[]>
  condition: () => boolean;
  parent: string;
}

export async function configureRoutes(axShareConfig: AxShareConfigModel | null) {
  if (routesConfigured || !axShareConfig) return;

  const lazyRoutes: LazyRoute[] = [
    {
      fetch: () => import(
        /* webpackChunkName: "admin" */
        "@/router/admin"
      ).then(d => d.default.config),
      condition: () => axShareConfig.IsSubInstance,
      parent: "fs.manage",
    },
    {
      fetch: () => import(
        /* webpackChunkName: "setup" */
        "@/router/setup"
      ).then(d => d.default.config),
      condition: () => axShareConfig.AxShareOnPrem,
      parent: "/",
    },
    {
      fetch: () => import(
        /* webpackChunkName: "enterprise" */
        "@/router/enterprise"
      ).then(d => d.default.config),
      condition: () => axShareConfig.SubInstancesSupported,
      parent: "/",
    },
  ];

  const configs: Record<string, RouteConfig[]> = {};
  const lazyRouteTasks: Promise<void>[] = [];
  for (const lazyRoute of lazyRoutes) {
    if (lazyRoute.condition()) {
      const task = lazyRoute.fetch().then(entries => {
        const newConfig = configs[lazyRoute.parent] || (configs[lazyRoute.parent] = []);
        newConfig.push(...entries);
      });
      lazyRouteTasks.push(task);
    }
  }

  if (lazyRouteTasks.length > 0) {
    await Promise.all(lazyRouteTasks);
    addToRouter(configs);
    routeStatus = RouteStatus.Reconfigured;
  }
  routesConfigured = true;
}

export function setRouteStatus(status: RouteStatus) {
  routeStatus = status;
}

function addToRouter(routes: Record<string, RouteConfig[]>) {
  for (const routeName in routes) {
    if (Object.prototype.hasOwnProperty.call(routes, routeName)) {
      const routeConfigs = routes[routeName];
      for (const routeConfig of routeConfigs) {
        router.addRoute(routeName, routeConfig);
      }
    }
  }
}
