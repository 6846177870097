
import Vue from "vue";

export default Vue.extend({
  props: {
    signin: {
      type: Boolean,
      default: false,
    },
    isRp10: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    src() {
      // eslint-disable-next-line global-require
      if (this.isRp10) return require("@/assets/img/login_left_banner-web-2x.png");
      if (this.signin) {
        // eslint-disable-next-line global-require
        return require("@/assets/img/rp-signin-9.png");
      }
      // eslint-disable-next-line global-require
      return require("@/assets/img/rp-signup-9.png");
    },
  },
});
