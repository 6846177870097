import { RouteConfig } from "vue-router";

import { isElectron } from "@/common/environment";
import { wellKnownRoutes } from "@/common/axshare";

const AxUploadExportedBatch = () => import("@/components/AxUploadExportedBatch.vue");

export type Keys = "uploadfromsketch";

const routes: { readonly [R in Keys]: RouteConfig } = {
  uploadfromsketch: {
    ...wellKnownRoutes.uploadFromSketch,
    component: AxUploadExportedBatch,
  },
};

const config = [];
if (isElectron) {
  config.push(routes.uploadfromsketch);
}

export default {
  routes,
  config,
};
