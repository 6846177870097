
import Vue from "vue";

import AxIcon from "@/components/AxIcon.vue";
import AxCactus from "@/components/icons/AxCactus.vue";

export default Vue.extend({
  props: {
    components: {
      type: Object,
      default() {
        return {
          AxIcon,
          AxCactus,
        };
      },
    },

    title: {
      type: String,
      default: "",
    },

    subTitle: {
      type: String,
      default: "",
    },
  },
});
