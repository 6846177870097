
import Vue, { VNode } from "vue";

const EVENTS = ["online", "offline", "load"];

export default Vue.extend({
  data() {
    return {
      supportsStatusCheck: typeof window.navigator.onLine !== "undefined",
      isOnline: false,
    };
  },

  created() {
    this.updateStatus(true);
    EVENTS.forEach(event => window.addEventListener(event, this.statusChanged));
  },

  beforeDestroy() {
    EVENTS.forEach(event => window.removeEventListener(event, this.statusChanged));
  },

  methods: {
    statusChanged() {
      this.updateStatus();
    },

    updateStatus(initial = false) {
      const before = this.isOnline;
      this.isOnline = this.supportsStatusCheck ? window.navigator.onLine : true;
      const changed = before !== this.isOnline;
      if (!initial && changed) {
        this.$emit("change", this.isOnline);
      }
    },
  },

  render(h): VNode {
    return (
      (this.$scopedSlots.default &&
        ((this.$scopedSlots.default({
          isOnline: this.isOnline,
        }) as unknown) as VNode)) ||
      h("")
    );
  },
});
