import { Payload } from "vuex";

import { PayloadMap } from "./typed";

export enum ActionTypes {
  Initialize = "Initialize",
  LoadConfig = "Load Config",
  SetupAccountService = "Setup Account Service",
  Authenticate = "Authenticate",
  Logout = "Logout",
  ApiCall = "[API Call] Api Call",
  ApiCallRetry = "[API Call] Retry",
  ApiCallClear = "[API Call] Clear",
  LoadApiVersionInfo = "Load Api Version Info",
}

export interface ApiCallOptions {
  id?: string;
  action: () => any;
  onFailure?: (error?: Error | unknown) => any;
}

export class LoadConfig implements Payload {
  public readonly type = ActionTypes.LoadConfig;
  constructor(public readonly force: boolean = false) { }
}

export class ApiCall implements Payload {
  public readonly type = ActionTypes.ApiCall;
  constructor(public readonly options: ApiCallOptions) { }
}

export class ApiCallRetry implements Payload {
  public readonly type = ActionTypes.ApiCallRetry;
  constructor(public readonly apiCallId: string) { }
}

export class ApiCallClear implements Payload {
  public readonly type = ActionTypes.ApiCallClear;
}

export class SetupAccountService implements Payload {
  public readonly type = ActionTypes.SetupAccountService;
  constructor(
    public readonly force: boolean = false,
    public readonly authToken = "",
    public readonly useGet = false,
  ) { }
}

export class Initialize implements Payload {
  public readonly type = ActionTypes.Initialize;
  constructor(
    public readonly force: boolean = false,
    public readonly apiUrl = "",
    public readonly authToken = "",
    public readonly useGet = false,
  ) { }
}

export class Authenticate implements Payload {
  public readonly type = ActionTypes.Authenticate;
}

export class Logout implements Payload {
  public readonly type = ActionTypes.Logout;
  constructor(public readonly onSuccess?: () => Promise<any>, public readonly onFailure?: () => Promise<any>) { }
}

export class LoadApiVersionInfo implements Payload {
  public readonly type = ActionTypes.LoadApiVersionInfo;
}

export interface ActionPayloadMap extends PayloadMap<ActionTypes> {
  [ActionTypes.LoadConfig]: LoadConfig;
  [ActionTypes.ApiCall]: ApiCall;
  [ActionTypes.ApiCallRetry]: ApiCallRetry;
  [ActionTypes.SetupAccountService]: SetupAccountService;
  [ActionTypes.Initialize]: Initialize;
  [ActionTypes.Authenticate]: Authenticate;
  [ActionTypes.Logout]: Logout;
  [ActionTypes.LoadApiVersionInfo]: LoadApiVersionInfo;
}
