import { GetterTree } from "vuex";

import { resolveApiBaseUrl } from "@/common/axshare/api";
import { fileSize } from "@/common/lib";
import { AsyncActionState } from "@/store/async-action";
import { AxShare } from "@/store/state";

const getterTree: GetterTree<AxShare, AxShare> = {
  actionState(state) {
    return (actionType: string) => state.actions[actionType] || AsyncActionState.NotStarted;
  },
  actionStateFetching(_, getters) {
    return (actionType: string) => getters.actionState(actionType) === AsyncActionState.Fetching;
  },

  maxFileSizeHumanized(state) {
    if (state.axShareConfig) {
      const { MaxFileSize } = state.axShareConfig;
      return fileSize(MaxFileSize);
    }
    return "unknown";
  },

  headerMinimized(state) {
    return state && state.route && state.route.meta && !!state.route.meta.headerMinimized;
  },

  isSubInstance(state) {
    return !!state.axShareConfig && state.axShareConfig.IsSubInstance;
  },

  failuredApiCallsIds(state) {
    const { apiCalls } = state;
    return Object.keys(apiCalls).filter(key => {
      const apiCall = apiCalls[key];
      return !!apiCall && apiCall.status === AsyncActionState.Failure;
    });
  },

  appConfigured(state) {
    const { axShareConfig } = state;

    // note that '===' check is required here
    return !!axShareConfig && axShareConfig.NeedsSetup === false && axShareConfig.NeedsDbUpgrade === false;
  },

  onPremAppConfigured(state, getters) {
    const { axShareConfig } = state;

    // note that '===' check is required here
    return !!axShareConfig && axShareConfig.AxShareOnPrem === true && getters.appConfigured;
  },

  apiUrl(state) {
    return resolveApiBaseUrl(state.axShareConfig);
  },

  isOnPrem({ axShareConfig }) {
    return !!axShareConfig && axShareConfig.AxShareOnPrem === true;
  },
};

export default getterTree;
