
import Vue from "vue";

import { equalsIgnoringCase, memoizer } from "@/common/lib";
import { simpleHash } from "@/common/utils";

import AxIcon from "@/components/AxIcon.vue";

/* eslint-disable key-spacing */
const iconsToLetter = {
  octopus:  ["a", "k", "u"],
  rabbit:   ["b", "l", "v"],
  seahorse: ["c", "m", "w"],
  fox:      ["d", "n", "x"],
  lion:     ["e", "o", "y"],
  swan:     ["f", "p", "z"],
  kangaroo: ["g", "q"],
  bear:     ["h", "r"],
  ram:      ["i", "s"],
  toucan:   ["j", "t"],
};
/* eslint-enable key-spacing */

const lettersToIcon = Object.entries(iconsToLetter).reduce((acc, [icon, letters]) => {
  for (let i = 0; i < letters.length; i++) {
    const letter = letters[i];
    acc[letter] = icon;
  }
  return acc;
}, {} as Record<string, string>);

const getIconName = memoizer((name: string) => {
  const hash = simpleHash(name);
  // 26 letters in alphabet
  // 97 - is a charcode for 'a'
  const letter = String.fromCharCode(Math.abs(hash % 26) + 97);
  return lettersToIcon[letter];
});

export default Vue.extend({
  components: {
    AxIcon,
  },

  props: {
    title: {
      type: String,
      default: "",
    },

    emailName: {
      type: String,
      default: "",
    },
  },

  computed: {
    firstLetter(): string {
      if (!this.emailName) return "";
      return this.emailName.charAt(0).toLowerCase();
    },

    isAnonymous(): boolean {
      return equalsIgnoringCase(this.emailName, "Anonymous");
    },

    iconName(): string {
      if (this.isAnonymous) return "avatars/bear";
      if (this.emailName) {
        const icon = getIconName(this.emailName);
        if (icon) {
          return `avatars/${icon}`;
        }
      }
      return "avatars/bear";
    },
  },
});
