import { Payload } from "vuex";

import { AxShareConfigModel, VersionInfoResponse } from "@shared/models";

import { AsyncActionState } from "@/store/async-action";

import { PayloadMap } from "./typed";

export enum MutationTypes {
  Reset = "Reset",
  QueryAction = "Query Action",
  ConfigSet = "Config Set",
  SetupAccountService = "Setup Account Service",
  InitializationStatus = "Set Initialization Status",
  SetNavigationError = "SetNavigationError",
  ApiCallAdd = "Api Call Add",
  ApiCallRemove = "Api Call Remove",
  ApiCallSetStatus = "Api Call Set Status",
  ApiCallRetry = "Api Call Retry",
  ApiVersionSet = "Api Version Set",
  ApiCallClear = "Api Call Clear",
}

export class Reset implements Payload {
  public readonly type = MutationTypes.Reset;
}

export class QueryAction implements Payload {
  public readonly type = MutationTypes.QueryAction;
  constructor(public readonly state: AsyncActionState, public action: string) { }
}

export class Fetching extends QueryAction {
  constructor(public action: string) {
    super(AsyncActionState.Fetching, action);
  }
}

export class Success extends QueryAction {
  constructor(public action: string) {
    super(AsyncActionState.Success, action);
  }
}

export class Failure extends QueryAction {
  constructor(public action: string) {
    super(AsyncActionState.Failure, action);
  }
}

export class ConfigSet implements Payload {
  public readonly type = MutationTypes.ConfigSet;
  constructor(public config: AxShareConfigModel) { }
}

export class SetupAccountService implements Payload {
  public readonly type = MutationTypes.SetupAccountService;
  constructor(
    public readonly accountServiceHost: string,
    public readonly axShareHost: string,
    public readonly authToken = "",
    public readonly useGet = false,
  ) { }
}

export class InitializationStatus implements Payload {
  public readonly type = MutationTypes.InitializationStatus;
  constructor(public status: "success" | "failure", public error?: Error | unknown) { }
}

export class SetNavigationError implements Payload {
  public readonly type = MutationTypes.SetNavigationError;
  constructor(public error?: Error | unknown) { }
}

export class ApiCallAdd implements Payload {
  public readonly type = MutationTypes.ApiCallAdd;
  constructor(
    public readonly apiCallId: string,
    public readonly action: () => any,
    public readonly onFailure?: (error?: Error | unknown) => any,
  ) { }
}

export class ApiCallRemove implements Payload {
  public readonly type = MutationTypes.ApiCallRemove;
  constructor(public apiCallId: string) { }
}

export class ApiCallClear implements Payload {
  public readonly type = MutationTypes.ApiCallClear;
}

export class ApiCallSetStatus implements Payload {
  public readonly type = MutationTypes.ApiCallSetStatus;
  constructor(public apiCallId: string, public status: AsyncActionState) { }
}

export class ApiCallRetry implements Payload {
  public readonly type = MutationTypes.ApiCallRetry;
  constructor(public apiCallId: string) { }
}

export class ApiVersionSet implements Payload {
  public readonly type = MutationTypes.ApiVersionSet;
  constructor(public apiVersionInfo: VersionInfoResponse) { }
}

export interface MutationPayloadMap extends PayloadMap<MutationTypes> {
  [MutationTypes.Reset]: Reset;
  [MutationTypes.QueryAction]: QueryAction;
  [MutationTypes.ConfigSet]: ConfigSet;
  [MutationTypes.SetupAccountService]: SetupAccountService;
  [MutationTypes.InitializationStatus]: InitializationStatus;
  [MutationTypes.SetNavigationError]: SetNavigationError;
  [MutationTypes.ApiCallAdd]: ApiCallAdd;
  [MutationTypes.ApiCallRemove]: ApiCallRemove;
  [MutationTypes.ApiCallClear]: ApiCallClear;
  [MutationTypes.ApiCallSetStatus]: ApiCallSetStatus;
  [MutationTypes.ApiCallRetry]: ApiCallRetry;
  [MutationTypes.ApiVersionSet]: ApiVersionSet;
}
