
import Vue, { VNode } from "vue";
import { PropOptions } from "vue/types/options";

const vNodeProp: PropOptions<VNode> = {
  type: Object,
  required: false,
  default: undefined,
};

const props = {
  vnode: vNodeProp,
};

export default Vue.extend({
  functional: true,
  props,
  render(h, ctx) {
    if (ctx.props.vnode) {
      return ctx.props.vnode;
    }
    const children = ctx.scopedSlots.default && ctx.scopedSlots.default({});
    return children || h();
  },
});
