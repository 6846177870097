
import Vue from "vue";

import { arrayProp } from "@/components/utils";

const props = {
  errors: arrayProp<string>(),
  color: {
    type: String,
    default: "#e92525",
  },
};

export default Vue.extend({
  props,
  computed: {
    errorStyle(): Partial<CSSStyleDeclaration> {
      return {
        color: this.color,
      };
    },
  },
});
