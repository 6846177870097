
import Vue from "vue";
import { PropValidator } from "vue/types/options";

import { FilesystemNodeTypes } from "@/common/fs";

import AxDialog from "@/components/AxDialog.vue";
import AxFilesystemNodeClone from "@/components/AxFilesystemNodeClone.vue";
import AxFilesystemNodeDelete from "@/components/AxFilesystemNodeDelete.vue";
import AxFilesystemNodeMove from "@/components/AxFilesystemNodeMove.vue";
import AxFilesystemNodeRename from "@/components/AxFilesystemNodeRename.vue";
import AxProjectFileUpload from "@/components/AxProjectFileUpload.vue";
import { FilesystemSearchItem } from "@/components/types/AxSearch";

interface DialogOptions {
  show: boolean;
  submit?: () => void;
  cancel?: () => void;
}
export interface ShortcutActionsOptions {
  move: DialogOptions;
  delete: DialogOptions;
  clone: DialogOptions;
  rename: DialogOptions;
  upload: DialogOptions;
  folderId: string;
  items: (FilesystemNodeTypes | FilesystemSearchItem)[];
}

const optionsProp: PropValidator<ShortcutActionsOptions> = {
  type: Object,
  required: true,
};

export default Vue.extend({
  components: {
    AxDialog,
    AxFilesystemNodeClone,
    AxFilesystemNodeDelete,
    AxFilesystemNodeMove,
    AxFilesystemNodeRename,
    AxProjectFileUpload,
  },
  props: {
    options: optionsProp,
  },
});
