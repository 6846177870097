import Vue from "vue";
import Router, { RouteConfig } from "vue-router";

import fsRouter, { Keys as fs } from "@/router/fs";
import homeRouter, { Keys as home } from "@/router/home";
import projectRouter, { Keys as project } from "@/router/project";

import { isDesktopLocal } from "@/common/environment";
import * as guards from "./guards";
import store from "@/store";
import { SetNavigationError } from "@/store/mutationTypes";

Vue.use(Router);

type Keys = home | fs | project;

export const ROUTES: { readonly [R in Keys]: RouteConfig } = {
  ...homeRouter.routes,
  ...fsRouter.routes,
  ...projectRouter.routes,
};

const router = new Router({
  mode: isDesktopLocal ? "hash" : "history",
  base: process.env.VUE_APP_BASE_URL,
  routes: [...homeRouter.config],
});

guards.init(router);
guards.auth(router);

router.onError(error => {
  try {
    store.commit(new SetNavigationError(error));
  } catch {
    console.error("Error occurred when trying to handle router navigation error");
    console.error(error);
  }
});

export default router;
