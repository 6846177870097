import { GetterTree } from "vuex";

import { ShortcutJs } from "@shared/models";

import { prototypeUrls, PrototypeUrls } from "@/common/axshare";
import {
  FilesystemNode,
  FilesystemNodeShortcut,
  FilesystemNodeType,
  FilesystemNodeTypes,
  FilesystemNodeWithContents,
  FilesystemNodeWorkspace,
  isShortcutNode,
  isShortcutSearchNode,
  WorkspaceRights,
  computeWorkspaceRights,
} from "@/common/fs";
import { find } from "@/common/lib";
import { AxShareFilesystem, rootNode } from "@/store/fs/state";
import { AxShare } from "@/store/state";

const getterTree: GetterTree<AxShareFilesystem, AxShare> = {
  current(_, getters, rootState): FilesystemNodeWithContents {
    const { route } = rootState;
    if (route && route.params) {
      const { id, workspaceId, shortcut } = route.params;
      const node: FilesystemNodeTypes =
        getters.getFsNode(id) || getters.getFsNode(workspaceId) || getters.getFsNode(shortcut);
      if (node) {
        const contents: FilesystemNodeTypes[] = getters.nodeContents(node.id);
        return {
          node,
          contents,
        };
      }
    }
    return {
      node: rootNode,
      contents: [],
    };
  },

  navigating(state: AxShareFilesystem, _, rootState): boolean {
    const { route } = rootState;
    if (!route) return false;
    const { id, workspaceId } = route.params;
    return state.navigating[id] === true || state.navigating[workspaceId] === true;
  },

  navigatingError(state: AxShareFilesystem, _, rootState): boolean {
    const { route } = rootState;
    if (!route) return false;
    const { id, workspaceId } = route.params;
    return !!state.navigatingError[id] || !!state.navigatingError[workspaceId];
  },

  isRoot(_, getters): boolean {
    const current: FilesystemNodeWithContents = getters.current;
    return current.node.id === rootNode.id;
  },

  prototypeUrls(state, _, rootState) {
    return (shortcut: FilesystemNodeShortcut | ShortcutJs | string) => {
      if (typeof shortcut === "string") {
        const project = state.nodes[shortcut];
        if (project && project.type === FilesystemNodeType.Shortcut) {
          return prototypeUrls(rootState.axShareConfig, project);
        }
      } else if (isShortcutNode(shortcut) || isShortcutSearchNode(shortcut)) {
        return prototypeUrls(rootState.axShareConfig, shortcut);
      } else {
        return prototypeUrls(rootState.axShareConfig, shortcut.PrototypeUrl, shortcut.Shortcut);
      }
    };
  },

  currentPrototypeUrls(_, getters): PrototypeUrls | undefined {
    const current: FilesystemNodeTypes = getters.current.node;
    if (current.type === FilesystemNodeType.Shortcut) {
      return getters.prototypeUrls(current);
    }
  },

  getFsNode(state) {
    return (id: string | undefined) => id && state.nodes[id.toLowerCase()];
  },

  getNodeByFolderId(_, getters) {
    return (id: string) => getters.getFsNode(id) || getters.getWorkspaceByRootFolderId(id);
  },

  nodeContents(state) {
    return (id: string) => {
      if (!id) return [];
      const contentNodes = state.contents[id.toLowerCase()];
      if (!contentNodes) return [];
      return Object.values(state.nodes).filter(node => node && contentNodes.some(content => node.id === content));
    };
  },

  filesystemPath(_, getters) {
    const maxDepth = 100;
    const path: FilesystemNode[] = [];
    const current: FilesystemNodeWithContents = getters.current;
    let node: FilesystemNode | undefined = current.node;
    if (!node) return path;
    let depth = 0;
    while (node !== undefined && depth < maxDepth) {
      path.push(node);
      node = node.parent;
      depth++;
    }
    return path.reverse();
  },

  currentFolderId(_, getters) {
    const currentNode: FilesystemNodeWithContents = getters.current;
    if (!currentNode) return undefined;
    const { node } = currentNode;
    if (node.type === FilesystemNodeType.Workspace) {
      return node.rootFolderId;
    }
    if (node.type === FilesystemNodeType.Folder) {
      return node.id;
    }
  },

  workspaces(state) {
    return getNodesOfType(state, FilesystemNodeType.Workspace);
  },

  invitations(state) {
    return getNodesOfType(state, FilesystemNodeType.Invitation);
  },

  shortcuts(state) {
    return getNodesOfType(state, FilesystemNodeType.Shortcut);
  },

  folders(state) {
    return getNodesOfType(state, FilesystemNodeType.Folder);
  },

  defaultWorkspace(_, getters) {
    return find(getters.workspaces, (ws: FilesystemNodeWorkspace) => ws.isDefault);
  },

  getWorkspaceByRootFolderId(state) {
    return (folderId: string) => {
      for (const nodeId in state.nodes) {
        if (Object.prototype.hasOwnProperty.call(state.nodes, nodeId)) {
          const node = state.nodes[nodeId];
          if (node && node.type === FilesystemNodeType.Workspace && node.rootFolderId === folderId) {
            return node;
          }
        }
      }
    };
  },

  tryFindParent(state) {
    return (id: string) => {
      const node = state.nodes[id.toLowerCase()];
      if (node) return node.parent;
    };
  },

  findWorkspace(state, getters) {
    return (id: string) => {
      const node = state.nodes[id.toLowerCase()];
      if (!node) return undefined;
      if (node.type === FilesystemNodeType.Workspace) return node;
      if (node.parent) return getters.findWorkspace(node.parent.id);
    };
  },

  getClosestFolderId(state, getters) {
    return (id: string) => {
      const node = state.nodes[id.toLowerCase()];
      if (!node) return undefined;
      if (node.type === FilesystemNodeType.Folder) return node.id;
      if (node.type === FilesystemNodeType.Workspace) return node.rootFolderId;
      if (node.parent) return getters.getClosestFolderId(node.parent.id);
    };
  },

  workspaceRights(_state, _getters, rootState, rootGetters) {
    return (workspace: FilesystemNodeWorkspace): WorkspaceRights => {
      const userInfo = rootState.user.userInfo;
      if (!userInfo) throw new Error("Can't compute workspace rights when user profile isn't available.");
      const { isSubInstance, hasAuthorRights, isAdminUser } = rootGetters;

      return computeWorkspaceRights(workspace, {
        userInfo,
        hasAuthorRights,
        isAdminUser,
        isSubInstance,
      });
    };
  },
};

const getNodesOfType = (state: AxShareFilesystem, type: FilesystemNodeType) => {
  const initial: { [id: string]: FilesystemNodeTypes } = {};
  return Object.values(state.nodes).reduce((acc, node) => {
    if (node && node.type === type) {
      acc[node.id] = node;
    }
    return acc;
  }, initial);
};

export default getterTree;
