
import Vue from "vue";

import AxIcon from "@/components/AxIcon.vue";
import AxAxureLogo from "@/components/icons/AxAxureLogo.vue";

export default Vue.extend({
  components: {
    AxIcon,
    AxAxureLogo,
  },
  created() {
    document.body.classList.add("body-fluid");
    document.body.classList.add("rp-auth-bg");
  },
});
