import { DrawBadgeParams } from "@/desktop/types";

export class BadgeDrawer {
  private readonly defaultOptions: Required<DrawBadgeParams> = {
    radius: 8,
    backgroundColor: "#ff0000",
    textColor: "#fff",
    text: "",
    strokeWidth: 0,
    strokeColor: "",
    font: "12px Arial",
    textOffsetTop: 1,
  };

  public drawBadgeAsBase64(drawerOptions: DrawBadgeParams): string {
    const options: Required<DrawBadgeParams> = { ...this.defaultOptions, ...drawerOptions };

    // reduce radius by 1px, so it will fit into our canvas
    const radius = options.radius;
    const size = radius * 2;
    const img: HTMLCanvasElement = document.createElement("canvas");
    img.width = size;
    img.height = size;
    const context = img.getContext("2d");

    if (!context) {
      return "";
    }

    context.clearRect(0, 0, size, size);
    context.fillStyle = options.backgroundColor;
    context.beginPath();
    context.arc(radius, radius, radius, 0, Math.PI * 2);
    context.fill();
    if (options.strokeWidth) {
      context.lineWidth = options.strokeWidth;
      context.strokeStyle = options.strokeColor;
      context.stroke();
    }
    context.font = options.font;
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillStyle = options.textColor;
    context.fillText(options.text, radius, radius + options.textOffsetTop);

    const base64imageURL = img.toDataURL();
    return base64imageURL;
  }
}

export const badgeDrawer = new BadgeDrawer();
