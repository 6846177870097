import { ChangeAccountInfoModel } from "@/services/models/account";
import { Payload, PayloadMap } from "@/store/typed";

export enum ActionTypes {
  GetUserInfo = "[User] Get Info",
  GetUserProfile = "[User] Get Profile",
  ChangeAccountInfo = "[User] Change Account Info",
  SetUserTimeZone = "[User] Set Time Zone",
}

export class GetUserInfo implements Payload {
  public static readonly type = ActionTypes.GetUserInfo;
  public readonly type = GetUserInfo.type;
}

export class GetUserProfile implements Payload {
  public static readonly type = ActionTypes.GetUserProfile;
  public readonly type = GetUserProfile.type;
}

export class ChangeAccountInfo implements Payload {
  public static readonly type = ActionTypes.ChangeAccountInfo;
  public readonly type = ChangeAccountInfo.type;
  constructor(public model: ChangeAccountInfoModel) { }
}

export class SetUserTimeZone implements Payload {
  public static readonly type = ActionTypes.SetUserTimeZone;
  public readonly type = SetUserTimeZone.type;
  constructor(public timeZone?: string) { }
}

export interface ActionPayloadMap extends PayloadMap<ActionTypes> {
  [ActionTypes.GetUserInfo]: GetUserInfo;
  [ActionTypes.GetUserProfile]: GetUserProfile;
  [ActionTypes.ChangeAccountInfo]: ChangeAccountInfo;
  [ActionTypes.SetUserTimeZone]: SetUserTimeZone;
}
