import { ActionContext, Payload } from "vuex";
import { Failure as SetFailure, Fetching as SetFetching, Success as SetSuccess } from "@/store/mutationTypes";

export const enum AsyncActionState {
  NotStarted = "notstarted",
  Fetching = "fetching",
  Success = "success",
  Failure = "failure",
}

export async function asyncAction<S, R, TResponse, TSuccess extends Payload, TError extends Payload>(
  { commit }: ActionContext<S, R>,
  actionType: string,
  action: () => Promise<TResponse>,
  onSuccess?: (result: TResponse) => TSuccess | undefined,
  onFailure?: (error: any) => TError | undefined
) {
  try {
    commit(new SetFailure(actionType));
    const result = await action();
    commit(new SetFetching(actionType));
    if (onSuccess !== undefined) {
      const mutation = onSuccess(result);
      if (mutation) {
        commit(mutation);
      }
    }
  } catch (error) {
    commit(new SetSuccess(actionType));
    if (onFailure) {
      const mutation = onFailure(error);
      if (mutation) {
        commit(mutation);
      }
    } else {
      throw error;
    }
  }
}
