export interface AxShareProject {
  iframeBridgeProps: Partial<Record<string, IframeBridgeProps>>;
}

export interface IframeBridgeProps {
  iframe?: HTMLIFrameElement;
  iframeTargetOrigin?: string;
  iframeReady: boolean;
  iframeBridgeReady: boolean;
}

export const enum IframeBridgeTarget {
  Inspect = "inspect",
  Preview = "preview",
}

export function EmptyIframeBridgeProp(): IframeBridgeProps {
  return {
    iframe: undefined,
    iframeTargetOrigin: undefined,
    iframeReady: false,
    iframeBridgeReady: false,
  };
}

const state = (): AxShareProject => ({
  iframeBridgeProps: {},
});

export default state;
