import { isApple } from "@/common/lib";

const DESKTOP_APP_DOWNLOAD_MAC = "https://www.axure.cloud/desktop/mac/AxureCloud.dmg";
const DESKTOP_APP_DOWNLOAD_WINDOWS = "https://www.axure.cloud/desktop/win/AxureCloud.exe";
export const DOCS_DISCUSSIONS_LINK = "https://docs.axure.com/axure-cloud/basics/discussions/";

export const DESKTOP_APP_DOWNLOAD_URL = isApple ? DESKTOP_APP_DOWNLOAD_MAC : DESKTOP_APP_DOWNLOAD_WINDOWS;

export const SKETCH_PLUGIN_DOWNLOAD_URL = "https://www.axure.com/misc/sketch/Axure.sketchplugin.zip";
export const XD_PLUGIN_DOWNLOAD_URL = "https://adobe.com/go/xd_plugins_discover_plugin?pluginId=2d46a4a0";
export const FIGMA_PLUGIN_DOWNLOAD_URL = "https://www.figma.com/community/plugin/837098402230990991";
export const FEEDBACK_URL = "https://www.axure.com/cloud/feedback";
export const HELP_PAGE_URL = "https://docs.axure.com/axure-cloud/reference/publishing-axure-rp-projects/";
