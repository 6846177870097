import { Action, Artboard, Asset, Master, Platform } from "@shared/expo";
import { UploadBgImg } from "@/services/expo.service";
import { Payload, PayloadMap } from "@/store/typed";

export enum ActionTypes {
  SetupProject = "[Expo] Setup Project",

  ProjectFetchExpo = "[Expo][Project] Fetch Expo",
  ProjectContentFetch = "[Expo][Project] Content Fetch",
  ProjectZoomSave = "[Expo][Project] Zoom Save",
  ProjectPlatformUpdate = "[Expo][Project] Platform Update",
  ProjectPlatformBackgroundImageUpload = "[Expo][Project] Platform Background Image Upload",
  ProjectPlatformBackgroundImageDelete = "[Expo][Project] Platform Background Image Delete",

  ArtboardAdd = "[Expo] Artboard Add",
  ArtboardRemove = "[Expo] Artboard Remove",
  ArtboardRemoveSelection = "[Expo] Artboard Remove Selection",

  ArtboardArchive = "[Expo] Artboard Archive",
  ArtboardArchiveSelection = "[Expo] Artboard Archive Selection",
  ArtboardUnarchive = "[Expo] Artboard Archive",
  ArtboardUnarchiveSelection = "[Expo] Artboard Archive Selection",
  ArtboardReorder = "[Expo] Artboard Reorder",
  ArtboardAddMaster = "[Expo] Artboard Add Master",
  ArtboardRemoveMaster = "[Expo] Artboard Remove Master",

  ArtboardActionRemove = "[Expo] Artboard Action Remove",
  ArtboardActionSave = "[Expo] Artboard Action Save",

  MasterActionRemove = "[Expo] Master Action Remove",
  MasterActionSave = "[Expo] Master Action Save",

  ProjectMasterCreate = "[Expo] Project Master Create",
  ProjectMasterRename = "[Expo] Project Master Rename",
  ProjectMasterCopy = "[Expo] Project Master Copy",
  ProjectMasterDelete = "[Expo] Project Master Delete",

  ArtboardSetHeaderFooterHeight = "[Expo] Artboard Set Header Footer Height",
}

export class SetupProject implements Payload {
  public readonly type = ActionTypes.SetupProject;
  constructor(
    public readonly options: {
      mode: string;
      shortcut: string;
      archiveMode: boolean;
      resourceFolder: string;
      resourceAxureFolder: string;
      platform: Platform | undefined;
      bgImgUrl: string;
    }
  ) {}
}

export class ProjectFetchExpo implements Payload {
  public readonly type = ActionTypes.ProjectFetchExpo;
  constructor(public readonly shortcutId: string) {}
}

export class ProjectContentFetch implements Payload {
  public readonly type = ActionTypes.ProjectContentFetch;
  constructor(public readonly shortcut: string) {}
}

export class ProjectZoomSave implements Payload {
  public readonly type = ActionTypes.ProjectZoomSave;
  constructor(public readonly shortcut: string, public readonly zoom: number) {}
}

export class ProjectPlatformUpdate implements Payload {
  public readonly type = ActionTypes.ProjectPlatformUpdate;
  constructor(public readonly shortcut: string, public readonly platform: Platform | undefined) {}
}

export class ProjectPlatformBackgroundImageUpload implements Payload {
  public readonly type = ActionTypes.ProjectPlatformBackgroundImageUpload;
  constructor(public readonly shortcut: string, public readonly response: UploadBgImg) {}
}

export class ProjectPlatformBackgroundImageDelete implements Payload {
  public readonly type = ActionTypes.ProjectPlatformBackgroundImageDelete;
  constructor(public readonly shortcut: string) {}
}

export class ArtboardAdd implements Payload {
  public readonly type = ActionTypes.ArtboardAdd;
  constructor(
    public readonly shortcut: string,
    public readonly artboard: Artboard,
    public readonly asset: Asset,
    public readonly url: string
  ) {}
}

export class ArtboardRemove implements Payload {
  public readonly type = ActionTypes.ArtboardRemove;
  constructor(public readonly shortcut: string, public readonly artboardId: string) {}
}

export class ArtboardArchive implements Payload {
  public readonly type = ActionTypes.ArtboardArchive;
  constructor(public readonly shortcut: string, public readonly artboardId: string) {}
}

export class ArtboardUnarchive implements Payload {
  public readonly type = ActionTypes.ArtboardUnarchive;
  constructor(public readonly shortcut: string, public readonly artboardId: string) {}
}

export class ArtboardRemoveSelection implements Payload {
  public readonly type = ActionTypes.ArtboardRemoveSelection;
  constructor(public readonly shortcut: string) {}
}

export class ArtboardArchiveSelection implements Payload {
  public readonly type = ActionTypes.ArtboardArchiveSelection;
  constructor(public readonly shortcut: string) {}
}

export class ArtboardUnarchiveSelection implements Payload {
  public readonly type = ActionTypes.ArtboardUnarchiveSelection;
  constructor(public readonly shortcut: string) {}
}

export class ArtboardReorder implements Payload {
  public readonly type = ActionTypes.ArtboardReorder;
  constructor(
    public readonly shortcut: string,
    public readonly artboardId: string,
    public readonly tailArtboardId: string,
    public readonly fullResequence: boolean
  ) {}
}

export class ArtboardAddMasterAction implements Payload {
  public readonly type = ActionTypes.ArtboardAddMaster;
  constructor(public readonly shortcut: string, public readonly artboardId: string, public readonly masterId: string) {}
}

export class ArtboardRemoveMasterAction implements Payload {
  public readonly type = ActionTypes.ArtboardRemoveMaster;
  constructor(public readonly shortcut: string, public readonly artboardId: string, public readonly masterId: string) {}
}

export class ArtboardActionSave implements Payload {
  public readonly type = ActionTypes.ArtboardActionSave;
  constructor(
    public readonly shortcut: string,
    public readonly artboard: Artboard,
    public readonly originalAction: Action,
    public readonly action: Action
  ) {}
}

export class ArtboardActionRemove implements Payload {
  public readonly type = ActionTypes.ArtboardActionRemove;
  constructor(public readonly shortcut: string, public readonly artboard: Artboard, public readonly action: Action) {}
}

export class MasterActionSave implements Payload {
  public readonly type = ActionTypes.MasterActionSave;
  constructor(
    public readonly shortcut: string,
    public readonly artboard: Artboard,
    public readonly master: Master,
    public readonly originalAction: Action,
    public readonly action: Action
  ) {}
}

export class MasterActionRemove implements Payload {
  public readonly type = ActionTypes.MasterActionRemove;
  constructor(public readonly shortcut: string, public readonly master: Master, public readonly action: Action) {}
}

export class ProjectMasterCreate implements Payload {
  public readonly type = ActionTypes.ProjectMasterCreate;
  constructor(
    public readonly shortcut: string,
    public readonly bundle: { name: string; artboard: Artboard; callback: (master: Master) => void }
  ) {}
}

export class ProjectMasterRename implements Payload {
  public readonly type = ActionTypes.ProjectMasterRename;
  constructor(public readonly shortcut: string, public readonly master: Master, public readonly name: string) {}
}

export class ProjectMasterCopy implements Payload {
  public readonly type = ActionTypes.ProjectMasterCopy;
  constructor(
    public readonly shortcut: string,
    public readonly master: Master,
    public readonly artboard: Artboard,
    public readonly callback: (master: Master) => void
  ) {}
}

export class ProjectMasterDelete implements Payload {
  public readonly type = ActionTypes.ProjectMasterDelete;
  constructor(public readonly shortcut: string, public readonly master: Master) {}
}

export class ArtboardSetHeaderFooterHeight implements Payload {
  public readonly type = ActionTypes.ArtboardSetHeaderFooterHeight;
  constructor(
    public readonly shortcut: string,
    public readonly artboard: Artboard,
    public readonly height: number,
    public readonly isHeader: boolean
  ) {}
}

export interface ActionPayloadMap extends PayloadMap<ActionTypes> {
  [ActionTypes.SetupProject]: SetupProject;
  [ActionTypes.ProjectFetchExpo]: ProjectFetchExpo;
  [ActionTypes.ProjectContentFetch]: ProjectContentFetch;
  [ActionTypes.ProjectZoomSave]: ProjectZoomSave;
  [ActionTypes.ProjectPlatformUpdate]: ProjectPlatformUpdate;
  [ActionTypes.ProjectPlatformBackgroundImageUpload]: ProjectPlatformBackgroundImageUpload;
  [ActionTypes.ProjectPlatformBackgroundImageDelete]: ProjectPlatformBackgroundImageDelete;
  [ActionTypes.ArtboardAdd]: ArtboardAdd;
  [ActionTypes.ArtboardRemove]: ArtboardRemove;
  [ActionTypes.ArtboardRemoveSelection]: ArtboardRemoveSelection;
  [ActionTypes.ArtboardArchive]: ArtboardArchive;
  [ActionTypes.ArtboardArchiveSelection]: ArtboardArchiveSelection;
  [ActionTypes.ArtboardUnarchive]: ArtboardUnarchive;
  [ActionTypes.ArtboardUnarchiveSelection]: ArtboardUnarchiveSelection;
  [ActionTypes.ArtboardReorder]: ArtboardReorder;
  [ActionTypes.ArtboardAddMaster]: ArtboardAddMasterAction;
  [ActionTypes.ArtboardRemoveMaster]: ArtboardRemoveMasterAction;
  [ActionTypes.ArtboardActionRemove]: ArtboardActionRemove;
  [ActionTypes.ArtboardActionSave]: ArtboardActionSave;
  [ActionTypes.MasterActionRemove]: MasterActionRemove;
  [ActionTypes.MasterActionSave]: MasterActionSave;
  [ActionTypes.ProjectMasterCreate]: ProjectMasterCreate;
  [ActionTypes.ProjectMasterRename]: ProjectMasterRename;
  [ActionTypes.ProjectMasterCopy]: ProjectMasterCopy;
  [ActionTypes.ProjectMasterDelete]: ProjectMasterDelete;
  [ActionTypes.ArtboardSetHeaderFooterHeight]: ArtboardSetHeaderFooterHeight;
}
