import actions from "@/store/user/actions";
import getters from "@/store/user/getters";
import mutations from "@/store/user/mutations";
import state from "@/store/user/state";

export default {
  state,
  actions,
  mutations,
  getters,
};
