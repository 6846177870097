
import Vue from "vue";

import { arrayProp } from "@/components/utils";

export default Vue.extend({
  props: {
    messages: arrayProp<string>(),
  },
});
