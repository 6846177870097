import { DesktopParams } from "@/desktop/types";

export interface DesktopStore {
  desktopParams: DesktopParams;
}

const state = (): DesktopStore => ({
  desktopParams: {
    isMaximized: false,
    isFullScreen: false,
  },
});

export default state;
