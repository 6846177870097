import Vue from "vue";

import { MutationPayloadMap, MutationTypes } from "@/store/notifications/mutationTypes";
import { NotificationStore } from "@/store/notifications/state";
import { MutationTree } from "@/store/typed";

const mutations: MutationTree<NotificationStore, MutationPayloadMap> = {
  [MutationTypes.AddNotification](state, { notification }) {
    Vue.set(state.notifications, notification.Id, notification);
  },

  [MutationTypes.AddNotificationMuteItem](state, { notification }) {
    Vue.set(state.notificationMuteItems, notification.Id, notification);
  },

  [MutationTypes.EditNotification](state, { notification }) {
    Vue.set(state.notifications, notification.Id, notification);
  },

  [MutationTypes.SetUserSubscriptions](state, { notifications }) {
    for (const notification of notifications) {
      Vue.set(state.notifications, notification.Id, notification);
    }
  },

  [MutationTypes.SetUserGuestSubscriptions](state, { notifications }) {
    for (const notification of notifications) {
      Vue.set(state.guestSubscriptions, notification.Id, notification);
    }
  },

  [MutationTypes.SetNotificationMuteItems](state, { notifications }) {
    for (const notification of notifications) {
      Vue.set(state.notificationMuteItems, notification.Id, notification);
    }
  },

  [MutationTypes.DeleteUserSubscription](state, { notificationId }) {
    Vue.delete(state.notifications, notificationId);
  },

  [MutationTypes.DeleteUserGuestSubscription](state, { notificationId }) {
    Vue.delete(state.guestSubscriptions, notificationId);
  },

  [MutationTypes.DeleteNotificationMuteItem](state, { notificationId }) {
    Vue.delete(state.notificationMuteItems, notificationId);
  },
};

export default mutations;
