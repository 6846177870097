
import { parseISO, Duration } from "date-fns";
import Vue from "vue";
import { Location } from "vue-router";

import { AxShareConfigModel } from "@shared/models";

import { resolveRedirect, AnalyticsClickEvents } from "@/common/axshare";
import { FEEDBACK_URL, HELP_PAGE_URL } from "@/common/axshare/links";
import { VersionCheck } from "@/common/axshare/new-version";
import { persistentStorage } from "@/common/axshare/persistent";
import { isElectron } from "@/common/environment";
import { Main, Renderer } from "@/desktop/events";
import { ROUTES } from "@/router";
import adminRouter from "@/router/admin";
import store from "@/store";
import { Logout } from "@/store/actionTypes";
import { MigrateWorkspaces } from "@/store/migration/mutationTypes";
import { AxShare } from "@/store/state";
import { GetUserInfo, GetUserProfile } from "@/store/user/actionTypes";

import AxButton from "@/components/AxButton.vue";
import AxDropdownButton from "@/components/AxDropdownButton.vue";
import AxIcon from "@/components/AxIcon.vue";
import AxList from "@/components/AxList.vue";
import AxListItem from "@/components/AxListItem.vue";
import AxListItemStatic from "@/components/AxListItemStatic.vue";
import AxUser from "@/components/AxUser.vue";
import AxWhatsNewDialog from "@/components/AxWhatsNewDialog.vue";

export default Vue.extend({
  components: {
    AxButton,
    AxDropdownButton,
    AxIcon,
    AxList,
    AxListItem,
    AxListItemStatic,
    AxUser,
    AxWhatsNewDialog,
  },

  props: {
    linkClasses: {
      type: String,
      default: "",
    },
  },

  data() {
    const feedbackUrl = FEEDBACK_URL;
    const productHelpUrl = HELP_PAGE_URL;
    const lastVersion = persistentStorage.getLastVersion();
    const outdatedAfter: Duration = { days: 4 };
    return {
      useTimer: undefined as NodeJS.Timeout | undefined,
      now: new Date(),
      outdatedAfter,
      lastVersion,
      feedbackUrl,
      productHelpUrl,
      AnalyticsClickEvents,
      whatsNewDialog: false,
      notificationsRoute: ROUTES.notification,
      manageAccountRoute: ROUTES["manage.account"],
      versionCheck: undefined as VersionCheck | undefined,
      adminSettingsRoute: {
        name: adminRouter.routes.admin.name,
        path: adminRouter.routes.admin.path,
      },
    };
  },

  computed: {
    axShareConfig(): AxShareConfigModel | undefined {
      const { axShareConfig } = this.$store.state as AxShare;
      return axShareConfig || undefined;
    },

    isSubInstanceAdmin(): boolean {
      return this.$store.getters.isSubInstanceAdmin;
    },

    isSubInstanceTechnicalAdmin(): boolean {
      return this.$store.getters.isSubInstanceTechnicalAdmin;
    },

    loaded(): boolean {
      return !!this.userInfo && !!this.userProfile;
    },

    userInfo() {
      const { user } = this.$store.state as AxShare;
      return user.userInfo;
    },

    userProfile() {
      const { user } = this.$store.state as AxShare;
      return user.profile;
    },

    userName(): string {
      return this.userInfo?.nickname || this.userInfo?.userEmail || "";
    },

    allowWorkspacesMigrate(): boolean {
      return this.$store.getters.allowWorkspacesMigrate;
    },

    isSuperAdminUser(): boolean {
      return this.$store.getters.isSuperAdminUser;
    },

    isOnPrem(): boolean {
      return !!this.axShareConfig && this.axShareConfig.AxShareOnPrem;
    },

    showVersion(): boolean {
      return !!this.axShareConfig && this.axShareConfig.AxShareOnPrem && !!this.axShareConfig.Version;
    },

    version(): string {
      if (!this.axShareConfig || !this.axShareConfig.Version) {
        return "";
      }
      return this.axShareConfig.Version;
    },

    serverVersion(): string {
      if (this.isOnPrem) {
        return this.version;
      }
      if (!this.axShareConfig || !this.axShareConfig.ReleaseDate) {
        return "";
      }
      return this.releaseDate.getTime().toString();
    },

    releaseDate(): Date {
      if (this.isOnPrem) {
        return new Date();
      }
      if (!this.axShareConfig || !this.axShareConfig.ReleaseDate) {
        return new Date();
      }
      try {
        const releaseDate = parseISO(this.axShareConfig.ReleaseDate);
        return releaseDate;
      } catch {
        return new Date();
      }
    },

    notifyAboutNewVersion(): boolean {
      if (this.versionCheck) {
        return this.versionCheck.notifyAboutNewVersion(this.now);
      }
      return false;
    },
  },

  async created() {
    const oneHourInMs = 60 * 60 * 1000;
    this.useTimer = setInterval(() => {
      this.now = new Date();
    }, oneHourInMs);

    const { user } = this.$store.state as AxShare;
    if (!user.userInfo) {
      this.$store.dispatch(new GetUserInfo());
    }
    if (!user.profile) {
      this.$store.dispatch(new GetUserProfile());
    }

    this.versionCheck = new VersionCheck(this.serverVersion, this.releaseDate, this.outdatedAfter);
    if (isElectron) {
      window.AxureCloudNative.ipc.on(Renderer.Logout, this.logout);
    }
  },

  beforeDestroy() {
    if (this.useTimer) {
      clearInterval(this.useTimer);
    }
  },

  methods: {
    moveNotificationRoute(): Location {
      return {
        name: ROUTES.notification.name,
      };
    },

    manageAccount(_open: boolean) {
      // this.manageAccountDialog = open;
    },

    openWhatsNewDialog() {
      if (this.versionCheck) {
        this.versionCheck.acknowledge();
        this.now = new Date();
      }
      this.whatsNewDialog = true;
    },

    closeWhatsNewDialog() {
      this.whatsNewDialog = false;
    },

    beginMigration() {
      this.$store.commit(new MigrateWorkspaces(true));
    },

    async logout() {
      const onSuccess = () => new Promise<void>((resolve, reject) => {
        const afterLogoutRedirect = resolveRedirect({ name: ROUTES.login.name });
        if (afterLogoutRedirect) {
          this.$router.push(afterLogoutRedirect, () => resolve(), reject);
        } else {
          resolve();
        }
      });

      if (isElectron) {
        window.AxureCloudNative.ipc.send(Main.LogoutPerformed);
      }

      await this.$store.dispatch(new Logout(onSuccess));
    },
  },
});
