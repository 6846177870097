
import Vue from "vue";

import AxVNode from "@/components/AxVNode.vue";

export default Vue.extend({
  components: {
    AxVNode,
  },
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes(): any {
      return {
        "ax-panel": true,
        "ax-panel--small": this.small,
      };
    },
  },
});
