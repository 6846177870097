
import Vue from "vue";
import { PropValidator } from "vue/types/options";

import {
  FilesystemNodeDeletable,
  FilesystemNodeFolder,
  FilesystemNodeShortcut,
  FilesystemNodeType,
  FilesystemNodeWorkspace,
} from "@/common/fs";
import { deleteItems } from "@/services/fs.service";
import { NodeRemove } from "@/store/fs/actionTypes";

import AxConfirmDialog from "@/components/AxConfirmDialog.vue";

const itemsProp: PropValidator<FilesystemNodeDeletable[]> = {
  type: Array,
  required: true,
};

const props = {
  items: itemsProp,
};

export default Vue.extend({
  components: {
    AxConfirmDialog,
  },

  props: {
    folder: {
      type: String,
      required: false,
      default: "",
    },

    ...props,
  },

  computed: {
    workspaces(): FilesystemNodeWorkspace[] {
      return this.items.filter(item => item.type === FilesystemNodeType.Workspace) as FilesystemNodeWorkspace[];
    },

    folders(): FilesystemNodeFolder[] {
      return this.items.filter(item => item.type === FilesystemNodeType.Folder) as FilesystemNodeFolder[];
    },

    shortcuts(): FilesystemNodeShortcut[] {
      return this.items.filter(item => item.type === FilesystemNodeType.Shortcut) as FilesystemNodeShortcut[];
    },

    onlyWorkspaces(): boolean {
      return this.workspaces.length > 0 && this.folders.length === 0 && this.shortcuts.length === 0;
    },
  },

  methods: {
    async submit() {
      const { items, folder } = this;
      const result = await deleteItems(items, folder);
      if (result && result.success !== false) {
        this.$emit("submit");
        await this.$store.dispatch(new NodeRemove(items, folder));
      }
    },

    cancel() {
      this.$emit("cancel");
    },
  },
});
