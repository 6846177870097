import { badgeDrawer } from "@/common/notifications/BadgeDrawer";
import { AxShare } from "@/store/state";

import { ActionTree } from "../typed";

import { ActionPayloadMap, ActionTypes } from "./actionTypes";

const actions: ActionTree<AxShare, AxShare, ActionPayloadMap> = {
  async [ActionTypes.NewProjectMenuAction](_context) {
    // perform your action here
  },

  async [ActionTypes.DrawBadge](_context, { options }): Promise<string> {
    const base64imageURL = badgeDrawer.drawBadgeAsBase64(options);

    return base64imageURL;
  },
};

export default actions;
