
import Vue from "vue";

import { SsoResponse } from "@shared/models";

import { getUserName } from "@/common/axshare";
import { sortDirectoryTree } from "@/common/fs";
import { orderBy } from "@/common/lib";
import { getUserDirectoryTree } from "@/services/fs.service";
import {
  FilesystemEntryTreeNode, FilesystemEntryTree, DirectoryTreeNode,
} from "@/services/models";
import { EntryType } from "@/services/models/notification";
import { getUserFsEntryTree } from "@/services/notification.service";
import { AxShare } from "@/store/state";

import AxAutocomplete from "@/components/AxAutocomplete.vue";
import AxButton from "@/components/AxButton.vue";
import AxFilesystemTreeProjectsList from "@/components/AxFilesystemTreeProjectsList.vue";
import AxIcon from "@/components/AxIcon.vue";
import AxProjectThumbnail from "@/components/AxProjectThumbnail.vue";
import AxWorkspaceIcon from "@/components/AxWorkspaceIcon.vue";
import AxFolder from "@/components/icons/AxFolder.vue";

interface TreeNode<T = any> {
  data: T;
  value: string;
  label: string;
}

interface Item {
  id: string;
  level: number;
  owner: SsoResponse | null;
  targetType: EntryType;
  hasChildren: boolean;
  childNames: string[];
  isCollapsed: boolean;
}

const allProjects = {
  id: "00000000-0000-0000-0000-000000000000",
  label: "All Projects",
};

export default Vue.extend({
  components: {
    AxAutocomplete,
    AxFilesystemTreeProjectsList,
    AxButton,
    AxProjectThumbnail,
    AxWorkspaceIcon,
    AxIcon,
    AxFolder,
  },

  props: {
    value: {
      type: [String, Object],
      default: null,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    select: {
      type: String,
      default: null,
    },

    showAll: {
      type: Boolean,
      default: false,
    },

    renderDefaultAllItem: {
      type: Boolean,
      default: true,
    },

    workspacesOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      active: [this.select],
      fsEntries: undefined as FilesystemEntryTree | undefined,
      workspacesRoot: undefined as DirectoryTreeNode | undefined,
      selected: undefined as TreeNode | undefined,
      searchFields: ["label", "data.childNames"],
      collapsed: {} as Record<string, boolean>,
      innerValue: this.value,
    };
  },

  computed: {
    user(): SsoResponse | undefined {
      const { user } = this.$store.state as AxShare;
      return user.userInfo;
    },

    tree(): TreeNode<Item>[] {
      let tree: TreeNode<Item>[] = [];

      if (this.workspacesOnly) {
        if (!this.workspacesRoot) return [];
        // slice first element, as it's virtual workspaces root node
        tree = this.generateWorkspaceNode(this.workspacesRoot, -1).slice(1);
      } else {
        if (!this.fsEntries) return [];
        tree = this.fsEntries
          .filter(n => this.userIsOwner(n) || this.showAll || n.explicitlyJoined)
          .flatMap(this.generate);
      }

      if (this.renderDefaultAllItem) {
        tree.unshift({
          value: allProjects.id,
          label: allProjects.label,
          data: {
            id: allProjects.id,
            targetType: EntryType.All,
            hasChildren: true,
            childNames: [],
            isCollapsed: false,
            level: 0,
            owner: null,
          },
        });
      }
      return tree;
    },
  },

  async created() {
    this.loading = true;
    try {
      if (this.workspacesOnly) {
        this.workspacesRoot = (await getUserDirectoryTree()).workspacesRoot;
      } else {
        this.fsEntries = await getUserFsEntryTree();
      }
    } catch {
      //
    }
    this.loading = false;
  },

  methods: {
    toggleCollapse(id: string) {
      Vue.set(this.collapsed, id, !this.collapsed[id]);
    },

    isCollapsed(id: string) {
      return !!this.collapsed[id];
    },

    isCollapsible(node: Item) {
      return this.isWorkspace(node) && node.hasChildren;
    },

    shouldSkipOption(option: TreeNode<Item>) {
      return option.data.isCollapsed && !this.isWorkspace(option.data);
    },

    updateValue(selectedValue: TreeNode<Item>) {
      this.innerValue = selectedValue;
      this.selected = selectedValue;

      if (selectedValue) {
        this.$emit("input", selectedValue.data);
      } else {
        this.$emit("input");
      }
    },

    generate(node: FilesystemEntryTreeNode): TreeNode<Item>[] {
      const hasChildren = node.entries && node.entries.length > 0;

      const nodes: TreeNode<Item>[] = [];

      const parentNode: TreeNode<Item> = {
        value: node.folderId,
        label: node.folderName,
        data: {
          ...node,
          id: node.folderId,
          targetType: EntryType.Filesystem,
          owner: node.owner,
          hasChildren,
          level: 0,
          isCollapsed: this.isCollapsed(node.folderId),
          childNames: [] as string[],
        },
      };
      nodes.push(parentNode);

      if (hasChildren) {
        const children: TreeNode<Item>[] = orderBy(node.entries, n => n.Name).map(n => {
          const child: TreeNode<Item> = {
            value: n.shortcutId,
            label: n.Name,
            data: {
              ...n,
              id: n.shortcutId,
              owner: node.owner,
              targetType: EntryType.Shortcut,
              level: 1,
              isCollapsed: parentNode.data.isCollapsed,
              hasChildren: false,
              childNames: [],
            },
          };
          return child;
        });
        nodes.push(...children);

        const childNames = children.reduce((acc, child) => {
          acc.push(child.label);
          return acc;
        }, [] as string[]);
        parentNode.data.childNames.push(...childNames);
      }

      return nodes;
    },

    generateWorkspaceNode(node: DirectoryTreeNode, level = 0, parentCollapsed = false): TreeNode<Item>[] {
      const hasChildren = node.children && node.children.length > 0;
      const showAll = this.showAll;

      const nodes: TreeNode[] = [];
      const targetType = node.isFolder ? EntryType.Folder : EntryType.Filesystem;
      const isCollapsed = parentCollapsed || this.isCollapsed(node.id);

      const parentNode: TreeNode<Item> = {
        value: node.id,
        label: node.name,
        data: {
          ...node,
          targetType,
          level,
          isCollapsed,
          hasChildren,
          childNames: [],
        },
      };
      nodes.push(parentNode);

      if (hasChildren) {
        const children = sortDirectoryTree(node.children)
          .filter(n => showAll || this.userIsOwner(n) || n.explicitlyJoined)
          .flatMap(item => this.generateWorkspaceNode(item, level + 1, isCollapsed));
        nodes.push(...children);

        const childNames = children.reduce((acc, child) => {
          acc.push(child.label);
          return acc;
        }, [] as string[]);
        parentNode.data.childNames.push(...childNames);
      }

      return nodes;
    },

    getOwnerDescription(node: FilesystemEntryTreeNode) {
      if (!this.userIsOwner(node)) {
        return `(Owner: ${this.getUserName(node.owner)})`;
      }
      return "";
    },

    userIsOwner(node: FilesystemEntryTreeNode | DirectoryTreeNode) {
      if (this.user) {
        return this.user.userId === node.owner.userId;
      }
      return false;
    },

    getUserName(user: SsoResponse): string {
      return getUserName(user) || user.userEmail || "";
    },

    isWorkspace(node: Item) {
      const { targetType } = node;
      return targetType === EntryType.Filesystem;
    },

    isFolder(node: Item) {
      const { targetType } = node;
      return targetType === EntryType.Folder;
    },

    isProject(node: Item) {
      const { targetType } = node;
      return targetType === EntryType.Shortcut;
    },
  },
});
