import { Payload } from "vuex";

import { PayloadMap } from "@/store/typed";

export enum MutationTypes {
  SetShowAllWorkspaces = "[Admin] Set Show All Workspaces",
  SetSavedWorkspaceId = "[Settings] Set Saved Workspace Id",
  SetShowArchived = "[Admin] Set Show Archived",
}

export class SetShowAllWorkspaces implements Payload {
  public readonly type = MutationTypes.SetShowAllWorkspaces;
  constructor(public show: boolean = false) {}
}

export class SetSavedWorkspaceId implements Payload {
  public readonly type = MutationTypes.SetSavedWorkspaceId;
  constructor(public id?: string) {}
}

export class SetShowArchived implements Payload {
  public readonly type = MutationTypes.SetShowArchived;
  constructor(public show: boolean = false) {}
}

export interface MutationPayloadMap extends PayloadMap<MutationTypes> {
  [MutationTypes.SetShowAllWorkspaces]: SetShowAllWorkspaces;
  [MutationTypes.SetSavedWorkspaceId]: SetSavedWorkspaceId;
  [MutationTypes.SetShowArchived]: SetShowArchived;
}
