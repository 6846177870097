import { GetterTree } from "vuex";

import { isElectron } from "@/common/environment";
import { AxShareMigration } from "@/store/migration/state";
import { AxShare } from "@/store/state";

const getterTree: GetterTree<AxShareMigration, AxShare> = {
  allowWorkspacesMigrate(_state, getters, _rootState) {
    if (isElectron) return false;
    if (getters.isOnPrem) return false;
    if (getters.isSubInstanceReviewer) return false;
    if (getters.isSubInstanceTechnicalAdmin) return false;
    return !!_rootState.axShareConfig && _rootState.axShareConfig.EnableWorkspacesMigration;
  },
};

export default getterTree;
