
import Vue from "vue";

import { redirectParamName, wellKnownRoutes } from "@/common/axshare";
import { openExternal } from "@/common/desktop/links";
import { isElectron } from "@/common/environment";
import { updateQueryStringParam } from "@/common/lib";
import { AxShare } from "@/store/state";

import AxButton from "@/components/AxButton.vue";

export default Vue.extend({
  components: {
    AxButton,
  },

  props: {
    cssClass: {
      type: String,
      default: "",
    },
  },

  methods: {
    async handleSamlLogin() {
      const { axShareConfig } = this.$store.state as AxShare;
      if (axShareConfig) {
        let samlUrl = axShareConfig.SamlUrl;
        if (samlUrl) {
          const redirectParam = this.$route.query[redirectParamName];
          const redirect = Array.isArray(redirectParam) ? redirectParam[0] : redirectParam;
          if (redirect) {
            const resolved = this.$router.resolve({
              name: wellKnownRoutes.login.name,
              query: this.$route.query,
            });
            samlUrl = updateQueryStringParam(samlUrl, "redirect", encodeURIComponent(resolved.href));
          }
          if (isElectron) {
            samlUrl = updateQueryStringParam(samlUrl, "redirectToDesktop", "true");
            openExternal(samlUrl);
          } else {
            window.location.href = samlUrl;
          }
        }
      }
    },
  },
});
