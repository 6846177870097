const sizeSymbols = ["kB", "MB", "GB", "TB", "PB", "EB"];
export const fileSize = (bytes: number) => {
  if (bytes === 0) {
    return bytes + sizeSymbols[0];
  }
  let i = -1;
  do {
    bytes /= 1000;
    i++;
  } while (bytes > 999);

  return Math.max(bytes, 0.1).toFixed(1) + sizeSymbols[i];
};
