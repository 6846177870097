import { NotificationJs, NotificationMuteItemJs } from "@/generated/models";

export interface NotificationStore {
    notifications: { [key: string]: NotificationJs };
    guestSubscriptions: { [key: string]: NotificationJs };
    notificationMuteItems: { [key: string]: NotificationMuteItemJs };
  }

const state = (): NotificationStore => ({
  notifications: {},
  guestSubscriptions: {},
  notificationMuteItems: {},
});

export default state;
