
import Vue from "vue";

import { DEFAULT_WORKSPACE_NAME } from "@/common/axshare/constants";
import { equalsIgnoringCase, tryDecodeURIComponent } from "@/common/lib";
import WelcomeLayout from "@/layouts/WelcomeLayout.vue";
import { ROUTES } from "@/router";

import AxIcon from "@/components/AxIcon.vue";
import AxAxureLogo from "@/components/icons/AxAxureLogo.vue";

export default Vue.extend({
  components: {
    AxAxureLogo,
    AxIcon,
    WelcomeLayout,
  },

  props: {
    workspaceName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      homeRoute: { name: ROUTES.home.name },
    };
  },

  computed: {
    showWorkspaceName(): boolean {
      return !equalsIgnoringCase(this.decodedWorkspaceName, DEFAULT_WORKSPACE_NAME);
    },

    decodedWorkspaceName(): string {
      const workspaceName = this.workspaceName;
      return tryDecodeURIComponent(workspaceName);
    },
  },
});
