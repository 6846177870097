var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ax-form-invite"},[(_vm.canInviteUsers)?_c('AxManageUsersInvite',{attrs:{"autofocus":"","force-action":_vm.isDefaultWorkspace,"workspace":_vm.workspace,"custom-submit":_vm.isDefaultWorkspace ? _vm.shareDefaultWorkspaceProject : undefined,"existing-users-title":"existing workspace member(s):","edit-mode":_vm.rights.securityConfigure},on:{"submit":_vm.cancel},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"form-title"},[_vm._v(" "+_vm._s(_vm.inviteUsersTitle)+" ")])]},proxy:true},{key:"description",fn:function(){return [_c('AxFormGroup',[_c('span',{staticClass:"ax-form-invite__description"},[_vm._v(" Inviting users to a workspace gives them access to the projects in the workspace. ")])])]},proxy:true},(_vm.isDefaultWorkspace)?{key:"action",fn:function({ submitting, handleSubmit, values }){return [_c('div',{staticClass:"ax-form-invite__action"},[_c('AxButton',{attrs:{"block":"","color":"upload","type":"submit","loading":submitting},on:{"click":function($event){$event.preventDefault();return (() => {
              values.usersInvite.viewerOnly = false;
              handleSubmit();
            }).apply(null, arguments)}}},[_vm._v(" Create Workspace and Invite Others ")])],1),_c('div',{staticClass:"ax-form-invite__action"},[_c('AxButton',{staticClass:"mb-2",attrs:{"color":"link","type":"submit","loading":submitting},on:{"click":function($event){$event.preventDefault();return (() => {
              values.usersInvite.viewerOnly = true;
              handleSubmit();
            }).apply(null, arguments)}}},[_vm._v(" Create Workspace and Invite as Viewers ")])],1)]}}:null],null,true)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }