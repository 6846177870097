
import Vue from "vue";

import { prop } from "@/components/utils";
import { ApiExceptionError } from "@/common/axshare/errors";

export default Vue.extend({
  props: {
    error: prop(ApiExceptionError, {
      required: true,
    }),
  },
});
