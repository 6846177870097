
import Vue from "vue";

import { VersionInfoResponse } from "@shared/models";

import { AxShare } from "@/store/state";

import { persistentStorage } from "../common/axshare/persistent";
import { commitHash } from "../common/environment";
import { LoadApiVersionInfo } from "../store/actionTypes";
import { ApiVersionSet } from "../store/mutationTypes";

import AxRetry from "@/components/AxRetry.vue";

export default Vue.extend({
  components: {
    AxRetry,
  },

  props: {
    interval: {
      type: Number,
      required: false,
      default: 1000 * 60 * 10,
    },
  },

  data() {
    return {
      willReloadOnNextNavigation: false,
      reloading: false,
    };
  },

  computed: {
    versionInfo(): VersionInfoResponse | null {
      const { apiVersionInfo } = this.$store.state as AxShare;
      return apiVersionInfo;
    },

    hash(): string {
      if (commitHash) {
        return commitHash;
      }
      return this.versionInfo ? this.versionInfo.BuildVersion : "";
    },

    shortHash(): string {
      return this.hash.substring(0, 7);
    },

    repoUrl(): string {
      const repoUrl = process.env.VUE_APP_REPO_URL;
      return repoUrl || "";
    },

    commitOnRemote(): string {
      return `${this.repoUrl}${this.hash}`;
    },
  },

  watch: {
    versionInfo(newVersion: VersionInfoResponse, oldVersion: VersionInfoResponse) {
      if (!oldVersion || !newVersion) {
        return;
      }
      if (oldVersion.BuildVersion && newVersion.BuildVersion) {
        if (oldVersion.BuildVersion !== newVersion.BuildVersion) {
          this.reloadOnNextRouteNavigation();
        }
      } else if (oldVersion.EnterpriseVersion && newVersion.EnterpriseVersion) {
        if (oldVersion.EnterpriseVersion !== newVersion.EnterpriseVersion) {
          this.reloadOnNextRouteNavigation();
        }
      }
    },
  },

  methods: {
    async pollApiVersionInfo() {
      const lastPolling = persistentStorage.getApiVersionInfo();
      if (!lastPolling || !lastPolling.date || !lastPolling.value) {
        await this.loadApiVersionInfo();
      } else {
        const dateDiff = Date.now() - new Date(lastPolling.date).getTime();
        if (dateDiff >= this.interval) {
          await this.loadApiVersionInfo();
        } else {
          this.$store.commit(new ApiVersionSet(lastPolling.value));
        }
      }
    },

    async loadApiVersionInfo() {
      await this.$store.dispatch(new LoadApiVersionInfo());
    },

    reloadOnNextRouteNavigation() {
      if (this.willReloadOnNextNavigation || this.reloading) return;
      this.willReloadOnNextNavigation = true;
      // register a guard that will reload on next route navigation if it's safe route
      this.$router.beforeEach((to, _from, next) => {
        if (to.meta && to.meta.safeToApplySWUpdate) {
          this.reloading = true;
          window.location.reload();
        } else {
          next();
        }
      });
    },
  },

  render(h) {
    return h("");
  },
});
