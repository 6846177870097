
import Vue from "vue";
import { PropOptions } from "vue/types/options";

import { FilesystemNodeWorkspace } from "@/common/fs";
import { unjoinShare } from "@/services/fs.service";
import { ManageUsersLeaveModel } from "@/services/models/filesystem";
import { AxShare } from "@/store/state";

import AxButton from "@/components/AxButton.vue";
import AxChangeOwner from "@/components/AxChangeOwner.vue";
import AxForm from "@/components/AxForm.vue";
import AxFormGroup from "@/components/AxFormGroup.vue";
import { Submit } from "@/components/types/AxForm";

const workspaceProp: PropOptions<FilesystemNodeWorkspace> = {
  type: Object,
  required: true,
};

export default Vue.extend({
  components: {
    AxButton,
    AxForm,
    AxFormGroup,
    AxChangeOwner,
  },

  props: {
    workspace: workspaceProp,
  },

  data() {
    const model: ManageUsersLeaveModel = {
      NewOwner: "",
      currentFolderId: this.workspace.rootFolderId,
      FilesystemId: this.workspace.id,
    };
    const newOwners = this.workspace.sharedWith.filter(item => !item.isViewer);
    if (this.workspace.isOwner && newOwners.length) {
      model.NewOwner = newOwners[0].user.userId;
    }
    return {
      model,
      newOwners,
    };
  },

  computed: {
    isOwner(): boolean {
      return this.workspace.isOwner;
    },

    isSubInstance(): boolean {
      const { axShareConfig } = this.$store.state as AxShare;
      return axShareConfig !== null && axShareConfig.IsSubInstance;
    },
  },

  methods: {
    canLeave(newOwner: string): boolean {
      return this.isOwner === false || this.newOwners.some(item => item.user.userId === newOwner);
    },

    async submit({ values }: Submit<ManageUsersLeaveModel>) {
      await unjoinShare(values);
      this.$emit("submit");
    },

    onOwnerChanged() {
      this.submit({ values: { ...this.model, NewOwner: "" } });
    },
  },
});
