import axios, { AxiosRequestTransformer, AxiosRequestConfig } from "axios";

import { makeId } from "@/common/lib";

const { transformRequest } = axios.defaults;
// eslint-disable-next-line no-nested-ternary
export const transformDefaults: AxiosRequestTransformer[] = !transformRequest
  ? []
  : Array.isArray(transformRequest)
    ? transformRequest
    : [transformRequest];

export const addAuthorizationHeader = (headers: any, token: string | null | undefined) => {
  if (token) {
    headers.set("Authorization", token, true);
  }
};

export const transformRequestDefaults = transformDefaults;

export const withNoCacheHeaders: AxiosRequestConfig = {
  headers: {
    "Cache-Control": "private, no-cache, no-store",
    Pragma: "no-cache",
  },
};

export const timestampInterceptor = (timestampParam = "t") => (config: AxiosRequestConfig) => {
  try {
    while (config.params[timestampParam] !== undefined) {
      // eslint-disable-next-line no-param-reassign
      timestampParam = makeId(6);
    }

    // eslint-disable-next-line no-param-reassign
    config.params[timestampParam] = Date.now().toString();
    return config;
  } catch {
    return config;
  }
};
