

import Vue from "vue";

export default Vue.extend({

  beforeRouteUpdate(to, from, next) {
    // animation should be simultaneous if navigating between fs viewer routes
    this.simultaneous = !!to.meta?.fsViewer && !!from.meta?.fsViewer;
    next();
  },

  data() {
    return {
      simultaneous: false,
    };
  },
});
