import { Location, Route, RawLocation } from "vue-router";

import { isDesktopLocal } from "@/common/environment";
import { testSameOrigin } from "@/common/lib/browser";

import { isRelativeUrl, removeDuplicateForwardSlashes } from "../lib";

import { resolveApiBaseUrl } from "./api";

export const redirectParamName = "redirect";

export interface RouterNavigationLink {
  title: string;
  to: Location;
}

interface MakeUrlOptions {
  relative?: boolean;
  baseUrl?: string;
}

const defaultOptions: MakeUrlOptions = {
  relative: false,
  baseUrl: undefined,
};

export const wellKnownRoutes = {
  home: {
    path: "/",
    name: "home",
  },
  uploadFromSketch: {
    path: "/upload-from-sketch",
    name: "uploadfromsketch",
  },
  downloadingUpdatesProgress: {
    path: "/downloading-updates-progress",
    name: "downloadingupdatesprogress",
  },
  proxylogin: {
    path: "/proxy-login",
    name: "proxylogin",
    props: true,
  },
  login: {
    path: "/login",
    name: "login",
  },
};

function isRoute(to: Route | RawLocation): to is Route {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (to as any).fullPath !== undefined;
}

export const makeUrl = (bundleSuffix: string, to: Route | RawLocation, options: MakeUrlOptions = defaultOptions) => {
  const { relative, baseUrl } = options;
  const base = relative ? "/" : baseUrl || resolveApiBaseUrl();
  const path = typeof to === "string" ? to : (isRoute(to) ? to.fullPath : to.path) || to.path || "";
  const url = removeDuplicateForwardSlashes(`${base}/${bundleSuffix}/${path}`);
  return url;
};

export const makeWebUrl = (to: Route | RawLocation, options = defaultOptions) => {
  const webSuffix = process.env.VUE_APP_BASE_URL || "";
  return makeUrl(webSuffix, to, options);
};

export const resolveRedirect = (to: Route | RawLocation): RawLocation | undefined => {
  if (isDesktopLocal) {
    const desktopUrl = makeWebUrl(to);
    window.location.href = decodeURIComponent(desktopUrl);
    return;
  }

  return to as RawLocation;
};

export const resolveAfterAuthRedirect = (to: Route | Location, axShareHost: string = ""): RawLocation | undefined => {
  const { query } = to;
  if (query !== undefined && query[redirectParamName]) {
    const { [redirectParamName]: redirect } = query;
    if (typeof redirect === "string") {
      const isAbsoluteUrl = !isRelativeUrl(redirect);
      if (isDesktopLocal || isAbsoluteUrl) {
        const isSameOrigin = testSameOrigin(redirect, axShareHost);
        if (!isSameOrigin) return wellKnownRoutes.home;
        window.location.href = redirect + to.hash;
        return;
      }
      return { path: redirect, hash: to.hash };
    }
  }
  const resolved = resolveRedirect(to);
  if (!resolved) {
    return;
  }

  return wellKnownRoutes.home;
};
