
import Vue from "vue";

export default Vue.extend({
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    selected(val: boolean) {
      if (val) {
        (this.$el as HTMLElement).focus();
        this.$emit("focus");
      }
    },
  },
});
