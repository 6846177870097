
import Vue from "vue";
import { Store } from "vuex";

import {
  FilesystemNode, FilesystemNodeType, FilesystemNodeTypes, FilesystemNodeWorkspace, isWorkspaceNode,
} from "@/common/fs";
import { getObjectValueByPath, orderBy } from "@/common/lib";
import FilesystemViewer from "@/layouts/FilesystemViewer.vue";
import { RefreshOptionsQuery } from "@/router/fs";
import { Fetch, Navigate, NavigationRestore } from "@/store/fs/actionTypes";
import { SetSavedWorkspaceId } from "@/store/settings/mutationTypes";
import { AxShare } from "@/store/state";

import AxNavigation from "./AxNavigation.vue";

import AxFilesystemTable from "@/components/AxFilesystemTable.vue";
import AxFilesystemViewerHeader from "@/components/AxFilesystemViewerHeader.vue";
import { CustomSort } from "@/components/types/AxDataTable";

const AxFilesystemFeed = () => import("@/components/AxFilesystemFeed.vue");

const filesystemSort: CustomSort<FilesystemNodeTypes> = (items, path, isDescending) => orderBy(
  items,
  // folders should be always on top
  item => ({ value: item.type === FilesystemNodeType.Folder, descending: true }),
  item => ({ value: getObjectValueByPath(item, path), descending: isDescending }),
  item => ({ value: item.name, descending: false }),
);

export default Vue.extend({
  components: {
    AxFilesystemFeed,
    AxFilesystemTable,
    AxFilesystemViewerHeader,
    AxNavigation,
    FilesystemViewer,
  },

  beforeRouteEnter(to, _from, next) {
    next(vm => {
      const { workspaceId } = to.params;
      vm.$store.commit(new SetSavedWorkspaceId(workspaceId));
    });
  },

  props: {
    id: {
      type: String,
      required: true,
    },

    workspaceId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      filesystemSort,
    };
  },

  computed: {
    loading(): boolean {
      return this.$store.getters.navigating;
    },

    isRoot(): boolean {
      return this.$store.getters.isRoot;
    },

    current(): {
      node: FilesystemNodeTypes;
      contents: FilesystemNode[];
    } {
      return this.$store.getters.current;
    },

    node(): FilesystemNodeTypes {
      return this.current.node;
    },

    workspace(): FilesystemNodeWorkspace | undefined {
      return isWorkspaceNode(this.node) ? this.node : this.$store.getters.findWorkspace(this.node.id);
    },

    items(): FilesystemNode[] {
      return this.current.contents;
    },

    canGoBack(): boolean {
      if (this.node.type === FilesystemNodeType.Workspace) return false;
      const parent = this.node.parent;
      return parent !== undefined && parent.type !== FilesystemNodeType.Root;
    },

    upperLevel(): FilesystemNode | undefined {
      return this.node.parent;
    },

    viewOnly(): boolean {
      const node = this.node;
      if (node.type === FilesystemNodeType.Workspace) {
        return node.viewOnly;
      }
      if (node.type === FilesystemNodeType.Folder) {
        return node.viewOnly;
      }
      return false;
    },

    enableActivityFeed(): boolean {
      const { axShareConfig } = this.$store.state as AxShare;
      if (axShareConfig) {
        return axShareConfig.EnableActivityFeedUI;
      }
      return false;
    },
  },

  watch: {
    id() {
      this.load();
    },

    "$route.query": function _() {
      const { contents } = this.$route.query as RefreshOptionsQuery;
      const refresh = contents === String(true);
      if (refresh) {
        this.refresh();
      }
    },
  },

  mounted() {
    this.load();
  },

  methods: {
    async load() {
      const store = this.$store as Store<AxShare>;
      const { id, workspaceId } = this;

      let node: FilesystemNodeTypes = store.getters.getFsNode(id);
      if (node) {
        return store.dispatch(new Navigate(node));
      }

      // There is a case when workspace navigation should be skipped.
      // In case when navigating to workspace's rootFolder, skip it
      // as it's handled on the upper level in FilesystemWorkspace component.
      if (store.getters.getFsNode(workspaceId)) {
        const workspaceRootFolder: FilesystemNodeWorkspace | undefined = store.getters.getWorkspaceByRootFolderId(id);
        if (workspaceRootFolder) return;
      }

      await store.dispatch(new NavigationRestore(id));
      node = store.getters.getFsNode(id) || store.getters.getFsNode(workspaceId);
      return store.dispatch(new Navigate(node));
    },

    async refresh() {
      const store = this.$store as Store<AxShare>;
      const { id, workspaceId } = this;

      const node: FilesystemNodeTypes = store.getters.getFsNode(id) || store.getters.getFsNode(workspaceId);
      if (node) {
        return store.dispatch(new Fetch(node));
      }
    },
  },
});
