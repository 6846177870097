
import Vue from "vue";

import { AxShare } from "@/store/state";

import AxDialog from "@/components/AxDialog.vue";
import AxReleaseHistory from "@/components/AxReleaseHistory.vue";

export default Vue.extend({
  components: {
    AxDialog,
    AxReleaseHistory,
  },

  data() {
    return {
      dialog: true,
    };
  },
  computed: {
    isOnPrem(): boolean {
      const { axShareConfig } = this.$store.state as AxShare;
      return axShareConfig !== null && axShareConfig.AxShareOnPrem;
    },
  },

  methods: {
    cancel() {
      this.dialog = false;
      this.$emit("cancel");
    },
  },
});
