declare global {
  namespace NodeJS {
    interface ProcessEnv {
      NODE_ENV: "development" | "production";
      // provided by vue-cli-plugin-electron-builder, but it's actually boolean
      IS_ELECTRON: string | undefined;
      VUE_APP_IS_ELECTRON: "true" | "false";
      VUE_APP_IS_ELECTRON_MAIN: "true" | "false";
      VUE_APP_IS_ELECTRON_LOCAL: "true" | "false";
      VUE_APP_AXSHARE_LATEST: "true" | "false";
      VUE_APP_BASE_URL: string | undefined;
      VUE_APP_AXSHARE_API_URL: string | undefined;
      VUE_APP_AXSHARE_ACCOUNT_SERVICE_URL: string | undefined;
      VUE_APP_AXSHARE_ACCOUNT_SERVICE_CLASSIC: "true" | "false";
      VUE_APP_DESKTOP_AXSHARE_API_URL: string | undefined;
      VUE_APP_DESKTOP_AXSHARE_SUBINSTANCE_TLD: string | undefined;
      VUE_APP_DESKTOP_LOGIN: "true" | "false";
      VUE_APP_EXPO_PREVIEW_PINS_DISABLED: "true" | "false";
      VUE_APP_ON_PREM: "true" | "false";
      VUE_APP_PWA_ENABLED: "true" | "false";
    }
  }
}

export const isDevelopment = process.env.NODE_ENV === "development";
export const isProduction = process.env.NODE_ENV === "production";
export const isLatest = process.env.VUE_APP_AXSHARE_LATEST === "true";
export const isExpoPreviewPinsDisabled = process.env.VUE_APP_EXPO_PREVIEW_PINS_DISABLED === "true";
export const serviceWorkerEnabled = process.env.VUE_APP_PWA_ENABLED === "true";
export const commitHash = isDevelopment ? process.env.VUE_APP_COMMIT_HASH : "";

function isElectronRendererFn() {
  if (typeof window === "undefined") return false;
  if (window.AxureCloudNative !== undefined) return true;
  const { process }: { process: NodeJS.Process } = window as any;
  if (typeof process === "object" && process.type === "renderer") return true;
  if (
    typeof navigator === "object"
    && typeof navigator.userAgent === "string"
    && (
      navigator.userAgent.indexOf("Electron") >= 0
      || navigator.userAgent.indexOf("Axure Cloud") >= 0
      || navigator.userAgent.indexOf("axure-cloud") >= 0
    )
  ) {
    return true;
  }
  return false;
}

function isElectronMainFn() {
  return typeof process !== "undefined" && typeof process.versions === "object" && !!process.versions.electron;
}

export const isElectronBuild = process.env.VUE_APP_IS_ELECTRON === "true";
export const isElectronRenderer = isElectronRendererFn();
export const isElectronMain = isElectronMainFn();
export const isElectron = isElectronMain || isElectronRenderer;

export const isDesktopLocal = (isElectronBuild && (process.env.NODE_ENV === "production" && process.env.VUE_APP_IS_ELECTRON_LOCAL === "true"))
  || process.env.VUE_APP_DESKTOP_LOGIN === "true";
