
import { ValidationResult, Validator } from "lakmus";
import Vue from "vue";
import { PropOptions } from "vue/types/options";

import { DbConnectionError, JsonSettingsLoadError } from "@/common/axshare/errors";
import { asError, deepCopy } from "@/common/lib";

import AxFormErrors from "@/components/AxFormErrors.vue";
import { Submit } from "@/components/types/AxForm";
import { arrayProp } from "@/components/utils";

class DefaultValidator extends Validator<any> {}
const defaultValidator = new DefaultValidator();

const validatorProp: PropOptions<Validator<any>> = {
  type: Object,
  required: false,
  default: (): Validator<any> => defaultValidator,
};

export default Vue.extend({
  components: {
    AxFormErrors,
  },

  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    submit: {
      type: Function,
      required: false,
      default: null,
    },

    clearErrorsOnSumbit: {
      type: Boolean,
      default: true,
    },

    errors: arrayProp<string>(),

    errorsColor: {
      type: String,
      default: "",
    },

    hideErrors: {
      type: Boolean,
      default: false,
    },

    validator: validatorProp,
  },

  data() {
    return {
      values: deepCopy(this.value),
      submitValues: undefined,
      errorsInner: this.errors,
      submitting: false,
    };
  },

  computed: {
    errorsOptions(): any {
      const options: Record<string, any> = {};
      if (this.errorsColor) {
        options.color = this.errorsColor;
      }
      return options;
    },

    validationResult(): ValidationResult {
      const { values } = this;
      return this.validator.validate(values);
    },
  },

  watch: {
    value: {
      handler(newForm) {
        this.values = deepCopy(newForm);
      },
      deep: true,
    },

    errors: {
      handler(errors) {
        this.errorsInner = errors;
      },
    },
  },

  methods: {
    async handleSubmit() {
      if (this.submitting) return;
      this.submitting = true;
      if (this.clearErrorsOnSumbit) {
        this.errorsInner = [];
        this.$emit("errors-cleared");
      }
      const submit: Submit = {
        values: this.values,
      };
      this.submitValues = deepCopy(this.values);
      if (this.submit) {
        try {
          await this.submit(submit);
        } catch (error) {
          const errorMessage = asError(error).message;

          // rethrow DbConnectionError or JsonSettingsLoadError  further, so it can be handled globally by Vue error handler
          if (error && (error instanceof DbConnectionError || error instanceof JsonSettingsLoadError)) throw error;

          this.errorsInner = [errorMessage];
          this.$emit("on-error", errorMessage);
        } finally {
          this.submitting = false;
        }
      } else {
        this.$emit("submit", submit);
      }
      this.submitting = false;
    },
  },
});
