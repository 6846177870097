import { RouteConfig } from "vue-router";

const AxNotificationsActions = () => import("@/components/AxNotificationsActions.vue");
const AxNotificationsChangeUrl = () => import("@/components/AxNotificationsChangeUrl.vue");
const AxNotificationsRemoveGuestSubscription = () => import("@/components/AxNotificationsRemoveGuestSubscription.vue");
const Notifications = () => import("@/pages/ProjectNotifications.vue");

export type Keys =
  | "notification"
  | "notification.add"
  | "notification.edit"
  | "notification.changeurl"
  | "notification.removeguest"
  | "notification.mute";

const routes: { readonly [R in Keys]: RouteConfig } = {
  notification: {
    path: "/notification/manage",
    alias: "notification",
    name: "notification",
    component: Notifications,
  },
  "notification.add": {
    path: ":type/add",
    name: "notification.add",
    component: AxNotificationsActions,
    props: route => ({
      action: "add",
      type: route.params.type,
    }),
  },
  "notification.edit": {
    path: ":type/:id/edit",
    name: "notification.edit",
    component: AxNotificationsActions,
    props: route => ({
      id: route.params.id,
      action: "edit",
      type: route.params.type,
    }),
  },
  "notification.changeurl": {
    path: ":type/:id/url",
    name: "notification.changeurl",
    component: AxNotificationsChangeUrl,
    props: true,
  },
  "notification.removeguest": {
    path: ":type/:id/guest",
    name: "notification.removeguest",
    component: AxNotificationsRemoveGuestSubscription,
    props: true,
  },
  "notification.mute": {
    path: ":type/mute",
    name: "notification.mute",
    component: AxNotificationsActions,
    props: route => ({
      action: "mute",
      type: route.params.type,
    }),
  },
};

const config = [
  {
    ...routes.notification,
    children: [
      routes["notification.add"],
      routes["notification.edit"],
      routes["notification.changeurl"],
      routes["notification.removeguest"],
      routes["notification.mute"],
    ],
  },
];

export default {
  routes,
  config,
};
