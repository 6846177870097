import Vue, { PropOptions } from "vue";

import { PrototypeUrls, prototypeLinkHandler, prototypeLinkHandlerNoAuth } from "@/common/axshare";
import { FilesystemNodeShortcut, isShortcutNode } from "@/common/fs";

import { FilesystemSearchItem } from "@/components/types/AxSearch";

const projectProp: PropOptions<FilesystemNodeShortcut | FilesystemSearchItem> = {
  type: Object,
  required: true,
  validator: item => isShortcutNode(item),
};

export const shortcutLinkMixin = Vue.extend({
  props: {
    full: {
      type: Boolean,
      default: false,
    },

    project: projectProp,
  },

  computed: {
    url(): string {
      return this.full ? this.urls.full : this.urls.display;
    },

    urls(): PrototypeUrls {
      const project = this.project;
      return this.$store.getters.prototypeUrls(project);
    },
  },

  methods: {
    openPrototypeLink(event: MouseEvent) {
      /* Clicks on prototype links might navigate to different url that is displayed:
        1. If project is user-only (requires user to have an account on Axure Cloud), then it should always go through full authentication attempt.
        2. If project has password, then prototype password authentication might not be required, if user is already logged in on Axure Cloud.
        3. Otherwise don't hijack click event, and let it open normally.
      */
      if (this.project.isUserOnlyWorkspace) {
        prototypeLinkHandler(this.urls, event);
      } else if (this.project.hasPassword) {
        prototypeLinkHandlerNoAuth(this.urls, event);
      } else {
        return true;
      }
    },
  },
});
