
import Vue from "vue";
import { PropOptions } from "vue/types/options";

import { fileSize } from "@/common/lib";

export interface Progress {
  current: number;
  total: number;
  lengthComputable: boolean;
}

const progressProp: PropOptions<Progress> = {
  type: Object,
  required: true,
};

const props = {
  progress: progressProp,
};

export default Vue.extend({
  filters: {
    fileSize(bytes: number) {
      return fileSize(bytes);
    },
  },
  props,

  computed: {
    progressComputable(): boolean {
      return this.progress.lengthComputable && this.progress.current !== 0 && this.progress.total !== 0;
    },

    completed(): boolean {
      return this.progress.current === this.progress.total;
    },

    percentage(): number {
      return (this.progress.current / this.progress.total) * 100;
    },
  },
});
