
import Vue from "vue";

import { ListJs, RecentJs } from "@shared/models";

import {
  FilesystemNode, FilesystemNodeRecents, FilesystemNodeType, toShortcutNode,
} from "@/common/fs";
import FilesystemViewer from "@/layouts/FilesystemViewer.vue";
import { getRecents } from "@/services/fs.service";
import { ApiCall, ApiCallOptions } from "@/store/actionTypes";
import { RecentsLoad } from "@/store/fs/mutationTypes";
import { AxShare } from "@/store/state";

import AxFilesystemRecentsTable from "@/components/AxFilesystemRecentsTable.vue";
import AxFilesystemViewerActionsBase from "@/components/AxFilesystemViewerActionsBase.vue";
import AxFilesystemViewerHeader from "@/components/AxFilesystemViewerHeader.vue";
import AxIcon from "@/components/AxIcon.vue";

export default Vue.extend({
  components: {
    FilesystemViewer,
    AxIcon,
    AxFilesystemRecentsTable,
    AxFilesystemViewerHeader,
    AxFilesystemViewerActionsBase,
  },

  data() {
    const recentsNode: FilesystemNodeRecents = {
      id: "recent",
      name: "Recents",
      type: FilesystemNodeType.Recents,
      listingNode: undefined,
      parent: undefined,
      totalItems: 0,
      created: new Date(),
    };

    return {
      recentsNode,
      recents: undefined as ListJs<RecentJs> | undefined,
    };
  },

  computed: {
    loading(): boolean {
      const { fs } = this.$store.state as AxShare;
      return fs.recentsLoad;
    },

    items(): FilesystemNode[] {
      if (!this.recents) return [];
      return this.recents.Items.map(recent => toShortcutNode(recent.Shortcut, this.recentsNode));
    },
  },

  watch: {
    "$route.query": function (query: any) {
      const { refresh } = query;
      if (this.recents && refresh === String(true)) {
        this.load();
      }
    },
  },

  created() {
    this.load();
  },

  methods: {
    async load() {
      const loadImpl = async () => {
        this.$store.commit(new RecentsLoad(true));
        try {
          this.recents = await getRecents();
          if (Object.keys(this.$route.query).length > 0) {
            this.$router.replace({ query: undefined });
          }
          this.$store.commit(new RecentsLoad(false));
        } catch (error) {
          console.warn("Error occurred when trying to load recents");
          throw error;
        } finally {
          this.$store.commit(new RecentsLoad(false));
        }
      };

      const options: ApiCallOptions = {
        action: () => loadImpl(),
      };
      await this.$store.dispatch(new ApiCall(options));
    },
  },
});
