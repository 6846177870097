import { ActionPayloadMap, ActionTypes } from "@/store/project/actionTypes";
import { AxShareProject } from "@/store/project/state";
import { AxShare } from "@/store/state";
import { ActionTree } from "@/store/typed";

const actions: ActionTree<AxShareProject, AxShare, ActionPayloadMap> = {
  [ActionTypes.PostToIframe]({ state }, { key, data, iframeBridgeTarget }) {
    const iframeBridgeProps = state.iframeBridgeProps;

    for (const iframeTarget in iframeBridgeProps) {
      if (Object.prototype.hasOwnProperty.call(iframeBridgeProps, iframeTarget)) {
        const iframeProps = iframeBridgeProps[iframeTarget];

        if (iframeProps && (iframeTarget === iframeBridgeTarget || iframeBridgeTarget === undefined)) {
          const { iframe, iframeTargetOrigin, iframeBridgeReady } = iframeProps;
          if (iframe && iframeTargetOrigin && iframeBridgeReady) {
            postMessageToIframe(iframe, iframeTargetOrigin, key, data);
          }
        }
      }
    }
  },
};

const postMessageToIframe = (iframe: HTMLIFrameElement, targetOrigin: string, key: string, data?: any) => {
  if (iframe.contentWindow) {
    const message = JSON.stringify({
      key,
      data,
    });
    iframe.contentWindow.postMessage(message, targetOrigin);
  }
};

export default actions;
