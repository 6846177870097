
import Vue from "vue";
import { PropOptions } from "vue/types/options";

import { ShortcutJs } from "@shared/models";
import { FilesystemNodeShortcut, isShortcutNode, ShortcutState, ShortcutStates, ShortcutStateName } from "@/common/fs";
import AxProjectLoader from "@/components/AxProjectLoader.vue";
import AxProjectStateEmpty from "@/components/AxProjectStateEmpty.vue";
import AxProjectStateGenerating from "@/components/AxProjectStateGenerating.vue";
import AxProjectStateGenerationFailed from "@/components/AxProjectStateGenerationFailed.vue";

const projectProp: PropOptions<FilesystemNodeShortcut | ShortcutJs> = {
  type: Object,
  required: false,
  default: undefined,
};
const projectIdProp = {
  type: String,
  required: false,
};
const projectStateProp = {
  type: String,
  required: false,
  validator(state: ShortcutStateName) {
    return ShortcutStates.includes(state);
  },
};

const props = {
  project: projectProp,
  projectId: projectIdProp,
  projectState: projectStateProp,
};

export default Vue.extend({
  components: {
    AxProjectLoader,
    AxProjectStateEmpty,
    AxProjectStateGenerating,
    AxProjectStateGenerationFailed,
  },

  props: {
    fetchUpdates: {
      type: Boolean,
      default: true,
    },

    showDefaults: {
      type: Boolean,
      default: true,
    },

    ...props,
  },

  computed: {
    state(): ShortcutStateName {
      if (this.project) {
        return isShortcutNode(this.project)
          ? this.project.shortcutState
          : (this.project.ShortcutState as ShortcutStateName);
      }

      return this.projectState as ShortcutStateName;
    },

    shortcut(): string {
      if (this.project) {
        return isShortcutNode(this.project) ? this.project.id : this.project.Shortcut;
      }

      return this.projectId;
    },

    empty(): boolean {
      return this.state === ShortcutState.Empty;
    },

    generating(): boolean {
      return this.state === ShortcutState.Generating;
    },

    notGenerated(): boolean {
      return this.state === ShortcutState.Deleted || this.state === ShortcutState.Empty;
    },

    generationFailed(): boolean {
      return this.state === ShortcutState.GenerationFailed || this.state === ShortcutState.Empty;
    },

    generated(): boolean {
      return this.state === ShortcutState.Ok;
    },
  },
});
