
import Vue from "vue";
import { Location } from "vue-router";

import AxLogin from "@/components/AxLogin.vue";
import { ROUTES } from "@/router";
import { GetAdminInfo } from "@/store/admin/actionTypes";

export default Vue.extend({
  components: { AxLogin },

  computed: {
    signupRoute(): Location {
      return {
        name: ROUTES.signup.name,
        query: this.$route.query,
      };
    },
  },

  methods: {
    async authSuccess() {
      await this.$store.dispatch(new GetAdminInfo());
    },
  },
});
