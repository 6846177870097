import { IframeBridgeTarget } from "@/store/project/state";
import { Payload, PayloadMap } from "@/store/typed";

export enum MutationTypes {
  SetIframeBridge = "[Project] Set Iframe Bridge HTML Element",
  SetIframeReady = "[Project] Set Iframe Element Ready",
  SetIframeBridgeReady = "[Project] Set Iframe Bridge Ready",
}

export class SetIframeBridge implements Payload {
  public readonly type = MutationTypes.SetIframeBridge;
  constructor(
    public iframeBridgeTarget: IframeBridgeTarget,
    public iframe?: HTMLIFrameElement,
    public target?: string
  ) {}
}

export class SetIframeReady implements Payload {
  public readonly type = MutationTypes.SetIframeReady;
  constructor(public iframeBridgeTarget: IframeBridgeTarget) {}
}

export class SetIframeBridgeReady implements Payload {
  public readonly type = MutationTypes.SetIframeBridgeReady;
  constructor(public iframeBridgeTarget: IframeBridgeTarget) {}
}

export interface MutationPayloadMap extends PayloadMap<MutationTypes> {
  [MutationTypes.SetIframeBridge]: SetIframeBridge;
  [MutationTypes.SetIframeReady]: SetIframeReady;
  [MutationTypes.SetIframeBridgeReady]: SetIframeBridgeReady;
}
