import {
  Action,
  AddHotspotRequest,
  ArchiveArtboardRequest,
  Artboard,
  Asset,
  CreateMasterRequest,
  DeleteArtboardRequest,
  DeleteArtboardsRequest,
  DeleteHotspotRequest,
  DeviceDropDownOption,
  EditHotspotRequest,
  Master,
  ModifyMasterRequest,
  Platform,
  Project,
  ReorderArtboardRequest,
  SetHeaderFooterHeightRequest,
  SetZoomRequest,
  UpdatePlatformRequest,
  WebPlatform,
  ExpoStaticContent,
} from "@shared/expo";
import { VarsJs, CreateMasterJs } from "@shared/models";

import { exec } from "@/services/api";
import { CreateExpoProjectModel } from "@/services/models/expo";
import { ResponseObject } from "@/services/models/responseObject";
import { server } from "@/services/server";
import { objectToFormData } from "@/services/utils/formData";

export interface ArtboardBundle {
  artboard: Artboard;
  assets: { [key: string]: Asset };
  imgUrls: { [key: string]: string };
  thumbnails: { [key: string]: string };
}

interface HotspotAddResponse {
  artboardId: string;
  action: Action;
  tagVal: string;
}

interface HotspotEditResponse {
  artboardId: string;
  action: Action;
  tagVal: string;
}

interface HotspotDeleteResponse {
  artboardId: string;
  actionId: string;
}

export interface UploadAsset {
  artboards: Artboard[];
  urls: string[];
  assets: Asset[];
}

export interface UploadBgImg {
  platform: WebPlatform;
  url: string;
}

export async function getStaticContent(shortcut: string, archivedOnly: boolean) {
  return exec<ExpoStaticContent>(server.get(`/expo/getstaticcontent/${shortcut}?archivedOnly=${archivedOnly}`));
}

export async function getProject(shortcut: string, getFullResponse: boolean = false) {
  const result = await exec(server.get(`/expo/getproject/${shortcut}`));
  return getFullResponse ? result : (result.project as Project);
}

export async function updatePlatform(shortcut: string, platform: Platform) {
  const data: UpdatePlatformRequest = { platform };
  return exec<ResponseObject>(server.post(`/expo/updateplatform/${shortcut}`, data));
}

export async function uploadBackgroundImage(shortcut: string, img: FormData) {
  const formData = objectToFormData({}, undefined, img);
  return exec<ResponseObject>(server.post(`/expo/uploadbackgroundimg/${shortcut}`, formData));
}

export async function deleteBackgroundImage(shortcut: string) {
  return exec<UploadBgImg>(server.post(`/expo/deletebackgroundimg/${shortcut}`));
}

export async function getArtboard(shortcut: string, artboardId: string) {
  const response = await exec<ArtboardBundle>(server.get(`/expo/getartboard/${shortcut}?artboardId=${artboardId}`));
  return {
    artboard: response.artboard,
    artboardAsset: response.assets[response.artboard.AssetId],
    artboardAssetSrc: response.imgUrls[response.artboard.AssetId],
    artboardThumbnailSrc: response.thumbnails[response.artboard.AssetId],
    assets: response.assets,
    assetsUrls: response.imgUrls,
    thumbnails: response.thumbnails,
  };
}

export function artboardDelete(shortcut: string, artboardId: string) {
  const data: DeleteArtboardRequest = { artboardId };
  return exec<ResponseObject>(server.post(`/expo/deleteartboard/${shortcut}`, data));
}

export function artboardsDelete(shortcut: string, artboardIds: string[]) {
  const data: DeleteArtboardsRequest = { artboardIds };
  return exec<ResponseObject>(server.post(`/expo/deleteartboards/${shortcut}`, data));
}

export function artboardArchive(shortcut: string, artboardId: string) {
  const data: ArchiveArtboardRequest = { artboardId };
  return exec<ResponseObject>(server.post(`/expo/archiveartboard/${shortcut}`, data));
}

export function artboardUnarchive(shortcut: string, artboardId: string) {
  const data: ArchiveArtboardRequest = { artboardId };
  return exec<ResponseObject>(server.post(`/expo/unarchiveartboard/${shortcut}`, data));
}

export function artboardReorder(
  shortcut: string,
  artboardId: string,
  tailArtboardId: string = "",
  fullResequence: boolean = false
) {
  const data: ReorderArtboardRequest = {
    targetAbId: artboardId,
    tailAbId: tailArtboardId,
    fullResequence,
  };
  return exec<ResponseObject>(server.post(`/expo/reorderartboard/${shortcut}`, data));
}

export function addArtboard(shortcut: string, artboard: File) {
  const data = new FormData();
  data.append("fileData", artboard);
  return exec<ResponseObject>(server.post(`/expo/addartboard/${shortcut}`, data));
}

export function addRemoveArtboardMaster(
  shortcut: string,
  artboardId: string,
  masterId: string,
  isAdd: boolean = false
) {
  const data: ModifyMasterRequest = {
    artboardId,
    masterId,
    name: "",
  };

  return exec<ResponseObject>(server.post(`/expo/${isAdd ? "addmaster" : "removemaster"}/${shortcut}`, data));
}

export function hotspotAdd(shortcut: string, artboard: Artboard, action: Action) {
  const data: AddHotspotRequest = {
    tag: "",
    hotspot: action.Hotspot,
    setting: action.Setting,
    type: action.Type,
    artboardId: action.IsMaster ? undefined : artboard.Id,
    masterId: action.IsMaster ? action.ParentId : undefined,
    relativeToBottom: action.RelativeToBottom,
  };
  return exec<HotspotAddResponse>(server.post(`/expo/addhotspot/${shortcut}`, data));
}

export function hotspotEdit(shortcut: string, originalAction: Action, action: Action) {
  const data: EditHotspotRequest = {
    sourceId: originalAction.ParentId,
    targetId: action.ParentId,
    actionId: action.Id,
    hotspot: action.Hotspot,
    setting: action.Setting,
    type: action.Type,
    relativeToBottom: action.RelativeToBottom,
    isSourceMaster: originalAction.IsMaster,
    isTargetMaster: action.IsMaster,
  };

  return exec<HotspotEditResponse>(server.post(`/expo/edithotspot/${shortcut}`, data));
}

export function hotspotDelete(shortcut: string, isMaster: boolean, sourceId: string, actionId: string) {
  const data: DeleteHotspotRequest = {
    isMaster,
    sourceId,
    actionId,
  };
  return exec<HotspotDeleteResponse>(server.post(`/expo/deletehotspot/${shortcut}`, data));
}

export function masterCreate(shortcut: string, name: string, artboardId: string) {
  const data: CreateMasterRequest = {
    name,
    artboardId,
  };
  return exec<CreateMasterJs>(server.post(`/expo/createmaster/${shortcut}`, data));
}

export function masterRename(shortcut: string, masterId: string, name: string) {
  const data: ModifyMasterRequest = {
    artboardId: "",
    masterId,
    name,
  };
  return exec(server.post(`/expo/renamemaster/${shortcut}`, data));
}

export function masterCopy(shortcut: string, artboardId: string, masterId: string) {
  const data: ModifyMasterRequest = {
    artboardId,
    masterId,
    name: "",
  };
  return exec<CreateMasterJs>(server.post(`/expo/copymaster/${shortcut}`, data));
}

export function masterDelete(shortcut: string, masterId: string) {
  const data: ModifyMasterRequest = {
    artboardId: "",
    masterId,
    name: "",
  };
  return exec(server.post(`/expo/deletemaster/${shortcut}`, data));
}

export async function saveProjectZoom(shortcut: string, zoom: number) {
  const data: SetZoomRequest = { zoom };
  return exec<ResponseObject>(server.post(`/expo/setzoom/${shortcut}`, data));
}

export async function setHeaderFooterHeight(shortcut: string, artboard: Artboard, height: number, isHeader: boolean) {
  const data: SetHeaderFooterHeightRequest = {
    artboardId: artboard.Id,
    height,
    isHeader,
  };
  return exec(server.post(`/expo/setheaderfooterheight/${shortcut}`, data));
}

export function createProject(model: CreateExpoProjectModel) {
  const formData = objectToFormData(model);
  return exec<VarsJs>(server.post("/expo/create", formData));
}

export function checkExpoNameValid(name: string, folderId: string) {
  const formData = objectToFormData({ name, folderId });
  return exec<ResponseObject>(server.post("/expo/checklimitexponamevalid", formData));
}

let deviceList: DeviceDropDownOption[] | undefined;
export async function getDeviceList() {
  // let's cache device list forever in memory because it changes extremely rarely
  if (deviceList) return deviceList;
  const deviceListResponse = await exec<DeviceDropDownOption[]>(server.get("/expo/getDeviceList"));
  deviceList = deviceListResponse;
  return deviceList;
}
