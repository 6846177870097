
import Vue from "vue";

import { SharedFilesystemMappingJs } from "@shared/models";

import AxUserAvatar from "@/components/AxUserAvatar.vue";
import { arrayProp } from "@/components/utils";

export default Vue.extend({
  components: {
    AxUserAvatar,
  },

  props: {
    items: arrayProp<SharedFilesystemMappingJs>(),

    inversed: {
      type: Boolean,
      default: false,
    },

    expandable: {
      type: Boolean,
      default: true,
    },

    avatarSize: {
      type: String,
      default: "24px",
    },
  },

  computed: {
    classes(): Record<string, boolean> {
      return {
        "ax-avatar-stack--inversed": this.inversed,
        "ax-avatar-stack--expandable": this.expandable,
      };
    },
  },

  methods: {
    avatarProps(item: SharedFilesystemMappingJs) {
      return {
        user: item.user,
        title: item.user.userEmail,
        w: this.avatarSize,
        h: this.avatarSize,
      };
    },
  },
});
