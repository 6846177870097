
import Vue, { VueConstructor } from "vue";

import { SsoResponse, SharedFilesystemMappingJs } from "@shared/models";

import { asError } from "@/common/lib";
import { workspaceMembersMixin } from "@/mixins/WorkspaceMembers";
import { fsRouterLocation } from "@/router/fs";
import {
  setReviewerOnly, setSecurityPrefs, setUserOnly, unshareFileSystem,
} from "@/services/fs.service";
import { ConfigureSecurityModel } from "@/services/models";
import { AccessLevels, ManageUsersModel, UserProfile } from "@/services/models/account";
import { ManageUsersRemoveModel } from "@/services/models/filesystem";
import { GetSubinstanceAccounts } from "@/store/admin/actionTypes";
import { Fetch } from "@/store/fs/actionTypes";

import AxForm from "@/components/AxForm.vue";
import AxManageUsersTable from "@/components/AxManageUsers/AxManageUsersTable.vue";
import { Submit } from "@/components/types/AxForm";

type Mixins = InstanceType<typeof workspaceMembersMixin>;

export default (Vue as VueConstructor<Vue & Mixins>).extend({
  components: {
    AxForm,
    AxManageUsersTable,
  },

  mixins: [workspaceMembersMixin],

  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const errors: string[] = [];

    return {
      persistent: false,
      dialog: false,
      submitting: false,
      errors,
    };
  },

  computed: {
    existingMembersInWorkspace(): number {
      return this.workspaceMembers.length;
    },

    workspaceMembers(): SharedFilesystemMappingJs[] {
      const { sharedWith, owner } = this;
      const workspaceMembers: SharedFilesystemMappingJs[] = [];

      if (this.sharedWith.length > 0) {
        workspaceMembers.push(...sharedWith);
      } else if (owner) {
        workspaceMembers.push(owner);
      }

      return workspaceMembers;
    },

    model(): ManageUsersModel {
      const { userOnly, httpsOnly, invited } = this.workspace;
      const invitedUserIds = invited.map(item => item.user.userId);

      const model: ManageUsersModel = {
        userOnly,
        httpsOnly,
        users: this.workspaceMembers.map(item => ({
          user: item.user,
          profile: this.getProfile(item.user),
          isViewer: item.isViewer,
          Level: this.getLevel(item.user),
          isViewerAccount: this.isViewerAccount(item.user),
          isOwner: this.owner && this.owner.user.userId === item.user.userId,
          invitationAccepted: invitedUserIds.includes(item.user.userId),
        })),
      };
      return model;
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      if (this.isSubInstance) {
        await this.$store.dispatch(new GetSubinstanceAccounts());
      }
    },

    async changeOwnerSubmit() {
      await this.$router.replace(fsRouterLocation(this.$route.params.id));
    },

    getProfile(user: SsoResponse): UserProfile {
      return {
        Name: user.userEmail,
        Bio: user.bio,
        Img: user.profileImageUrl,
      };
    },

    isViewerAccount(user: SsoResponse): boolean {
      const { isSubInstance } = this;
      if (isSubInstance) {
        const account = this.subInstanceAccounts.find(item => item.Id === user.userId);
        if (account) return account.Level === AccessLevels.Reviewer || account.Level === AccessLevels.TechnicalAdmin;
      }
      return false;
    },

    async remove(user: SsoResponse) {
      this.submitting = true;
      try {
        const usersRemove: Partial<ManageUsersRemoveModel> = {
          usersToRemove: [user.userId],
          filesystemId: this.workspace.id,
          removeAllUsers: false,
        };
        await unshareFileSystem(usersRemove);
        await this.$store.dispatch(new Fetch(this.workspace));
        this.$emit("remove");
      } catch (error) {
        this.errors.push(asError(error).message);
      } finally {
        this.submitting = false;
      }
    },

    async changeAccessLevel(user: SsoResponse, viewOnly: boolean) {
      this.errors = [];
      this.submitting = true;
      try {
        const { isSubInstance, workspace } = this;
        const index = this.model.users.findIndex(item => item.user.userId === user.userId);
        this.model.users[index].isViewer = viewOnly;
        if (isSubInstance) {
          await setReviewerOnly(workspace.id, user.userEmail, viewOnly);
        } else {
          let viewerUsers = this.model.users.filter(item => item.isViewer).map(item => item.user.userId);
          if (viewOnly) {
            viewerUsers.push(user.userId);
          } else {
            viewerUsers = viewerUsers.filter(item => item !== user.userId);
          }

          const configureSecurity: Partial<ConfigureSecurityModel> = {
            FilesystemId: workspace.id,
            userOnly: workspace.userOnly,
            httpsOnly: workspace.httpsOnly,
            viewerUsers,
          };
          await setSecurityPrefs(configureSecurity);
        }
        await this.$store.dispatch(new Fetch(this.workspace));
      } catch (error) {
        this.errors.push(asError(error).message);
      } finally {
        this.submitting = false;
      }
    },

    async submit({ values }: Submit<ManageUsersModel>) {
      const { workspace, model, isSubInstance } = this;
      const isUserOnlyModified = model.userOnly !== values.userOnly;
      const viewerUsers = values.users.filter(user => user.isViewer).map(item => item.user.userId);

      if (isSubInstance) {
        if (isUserOnlyModified) {
          await setUserOnly(workspace.id, values.userOnly);
        }
      }

      if (!isSubInstance) {
        const configureSecurity: Partial<ConfigureSecurityModel> = {
          FilesystemId: workspace.id,
          userOnly: values.userOnly,
          httpsOnly: values.httpsOnly,
          viewerUsers,
        };
        await setSecurityPrefs(configureSecurity);
      }

      this.$emit("submit");
    },

    cancel() {
      this.$emit("cancel");
    },
  },
});
