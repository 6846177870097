
import Vue from "vue";

import AxIcon from "@/components/AxIcon.vue";
import AxAxureLogo from "@/components/icons/AxAxureLogo.vue";

export default Vue.extend({
  components: {
    AxIcon,
    AxAxureLogo,
  },

  props: {
    logoRoute: {
      type: [Object, String],
      required: false,
      default: undefined,
    },
  },
});
