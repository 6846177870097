
import Vue from "vue";
import { PropOptions } from "vue/types/options";

import { SsoResponse } from "@shared/models";

import { ManageUserModel, AccessLevels } from "@/services/models/account";
import { AxShare } from "@/store/state";

import { AxShareUser } from "../../store/user/state";

import AxButton from "@/components/AxButton.vue";
import AxDataTable from "@/components/AxDataTable.vue";
import AxDropdownButton from "@/components/AxDropdownButton.vue";
import AxIcon from "@/components/AxIcon.vue";
import AxList from "@/components/AxList.vue";
import AxListItem from "@/components/AxListItem.vue";
import AxTooltip from "@/components/AxTooltip.vue";
import AxUser from "@/components/AxUser.vue";
import { Header } from "@/components/types/AxDataTable";

const usersProp: PropOptions<ManageUserModel[]> = {
  type: Array,
  required: true,
};

interface UserListItem extends ManageUserModel {
  accessLevelName: string;
  canChangeAccessLevel: boolean;
}

export default Vue.extend({
  components: {
    AxButton,
    AxDataTable,
    AxDropdownButton,
    AxList,
    AxListItem,
    AxIcon,
    AxUser,
    AxTooltip,
  },

  props: {
    users: usersProp,

    disabled: {
      type: Boolean,
      default: false,
    },

    editMode: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    headers(): Header[] {
      const headers: Header[] = [
        {
          text: "Email",
          value: "Email",
        },
        {
          text: "Review Only",
          value: "ViewOnly",
          width: this.editMode ? "20%" : "auto",
        },
        {
          text: "Remove User",
          width: "5%",
        },
      ];

      // Show "Pending Invitation" header only if has invited
      if (this.hasPendingInvitations) {
        headers.splice(1, 0, {
          text: "Pending Invitation",
          width: "5%",
        });
      }

      return headers;
    },

    hasPendingInvitations(): boolean {
      return this.usersList.filter(user => !!user.invitationAccepted).length > 0;
    },

    isSubInstance(): boolean {
      const { axShareConfig } = this.$store.state as AxShare;
      return axShareConfig !== null && axShareConfig.IsSubInstance;
    },

    currentUser(): AxShareUser {
      const { user } = this.$store.state as AxShare;
      return user;
    },

    usersList(): UserListItem[] {
      const users = this.users.map(u => ({
        ...u,
        isAdmin: u.Level === AccessLevels.SuperAdmin || u.Level === AccessLevels.Admin,
        canChangeAccessLevel: this.canChangeAccessLevel(u),
        accessLevelName: this.getAccessLevelName(u),
      }));
      return users;
    },
  },

  methods: {
    canChangeAccessLevel(user: ManageUserModel): boolean {
      const { editMode, isSubInstance } = this;
      const { isOwner } = user;

      return editMode && !isOwner && (!isSubInstance || (isSubInstance && user.Level === AccessLevels.Author));
    },

    getAccessLevelName(user: ManageUserModel) {
      let accessLevelName = "";
      if (user.isOwner) {
        accessLevelName = "Owner";
      } else if (this.isSubInstance && (user.Level === AccessLevels.Admin || user.Level === AccessLevels.SuperAdmin)) {
        accessLevelName = "Admin";
      } else {
        accessLevelName = user.isViewer ? "Viewer" : "Author";
      }

      return accessLevelName;
    },

    remove(user: SsoResponse) {
      this.$emit("remove", user);
    },

    changeAccessLevel(user: SsoResponse, viewOnly: boolean) {
      this.$emit("changeAccessLevel", user, viewOnly);
    },
  },
});
