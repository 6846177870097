
import Vue from "vue";
import { Location } from "vue-router";

import { FilesystemNodeType, FilesystemNodeTypes, FilesystemNodeWorkspace } from "@/common/fs";
import { fsRouterLocation } from "@/router/fs";

import AxNavigationLinkPrototype from "./AxNavigationLinkPrototype.vue";

export default Vue.extend({
  components: {
    AxNavigationLinkPrototype,
  },

  props: {
    id: {
      type: String,
      required: true,
    },

    navigationNode: {
      type: Object,
      required: false,
      default: undefined,
    },

    workspace: {
      type: Object,
      required: false,
      default: undefined,
    },
  },

  data() {
    return {
      FilesystemNodeType,
    };
  },

  computed: {
    node(): FilesystemNodeTypes {
      if (this.navigationNode) return this.navigationNode;

      const node = this.$store.getters.getFsNode(this.id);
      return node;
    },

    workspaceComputed(): FilesystemNodeWorkspace | undefined {
      return this.workspace || this.$store.getters.findWorkspace(this.id);
    },

    linkReady(): boolean {
      const { id, type } = this.node;
      if (type === FilesystemNodeType.Root) return true;
      if (type === FilesystemNodeType.Folder) {
        return id !== undefined && this.workspaceComputed !== undefined;
      }
      return id !== undefined;
    },

    fsLocation(): Location {
      const { type } = this.node;
      if (type === FilesystemNodeType.Folder && this.workspaceComputed) {
        return fsRouterLocation(this.node, { workspaceId: this.workspaceComputed.id });
      }
      return fsRouterLocation(this.node);
    },
  },
});
