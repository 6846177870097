
import Vue, { VNodeData } from "vue";

import { isIE } from "@/common/lib";

export default Vue.extend({
  functional: true,
  props: {
    name: {
      type: String,
      required: false,
      default: "",
    },
    size: {
      type: String,
      default: "auto",
    },
    w: {
      type: String,
      default: "",
    },
    h: {
      type: String,
      default: "",
    },
    rotate: {
      type: [String, Number],
      default: "",
    },
  },

  render(h, context) {
    const { props, data, slots, listeners } = context;

    const style: Partial<CSSStyleDeclaration> = {};
    if (props.w) {
      style.width = context.props.w;
    }
    if (props.h) {
      style.height = context.props.h;
    }
    if (props.rotate) {
      const translateY = typeof props.rotate === "number" ? `${(props.rotate / 360) * 100}%` : 0;
      const rotation = typeof props.rotate === "number" ? `${props.rotate}deg` : props.rotate;
      style.transform = `rotate(${rotation}) translateY(${translateY})`;
    }

    const innerIcon = slots().default;
    const classBinding = [`ax-icon--${props.size}`, data.class, data.staticClass];
    if (isIE) {
      // marker class to fix SVG icons behavior in Internet Explorer
      classBinding.push("ax-icon--ie");
    }
    const nodeData: VNodeData = {
      class: classBinding,
      staticClass: "ax-icon",
      style,
      on: listeners,
      ref: "icon",
    };

    if (!innerIcon) {
      nodeData.domProps = {
        // don't use string template, as it breaks jest resolve in watch mode
        // see: https://github.com/facebook/jest/issues/7511
        // eslint-disable-next-line prefer-template, import/no-dynamic-require, global-require
        innerHTML: require("@/assets/svg/" + props.name + ".svg"),
      };
    }

    return h("div", nodeData, [innerIcon]);
  },
});
