
import Vue from "vue";
import { Location } from "vue-router";

import { AnalyticsClickEvents } from "@/common/axshare";
import { accountServiceUnavailable } from "@/common/axshare/messages";
import { createCustomEvent } from "@/common/lib/events";
import { hashPassword } from "@/common/utils";
import { ROUTES } from "@/router";
import AccountService from "@/services/account.service";
import { DoLoginModel } from "@/services/models/account";
import { AxShare } from "@/store/state";

import AxButton from "@/components/AxButton.vue";
import AxForgotPassword from "@/components/AxForgotPassword.vue";
import AxForm from "@/components/AxForm.vue";
import AxFormErrors from "@/components/AxFormErrors.vue";
import AxFormGroup from "@/components/AxFormGroup.vue";
import AxFormSuccess from "@/components/AxFormSuccess.vue";
import AxInput from "@/components/AxInput.vue";
import { Submit } from "@/components/types/AxForm";

interface Model {
  email: string;
  password: string;
  staySignedIn: boolean;
}

export default Vue.extend({
  components: {
    AxButton,
    AxForm,
    AxFormGroup,
    AxForgotPassword,
    AxInput,
    AxFormErrors,
    AxFormSuccess,
  },

  props: {
    requestId: {
      type: String,
      required: true,
    },
    machineId: {
      type: String,
      required: false,
      default: "",
    },
    rpVersion: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      AnalyticsClickEvents,
      model: {
        email: "",
        password: "",
        staySignedIn: true,
      },
      errors: [] as string[],
      successMessages: [] as string[],
    };
  },

  computed: {
    accountService(): AccountService | undefined {
      const { accountService } = this.$store.state as AxShare;
      if (accountService) {
        return accountService();
      }
      return undefined;
    },

    signupRoute(): Location {
      return { name: ROUTES["rp.signup"].name, query: this.$route.query };
    },

    buttonClass(): string {
      return (this.machineId ? "login-button-10 login-submit-button " : "login-button-9 login-submit-button ")
        + AnalyticsClickEvents.LogInSubmitButton.eventClass;
    },
  },

  mounted() {
    const { user } = this.$store.state as AxShare;
    if (user && user.userInfo) {
      this.model.email = user.userInfo.userEmail;
    }
  },

  methods: {
    forgotPasswordErrors(errors: string) {
      this.setStatusMessages({ errors });
    },

    forgotPasswordSuccess(success: string) {
      this.setStatusMessages({ success });
    },

    setStatusMessages({ success = [], errors = [] }: { success?: string | string[]; errors?: string | string[] }) {
      const successMessages = Array.isArray(success) ? success : [success];
      const errorMessages = Array.isArray(errors) ? errors : [errors];
      this.successMessages = successMessages;
      this.errors = errorMessages;
    },

    addError(errorMessage: string) {
      this.errors.push(errorMessage);
    },

    clearStatusMessages() {
      this.setStatusMessages({ success: [], errors: [] });
    },

    async submit({ values }: Submit<Model>) {
      this.clearStatusMessages();
      const { accountService } = this;
      if (!accountService) {
        throw new Error(accountServiceUnavailable);
      }
      const { email, password, staySignedIn } = values;
      const loginPassword = await hashPassword(password);
      const passwordBlank = !password;
      const altPassword = loginPassword;

      const model: DoLoginModel = {
        loginEmail: email,
        loginPassword,
        altPassword,
        staySignedIn,
        clearPass: false,
        accountService: true,
        passwordBlank,
        rpRequestId: this.requestId,
      };

      const response = await accountService.doLoginRequest(model);
      if (response.success) {
        // dispatch custom event for tracking purposes
        const event = createCustomEvent("rp-signin-success", { email });
        document.dispatchEvent(event);

        await this.$router.push({
          name: ROUTES["rp.redirect"].name,
          params: {
            token: response.authToken,
          },
          query: {
            r: this.requestId,
            m: this.machineId,
            rp: this.rpVersion,
          },
        });
      } else {
        await this.$router.push({
          name: ROUTES["rp.error"].name,
        });
      }
    },
  },
});
