
import {
  ValidationError, Validator, PropertyValidator, ValidationResult, ValidationContext,
} from "lakmus";
import Vue, { VNode } from "vue";
import { PropValidator } from "vue/types/options";

import {
  multiSplit, isApple, isKeyboardKey, KeyCodeKeys,
} from "@/common/lib";

import { arrayProp } from "@/components/utils";

const validationSuccess = {
  isValid: true,
  errors: [] as ValidationError[],
};

interface Tags {
  newTag: string;
  tags: string[];
}

interface TagsValidatorOptions {
  allowDuplicates: boolean;
}

export class UniqueValidator<T> extends PropertyValidator {
  private collectionAccessor?: (instance: T) => any[];

  constructor(errorMessage?: string) {
    super(errorMessage);
  }

  public withCollection(obj: (instance: T) => any[]) {
    this.collectionAccessor = obj;
    return this;
  }

  public isValid(context: ValidationContext): boolean {
    if (!this.collectionAccessor) {
      return false;
    }

    return !this.collectionAccessor(context.instance).includes(context.propertyValue);
  }

  public getErrorMessage(ctx: ValidationContext) {
    return `'${ctx.propertyValue}' is already added`;
  }
}

class TagsValidator extends Validator<Tags> {
  constructor(
    private readonly options: TagsValidatorOptions = {
      allowDuplicates: false,
    },
    private readonly newTagValidators?: PropertyValidator | PropertyValidator[],
  ) {
    super();

    if (!this.options.allowDuplicates) {
      this.ruleFor(x => x.newTag).setValidator(new UniqueValidator<Tags>().withCollection(x => x.tags));
    }

    if (this.newTagValidators) {
      const validators = Array.isArray(this.newTagValidators) ? this.newTagValidators : [this.newTagValidators];
      if (validators) {
        for (const validator of validators) {
          this.ruleFor(x => x.newTag).setValidator(validator);
        }
      }
    }
  }
}

export default Vue.extend({
  props: {
    value: arrayProp<string>(),

    disableAddOnBlur: {
      type: Boolean,
      default: false,
    },

    validator: {
      type: [Object, Array],
      default: undefined,
    } as PropValidator<PropertyValidator>,

    allowDuplicates: {
      type: Boolean,
      default: false,
    },

    submitKeys: arrayProp<KeyCodeKeys>({
      default(): KeyCodeKeys[] {
        return ["enter"] as KeyCodeKeys[];
      },
    }),

    separators: {
      type: Array,
      default(): string[] {
        return [];
      },
    } as PropValidator<string[]>,
  },

  data() {
    return {
      newTag: "",
      innerValidator: Object.freeze(new TagsValidator({ allowDuplicates: this.allowDuplicates }, this.validator)),
      validationResult: validationSuccess,
    };
  },

  computed: {
    firstTag(): string | undefined {
      return this.value[0];
    },

    lastTag(): string | undefined {
      return this.value[this.value.length - 1];
    },
  },

  methods: {
    addTag() {
      const tag = this.newTag.trim();
      if (!tag) {
        this.clearValidation();
        return;
      }

      const validationResult = this.validate(tag);
      if (!validationResult.isValid) {
        this.$emit("validation-error", validationResult);
        return;
      }
      if (tag) {
        this.$emit("input", [...this.value, tag]);
        this.clearNewTag();
        this.$emit("added", tag);
        return true;
      }
    },

    removeTag(tag: any) {
      if (tag) {
        this.$emit("input", this.value.filter(t => t !== tag));
        if (this.newTag) {
          this.$emit("edit", tag);
        } else {
          this.$emit("removed", tag);
        }
      }
    },

    clearNewTag() {
      this.newTag = "";
    },

    validate(tag: string): ValidationResult {
      this.validationResult = this.innerValidator.validate({
        newTag: tag,
        tags: this.value,
      });
      return this.validationResult;
    },

    updateValueFromEvent(e: string | InputEvent) {
      let value: string = "";
      if (typeof e === "string") {
        value = e;
      } else {
        if (!e.target) return;
        if (e.target instanceof HTMLInputElement) {
          value = e.target.value;
        }
      }
      this.newTag = value;
      this.clearValidation();
    },

    clearValidation() {
      this.validationResult = validationSuccess;
    },

    tryHandleAdd(e: KeyboardEvent, shouldPrevent: boolean) {
      if (this.submitKeys.some(submitKey => isKeyboardKey(e, submitKey))) {
        if (shouldPrevent) {
          e.preventDefault();
        }
        this.addTag();
        return true;
      }
    },

    tryHandleRemove(e: KeyboardEvent, shouldPrevent: boolean) {
      if (isKeyboardKey(e, "backspace")) {
        if (!this.newTag) {
          if (this.lastTag) {
            this.newTag = this.lastTag;
          }
          if (shouldPrevent) {
            e.preventDefault();
          }
          this.removeTag(this.lastTag);
          return true;
        }
      }
    },

    async handleClipboardEvent(e: ClipboardEvent) {
      try {
        const clipboardData = e.clipboardData;
        if (clipboardData) {
          const pastedText = clipboardData.getData("text");
          const emails = multiSplit(pastedText, this.separators, true);
          if (emails.length > 1) {
            for (const email of emails) {
              this.newTag = email;
              this.addTag();
              // eslint-disable-next-line no-await-in-loop
              await this.$nextTick();
              this.newTag = "";
            }

            e.preventDefault();
          }
        }
      } catch {
        // don't prevent and let browser continue with default paste behavior
      }
    },
  },

  render(h): VNode {
    return (
      (((this.$scopedSlots.default
        && this.$scopedSlots.default({
          tags: this.value,
          current: this.newTag,
          firstTag: this.firstTag,
          lastTag: this.lastTag,
          addTag: this.addTag,
          removeTag: this.removeTag,
          validationResult: this.validationResult,
          clearNewTag: this.clearNewTag,
          inputAttrs: {
            value: this.newTag,
          },
          inputEvents: {
            input: (e: string | InputEvent) => {
              this.updateValueFromEvent(e);
            },
            blur: () => {
              if (!this.disableAddOnBlur) {
                this.addTag();
              }
            },
            keydown: (e: KeyboardEvent) => {
              const target = e.target as HTMLInputElement;
              let shouldPrevent = true;
              if (isKeyboardKey(e, "tab")) {
                // allow tabbing further when input is empty
                shouldPrevent = false;
              }
              if (isKeyboardKey(e, "left")) {
                if (target.selectionStart === 0) {
                  this.$emit("escape-input");
                }
              }
              if (isKeyboardKey(e, "a")) {
                if ((isApple && e.metaKey) || (!isApple && e.ctrlKey)) {
                  if (!this.newTag) {
                    e.preventDefault();
                    this.$emit("select-all");
                    return;
                  }
                }
              }
              const handled = this.tryHandleAdd(e, shouldPrevent) || this.tryHandleRemove(e, shouldPrevent);
              return handled;
            },
            paste: (e: ClipboardEvent) => {
              this.$emit("before-paste");
              this.handleClipboardEvent(e).finally(() => {
                this.$emit("after-paste");
              });
            },
          },
        })) as unknown) as VNode) || h("")
    );
  },
});
