import { RouteConfig } from "vue-router";
import { RoutePropsFunction } from "vue-router/types/router";

import { isSketchProject, RouterNavigationLink } from "@/common/axshare";
import { FilesystemNodeShortcut, ShortcutState } from "@/common/fs";
import fsRouter from "@/router/fs";
import homeRouter from "@/router/home";
import { getPrototypeRedirectCommentInfo } from "@/services/project.service";
import store from "@/store";
import { ApiCall, ApiCallOptions } from "@/store/actionTypes";
import { ProjectLoad } from "@/store/fs/actionTypes";

import configureRouter, { Keys as configure } from "./configure";
import expoRouter, { Keys as expo } from "./expo";

const Project = () => import(/* webpackChunkName: "project" */ "@/pages/Project.vue");
const License = () => import(/* webpackChunkName: "project" */ "@/pages/License.vue");
const Overview = () => import(/* webpackChunkName: "project" */ "@/pages/ProjectOverview.vue");
const Discussions = () => import(/* webpackChunkName: "project" */ "@/pages/ProjectDiscussions.vue");
const DiscussionsOverview = () => import(/* webpackChunkName: "project" */ "@/pages/ProjectDiscussionsOverview.vue");
const ProjectNavigation = () => import(/* webpackChunkName: "project" */ "@/components/AxProjectNavigation.vue");
const History = () => import(/* webpackChunkName: "project" */ "@/pages/ProjectHistory.vue");

// prettier-ignore
export type Keys =
  | "project"
  | "project.overview"
  | "project.comments"
  | "project.discussions"
  | "project.discussions.overview"
  | "project.history"
  | configure
  | expo;

const props: RoutePropsFunction = route => ({
  project: store.getters.current.node,
  ...route.params,
});

const routes: { readonly [R in Keys]: RouteConfig } = {
  "project.comments": {
    path: "project/:shortcut/comments",
    name: "project.comments",
    async beforeEnter(to, _, next) {
      const { shortcut } = to.params;
      const projectOverview = { name: routes["project.overview"].name, params: { shortcut } };
      try {
        const commentInfo = await getPrototypeRedirectCommentInfo(shortcut);

        if (!commentInfo || !commentInfo.IssueCode || !commentInfo.ShortPageId) {
          return next(projectOverview);
        }

        return next({
          name: routes["expo.preview"].name,
          params: { shortcut, screen: commentInfo.ShortPageId },
          query: { comment: commentInfo.IssueCode },
        });
      } catch {
        return next(projectOverview);
      }
    },
  },
  project: {
    path: "project/:shortcut",
    name: "project",
    components: {
      default: Project,
      projectNavigation: ProjectNavigation,
      license: License,
    },
    props: {
      default: props,
      projectNavigation: props,
    },
    async beforeEnter(to, from, next) {
      const fetchProject = async () => {
        const { shortcut } = to.params;

        // rewrite to lowercase shortcut parameter
        if (to.name && shortcut !== shortcut.toLowerCase()) {
          // eslint-disable-next-line no-param-reassign
          to.params.shortcut = shortcut.toLowerCase();
          return next({
            ...to,
            name: to.name,
          });
        }

        await store.dispatch(new ProjectLoad(shortcut));

        const project: FilesystemNodeShortcut | undefined = store.getters.getFsNode(shortcut);
        if (!project) {
          return next(fsRouter.routes.fs);
        }

        if (project.shortcutState === "Deleted") {
          const navigateToProjectDeleted = { name: homeRouter.routes.projectDeleted.name };
          return next(navigateToProjectDeleted);
        }

        // Block access to configure specific routes
        const { axShareConfig } = store.state;
        if (axShareConfig) {
          const routeName = (to.name || "").toLowerCase();
          if (routeName.indexOf(".configure") >= 0) {
            // Block access to all configure routes if advanced tab is not available
            if (!isProjectAdvancedTabAvailable(project)) {
              const navigateToOverview = { name: routes["project.overview"].name, params: { shortcut } };
              return next(navigateToOverview);
            }

            // Block access to plugins specific routes if plugins feature is turned off
            if (routeName.indexOf(".plugins") >= 0
              && !axShareConfig.EnablePlugins) {
              const navigateToConfigure = { name: routes["project.configure"].name, params: { shortcut } };
              return next(navigateToConfigure);
            }
          }
        }

        next();
      };

      const onFailure = (_error?: Error | unknown) => {
        if (store.state.initializationStatus === "failure") {
          return next();
        }
        if (from.path === homeRouter.routes.home.path) {
          return next(homeRouter.routes.home.path);
        }

        return next(false);
      };
      const options: ApiCallOptions = {
        action: fetchProject,
        onFailure,
      };
      await store.dispatch(new ApiCall(options));
    },
  },
  "project.overview": {
    path: "overview",
    name: "project.overview",
    component: Overview,
    props,
  },
  "project.discussions": {
    path: "discussions",
    name: "project.discussions",
    component: Discussions,
    props,
  },
  "project.discussions.overview": {
    path: ":issueCode/overview",
    name: "project.discussions.overview",
    component: DiscussionsOverview,
    props,
  },
  "project.history": {
    path: "history",
    name: "project.history",
    component: History,
    props,
  },
  ...configureRouter.routes,
  ...expoRouter.routes,
};

const config = [
  routes["project.comments"],
  {
    ...routes.project,
    redirect: routes["project.overview"],
    children: [
      routes["project.overview"],
      {
        ...routes["project.discussions"],
        children: [routes["project.discussions.overview"]],
      },
      routes["project.history"],
      ...configureRouter.config,
      ...expoRouter.config,
    ],
  },
];

export const projectLinks = (project: FilesystemNodeShortcut) => {
  const { id: shortcut } = project;
  const links: RouterNavigationLink[] = [
    { title: "Overview", to: { name: routes["project.overview"].name, params: { shortcut } } },
    { title: "Discussions", to: { name: routes["project.discussions"].name, params: { shortcut } } },
    { title: "History", to: { name: routes["project.history"].name, params: { shortcut } } },
  ];

  if (isProjectAdvancedTabAvailable(project)) {
    links.push({ title: "Advanced", to: { name: routes["project.configure"].name, params: { shortcut } } });
  }

  return links;
};

export const isProjectAdvancedTabAvailable = (project: FilesystemNodeShortcut) => {
  const { axShareConfig } = store.state;
  return axShareConfig
    && project.shortcutState === ShortcutState.Ok
    && !project.viewOnly
    && !isSketchProject(project);
};

export default {
  routes,
  config,
};
