import { SsoResponse } from "@shared/models";

import { ManageUsersInviteModel } from "@/services/models/filesystem";

export interface ChangeAccountInfoModel {
  currentEmail?: string;
  currentPassword: string;
  newEmail?: string;
  newPassword?: string;
}

export interface UserProfile {
  Name: string;
  Bio: string;
  Img: string;
}

export interface DoLoginModel {
  loginEmail: string;
  loginPassword: string;
  altPassword: string;
  staySignedIn: boolean;
  clearPass: boolean;
  passwordBlank: boolean;
  accountService: boolean;
  redirect?: string;
  rpRequestId?: string;
}

export interface ManageUserModel {
  user: SsoResponse;
  profile: UserProfile;
  isViewer: boolean;
  isViewerAccount?: boolean;
  Level?: number;
  isOwner?: boolean;
  invitationAccepted?: boolean;
}

export interface ManageUsersModel {
  users: ManageUserModel[];
  usersInvite?: ManageUsersInviteModel;
  userOnly: boolean;
  httpsOnly: boolean;
}

export const enum AccessLevels {
  Regular = 0,
  SuperAdmin = 1,
  Admin = 2,
  Author = 3,
  Reviewer = 4,
  TechnicalAdmin = 5,
}
