
import Vue from "vue";

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    change(event: any) {
      this.$emit("input", event.target.checked);
    },
  },
});
