
import Vue from "vue";

export default Vue.extend({
  props: {
    top: {
      type: String,
      default: "#F6DB84",
    },
    bottom: {
      type: String,
      default: "#FCE7A4",
    },
  },
});
