import { RouteConfig } from "vue-router";

import { ROUTES } from "@/router";
import store from "@/store";
import { AxShare } from "@/store/state";

const AxSubInstanceFirstUse = () => import("@/components/AxSubInstanceFirstUse.vue");

export type Keys = "firstUse";

const routes: { readonly [R in Keys]: RouteConfig } = {
  firstUse: {
    path: "/firstUse",
    name: "firstUse",
    component: AxSubInstanceFirstUse,
    beforeEnter(_to, _from, next) {
      const { axShareConfig } = store.state as AxShare;
      if (axShareConfig !== null && axShareConfig.IsSubInstance) return next();

      // redirect to home page for non-subinstances
      return next({ name: ROUTES.home.name });
    },
  },
};

const config = [routes.firstUse];

export default { routes, config };
