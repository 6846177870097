import Vue from "vue";

import { isElectron } from "@/common/environment";

export const desktopClasses = Vue.extend({
  computed: {
    isWindowsPlatform(): boolean {
      return window.AxureCloudNative.process.platform === "win32";
    },

    isMacPlatform(): boolean {
      return window.AxureCloudNative.process.platform === "darwin";
    },

    classes(): Record<string, boolean> {
      // class names should be kept in sync with mixins in _desktop.scss
      return {
        "desktop-app": isElectron,
        "desktop-app--windows": isElectron && this.isWindowsPlatform,
        "desktop-app--mac": isElectron && this.isMacPlatform,
      };
    },
  },
});
