
import Vue, { VNode, VNodeData } from "vue";

type Value = string | number | boolean | object;

export default Vue.extend({
  props: {
    text: {
      type: String,
      default: "",
    },
    inputClass: {
      type: String,
      default: "item-horizontal",
    },
    value: {
      type: Boolean,
      default: null,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    isDisabled(): boolean {
      return this.disabled;
    },

    createInputElement(value: boolean) {
      const data: VNodeData = {
        attrs: {
          readonly: this.readonly,
          disabled: this.disabled,
          type: "checkbox",
        },
        domProps: {
          checked: value,
          indeterminate: this.indeterminate,
        },
        on: {
          change: (event: any) => {
            this.$emit("input", event.target.checked);
          },
        },
      };

      return this.$createElement("input", data);
    },

    createLabelElement(label: string) {
      const children = (this.$scopedSlots.default && this.$scopedSlots.default({})) || label;
      if (!children) return undefined;

      let cssClass = "ms-1";
      if (this.disabled) cssClass += " disabled";

      return this.$createElement("span", { class: cssClass }, children);
    },

    createProps() {
      return {
        text: this.text,
        checked: this.value,
        disabled: this.disabled,
        readonly: this.readonly,
        change: (event: any) => {
          this.$emit("input", event.target.checked);
        },
      };
    },

    genItem() {
      const children = [this.createInputElement(this.value), this.createLabelElement(this.text)];

      const node = this.$scopedSlots.default
        ? this.$scopedSlots.default(this.createProps())
        : this.$createElement("label", { class: this.inputClass }, children);

      return node;
    },
  },

  render(h): VNode {
    return h("div", [this.genItem()]);
  },
});
