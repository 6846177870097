
import Vue, { VueConstructor } from "vue";

import { workspaceMembersMixin } from "@/mixins/WorkspaceMembers";
import { GetSubinstanceAccounts } from "@/store/admin/actionTypes";
import { AxShare } from "@/store/state";

import AxSharedWith from "@/components/AxSharedWith.vue";

type Mixins = InstanceType<typeof workspaceMembersMixin>;

export default (Vue as VueConstructor<Vue & Mixins>).extend({
  components: {
    AxSharedWith,
  },

  mixins: [workspaceMembersMixin],

  props: {
    canInvite: {
      type: Boolean,
      default: false,
    },

    notStackedLength: {
      type: Number,
      default: 0,
    },

    inversed: {
      type: Boolean,
      default: false,
    },

    stackExpandable: {
      type: Boolean,
      default: true,
    },

    stackInversed: {
      type: Boolean,
      default: false,
    },

    avatarSize: {
      type: String,
      default: "24px",
    },

    stackLength: {
      type: Number,
      default: 3,
    },

    inviteOthers: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    isSubInstance(): boolean {
      const { axShareConfig } = this.$store.state as AxShare;
      return axShareConfig !== null && axShareConfig.IsSubInstance;
    },
  },

  async mounted() {
    if (this.isSubInstance && this.subInstanceAccounts.length === 0) {
      await this.$store.dispatch(new GetSubinstanceAccounts());
    }
  },

});
