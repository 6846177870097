import { CommitOptions, DispatchOptions, Payload, Store } from "vuex";
import VuexPersistence from "vuex-persist";

import { isElectronRenderer } from "@/common/environment";
import { Main, Renderer } from "@/desktop/events";
import { DispatchResult } from "@/desktop/main-process/bridge/store";
import { AxShare } from "@/store/state";

export const desktopBridge = <T>(store: Store<T>) => {
  if (isElectronRenderer) {
    if (!window.AxureCloudNative) return;
    const { ipc } = window.AxureCloudNative;

    ipc.on(Main.VuexCommit, (_event: any, data: Payload, options?: CommitOptions) => {
      store.commit(data, options);
    });

    ipc.on(
      Main.VuexDispatch,
      async (_event: any, correlationParam: string, data: Payload, options?: DispatchOptions) => {
        ipc.send(Renderer.VuexDispatchAck + correlationParam);
        let dispatchResult: DispatchResult;
        try {
          const result = await store.dispatch(data, options);
          dispatchResult = { data: result, success: true };
        } catch (err) {
          dispatchResult = { data: err, success: false };
        }
        ipc.send(Renderer.VuexDispatchComplete + correlationParam, dispatchResult);
      }
    );
  }
};

export const vuexPersist = new VuexPersistence<AxShare>({
  key: "axure-cloud-state",
  reducer: state => ({ settings: state.settings }),
});
