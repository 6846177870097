
import Vue from "vue";
import { PropValidator } from "vue/types/options";

import { noop } from "@/common/lib";

import AxButton from "@/components/AxButton.vue";
import AxButtonGroup from "@/components/AxButtonGroup.vue";
import AxDialog from "@/components/AxDialog.vue";
import AxForm from "@/components/AxForm.vue";
import { Submit } from "@/components/types/AxForm";

export default Vue.extend({
  components: {
    AxDialog,
    AxForm,
    AxButton,
    AxButtonGroup,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },

    title: {
      type: String,
      required: false,
      default: "Are You Sure?",
    },

    submitText: {
      type: String,
      default: "Submit",
    },

    submit: {
      type: Function,
      default: () => noop,
    } as PropValidator<(model: any) => Promise<any> | Function>,

    cancel: {
      type: Function,
      default: () => noop,
    } as PropValidator<() => Promise<any> | Function>,
  },

  data() {
    return {
      dialog: true,
    };
  },

  methods: {
    async submitHandler<T>({ values }: Submit<T>) {
      await this.submit(values);
    },

    async cancelHandler() {
      await this.cancel();
    },
  },
});
