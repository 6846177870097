import { AxShareConfigModel } from "@shared/models";

import { ShortcutType } from "@/services/models";

import { FilesystemNodeShortcut, isShortcutNode } from "../fs";
import { isRelativeUrl, stripProtocol } from "../lib/string";

import { resolveApiBaseUrl } from "./api";
import { isThumbnailSupportedProject } from "./project";

import { FilesystemSearchItem } from "@/components/types/AxSearch";

export interface PrototypeUrls {
  href: string;
  full: string;
  display: string;
  download: string;
  frame: string;
}

export function prototypeUrls(
  config: AxShareConfigModel | null,
  project: FilesystemNodeShortcut | FilesystemSearchItem
): PrototypeUrls;
export function prototypeUrls(config: AxShareConfigModel | null, prototypeUrl: string, shortcut: string): PrototypeUrls;
export function prototypeUrls(
  config: AxShareConfigModel | null,
  project: FilesystemNodeShortcut | FilesystemSearchItem | string,
  shortcutId?: string,
): PrototypeUrls {
  let prototypeUrl = "";
  let shortcut = "";
  let shortcutType: ShortcutType | undefined;
  if (typeof project === "object") {
    prototypeUrl = project.prototypeUrl || "";
    if (isShortcutNode(project)) {
      shortcut = project.shortcut;
      shortcutType = project.shortcutType;
    } else {
      shortcut = project.id;
      shortcutType = project.subtype;
    }
  } else if (typeof project === "string" && shortcutId) {
    prototypeUrl = project;
    shortcut = shortcutId;
  }

  const baseUrl = resolveApiBaseUrl(config);

  let href = "";
  let full = "";
  if (prototypeUrl !== "") {
    href = full = prototypeUrl;
  } else {
    href = `/${shortcut}`;
    full = `${baseUrl}/${shortcut}`;
  }
  let frame = full;
  if (isShortcutNode(project) && useFramedDomain(config, project)) {
    const withPort = process.env.NODE_ENV === "development";
    const port = withPort ? ":44300" : "";
    const protocol = config.MatchProtocolMode ? "http" : "https";
    frame = `${protocol}://${shortcut}${config.AxSitesFramedPrototypeDomain}${port}`;
  }

  let download = `${baseUrl}/prototype/downloadrpfile/${shortcut}`;
  if (shortcutType === ShortcutType.TeamRp) {
    download = `${baseUrl}/versions/DownloadRevision?shortcut=${shortcut}`;
  }

  return {
    href,
    full,
    display: stripProtocol(full),
    frame,
    download,
  };
}

function linkHandler(endpoint: string, urls: PrototypeUrls, event?: MouseEvent) {
  // If non absolute, use original href, since no need to obtain auth
  if (isRelativeUrl(urls.href)) {
    return true;
  }

  if (event && !event.defaultPrevented) {
    event.stopPropagation();
    event.preventDefault();
    window.open(`${endpoint}?url=${encodeURIComponent(urls.full)}`, "_blank", "noopener");
  }
  return false;
}

export function prototypeLinkHandler(urls: PrototypeUrls, event?: MouseEvent) {
  return linkHandler("/prototype/getprototypeurl", urls, event);
}

export function prototypeLinkHandlerNoAuth(urls: PrototypeUrls, event?: MouseEvent) {
  return linkHandler("/prototype/getvtkprototypeurl", urls, event);
}

function useFramedDomain(
  config: AxShareConfigModel | null,
  project: string | FilesystemNodeShortcut,
): config is AxShareConfigModel {
  return (
    !!config
    && !!config.AxSitesFramedPrototypeDomain
    && typeof project === "object"
    && isThumbnailSupportedProject(project)
  );
}
