import { MutationPayloadMap, MutationTypes } from "@/store/admin/mutationTypes";
import { AxShareAdmin } from "@/store/admin/state";
import { MutationTree } from "../typed";

const mutations: MutationTree<AxShareAdmin, MutationPayloadMap> = {
  [MutationTypes.SetAdminUserInfo](state, { userInfo }) {
    state.userInfo = userInfo;
  },

  [MutationTypes.SetAdminSubInstanceInfo](state, { subInstanceInfo }) {
    state.subInstanceInfo = subInstanceInfo;
  },

  [MutationTypes.SetAdminSubInstanceAccounts](state, { accounts }) {
    state.subInstanceAccounts = accounts;
  },
};

export default mutations;
