export class DbConnectionError extends Error {}

export class JsonSettingsLoadError extends Error {}

export class SignInReadOnlyError extends Error {}

export class ReadOnlyError extends Error {}

export class ApiExceptionError extends Error {
  exceptionId?: string;

  constructor(message: string, exceptionId?: string) {
    super(message);
    this.exceptionId = exceptionId;
  }
}
