
import Vue from "vue";
import { Location } from "vue-router";

import { prototypeUrls } from "@/common/axshare";
import { FilesystemNodeShortcut } from "@/common/fs";
import { ROUTES } from "@/router";
import { AxShare } from "@/store/state";

export default Vue.extend({
  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    to(): Location {
      const { shortcut } = this.item as FilesystemNodeShortcut;
      return {
        name: ROUTES.project.name,
        params: {
          shortcut: shortcut.toLowerCase(),
        },
      };
    },
    urls(): { href: string; display: string } {
      const { axShareConfig } = this.$store.state as AxShare;
      const project = this.item as FilesystemNodeShortcut;
      return prototypeUrls(axShareConfig, project);
    },
  },
});
