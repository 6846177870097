
import Vue from "vue";

import { FilesystemNodeWorkspace, WorkspaceRights } from "@/common/fs";
import { ROUTES } from "@/router";
import { fsRouterLocation } from "@/router/fs";

import AxDialog from "@/components/AxDialog.vue";
import AxManageUsersLeave from "@/components/AxManageUsers/AxManageUsersLeave.vue";

interface ComponentData {
  show: boolean;
  workspace: FilesystemNodeWorkspace | undefined;
}

export default Vue.extend({
  components: {
    AxDialog,
    AxManageUsersLeave,
  },

  props: {
    workspaceId: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },

  data(): ComponentData {
    return {
      show: true,
      workspace: this.$store.getters.getFsNode(this.workspaceId),
    };
  },

  beforeRouteEnter(_to, _from, next) {
    return next(vm => {
      const workspace: FilesystemNodeWorkspace | undefined = ((vm as any) as ComponentData).workspace;
      const rights: WorkspaceRights | undefined = workspace && vm.$store.getters.workspaceRights(workspace);
      if (!rights || !rights.userLeave) {
        vm.$router.push(ROUTES.fs);
      }
    });
  },

  methods: {
    submit() {
      this.$router.push({
        name: ROUTES.fs.name,
      });
    },

    cancel() {
      this.$router.push(fsRouterLocation(this.id));
    },
  },
});
