//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Master } from './expo';

export const enum OperationType {
  Add = 0,
  Edit = 1,
  Delete = 2
}
export const enum PackageType {
  Page = 0,
  Component = 1,
  Property = 2
}
export const enum SsoId {
  Axure = 0,
  AxShare = 1,
  Portal = 2
}
export const enum RpErrorTypes {
  None = 0,
  UnKnown = 1,
  AxShareError = 2,
  AxShareException = 3,
  AuthenticationFailed = 4,
  TokenExpired = 5,
  LoginLockedOut = 6,
  InReadonlyMode = 7,
  NoPermissionToAccess = 8,
  CreateShortCutParentDeleted = 9,
  ShortcutNotExist = 10,
  ShortcutHasNoRepository = 11,
  ShortcutDeleted = 12,
  CannotFindVersionFile = 13,
  EntryForShortcutNotFound = 14,
  CannotFindFileKeyFor = 15,
  RepoTranTaken = 16,
  LockFileFailed = 17,
  UnlockFileFailed = 18,
  CommitFailed = 19,
  CommitFilesOutDated = 20,
  FilesCheckedOutByOthers = 21,
  FilesDeleted = 22,
  HaveConflicts = 23,
  ExportPathReadOnly = 24,
  OpenFileReadOnly = 25,
  ServerNoResponse = 26,
  ConnectionErrorOffline = 27,
  SVNCannotFindTeamDirectory = 28,
  InvalidReturnValue = 29,
  WorkingCopyLocked = 30,
  TeamDirectoryNotFound = 31,
  CleanUpBackUpFileNotCreated = 32,
  CleanUpFileLocked = 33,
  NotATeamProject = 34,
  LocalFolderAccessError = 35,
  AccountNotFound = 36,
  ShortcutTypeMissMatch = 37,
  ShortcutGenerationFailed = 38,
  ShortcutStillGenerating = 39,
  FileTooNew = 40,
  EnterpriseRequired = 41,
  CannotCreateUser = 42,
  PreventNewUsers = 43,
  UserPasswordTooShort = 44,
  TrailingSpaceInPassword = 45,
  EmptyPassword = 46,
  EmailIsInvalid = 47,
  EmailAlreadyInUse = 48,
  SaveFromPreuploadFailed = 49,
  TeamProjectDowngraded = 50,
  InvalidPasswordComplexity = 60,
  InvalidUser = 61,
  PasswordForSuperAdmin = 62,
  PasswordForOtherAdmins = 63,
  ShouldNotPublishToTeam = 100,
  SamlRedirectToIdp = 600,
  SamlUserMismatched = 601,
  SamlCannotResetPassword = 602,
  LdapRequiresClearPassword = 700,
  AseExpired = 800,
  NotUsingEnterprise = 810,
  RedemptionKeyNotFound = 900
}
export const enum ApiErrorTypes {
  SignInReadOnlyError = 1,
  ReadOnlyError = 2
}
export const enum DiscussionAction {
  Create = 0,
  Resolve = 1,
  Unresolve = 2
}
export const enum DiscussionType {
  Issue = 0,
  Comment = 1
}
export const enum ShortcutAction {
  Create = 0,
  Edit = 1,
  Rename = 2,
  Moved = 3,
  Clone = 4,
  Deleted = 5
}
export const enum WorkspaceAction {
  Created = 0,
  Joined = 1,
  Left = 2,
  Archived = 3,
  Unarchived = 4,
  Migrated = 5,
  Renamed = 6
}
export const enum FolderAction {
  Moved = 0
}
export interface ActivityObjectHelpers
{
}
export interface ActivityFeedListingJs
{
  FeedItems: ActivityFeedItem[];
  Profiles: { [key:string]: UserProfileJs };
  FilesystemInfo: ActivityFeedFilesystemInfo;
  Shortcuts: { [key:string]: ActivityFeedShortcutJs };
}
export interface ActivityFeedFilesystemInfo
{
  Name: string;
  FilesystemId: string;
  OwnerId: string;
  OwnerName: string;
  IsDefaultFs: boolean;
  IsArchived: boolean;
  IsFavorite: boolean;
}
export interface ActivityFeedShortcutJs
{
  Shortcut: string;
  Name: string;
  ShortcutState: string;
  ShortcutType: number;
}
export interface ActivityFeedItem
{
  FilesystemId: string;
  Timestamp: string;
  Shortcut: string;
  Type: number;
  Data: EventItem;
}
export interface EventItem
{
  UserId: string;
  UserName: string;
}
export interface DiscussionsItem extends EventItem
{
  Action: DiscussionAction;
  Type: DiscussionType;
  IssueCode: string;
  IssueDescription: string;
  IssueDescriptionRichText: string;
  IssueCommentText: string;
  IssueCommentTextRichText: string;
  IssueCreator: string;
  IssueCreatorId?: string;
  ShortcutName: string;
  PageName: string;
  PageFileName: string;
  PageId: string;
  ShortPageId: string;
  MentionedUsers: string[];
  Images: string[];
}
export interface ShortcutItem extends EventItem
{
  Action: ShortcutAction;
  TargetPrototypeId?: string;
  PublishMessage: string;
  Changeset: Changeset;
  MentionedUsers: string[];
  ShortcutName: string;
  Current: string;
  Previous: string;
  CurrentFilesystemId?: string;
  CurrentFolderId?: string;
  CurrentFilesystemName: string;
  CurrentFolderName: string;
  PreviousFilesystemId?: string;
  PreviousFolderId?: string;
  PreviousFilesystemName: string;
  PreviousFolderName: string;
}
export interface WorkspaceItem extends EventItem
{
  Action: WorkspaceAction;
  Current: string;
  Previous: string;
}
export interface FolderItem extends EventItem
{
  Action: FolderAction;
  FolderId: string;
  FolderName: string;
  CurrentFilesystemId?: string;
  CurrentFolderId?: string;
  CurrentFilesystemName: string;
  CurrentFolderName: string;
  PreviousFilesystemId?: string;
  PreviousFolderId?: string;
  PreviousFilesystemName: string;
  PreviousFolderName: string;
}
export interface AxShareConfigModel
{
  AxSharePublicClientUrl: string;
  AxShareHost: string;
  AxShareHostUrl: string;
  AxShareHostSecureUrl: string;
  AxSitesFramedPrototypeDomain: string;
  AccountServiceUrl: string;
  AccountServiceSecureUrl: string;
  LicensingServiceHost: string;
  MatchProtocolMode: boolean;
  AxShareOnPrem: boolean;
  NeedsSetup: boolean;
  NeedsDbUpgrade: boolean;
  DisableSetup: boolean;
  LdapAuthenticationEnabled: boolean;
  Version: string;
  ReleaseDate: string;
  SchemaVersion: string;
  IsSubInstance: boolean;
  VCurrentUrl: string;
  MaxFileSize: number;
  SubInstancesSupported: boolean;
  SubInstanceTopLevelDomain: string;
  SamlUrl: string;
  EnablePlugins: boolean;
  EnableBetaFeatures: boolean;
  PasswordUseExtraRules: boolean;
  ManagePageMessage: string;
  ReadOnlyMode: boolean;
  ReadOnlyMessage: string;
  GoogleReCaptchaSiteKey: string;
  EnablePushNotifications: boolean;
  EnableWorkspacesMigration: boolean;
  EnableActivityFeedUI: boolean;
  AllowOndemand: boolean;
}
export interface AxShareCurrentUserInfo
{
  UserId: string;
  AccessLevel: number;
  SubInstanceProviderExists: boolean;
}
export interface AxShareIdentityProvider
{
  Id: string;
  SubInstanceId: string;
  Certificate: string;
  SignOnUrl: string;
  LogOutUrl: string;
  CertMeta: string;
  LogOnLink: string;
  SamlLdapConflictDetected: boolean;
  AllowCreateNewAccountsAutomatically: boolean;
}
export interface AxShareSubInstanceInfo
{
  Level: number;
  Expiration?: string;
  IsExpired: boolean;
  TrialDaysLeft: number;
  MaxAdminAuthors: number;
  IsGracePeriod: boolean;
  GracePeriodDaysLeft: number;
  OwnerEmail: string;
}
export interface Changeset
{
  Entries: ChangesetEntry[];
  Message: string;
}
export interface ChangesetEntry
{
  PackageId: string;
  PackageType: PackageType;
  ShortId: string;
  PackageName: string;
  OperationType: OperationType;
}
export interface Constants
{
  AccountServerErrorMessage: string;
}
export interface EmailSettings
{
  EmailsEnabled: boolean;
  EmailSender: string;
  EmailSenderAddress: string;
  SmtpSsl: boolean;
  SmtpHost: string;
  SmtpPort?: number;
  SmtpLogin: string;
  SmtpPassword: string;
  TraceEnabled: boolean;
  SmtpConfigured: boolean;
}
export interface FbEnabledJs
{
  feedbackEnabled: boolean;
  passwordProtected: boolean;
  viewCookieName: string;
  userCookieName: string;
  isSubInstance: boolean;
}
export interface FeedbackPollingInfoJs
{
  success: boolean;
  hasChanges: boolean;
  unreadIssueMentionsCount: number;
  unreadCommentMentionsCount: number;
  originalPollTime: string;
  lastPollTime: string;
  continuePolling: boolean;
  message: string;
}
export interface GetIssueCommentJs
{
  comment: IssueCommentJs;
  pageId: string;
  profile: { [key:string]: UserProfileJs };
  mostRecentCommentTimestamp?: string;
}
export interface DeleteIssueJs
{
  issue: DeletedIssueCode;
  mostRecentCommentTimestamp?: string;
}
export interface DeletedIssueCode
{
  code: string;
}
export interface DeleteCommentJs
{
  issue: IssueJs;
  comment: DeletedIssueCommentId;
  profiles: { [key:string]: UserProfileJs };
  mostRecentCommentTimestamp?: string;
}
export interface DeletedIssueCommentId
{
  id: string;
}
export interface GetIssueJs
{
  issue: IssueJs;
  pageId: string;
  profile: { [key:string]: UserProfileJs };
  mostRecentCommentTimestamp?: string;
}
export interface IssueListJs
{
  issues: IssueJs[];
  profiles: { [key:string]: UserProfileJs };
  lastPollTime: string;
  mostRecentCommentTimestamp: string;
  hasCommentsOnDeletedPages: boolean;
}
export interface IssueByShortcutListJs
{
  data: IssueByShortcutListItemsJs;
  success: boolean;
  readOnlyMode: boolean;
  readOnlyMessage: string;
  feedbackEnabled: boolean;
}
export interface IssueByShortcutListItemsJs
{
  issues: IssueJs[];
  profiles: { [key:string]: UserProfileJs };
  totalIssueCount: number;
}
export interface IssueCommentListJs
{
  comments: IssueCommentJs[];
  profiles: { [key:string]: UserProfileJs };
  isUnresolved: boolean;
}
export interface IssueJs
{
  id: string;
  code: string;
  shortPageId: string;
  pageId: string;
  pageName: string;
  fileName: string;
  description: string;
  isRichText?: boolean;
  userId?: string;
  creator: string;
  createdDate: string;
  modifier: string;
  modifiedDate?: string;
  changedDate: string;
  comments: IssueCommentJs[];
  numComments: number;
  lastIssueComment: IssueCommentJs;
  screenshotIds: string[];
  screenshotUrls: string[];
  fullsizeUrls: string[];
  x?: number;
  y?: number;
  color: string;
  pinNumber?: number;
  resolvedDate?: string;
  isUnread: boolean;
  hasUnreadMention: boolean;
}
export interface IssueCommentJs
{
  id: string;
  issueCode: string;
  userId?: string;
  commenter: string;
  commentText: string;
  isRichText?: boolean;
  commentDate: string;
  commentModifiedDate?: string;
  screenshotIds: string[];
  screenshotUrls: string[];
  fullsizeUrls: string[];
  hasUnreadMention: boolean;
}
export interface ImagePreUploadJs
{
  success: boolean;
  id: string;
}
export interface FrequentlyUsedEmojisJs
{
  LastUsed: string[];
}
export interface GetLicenseKey
{
  success: boolean;
  licenseKey: string;
  licenseJs: LicenseJs;
}
export interface IdentityProviderResponse extends ResponseObject
{
  Id: string;
  Name: string;
  SubInstanceId: string;
  SignOnUrl: string;
  CertThumbprint: string;
  Exclusive: boolean;
  LogOutUrl: string;
  CurrentAxShareHostSecureUrl: string;
}
export interface IsRp8UpgradeResponse
{
  success: boolean;
  isRp8Upgrade: boolean;
}
export interface LdapSettings
{
  AuthenticationProvider: number;
  ActiveDirectoryServerName: string;
  ActiveDirectoryDomainName: string;
  AllowActiveDirectoryCreateNewAccountsAutomatically: boolean;
  ActiveDirectoryAdminUser: string;
  ActiveDirectoryAdminPassword: string;
  LdapHost: string;
  LdapUserTemplate: string;
  LdapUserFilter: string;
  LdapUserEmailAttribute: string;
  LdapUseSSL: boolean;
  LdapCertificate: string;
  LdapAdminUser: string;
  LdapAdminPassword: string;
  LdapCertMeta: string;
  AllowLdapCreateNewAccountsAutomatically: boolean;
  LdapForgotPasswordLink: string;
  SamlLdapConflictDetected: boolean;
}
export interface MigrateToAcbResult
{
  UserActivationRequired: boolean;
  SystemAdmins: SsoResponse[];
}
export interface UsersForMigrate
{
  AlreadyMigrated: SsoResponse[];
  CanBeMigrated: SsoResponse[];
}
export interface ProjectPage
{
  Id: string;
  OriginalId: string;
  Name: string;
  FailedToGenerate: boolean;
  Thumbnails: ResponsiveImage[];
}
export interface ShortcutPagesResponse
{
  Shortcut: ShortcutJs;
  Pages: ProjectPage[];
}
export interface ExtendedProjectPage extends ProjectPage
{
  Url: string;
}
export interface PrototypeViewerParams
{
  Params: { [key:string]: string };
}
export interface ResponseObject
{
  success: boolean;
  message: string;
  errorMessage: string;
  redirecturl: string;
  forceRedirect?: boolean;
}
export interface ResponseObjectOf<T> extends ResponseObject
{
  data: T;
}
export interface CreateMasterJs
{
  Master: Master;
}
export interface ValidateRequestJs
{
  IsValid: boolean;
}
export interface FilesystemRootListingJs
{
  FilesystemInfo: FilesystemJs;
  Listing: ListingJs;
}
export interface VarsJs
{
  Vars: { [key:string]: string };
}
export interface ListingJs
{
  Invitations: InvitationJs[];
  Filesystems: FilesystemJs[];
  Folders: FolderRowJs[];
  Shortcuts: ShortcutJs[];
  AdditionalVars: { [key:string]: string };
}
export interface InvitationJs
{
  FilesystemId: string;
  Owner: string;
  Owner2: SsoResponse;
  Sender: SsoResponse;
  FilesystemName: string;
  Message: string;
  CreatedOn: string;
}
export interface FilesystemJs
{
  DisplayName: string;
  IsDefaultFs: boolean;
  FilesystemId: string;
  Owner: string;
  OwnerSso: SsoResponse;
  InvitedUsers: string[];
  SharedUsers: string[];
  InvitedUsers2: SharedFilesystemMappingJs[];
  SharedUsers2: SharedFilesystemMappingJs[];
  ViewerFs: boolean;
  HttpsOnly: boolean;
  UserViewOnly: boolean;
  CreatedOn: string;
  IsArchived: boolean;
  IsFavorite: boolean;
  NotificationState: FilesystemNotificationStateJs;
}
export interface FilesystemNotificationStateJs
{
  HasNewComments: boolean;
  UnreadMentionsCount: number;
}
export interface SharedFilesystemMappingJs
{
  user: SsoResponse;
  isViewer: boolean;
  isAdmin?: boolean;
}
export interface FolderRowJs
{
  FolderId: string;
  FolderName: string;
  CreatedOn: string;
  NotificationState: FolderNotificationStateJs;
  ModifiedOn: FolderModifiedOnJs;
}
export interface FolderModifiedOnJs
{
  Value: string;
  Shortcut: string;
}
export interface FolderNotificationStateJs
{
  HasNewComments: boolean;
  TotalCommentsCount?: number;
  NewCommentsCount: number;
  NewCommentShortcut: string;
  NewCommentShortcutFeedbackEnabled: boolean;
  UnreadMentionsCount: { [key:string]: number };
}
export interface ShortcutJs
{
  PrototypeUrl: string;
  CustomDomain: boolean;
  Shortcut: string;
  Name: string;
  CreatedOn: string;
  ModifiedOn?: string;
  DeletedOn?: string;
  HasPassword: boolean;
  FeedbackEnabled: boolean;
  IsUserOnly: boolean;
  ShortcutState: string;
  TargetPrototypeId?: string;
  TargetPrototypeFileName: string;
  TargetPrototypeCreatedOn?: string;
  TargetPrototypeGenerationFailedTimestamp?: string;
  TargetPrototypeGenerationTimestamp?: string;
  Regenerating?: boolean;
  InFsViewerFolder: boolean;
  ResourceDirVersion?: number;
  IsTeamProject: boolean;
  IsExpoProject: boolean;
  ShortcutType: number;
  IsUserOnlyWorkspace: boolean;
  WorkspaceName: string;
  FailedPagesCount: number;
  AllowOndemand: boolean;
  NotificationState: ShortcutNotificationStateJs;
}
export interface ShortcutNotificationStateJs
{
  HasNewComments: boolean;
  NewCommentsCount: number;
  CurrentCommentPageId?: string;
  CurrentIssueCode: string;
  TotalCommentsCount?: number;
  UnreadMentionsCount: { [key:string]: number };
}
export interface CommentsChangedArgs
{
  UserId?: string;
  FilesystemId: string;
  Shortcut: string;
  IssueCode: string;
  PageId?: string;
  Delta: number;
  LatestTimestamp?: string;
}
export interface WorkspaceInvitationDeletedArgs
{
  FilesystemId: string;
}
export interface ShortcutRedirectCommentInfoJs
{
  IssueCode: string;
  PageId?: string;
  ShortPageId: string;
}
export interface RecentJs
{
  Shortcut: ShortcutJs;
  Date: string;
}
export interface ListJs<T>
{
  Items: T[];
}
export interface DomainJs
{
  Owner: string;
  Id: string;
  Domain: string;
  Verified: boolean;
  Level: number;
  ShortcutName: string;
  ShortcutId: string;
}
export interface SeoJs
{
  Id: string;
  FileName: string;
  PageName: string;
  PageTitle: string;
  PrettyUrl: string;
  MetaDescription: string;
}
export interface HistoryListingJs
{
  TotalRevisionCount: number;
  Revisions: HistoryJs[];
  Profiles: { [key:string]: UserProfileJs };
}
export interface HistoryJs
{
  Revision: number;
  User: SsoResponse;
  Notes: string;
  Changeset: Changeset;
  NotesRichText: RichText;
  CreatedOn: string;
  NewMajorVersion?: number;
  OldMajorVersion?: number;
}
export interface RedirectJs
{
  Id: string;
  Type: number;
  From: string;
  To: string;
}
export interface WorkspaceUserRowJs
{
  Id: string;
  Email: string;
  CreatedOn: string;
  Level: number;
  ReviewOnly: boolean;
}
export interface AccountRowJs
{
  Id: string;
  Email: string;
  Nickname: string;
  CreatedOn: string;
  Level: number;
  IsSaml: boolean;
  IsDeactivated: boolean;
  IsRpEnterprise: boolean;
  TrialDaysLeft: number;
  IsValidatedRpEnterprise: boolean;
}
export interface NotificationJs
{
  Id: string;
  Name: string;
  ProjectName: string;
  WorkspaceName: string;
  TargetId?: string;
  TargetType: number;
  ShortcutType: number;
  ShortcutKey: string;
  Method: number;
  SubRate: number;
  SlackChannel: string;
  MsTeamsUrl: string;
  Type: number;
  PrototypeUrl: string;
}
export interface NotificationSettingJs
{
  NotificationType: number;
  ProjectCommentsType: number;
}
export interface NotificationMuteItemJs
{
  Id: string;
  NotificationType: number;
  TargetId?: string;
  TargetType: number;
  ProjectName: string;
  WorkspaceName: string;
}
export interface ZippedFileJs
{
  FileUrl: string;
}
export interface LicenseJs
{
  regName: string;
  key: string;
  aseUserCount: number;
  term: number;
  expireDate?: string;
  autoRenew: boolean;
  isTrial: boolean;
}
export interface TrialJs
{
  ExpireDate: string;
  TrialKey: string;
}
export interface LicenseInfoResponse
{
  BestLicense: LicenseJs;
  CurrentTrial: TrialJs;
}
export interface SsoResponse extends ResponseObject
{
  userId: string;
  userEmail: string;
  verified: boolean;
  pending: boolean;
  serverVersion: string;
  authToken: string;
  verifyAccountUrl: string;
  nickname: string;
  bio: string;
  profileImageUrl: string;
  modifiedOn: string;
  isRpEnterprise: boolean;
  isWhitelisted: boolean;
  isReviewer: boolean;
  meta: { [key:string]: string };
  welcomeEmailSent: boolean;
  localTimeZone: string;
  trialDaysLeft: number;
  isTrialActive: boolean;
}
export interface ExtendedSsoResponse extends SsoResponse
{
  IdentityProviderId?: string;
  IsIdpAdmin: boolean;
  IdpSessionId: string;
}
export interface VersionInfoResponse
{
  BuildVersion: string;
  EnterpriseVersion: string;
}
export interface RequestInviteResponse
{
  WorkspaceName: string;
}
export interface RpResponseObject extends ResponseObject
{
  Payload: any;
  ErrorType: RpErrorTypes;
  AuthToken: string;
  ExceptionId?: string;
  DeletedOn?: string;
  UserEmail: string;
  UserId: string;
}
export interface RpResponseObjectOf<T> extends RpResponseObject
{
  Payload: T;
}
export interface AseLicenseResponseObject extends ResponseObject
{
  License: LicenseJs;
  OwnerEmail: string;
}
export interface ExtensionMethods
{
}
export interface FilesystemFolderInfo
{
  RootFolderId: string;
  FilesystemInfo: FilesystemJs;
}
export interface FilesystemSearchItemJs
{
  id: string;
  folderId: string;
  workspaceId: string;
  name: string;
  type: string;
  subtype?: number;
  path: string;
  updated: string;
  lastUpdatedShortcut: string;
  viewOnly: boolean;
  isUserOnly?: boolean;
  prototypeUrl: string;
  shortcutState: string;
  hasPassword?: boolean;
  IsUserOnlyWorkspace?: boolean;
  ResourceDirVersion?: number;
}
export interface ApiErrorData
{
  ApiErrorType: ApiErrorTypes;
}
export interface NotificationEventArgs
{
  Id: string;
}
export interface ArtboardProjectChangedArgs extends NotificationEventArgs
{
  Shortcut: string;
}
export interface RpProjectChangedArgs extends NotificationEventArgs
{
  Shortcut: string;
}
export interface ProjectMovedArgs extends NotificationEventArgs
{
  Shortcut: string;
}
export interface ProjectDeletedArgs extends NotificationEventArgs
{
  Shortcut: string;
}
export interface WorkspaceInvitedArgs extends NotificationEventArgs
{
  FilesystemId: string;
  InvitedByMention: boolean;
  OwnerName: string;
  OwnerEmail: string;
  OwnerProfileImage: string;
  MentionComment: string;
}
export interface UserMentionedArgs extends NotificationEventArgs
{
  Shortcut: string;
  PageId: string;
  IssueCode: string;
  Message: string;
  AuthorEmail: string;
  AuthorName: string;
  AuthorProfileImage: string;
  MentionType: string;
}
export interface WorkspaceActivityEventArgs extends NotificationEventArgs
{
  FilesystemId: string;
}
export interface ResetPasswordValidateJs
{
  Email: string;
}
export interface UserProfileJs
{
  userId: string;
  userEmail: string;
  username: string;
  profileImg: string;
}
export interface ShareStatusJs
{
  success: boolean;
  readOnlyMode: boolean;
  readOnlyMessage: string;
  isSubInstance: boolean;
}
export interface TreeNodeJs
{
  name: string;
  id: string;
}
export interface TreeNodeFileJs extends TreeNodeJs
{
  filetype: string;
  rpmajorversion: string;
}
export interface TreeNodeWorkspaceJs extends TreeNodeJs
{
  owner: SsoResponse;
  defaultWorkspace: boolean;
  isFavorite: boolean;
  isFolder: boolean;
  explicitlyJoined: boolean;
  children: TreeNodeJs[];
}
export interface CloudLibrariesJs
{
  defaultLibraries: CloudLibraryJs[];
}
export interface CloudLibraryJs
{
  shortcut: string;
  id: string;
  name: string;
  fileType: string;
}
export interface TimeZoneJs
{
  Id: string;
  DisplayName: string;
  baseUtcOffsetMinutes: number;
}
export interface ResponsiveImage
{
  Url: string;
  Density: number;
}
export interface RichTextNodeTypes
{
  Doc: string;
  Paragraph: string;
  Text: string;
  Mention: string;
  Emoji: string;
}
export interface RichTextNodeTypesAttributes
{
}
export interface RichTextNodeAttributes
{
  id: string;
  label: string;
}
export interface RichTextNode
{
  type: string;
  text: string;
  content: RichTextNode[];
  attrs: { [key:string]: string };
}
export interface RichText extends RichTextNode
{
}
export interface RichTextComment
{
  Content: RichText;
}
export interface SetLicenseKey extends ResponseObject
{
  IsTrial: boolean;
}
export interface UserSuggestions
{
  Shortcut: string;
  FilesystemId: string;
  Suggestions: UserSuggestion[];
}
export interface UserSuggestion
{
  UserId: string;
  IsMe: boolean;
  UserEmail: string;
  UserName: string;
  UserBio: string;
  JoinedFilesystem: boolean;
}
export interface ContentType
{
  Json: string;
  WwwFormUrlEncoded: string;
}
export interface Mention
{
  Id: string;
  Label: string;
}
export interface Emoji
{
  Id: string;
  Native: string;
  ShortName: string;
}
