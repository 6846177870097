
import { HttpError } from "@microsoft/signalr";
import Vue from "vue";
import VueRouter from "vue-router";

import { wellKnownRoutes } from "@/common/axshare";
import {
  ApiExceptionError,
  SignInReadOnlyError,
  ReadOnlyError,
  DbConnectionError,
  JsonSettingsLoadError,
} from "@/common/axshare/errors";
import { isDevelopment, isProduction, isElectron } from "@/common/environment";
import { isEdge } from "@/common/lib";
import { ApiCallRetry, ApiCallClear } from "@/store/actionTypes";
import { AxShare } from "@/store/state";

import AxAppFailureApiException from "@/components/AxAppFailureApiException.vue";
import AxAppFailureDb from "@/components/AxAppFailureDb.vue";
import AxAppFailureFallback from "@/components/AxAppFailureFallback.vue";
import AxAppFailureJsonSettingsLoad from "@/components/AxAppFailureJsonSettingsLoad.vue";
import AxAppFailureReadOnly from "@/components/AxAppFailureReadOnly.vue";
import AxAppFailureReadOnlySignIn from "@/components/AxAppFailureReadOnlySignIn.vue";
import AxAppFailureReload from "@/components/AxAppFailureReload.vue";
import AxAppFailureRetry from "@/components/AxAppFailureRetry.vue";
import AxAxureLogoRow from "@/components/AxAxureLogoRow.vue";
import AxButton from "@/components/AxButton.vue";
import AxOnline from "@/components/AxOnline.vue";

type VueGlobalErrorHandlerArgs = [Error, Vue, string];
type VueGlobalErrorHandler = (...args: VueGlobalErrorHandlerArgs) => void;

export default Vue.extend({
  components: {
    AxAppFailureApiException,
    AxAppFailureDb,
    AxAppFailureJsonSettingsLoad,
    AxAppFailureFallback,
    AxAppFailureReadOnly,
    AxAppFailureReadOnlySignIn,
    AxAppFailureRetry,
    AxAppFailureReload,
    AxAxureLogoRow,
    AxButton,
    AxOnline,
  },

  props: {
    error: {
      type: [Error, Object],
      required: false,
      default: undefined,
    },

    forceError: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      acknowledged: false,
      originalFocus: null as HTMLElement | null,
      vueError: undefined as Error | undefined,
      promiseRejection: undefined as PromiseRejectionEvent | undefined,
      errorInfo: "",
      originalVueHandler: undefined as VueGlobalErrorHandler | undefined,
      originalWindowErrorHandler: undefined as OnErrorEventHandlerNonNull | undefined,
      fsRoute: {
        name: wellKnownRoutes.home.name,
      },
    };
  },

  computed: {
    hasError(): boolean {
      return (this.error || this.forceError || !!this.vueError || !!this.promiseRejection) && !this.acknowledged;
    },

    isDesktopWindows(): boolean {
      return isElectron && window.AxureCloudNative.process.platform === "win32";
    },

    isAuthed(): boolean {
      let isAuthed = false;
      const { accountService } = this.$store.state as AxShare;
      if (accountService) {
        isAuthed = accountService().IsAuthed;
      }
      return isAuthed;
    },

    failuredApiCallsIds(): string[] {
      return this.$store.getters.failuredApiCallsIds as string[];
    },

    canRetry(): boolean {
      return !this.isJsonSettingsLoadError && this.failuredApiCallsIds.length > 0;
    },

    canReload(): boolean {
      return this.isJsonSettingsLoadError;
    },

    dbError(): DbConnectionError | undefined {
      return this.unwrapError(DbConnectionError);
    },

    jsonSettingsLoadError(): JsonSettingsLoadError | undefined {
      return this.unwrapError(JsonSettingsLoadError);
    },

    apiExceptionError(): ApiExceptionError | undefined {
      return this.unwrapError(ApiExceptionError);
    },

    signInReadOnlyError(): SignInReadOnlyError | undefined {
      return this.unwrapError(SignInReadOnlyError);
    },

    readOnlyError(): ReadOnlyError | undefined {
      if (this.error && this.error instanceof ReadOnlyError) {
        return this.error;
      }
      if (this.promiseRejection && this.promiseRejection.reason instanceof ReadOnlyError) {
        return this.promiseRejection.reason;
      }
      if (this.vueError && this.vueError instanceof ReadOnlyError) {
        return this.vueError;
      }

      return undefined;
    },

    isDbError(): boolean {
      return !!this.dbError;
    },

    isJsonSettingsLoadError(): boolean {
      return !!this.jsonSettingsLoadError;
    },

    isApiExceptionError(): boolean {
      return !!this.apiExceptionError && !!this.apiExceptionError.exceptionId;
    },

    isSignInRoError(): boolean {
      return !!this.signInReadOnlyError;
    },

    isRoError(): boolean {
      return !!this.readOnlyError;
    },
  },

  watch: {
    error(val) {
      if (val) {
        this.acknowledged = false;
      }
    },

    hasError(val) {
      if (val && document.activeElement instanceof HTMLElement) {
        // save current focused element to restore focus after error is acknowledged
        this.originalFocus = document.activeElement;
        this.originalFocus.blur();
      }
    },
  },

  created() {
    this.originalVueHandler = Vue.config.errorHandler;
    this.originalWindowErrorHandler = window.onerror || undefined;

    Vue.config.errorHandler = this.handleVueError;
    window.onerror = this.handleWindowError;
    window.addEventListener("unhandledrejection", this.handleUnhandledRejection);
  },

  destroyed() {
    if (this.originalVueHandler) Vue.config.errorHandler = this.originalVueHandler;
    if (this.originalWindowErrorHandler) window.onerror = this.originalWindowErrorHandler;
    window.removeEventListener("unhandledrejection", this.handleUnhandledRejection);
  },

  methods: {
    unwrapError<T extends new(...args: any) => any>(Ctor: T) {
      if (this.error && this.error instanceof Ctor) {
        return this.error;
      }
      if (this.promiseRejection && this.promiseRejection.reason instanceof Ctor) {
        return this.promiseRejection.reason;
      }
      if (this.vueError && this.vueError instanceof Ctor) {
        return this.vueError;
      }

      return undefined;
    },

    handleVueError(...args: [Error | undefined, Vue, string]) {
      this.acknowledged = false;
      const [error, _vm, info] = args;
      if (!error) {
        // there is a bug in Vue that error might be undefined if exception has been thrown inside async handler
        // ignore those errors
        return;
      }
      this.tryLogError(error, info);

      if (VueRouter.isNavigationFailure(error)) {
        // ignore duplicated navigation in production builds,
        // as probably we can survive that
        if (isProduction) {
          return;
        }
      }

      if (info === "render") {
        // ignore 'render' errors, as likely app will be able to survive and still be usable
        // displaying full-screen overlay in this case is too much
        console.error(error);
        return;
      }

      this.vueError = error;
      this.errorInfo = info;

      if (this.originalVueHandler) {
        this.originalVueHandler(...(args as VueGlobalErrorHandlerArgs));
      }
    },

    handleWindowError(
      ...args: [Event | string, string | undefined, number | undefined, number | undefined, Error | undefined]
    ) {
      const [event, source, lineno, colno, error] = args;

      // Catch error which happens when resize prototype build page in Edge. See A9-1345 for more info.
      if (isEdge && error && error.message && error.message.includes("getPropertyValue")) {
        console.error(error);
        return false;
      }

      this.acknowledged = false;
      this.tryLogError(error, source, lineno, colno);

      this.vueError = error;
      if (typeof event === "string") {
        this.errorInfo = event;
      }

      if (this.originalWindowErrorHandler) {
        this.originalWindowErrorHandler(...args);
      }
    },

    handleUnhandledRejection(rejection: PromiseRejectionEvent) {
      if (rejection.reason === "Timeout") {
        // ignore global promise rejection thrown by recaptcha
        return;
      }

      if (isProduction && rejection.reason instanceof HttpError) {
        // ignore global promise rejection thrown by SignalR connection
        return;
      }

      // ignore router errors in production builds as probably we can survive that
      if (isProduction && VueRouter.isNavigationFailure(rejection.reason)) {
        return;
      }

      this.tryLogError(rejection.reason, rejection);
      this.acknowledged = false;
      this.promiseRejection = rejection;
      this.errorInfo = rejection.reason;
    },

    acknowledge() {
      this.acknowledged = true;

      // restore focused element
      if (this.originalFocus) {
        this.originalFocus.focus();
        this.originalFocus = null;
      }
    },

    async onlineStatusChanged(isOnline: boolean) {
      if (this.hasError && !this.forceError && isOnline) {
        this.acknowledge();
        await this.retry();
      }
    },

    async retry() {
      this.acknowledge();
      await Promise.all(this.failuredApiCallsIds.map(id => this.$store.dispatch(new ApiCallRetry(id))));
    },

    clearApiCalls() {
      this.$store.dispatch(new ApiCallClear());
    },

    tryLogError(error: Error | undefined, ...extras: any[]) {
      if (isDevelopment) {
        console.error(error, extras);
      }
    },
  },
});
