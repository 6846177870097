
import Vue from "vue";

import { isElectron } from "@/common/environment";
import { AxShare } from "@/store/state";

import AxAccountMenu from "./AxAccountMenu.vue";
import AxHeadingLogo from "./AxHeadingLogo.vue";

import AxHeaderSearch from "@/components/AxHeaderSearch.vue";

export default Vue.extend({
  components: {
    AxHeadingLogo,
    AxAccountMenu,
    AxHeaderSearch,
  },

  computed: {
    minimized(): boolean {
      return this.$store.getters.headerMinimized;
    },

    isSubInstance(): boolean {
      const { axShareConfig } = this.$store.state as AxShare;
      return axShareConfig !== null && axShareConfig.IsSubInstance;
    },

    isElectron(): boolean {
      return isElectron;
    },

    isFullScreen(): boolean {
      const { desktop } = this.$store.state as AxShare;
      return desktop.desktopParams.isFullScreen;
    },
  },
});
