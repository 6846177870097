import {
  DbConnectionError,
  ApiExceptionError,
  ReadOnlyError,
  SignInReadOnlyError,
  JsonSettingsLoadError,
} from "@/common/axshare/errors";
import { isRelativeUrl } from "@/common/lib/string";
import { ApiErrorData, ApiErrorTypes } from "@/generated/models";
import { ApiResponseObject, RawResponse } from "@/services/models/responseObject";
import { mapResponse } from "@/services/utils/responseMapper";

export interface Options {
  noRedirect?: boolean;
  ignoreResponseRedirectUrl?: boolean;
}

const defaultOptions: Options = {
  noRedirect: false,
  ignoreResponseRedirectUrl: false,
};

export async function exec<T = any>(
  request: Promise<{}>,
  extractorOrOptions: ((payload: ApiResponseObject<T>) => T) | Options = dataExtractor,
  options: Options = defaultOptions,
) {
  let payload: ApiResponseObject<T> | null = null;
  let response;
  try {
    response = await request;
  } catch (reason) {
    payload = mapResponse<T>(reason);
  }
  if (!payload) {
    payload = mapResponse<T>(response);
  }
  if (typeof extractorOrOptions === "function") {
    return processPayload(payload, extractorOrOptions, options);
  }
  return processPayload(payload, dataExtractor, extractorOrOptions);
}

function dataExtractor<T>(payload: ApiResponseObject<T>) {
  const hasData = payload.data !== undefined && payload.data !== null;
  if (hasData) {
    return payload.data;
  }
  return (payload as any) as T;
}

function processPayload<T>(
  payload: ApiResponseObject<T>,
  extractor: (payload: ApiResponseObject<T>) => T,
  options: Options,
): Promise<T> {
  return new Promise((resolve, reject) => {
    if (!options.noRedirect) {
      if (payload.forceRedirect && payload.redirecturl) {
        redirect(payload.redirecturl);
        return;
      }

      if (!options.ignoreResponseRedirectUrl && payload.redirecturl) {
        redirect(payload.redirecturl);
        return;
      }
    }

    const { success } = payload;
    if (!success) {
      const reason = payload.errorMessage || payload.message || "API returned { success: false } and message is missing.";
      if (process.env.NODE_ENV === "development") {
        logError(payload, reason);
      }

      const data: any = payload.data;
      if (isDbConnectionPayload(data) && data.dbConnectionFailed) {
        reject(new DbConnectionError(reason));
      }
      if (isJsonSettingsExceptionPayload(data) && data.jsonLoadException) {
        reject(new JsonSettingsLoadError(reason));
      }
      if (isApiErrorPayload(data)) {
        switch (data.ApiErrorType) {
          case ApiErrorTypes.SignInReadOnlyError:
            reject(new SignInReadOnlyError(reason));
            break;
          case ApiErrorTypes.ReadOnlyError:
            reject(new ReadOnlyError(reason));
            break;
          default:
            break;
        }
      }

      if (isExceptionResponse(payload)) {
        const exceptionId = getExceptionId(payload.response);
        reject(new ApiExceptionError(payload.data, exceptionId));
      }

      if (payload.message === "Network Error") {
        reject(new Error("We're having trouble connecting. Please, check your connection and try again."));
      }
      reject(new Error(reason));
    } else {
      resolve(extractor(payload));
    }
  });
}

function isDbConnectionPayload(data: any): data is { dbConnectionFailed: boolean } {
  return !!data && typeof data.dbConnectionFailed === "boolean";
}

function isJsonSettingsExceptionPayload(data: any): data is { jsonLoadException: boolean } {
  return !!data && typeof data.jsonLoadException === "boolean";
}

function isExceptionResponse(
  payload: ApiResponseObject,
): payload is ApiResponseObject<string> & { response: RawResponse } {
  return !!payload.response && !!getExceptionId(payload.response);
}

function getExceptionId(response: RawResponse) {
  return response.headers["x-exception-id"];
}

function isApiErrorPayload(data: any): data is ApiErrorData {
  return !!data && typeof data.ApiErrorType === "number";
}

function redirect(target: string) {
  if (isRelativeUrl(target)) {
    const baseURL = ""; // getBaseUrl();
    if (baseURL !== undefined && isRelativeUrl(baseURL)) {
      window.location.href = target;
      return;
    }
    window.location.href = `${baseURL}${target}`;
    return;
  }
  window.location.href = target;
}

function logError(payload: ApiResponseObject, reason?: string) {
  const error = {
    requestUrl: payload.requestUrl,
    reason,
  };
  console.error(`Error occurred during API request: ${prettify(error)}`);
  console.warn(payload);
}

function prettify(value: unknown) {
  return JSON.stringify(value, null, 2).replace(/\n\r?/g, "\n\t");
}
