
import Vue from "vue";

import { resolveRedirect, wellKnownRoutes } from "@/common/axshare";
import { isElectron } from "@/common/environment";
import { Main } from "@/desktop/events";
import { Logout } from "@/store/actionTypes";
import { AxShare } from "@/store/state";

import AxAppFailureRetry from "@/components/AxAppFailureRetry.vue";

export default Vue.extend({
  components: {
    AxAppFailureRetry,
  },

  props: {
    canRetry: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    readOnlyErrorMessage(): string {
      const { axShareConfig } = this.$store.state as AxShare;
      return axShareConfig ? axShareConfig.ReadOnlyMessage : "";
    },
  },

  methods: {
    async retry() {
      await this.logout();
      this.$emit("retry");
    },

    async logout() {
      const onSuccess = () => new Promise<void>((resolve, reject) => {
        const afterLogoutRedirect = resolveRedirect({ name: wellKnownRoutes.login.name });
        if (afterLogoutRedirect) {
          this.$router.push(afterLogoutRedirect, () => resolve(), reject);
        } else {
          resolve();
        }
      });

      if (isElectron) {
        window.AxureCloudNative.ipc.send(Main.LogoutPerformed);
      }

      await this.$store.dispatch(new Logout(onSuccess));
    },
  },
});
