// eslint-disable-next-line import/no-extraneous-dependencies
import { TrackEventOptions } from "@gtm-support/core";
import Vue from "vue";

export function trackEvent(payload: TrackEventOptions): void {
  if (!process.env.VUE_APP_GTM_TRACKING_ID) return;
  if (Vue.gtm) {
    return Vue.gtm.trackEvent({
      event: "GAEvent",
      category: payload.eventCategory,
      action: payload.eventAction,
      label: payload.label,
      value: payload.value,
    });
  }
}

export const AnalyticsClickEvents = {
  NewFolder: {
    eventClass: "analytics-track-new-folder",
  },
  NewProject: {
    eventClass: "analytics-track-new-project",
  },
  NewProjectArtboard: {
    eventClass: "analytics-track-new-project-artboard",
  },
  NewProjectRP: {
    eventClass: "analytics-track-new-project-rp",
  },
  Preview: {
    eventClass: "analytics-track-project-preview",
  },
  Inspect: {
    eventClass: "analytics-track-project-inspect",
  },
  Build: {
    eventClass: "analytics-track-project-build",
  },
  UserProfile: {
    eventClass: "analytics-track-user-profile",
  },
  AddAsset: {
    eventClass: "analytics-track-add-asset",
  },
  ProjectShare: {
    eventClass: "analytics-track-project-share",
  },
  AddHotspotConfirm: {
    eventClass: "analytics-track-add-hotspot-confirm",
  },
  LogInEmailInput: {
    eventClass: "analytics-track-log-in-email-input",
  },
  LogInSubmitButton: {
    eventClass: "analytics-track-log-in-submit-button",
  },
  SignUpEmailInput: {
    eventClass: "analytics-track-sign-up-email-input",
  },
  SignUpSubmitButton: {
    eventClass: "analytics-track-sign-up-submit-button",
  },
};
