
import { createPopper, Instance, Placement } from "@popperjs/core";
import flip from "@popperjs/core/lib/modifiers/flip";
import preventOverflow from "@popperjs/core/lib/modifiers/preventOverflow";
import { Wormhole } from "portal-vue";
import Vue, { VNode, VNodeData } from "vue";

const ctx = {
  axTooltipIsShown: false,
  axTooltipUseTimeout: true,
  popperJsInstance: undefined as Instance | undefined,
  timeoutBeforeShowing: undefined as NodeJS.Timeout | undefined,
  timeoutBeforeHiding: undefined as NodeJS.Timeout | undefined,
};

export default Vue.extend({
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      required: false,
      default: "",
    },

    timeout: {
      type: Number,
      required: false,
      default: 0,
    },

    tooltipPlacement: {
      type: String,
      required: false,
      default: "bottom",
    },

    offset: {
      type: Number,
      required: false,
      default: 10,
    },

    arrow: {
      type: Boolean,
      required: false,
      default: false,
    },

    tooltipClasses: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    persistOnHover: {
      type: Boolean,
      default: false,
    },

    reference: {
      type: Object,
      default: undefined,
    },
  },

  data() {
    return {
      tooltip: undefined as VNode | undefined,
      preventHiding: false,
      leaveTimeout: 50,
    };
  },

  beforeDestroy() {
    this.hideTooltip();
  },

  mounted() {
    let targetFromReference = this.reference as Vue | Element | undefined;

    if (targetFromReference && !(targetFromReference instanceof Element)) {
      targetFromReference = targetFromReference.$el;
    }

    if (targetFromReference) {
      this.initShowTooltip(targetFromReference);
    }
  },

  methods: {
    async onMouseEnter(e: Event) {
      const targetElement = e.target as Element;

      await this.initShowTooltip(targetElement);
    },

    onMouseLeave() {
      this.initHideTooltip();
    },

    async onFocus(e: Event) {
      const targetElement = e.target as Element;

      await this.initShowTooltip(targetElement);
    },

    onBlur() {
      this.initHideTooltip();
    },

    async initShowTooltip(targetElement: Element) {
      if (ctx.axTooltipUseTimeout) {
        ctx.timeoutBeforeShowing = setTimeout(async () => {
          await this.showTooltip(targetElement, true);
        }, this.timeout);
      } else {
        if (ctx.timeoutBeforeHiding) {
          clearTimeout(ctx.timeoutBeforeHiding);
        }
        await this.showTooltip(targetElement);
      }
    },

    async showTooltip(targetElement: Element, fadein?: boolean) {
      if (this.tooltip) {
        // previous tooltip is not hidden
        if (ctx.axTooltipIsShown) {
          await this.hideTooltip();
        }

        ctx.axTooltipIsShown = true;

        Wormhole.open({ to: "tooltips-area", from: "tooltip-component", passengers: [this.tooltip] });
        // https://portal-vue.linusb.org/guide/caveats.html#provide-inject
        // workaround to get elements from refs
        await this.$nextTick();
        await this.$nextTick();

        const tooltipElement = this.$refs.tooltipMessage as HTMLElement;

        if (targetElement && tooltipElement) {
          const tooltipPlacement = this.getPopupPlacementFromString(this.tooltipPlacement);

          if (fadein) {
            tooltipElement.classList.add("fade-in");
          } else {
            tooltipElement.classList.remove("fade-in");
          }

          ctx.popperJsInstance = createPopper(targetElement, tooltipElement, {
            placement: tooltipPlacement,
            modifiers: [
              preventOverflow,
              flip,
              {
                name: "offset",
                options: {
                  offset: [0, this.offset],
                },
              },
            ],
          });
        }
      }
    },

    async initHideTooltip() {
      if (ctx.timeoutBeforeShowing) {
        clearTimeout(ctx.timeoutBeforeShowing);
      }

      ctx.axTooltipUseTimeout = false;

      if (this.leaveTimeout === 0 && this.timeout === 0) {
        await this.hideTooltip();
      } else {
        ctx.timeoutBeforeHiding = setTimeout(async () => {
          if (this.preventHiding) return;

          if (ctx.axTooltipIsShown) {
            await this.hideTooltip();
          }

          ctx.axTooltipUseTimeout = true;
        }, this.leaveTimeout);
      }
    },

    async hideTooltip() {
      ctx.axTooltipIsShown = false;

      if (ctx.popperJsInstance) {
        ctx.popperJsInstance.destroy();
        ctx.popperJsInstance = undefined;
      }

      Wormhole.close({ to: "tooltips-area", from: "tooltip-component" });
      await this.$nextTick();
      await this.$nextTick();
    },

    genTooltip(): VNode {
      const data: VNodeData = {
        class: {
          "tooltip-message": true,
          ...this.tooltipClasses,
        },
        on: {
          mouseenter: () => {
            if (this.persistOnHover) {
              this.preventHiding = true;
            }
          },
          mouseleave: () => {
            if (this.persistOnHover) {
              this.preventHiding = false;
              this.initHideTooltip();
            }
          },
        },
        ref: "tooltipMessage",
      };

      const tooltipFromTitle = this.$createElement("span", [this.title]);

      const tooltipContent = this.$scopedSlots.tooltip ? this.$scopedSlots.tooltip({}) : tooltipFromTitle;

      const children = [tooltipContent];

      if (this.arrow) {
        const arrowElement = this.$createElement("div", {
          class: { arrow: true },
          attrs: { "data-popper-arrow": "data-popper-arrow" },
        });

        children.push(arrowElement);
      }

      return this.$createElement("div", data, children);
    },
    getPopupPlacementFromString(value: string): Placement {
      switch (value) {
        case "bottom":
          return "bottom";
        case "top":
          return "top";
        case "left":
          return "left";
        case "right":
          return "right";
        default:
          return "bottom";
      }
    },

    assignTooltipEvents(content: VNode, tooltipEvents: { [key: string]: Function | Function[] }) {
      const tooltipElement: VNode = Array.isArray(content) ? content[0] : content;

      let data: VNodeData = {};

      if (tooltipElement.data) {
        const oldData = tooltipElement.data;

        if (oldData.on) {
          oldData.on = {
            ...oldData.on,
            ...tooltipEvents,
          };
          data = { ...oldData };
        } else {
          data = {
            ...oldData,
            on: tooltipEvents,
          };
        }
      } else {
        data = {
          on: tooltipEvents,
        };
      }

      tooltipElement.data = data;
    },
  },

  render(h): VNode {
    const tooltipEvents = {
      mouseenter: this.onMouseEnter,
      mouseleave: this.onMouseLeave,
      focus: this.onFocus,
      blur: this.onBlur,
    };

    const content = (this.$scopedSlots.default
      && ((this.$scopedSlots.default({ tooltipEvents }) as unknown) as VNode)
    ) || h("");

    if (this.disabled) {
      return content;
    }

    this.tooltip = this.genTooltip();

    if (!this.reference) {
      this.assignTooltipEvents(content, tooltipEvents);
    }

    return content;
  },
});
