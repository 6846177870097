
import Vue from "vue";
import { PropOptions } from "vue/types/options";

import { FilesystemNodeWorkspace } from "@/common/fs";
import { shareFileSystem } from "@/services/fs.service";
import { ManageUsersInviteModel } from "@/services/models/filesystem";
import { Fetch } from "@/store/fs/actionTypes";
import { AxShare } from "@/store/state";
import { AxShareUser } from "@/store/user/state";

import AxButton from "@/components/AxButton.vue";
import AxForm from "@/components/AxForm.vue";
import AxFormGroup from "@/components/AxFormGroup.vue";

const workspaceProp: PropOptions<FilesystemNodeWorkspace> = {
  type: Object,
  required: true,
};

export default Vue.extend({
  components: {
    AxButton,
    AxForm,
    AxFormGroup,
  },

  props: {
    workspace: workspaceProp,
  },

  computed: {
    currentUser(): AxShareUser {
      const { user } = this.$store.state as AxShare;
      return user;
    },
  },

  methods: {
    async submit() {
      const { userInfo } = this.currentUser;
      const usersJoin: ManageUsersInviteModel = {
        userEmails: userInfo && userInfo.userEmail,
        viewerOnly: false,
        filesystemId: this.workspace.id,
      };

      await shareFileSystem(usersJoin);
      await this.$store.dispatch(new Fetch(this.workspace));
      this.cancel();
    },

    cancel() {
      this.$emit("cancel");
    },
  },
});
