
import Vue from "vue";

import { SsoId } from "@shared/models";

import { AnalyticsClickEvents, trackEvent } from "@/common/axshare";
import { accountServiceUnavailable } from "@/common/axshare/messages";
import { PasswordValidator } from "@/common/axshare/user";
import AccountService from "@/services/account.service";
import { AxShare } from "@/store/state";

import AxButton from "@/components/AxButton.vue";
import AxForm from "@/components/AxForm.vue";
import AxFormGroup from "@/components/AxFormGroup.vue";
import AxIcon from "@/components/AxIcon.vue";
import AxInput from "@/components/AxInput.vue";
import { Submit } from "@/components/types/AxForm";

interface Model {
  email: string;
  password: string;
}

export default Vue.extend({
  components: {
    AxButton,
    AxForm,
    AxFormGroup,
    AxInput,
    AxIcon,
  },

  props: {
    loginRoute: {
      type: Object,
      required: true,
    },

    hideHeading: {
      type: Boolean,
      default: false,
    },
  },

  metaInfo: {
    titleTemplate: "Sign up - %s",
  },

  data() {
    const { axShareConfig } = this.$store.state as AxShare;
    const passwordUseExtraRules = !!axShareConfig && axShareConfig.PasswordUseExtraRules;
    const errors: string[] = [];
    const passwordValidator = new PasswordValidator(passwordUseExtraRules);
    const model: Model = {
      email: "",
      password: "",
    };
    return {
      AnalyticsClickEvents,
      model,
      errors,
      passwordValidator,
    };
  },

  computed: {
    accountService(): AccountService | undefined {
      const { accountService } = this.$store.state as AxShare;
      if (accountService) {
        return accountService();
      }
      return undefined;
    },
  },

  methods: {
    async submit({ values }: Submit<Model>) {
      const { accountService } = this;
      if (!accountService) {
        throw new Error(accountServiceUnavailable);
      }

      const { email, password } = values;
      this.errors = [];

      if (!this.passwordValidator.isValid) {
        this.errors.push(this.passwordValidator.errorMessage());
        return;
      }

      await accountService.signUp(email, password, SsoId.AxShare.toString());
      await accountService.auth();

      trackEvent({ category: "Sign up Form", action: "Success" });
      this.$emit("auth-success");
    },
  },
});
