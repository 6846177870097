import { Payload } from "vuex";

import { DrawBadgeParams } from "@/desktop/types";

import { PayloadMap } from "../typed";

export enum ActionTypes {
  NewProjectMenuAction = "NewProjectMenuAction",
  DrawBadge = "AxDrawBadge",
}

export class NewProjectMenuAction implements Payload {
  public readonly type = ActionTypes.NewProjectMenuAction;
}

export class DrawBadge implements Payload {
  public readonly type = ActionTypes.DrawBadge;
  constructor(public readonly options: DrawBadgeParams) {}
}

export interface ActionPayloadMap extends PayloadMap<ActionTypes> {
  [ActionTypes.NewProjectMenuAction]: NewProjectMenuAction;
  [ActionTypes.DrawBadge]: DrawBadge;
}
