
import Vue from "vue";

import { isElectron } from "@/common/environment";
import { AxShare } from "@/store/state";

import AxAxureLogoRow from "@/components/AxAxureLogoRow.vue";
import AxIcon from "@/components/AxIcon.vue";

export default Vue.extend({
  components: {
    AxIcon,
    AxAxureLogoRow,
  },

  props: {
    noLogo: {
      type: Boolean,
      default: false,
    },

    logoRoute: {
      type: [Object, String],
      required: false,
      default: undefined,
    },

    copyright: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isFullScreen(): boolean {
      const { desktop } = this.$store.state as AxShare;
      return desktop.desktopParams.isFullScreen;
    },

    isElectron(): boolean {
      return isElectron;
    },
  },

  created() {
    document.body.classList.add("body-fluid");
  },

  destroyed() {
    document.body.classList.remove("body-fluid");
  },

});
