import { RouteConfig, Route } from "vue-router";

import { resolveAfterAuthRedirect, wellKnownRoutes } from "@/common/axshare";
import LoginSignup from "@/pages/LoginSignup.vue";
import { ROUTES } from "@/router";
import rpRouter, { Keys as rp } from "@/router/rp";
import store from "@/store";
import { AxShare } from "@/store/state";

import AxLogin from "@/components/AxLoginWeb.vue";
import AxSignup from "@/components/AxSignupWeb.vue";

const AxResetPassword = () => import("@/components/AxResetPassword.vue");

export type Keys = "entry" | "login" | "signup" | "resetpassword" | rp;

const loggedInRedirect = (to: Route) => {
  const {
    state: { accountService, axShareConfig },
  } = store;
  if (accountService && accountService().IsAuthed) {
    return resolveAfterAuthRedirect(to, axShareConfig?.AxShareHost);
  }
};

const routes: { readonly [R in Keys]: RouteConfig } = {
  ...rpRouter.routes,
  entry: {
    path: wellKnownRoutes.login.path,
    name: "entry",
    component: LoginSignup,
    meta: { requiresAuth: false },
  },
  login: {
    ...wellKnownRoutes.login,
    component: AxLogin,
    meta: { requiresAuth: false },
    beforeEnter(to, _from, next) {
      const loggedIn = loggedInRedirect(to);
      return loggedIn ? next(loggedIn) : next();
    },
  },
  signup: {
    path: "/signup",
    name: "signup",
    component: AxSignup,
    meta: { requiresAuth: false },
    beforeEnter(to, __, next) {
      const loggedIn = loggedInRedirect(to);
      if (loggedIn) return next(loggedIn);
      const { axShareConfig } = store.state as AxShare;
      if (axShareConfig && axShareConfig.IsSubInstance) {
        // signup should not be available for subinstances,
        // so redirect to home page in that case
        return next({ name: ROUTES.home.name });
      }
      return next();
    },
  },
  resetpassword: {
    path: "/resetpassword",
    name: "resetpassword",
    component: AxResetPassword,
    beforeEnter(to, _, next) {
      // token should be present to access password reset
      const { token } = to.query;
      if (token) return next();
      return next({ name: ROUTES.home.name });
    },
    meta: { requiresAuth: false },
  },
};

const config = [
  {
    ...routes.entry,
    children: [routes.login, routes.signup, routes.resetpassword],
  },
  ...rpRouter.config,
];

export default { routes, config };
