export const enum ScaleValues {
  ViewAt100 = "0",
  ScaleToWidth = "1",
  ScaleToFit = "2",
  ZoomMode = "3"
}

export interface OverlayOptions {
  hotspots?: boolean;
  comments?: boolean;
  notes?: boolean;
}
