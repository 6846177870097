
import Fuse from "fuse.js";
import Vue from "vue";
import { PropOptions } from "vue/types/options";

import { getObjectValueByPath } from "@/common/lib";

import AxButton from "@/components/AxButton.vue";
import AxDropdownButton from "@/components/AxDropdownButton.vue";
import AxIcon from "@/components/AxIcon.vue";
import AxInput from "@/components/AxInput.vue";
import AxList from "@/components/AxList.vue";
import AxListItem from "@/components/AxListItem.vue";
import { arrayProp } from "@/components/utils";

export interface Option<T = any> {
  label: string;
  value: T;
  children?: Option<T>[];
}

const optionsProp: PropOptions<Option[]> = {
  type: Array,
  required: false,
  default: () => ([]),
};

export default Vue.extend({
  components: {
    AxButton,
    AxList,
    AxListItem,
    AxDropdownButton,
    AxIcon,
    AxInput,
  },

  props: {
    options: optionsProp,

    optionsNoSort: {
      type: Boolean,
      required: false,
      default: false,
    },

    noEmpty: {
      type: Boolean,
      required: false,
      default: false,
    },

    shouldSkip: {
      type: Function,
      required: false,
      default: (_option: Option) => false,
    } as PropOptions<(option: Option) => boolean>,

    activatorRef: {
      type: [HTMLElement, Object, Function],
      required: false,
      default: undefined,
    },

    value: {
      type: [String, Array, Object],
      required: false,
      default: () => [],
    },

    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },

    valuePath: {
      type: String,
      required: false,
      default: "",
    },

    contentClasses: {
      type: String,
      required: false,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    disableLabel: {
      type: Boolean,
      default: false,
    },

    disableCaret: {
      type: Boolean,
      default: false,
    },

    inputClass: {
      type: String,
      default: "",
    },

    inputAttrs: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    inputEvents: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    autofocus: {
      type: Boolean,
      default: false,
    },

    openOnClick: {
      type: Boolean,
      default: false,
    },

    menuOpened: {
      type: Boolean,
      default: false,
    },

    searchFields: arrayProp<keyof Option>({
      default: () => ["label"],
    }),

    optionHeight: {
      type: Number,
      required: false,
      default: 30,
    },
  },

  data() {
    const options: Fuse.IFuseOptions<Option> = {
      threshold: 0.2,
      keys: this.searchFields,
      shouldSort: !this.optionsNoSort,
    };

    const fuse = new Fuse(this.options, options);

    const value: string | Option | Option[] | undefined | null = this.value;

    let initialOption: Option | undefined;
    if (value !== undefined && value !== null) {
      if (Array.isArray(value)) {
      //
      } else if (typeof value === "object") {
        initialOption = this.options.find(option => option.value === value.value);
      } else if (typeof value === "string") {
        initialOption = this.options.find(option => option.value === value);
      }
    }

    const pointer = initialOption ? this.options.indexOf(initialOption) : -1;

    return {
      isOpen: false,
      selectedLabel: initialOption ? initialOption.label : "",
      searchQuery: "",
      fuse,
      pointer,
      initialOption,
    };
  },

  computed: {
    filteredOptions(): Option[] {
      if (!this.searchQuery) {
        return this.options;
      }
      return this.fuse.search(this.searchQuery).map(d => d.item);
    },

    pointerPosition(): number {
      return this.pointer * this.optionHeight;
    },

    activeOption(): Option | undefined {
      return this.filteredOptions[this.pointer];
    },

    inputClassComputed() {
      return `${this.inputClass} search-input`;
    },
  },

  watch: {
    options(options) {
      this.fuse.setCollection(options);
    },

    filteredOptions() {
      this.pointerAdjust();
    },

    searchQuery(searchQuery) {
      if (searchQuery.length > 0) {
        this.activate();
        this.pointer = 0;
        return;
      }
      if (!this.openOnClick) {
        this.deactivate(false);
      }
    },

    selectedLabel(selectedLabel) {
      if (!selectedLabel && !this.noEmpty) {
        this.$emit("input");
      }
    },

    value(value) {
      this.$emit("update:input-started", value?.length > 0);
    },

    isOpen(value: boolean) {
      this.$emit("update:menu-opened", value);

      if (value) {
        // scroll list to current value
        this.$nextTick(() => {
          const listElement = this.resolvedListRef();
          if (!listElement) return;
          listElement.querySelector(".selected")?.scrollIntoView({ block: "center", inline: "center" });
        });
      }
    },
  },

  methods: {
    handleEscape(event: KeyboardEvent) {
      if (this.isOpen) {
        event.stopPropagation();
      }
      this.deactivate(false);
      this.tryRestore();
    },

    handleArrowDown() {
      if (this.isOpen) {
        this.pointerForward();
      } else {
        this.openAndActivate();
        this.pointerForward();
      }
    },

    handleArrowUp() {
      if (this.pointer > 0) {
        this.pointerBackward();
      } else {
        this.pointerReset();
        if (!this.searchQuery) {
          this.deactivate(false);
        }
      }
    },

    handleSpace(event: KeyboardEvent) {
      if (this.pointer === -1) {
        event.preventDefault();
        event.stopImmediatePropagation();

        if (this.isOpen) {
          this.deactivate(false);
        } else {
          this.openAndActivate();
        }
      }
    },

    updateSearch(search: string) {
      this.searchQuery = search;
      this.selectedLabel = search;
    },

    toggleCallback(openedMenu: boolean) {
      if (openedMenu) {
        this.activate();
      } else {
        this.deactivate(false);
      }
    },

    resolvedListRef(): HTMLElement | undefined {
      if (this.$refs.list) return (this.$refs.list as any).$el;
      const list = this.$el.querySelector<HTMLElement>(".ax-menu__content");
      if (list) return list;

      return undefined;
    },

    pointerForward() {
      if (this.isOpen && this.pointer < this.filteredOptions.length - 1) {
        this.pointer++;
        if (this.activeOption && this.shouldSkip(this.activeOption)) {
          this.pointerForward();
          return;
        }

        const listElement = this.resolvedListRef();
        if (!listElement) return;

        this.$nextTick(() => {
          listElement.querySelector(".active")?.scrollIntoView({ block: "nearest", inline: "nearest" });
        });
      }
    },

    pointerBackward() {
      if (this.isOpen && this.pointer > 0) {
        this.pointer--;
        if (this.activeOption && this.shouldSkip(this.activeOption)) {
          this.pointerBackward();
          return;
        }

        const listElement = this.resolvedListRef();
        if (!listElement) return;

        this.$nextTick(() => {
          listElement.querySelector(".active")?.scrollIntoView({ block: "nearest", inline: "nearest" });
        });
      }
    },

    optionHighlight(option: Option, index: number): Record<string, boolean> {
      const { pointer } = this;
      return {
        active: index === pointer,
        selected: this.value?.value === option.value,
      };
    },

    pointerAdjust() {
      if (this.isOpen && this.pointer >= this.filteredOptions.length - 1) {
        this.pointer = this.filteredOptions.length ? this.filteredOptions.length - 1 : -1;
      }
    },

    setPointer(index: number) {
      this.pointer = index;
    },

    openAndActivate() {
      this.isOpen = true;
      this.focus();
    },

    activate() {
      if (this.isOpen) return;
      this.isOpen = this.openOnClick || this.searchQuery.length > 0;
      this.focus();
    },

    deactivate(blur: boolean = true) {
      this.isOpen = false;
      this.pointerReset();

      if (blur) {
        const inputElement = (this.$refs.search as any).$refs.input as HTMLElement;
        inputElement.blur();
      }

      this.tryRestore();
    },

    focus() {
      const inputElement = (this.$refs.search as any).$refs.input as HTMLElement;
      if (inputElement && typeof inputElement.focus === "function" && document.activeElement !== inputElement) {
        inputElement.focus();

        // set cursor at the end of the input
        if (inputElement instanceof HTMLInputElement) {
          inputElement.setSelectionRange(inputElement.value.length, inputElement.value.length);
        }
      }
    },

    tryRestore() {
      if (this.value) {
        if (this.searchQuery || this.noEmpty) {
          this.selectedLabel = this.value.label;
          this.searchQuery = "";
        }
      }
    },

    addPointerElement(event: Event) {
      if (this.pointer >= 0) {
        event.preventDefault();
        const { isOpen, filteredOptions } = this;

        if (isOpen && filteredOptions.length > 0) {
          const item = filteredOptions[this.pointer];
          this.select(item);
        }
      }

      this.deactivate(false);
    },

    pointerReset() {
      this.pointer = -1;
      if (this.$refs.list) {
        ((this.$refs.list as any).$el as HTMLElement).scrollTop = 0;
      }
    },

    select(selected: Option) {
      this.searchQuery = "";
      this.selectedLabel = selected.label;
      this.$emit("select", { selected, deactivate: this.deactivate });
      let newValue: Option | Option[] = selected;
      if (this.multiple) {
        newValue = [...this.value, getObjectValueByPath(selected, this.valuePath)];
      }
      this.$emit("input", newValue);
    },

    remove() {
      if (Array.isArray(this.value)) {
        if (this.searchQuery.length === 0 && this.value.length > 0) {
          const lastSelected = this.value[this.value.length - 1];
          this.$emit("input", this.value.filter(val => val !== lastSelected));
          this.selectedLabel = lastSelected;
          this.searchQuery = lastSelected;
        }
      }
    },
  },
});
