import { jsDateFromAspDateString } from "@/common/lib";
import { isString } from "@/common/lib/guards";
import { createUniqueTag } from "@/common/utils";
import {
  GetIssueJs, IssueJs, IssueListJs,
  FbEnabledJs, FeedbackPollingInfoJs,
  IssueCommentJs, GetIssueCommentJs, DeleteIssueJs,
  ShareStatusJs,
  ImagePreUploadJs,
} from "@/generated/models";
import { exec } from "@/services/api";
import {
  AddIssueModel, AddIssueCommentModel,
  DeleteIssueModel, DeleteIssueCommentModel,
  UpdateIssueCommentModel, UpdateIssueModel,
  ResolveIssueModel,
  UpdatePinColorModel,
  UpdatePinLocationModel,
  UpdateIssueLastViewedTimestampModel,
  MarkAllCommentsAsReadModel,
  ImagePreUploadModel,
} from "@/services/models/issues";
import { objectToFormData } from "@/services/utils/formData";

import { server } from "./server";

export function getFeedbackEnabled(shortcut: string) {
  return exec<FbEnabledJs>(
    server.get("/issue/fbenabled", {
      params: { shortcut },
    }),
  );
}

export function getShareStatus() {
  return exec<ShareStatusJs>(
    server.get("/issue/getsharestatus"),
  );
}

export function getIssuesList(shortcut: string, pageId?: string) {
  return exec<IssueListJs>(
    server.get("/issue/list", {
      params: { shortcut, pageId },
    }),
  );
}

export function pollForUpdates(
  shortcut: string,
  lastPollTime: string,
  creator: string,
  userId?: string,
  pageId?: string,
) {
  return exec<FeedbackPollingInfoJs>(
    server.get("/issue/pollForUpdates", {
      params: {
        shortcut, pageId, lastPollTime, creator, userId,
      },
    }),
  );
}

export function setPinLocation(issue: IssueJs, x: number, y: number) {
  const model: UpdatePinLocationModel = {
    issueCode: issue.code,
    x,
    y,
  };

  const formData = objectToFormData(model);
  return exec(server.post("/issue/updatePinLocation", formData));
}

export function setPinColor(issue: IssueJs, color: number) {
  const model: UpdatePinColorModel = {
    issueCode: issue.code,
    color: color.toString(),
  };

  const formData = objectToFormData(model);
  return exec(server.post("/issue/updatePinColor", formData));
}

interface AddIssue {
  shortcut: string;
  userId: string;
  userName: string;
  pageId: string;
  pageName: string;
  x: number;
  y: number;
  color: string;
  content: Object | string;
  images: string[];
}

export function addIssue(
  params: AddIssue,
) {
  const {
    shortcut, userId, userName,
    pageId, pageName, content,
    x, y, color, images,
  } = params;

  const text = serializeContent(content);
  const model: AddIssueModel = {
    tag: createUniqueTag(),
    shortcut,
    pageId,
    pageName,
    userId,
    creator: userName,
    description: text,
    isRichText: true,
    x,
    y,
    color,
    pinNumber: 0,
    images: JSON.stringify(images),
  };

  const formData = objectToFormData(model);
  return exec<GetIssueJs>(server.post("/issue/add", formData));
}

export function updateIssue(
  shortcut: string,
  userId: string,
  userName: string,
  issue: IssueJs,
  newContent: Object | string,
  images: string[],
) {
  const text = serializeContent(newContent);

  const model: UpdateIssueModel = {
    shortcut,
    userId,
    tag: createUniqueTag(),
    pageId: issue.pageId,
    issueId: issue.code,
    creator: userName,
    issueText: text,
    isRichText: true,
    images: JSON.stringify(images),
  };

  const formData = objectToFormData(model);
  return exec<GetIssueJs>(server.post("/issue/updateIssue", formData));
}

export function updateIssueComment(
  shortcut: string,
  userId: string,
  userName: string,
  issue: IssueJs,
  comment: IssueCommentJs,
  newContent: Object | string,
  images: string[],
) {
  const text = serializeContent(newContent);

  const model: UpdateIssueCommentModel = {
    shortcut,
    userId,
    tag: createUniqueTag(),
    pageId: issue.pageId,
    commentId: comment.id,
    commenter: userName,
    commentText: text,
    isRichText: true,
    images: JSON.stringify(images),
  };

  const formData = objectToFormData(model);
  return exec<GetIssueCommentJs>(server.post("/issue/updateComment", formData));
}

export function addIssueComment(
  shortcut: string,
  userId: string,
  userName: string,
  issue: IssueJs,
  newContent: Object | string,
  images: string[],
) {
  const text = serializeContent(newContent);
  const model: AddIssueCommentModel = {
    shortcut,
    userId,
    tag: createUniqueTag(),
    pageId: issue.pageId,
    issueCode: issue.code,
    commentText: text,
    commenter: userName,
    isRichText: true,
    images: JSON.stringify(images),
  };

  const formData = objectToFormData(model);
  return exec<GetIssueCommentJs>(server.post("/issue/addComment", formData));
}

export function deleteIssue(
  shortcut: string,
  userId: string,
  userName: string,
  issue: IssueJs,
) {
  const model: DeleteIssueModel = {
    shortcut,
    tag: createUniqueTag(),
    commenter: userName,
    userId,
    issueCode: issue.code,
  };

  const formData = objectToFormData(model);
  return exec<DeleteIssueJs>(server.post("/issue/delete", formData));
}

export function deleteIssueComment(
  shortcut: string,
  userId: string,
  userName: string,
  comment: IssueCommentJs,
) {
  const model: DeleteIssueCommentModel = {
    shortcut,
    tag: createUniqueTag(),
    commenter: userName,
    userId,
    commentId: comment.id,
  };

  const formData = objectToFormData(model);
  return exec<DeleteIssueJs>(server.post("/issue/deleteComment", formData));
}

export function resolveIssue(
  userId: string,
  userName: string,
  issue: IssueJs,
  isResolve: boolean,
) {
  const model: ResolveIssueModel = {
    userId,
    isResolve,
    pageId: issue.pageId,
    creator: userName,
    issueCode: issue.code,
  };

  const formData = objectToFormData(model);
  return exec<GetIssueJs>(server.post("/issue/resolveIssue", formData));
}

export function updateIssueLastViewed(
  shortcut: string,
  issue: IssueJs,
) {
  const model: UpdateIssueLastViewedTimestampModel = {
    shortcut,
    issueId: issue.id,
    lastViewedCommentTimestamp: jsDateFromAspDateString(issue.changedDate).toISOString(),
  };

  const formData = objectToFormData(model);
  return exec(server.post("/issue/updateLastViewedCommentTimestamp", formData));
}

export function markIssueAsUnread(
  shortcut: string,
  issue: IssueJs,
) {
  const formData = objectToFormData({ shortcut, issueId: issue.id });
  return exec(server.post("/issue/markIssueAsUnread", formData));
}

export function markShortcutAsRead(
  shortcut: string,
  pageId?: string,
  upUntil?: Date,
) {
  const model: MarkAllCommentsAsReadModel = {
    shortcut,
    pageId,
  };

  if (upUntil) {
    model.upUntil = upUntil.toISOString();
  }

  const formData = objectToFormData(model);
  return exec(server.post("/issue/MarkAllCommentsAsRead", formData));
}

export function imagePreUpload(
  image: string,
  thumbnail: string,
  type: "issue" | "comment",
) {
  const model: ImagePreUploadModel = {
    image,
    thumbnail,
    type,
  };

  const formData = objectToFormData(model);
  return exec<ImagePreUploadJs>(server.post("/issue/ImagePreUpload", formData));
}

function serializeContent(content: Object | string) {
  if (isString(content)) {
    return content;
  }
  return JSON.stringify({
    Content: content,
  });
}
