
import Vue, { VNodeData } from "vue";
import { mergeData } from "vue-functional-data-merge";

export default Vue.extend({
  functional: true,
  props: {
    condensed: {
      type: Boolean,
      default: false,
    },
  },
  render(h, ctx) {
    const data: VNodeData = {
      staticClass: "ax-input-group",
      class: {
        "ax-input-group--condensed": ctx.props.condensed,
      },
    };
    return h("div", mergeData(ctx.data, data), ctx.slots().default);
  },
});
