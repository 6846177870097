export function preprocessNotificationName(workspaceName: string, projectName: string): string {
  let notificationName = workspaceName;

  if (projectName) {
    notificationName = `${workspaceName} \\ ${projectName}`;
  }

  const maxTextLength = 36;
  const halfTextLength = maxTextLength / 2;

  if (notificationName.length <= maxTextLength) return notificationName;

  if (workspaceName.length <= halfTextLength) return truncateText(notificationName, maxTextLength);

  if (projectName) {
    return `${truncateText(workspaceName, halfTextLength)} \\ ${truncateText(projectName, halfTextLength)}`;
  }

  return truncateText(notificationName, maxTextLength);
}

export function truncateText(text: string, length: number): string {
  if (text.length <= length) return text;

  const truncatedText = `${text.substring(0, length - 3)}...`;
  return truncatedText;
}
