import {
  ListJs, NotificationJs, ResponseObject, NotificationSettingJs, NotificationMuteItemJs,
} from "@shared/models";

import { exec } from "@/services/api";
import {
  AddNotificationModel,
  EditNotificationModel,
  NotificationSettingModel,
  NotificationMuteItemModel,
} from "@/services/models/notification";
import { server } from "@/services/server";
import { objectToFormData } from "@/services/utils/formData";

import { FilesystemEntryTree } from "./models";

export function getUserFsEntryTree() {
  return exec<FilesystemEntryTree>(server.get("/notification/getuserfsentrytree"));
}

export function getUserSubscriptions() {
  return exec<ListJs<NotificationJs>>(server.get("/notification/getusersubscriptions"));
}

export function getUserGuestSubscriptions() {
  return exec<ListJs<NotificationJs>>(server.get("/notification/getuserguestsubscriptions"));
}

export async function deleteSubscription(NotificationId: string) {
  const formData = objectToFormData({ NotificationId });
  const result = await exec<ResponseObject>(server.post("/notification/deletesubscription", formData));
  return result;
}

export async function deleteLegacyEmailSubscriptions() {
  const result = await exec<ResponseObject>(server.post("/notification/deletelegacyemailsubscriptions"));
  return result;
}

export async function addNotification(model: AddNotificationModel) {
  const formData = objectToFormData({ ...model });
  const result = await exec<NotificationJs>(
    server.post("/notification/addsubscription", formData),
  );
  return result;
}

export async function editNotification(model: EditNotificationModel) {
  const formData = objectToFormData({ ...model });
  const result = await exec<NotificationJs>(server.post("/notification/editsubscription", formData));
  return result;
}

export async function changeSlackUrlNotification(NotificationId: string) {
  const formData = objectToFormData({ NotificationId });
  const result = await exec<ResponseObject>(server.post("/notification/changeslackchannel", formData));
  return result;
}

export function getNotificationSettings() {
  return exec<ListJs<NotificationSettingJs>>(server.get("/notification/getnotificationsettings"));
}

export async function saveNotificationSettings(model: NotificationSettingModel) {
  const formData = objectToFormData({ ...model });
  const result = await exec<NotificationSettingJs>(server.post("/notification/savenotificationsettings", formData));
  return result;
}

export function getNotificationMuteItems() {
  return exec<ListJs<NotificationMuteItemJs>>(server.get("/notification/getnotificationmuteitems"));
}

export async function addNotificationMuteItem(model: NotificationMuteItemModel) {
  const formData = objectToFormData({ ...model });
  const result = await exec<NotificationMuteItemJs>(server.post("/notification/addnotificationmuteitem", formData));
  return result;
}

export async function deleteNotificationMuteItem(notificationMuteItemId: string) {
  const formData = objectToFormData({ notificationMuteItemId });
  const result = await exec<ResponseObject>(server.post("/notification/deletenotificationmuteitem", formData));
  return result;
}
