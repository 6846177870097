import { IframeBridgeTarget } from "@/store/project/state";
import { Payload, PayloadMap } from "@/store/typed";

export enum ActionTypes {
  PostToIframe = "[Project] Post To Current Iframe Bridge",
}

export class PostToIframe implements Payload {
  public readonly type = ActionTypes.PostToIframe;
  constructor(public key: string, public data?: any, public iframeBridgeTarget?: IframeBridgeTarget) {}
}

export interface ActionPayloadMap extends PayloadMap<ActionTypes> {
  [ActionTypes.PostToIframe]: PostToIframe;
}
