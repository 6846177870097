import { ExtendedProjectPage } from "@shared/models";

import { FilesystemNodeRoot, FilesystemNodeType, FilesystemNodeTypes } from "@/common/fs";
import { SearchResult } from "@/common/search/SearchResult";

import { FilesystemSearchItem } from "@/components/types/AxSearch";

export interface AxSearchData {
  isLoading: boolean;
  query: string;
  results: SearchResult<FilesystemSearchItem>[];
}

export interface AxShareFilesystem {
  firstLoad: boolean;
  recentsLoad: boolean;
  fetching: Record<string, boolean>;
  navigating: Record<string, boolean>;
  navigatingError: Record<string, Error | undefined>;
  nodes: Record<string, FilesystemNodeTypes | undefined>;
  contents: Record<string, string[]>;
  searchData: AxSearchData;
  firstPagesMap: Record<string, ExtendedProjectPage | undefined>;
}

export const rootNode: FilesystemNodeRoot = {
  id: "root",
  name: "Workspaces",
  type: FilesystemNodeType.Root,
  listingNode: undefined,
  parent: undefined,
  totalItems: 0,
  created: new Date(),
};

const state = (): AxShareFilesystem => ({
  firstLoad: true,
  recentsLoad: false,
  fetching: {},
  navigating: {},
  navigatingError: {},
  nodes: {
    [rootNode.id]: rootNode,
  },
  contents: {
    [rootNode.id]: [],
  },
  searchData: {
    isLoading: false,
    query: "",
    results: [],
  },
  firstPagesMap: {},
});

export default state;
