import { Route } from "vue-router";

import { AxShareConfigModel, VersionInfoResponse } from "@shared/models";

import AccountService from "@/services/account.service";
import admin, { AxShareAdmin } from "@/store/admin/state";
import { AsyncActionState } from "@/store/async-action";
import expo, { ExpoStore } from "@/store/expo/state";
import fs, { AxShareFilesystem } from "@/store/fs/state";
import migration, { AxShareMigration } from "@/store/migration/state";
import notifications, { NotificationStore } from "@/store/notifications/state";
import project, { AxShareProject } from "@/store/project/state";
import { AxShareSettings } from "@/store/settings/state";
import uploads, { AxShareUploads } from "@/store/uploads/state";
import user, { AxShareUser } from "@/store/user/state";

import desktop, { DesktopStore } from "./desktop/state";

export interface ApiCall {
  action: () => any;
  onFailure?: (error?: Error | unknown) => any;
  status: string;
  retry: number;
}

interface AxShareBase {
  initializationStatus: "success" | "failure" | null;
  initializationError?: Error | unknown;
  navigationError?: Error | unknown;
  accountService: (() => AccountService) | null;
  axShareHostUrl: string;
  axShareConfig: AxShareConfigModel | null;
  actions: { [action: string]: AsyncActionState | undefined };
  apiCalls: { [apiCallId: string]: ApiCall | undefined };
  apiVersionInfo: VersionInfoResponse | null;
}

interface RouteSyncedStore {
  route?: Route;
}

interface Modules {
  fs: AxShareFilesystem;
  project: AxShareProject;
  expo: ExpoStore;
  user: AxShareUser;
  admin: AxShareAdmin;
  settings: AxShareSettings;
  desktop: DesktopStore;
  uploads: AxShareUploads;
  migration: AxShareMigration;
  notifications: NotificationStore;
}

type PropsToAvoidReset = keyof RouteSyncedStore | "settings";
export interface AxShare extends AxShareBase, Modules, RouteSyncedStore { }
type AxShareInitial = Omit<AxShare, PropsToAvoidReset>;

// Removing 'route' property from initial state, it will be initialized by 'vuex-router-sync'
// By doing this we're preserving 'route' state when resetting to initial.
const state = (): AxShareBase => ({
  initializationStatus: null,
  initializationError: undefined,
  navigationError: undefined,
  axShareConfig: null,
  apiVersionInfo: null,
  accountService: null,
  axShareHostUrl: process.env.VUE_APP_AXSHARE_API_URL || "",
  actions: {},
  apiCalls: {},
});

export const initialState: () => AxShareInitial = () => ({
  ...state(),
  fs: fs(),
  project: project(),
  expo: expo(),
  user: user(),
  admin: admin(),
  desktop: desktop(),
  uploads: uploads(),
  migration: migration(),
  notifications: notifications(),
});

export default state as (() => AxShare);
