
import Vue from "vue";
import { PropOptions } from "vue/types/options";

import { ShortcutJs } from "@shared/models";
import { PrototypeUrls } from "@/common/axshare";
import { FilesystemNodeShortcut, isShortcutNode, isShortcutSearchNode } from "@/common/fs";
import { FilesystemSearchItem } from "@/components/types/AxSearch";

const itemProp: PropOptions<FilesystemNodeShortcut | ShortcutJs | FilesystemSearchItem> = {
  type: Object,
  required: false,
  default: undefined,
};
const props = {
  item: itemProp,
  linkText: {
    type: String,
    required: false,
  },
};

export default Vue.extend({
  props,

  computed: {
    fileAvailable(): boolean {
      return !!this.filename;
    },

    filename(): string {
      if (isShortcutNode(this.item)) {
        return (this.item.listingNode && this.item.listingNode.TargetPrototypeFileName) || "";
      }
      if (isShortcutSearchNode(this.item)) {
        return "Download";
      }
      return this.item.TargetPrototypeFileName;
    },

    urls(): PrototypeUrls {
      return this.$store.getters.prototypeUrls(this.item);
    },

    text(): string {
      return this.linkText || this.filename;
    },
  },
});
