import {
  FilesystemFolderInfo,
  FilesystemRootListingJs,
  ListingJs,
  ListJs,
  RecentJs,
  VarsJs,
  FilesystemSearchItemJs,
  InvitationJs,
  RequestInviteResponse,
  AccountRowJs,
  FolderRowJs,
} from "@shared/models";

import { FilesystemNodeDeletable, FilesystemNodeMovable, FilesystemNodeType } from "@/common/fs";
import { jsDateFromAspDateString } from "@/common/lib";
import { exec } from "@/services/api";
import { ShortcutType, UserFilesystemResponse } from "@/services/models";
import {
  ConfigureSecurityModel,
  DeleteItemsModel,
  DirectoryTree,
  FolderCreateModel,
  FolderNav,
  ManageUsersInviteModel,
  ManageUsersLeaveModel,
  ManageUsersRemoveModel,
  MoveItemsModel,
  RenameFolderInfo,
  RenameWorkspaceInfo,
} from "@/services/models/filesystem";
import { ResponseObject } from "@/services/models/responseObject";
import { server } from "@/services/server";
import { objectToFormData } from "@/services/utils/formData";

import { FilesystemSearchItem } from "@/components/types/AxSearch";

export function createFolder(model: FolderCreateModel) {
  const formData = objectToFormData({ ...model });
  return exec<FolderRowJs>(server.post("/fs/createfolder", formData));
}

export function moveItems(nodes: FilesystemNodeMovable[], from: string, to: string) {
  const items = nodes.map(item => {
    if (item.type === FilesystemNodeType.Folder) {
      return `folder:${item.id}`;
    }
    if (item.shortcut) {
      return `prototype:${item.shortcut}`;
    }
    return `prototype:${item.id}`;
  });
  const model: MoveItemsModel = {
    currentFolderId: from,
    destination: to,
    items,
  };
  const formData = objectToFormData(model);
  return exec<ResponseObject>(server.post("/fs/moveitems", formData));
}

export async function deleteItems(nodes: FilesystemNodeDeletable[], from: string) {
  const filesystemDelete = nodes.every(node => node.type === FilesystemNodeType.Workspace);

  const items = nodes.map(node => {
    const { type, id } = node;
    switch (type) {
      case FilesystemNodeType.Folder:
        return `folder:${id}`;
      case FilesystemNodeType.Shortcut:
        return `prototype:${id}`;
      case FilesystemNodeType.Workspace:
        return `filesystem:${id}`;
      default: {
        throw new Error(`Incorrect type passed trying to delete nodes, ${type}`);
      }
    }
  });

  const model: DeleteItemsModel = {
    items,
  };

  if (filesystemDelete) {
    model.filesystemDelete = true;
  } else {
    model.currentFolderId = from;
  }

  const formData = objectToFormData(model);
  const result = await exec<ResponseObject>(server.post("/fs/deleteitems", formData));
  return result;
}

export function archiveWorkspace(filesystemId: string, archive: boolean) {
  const formData = objectToFormData({ filesystemId, archive });
  return exec<ResponseObject>(server.post("/fs/archiveworkspace", formData));
}

export function favoriteWorkspace(filesystemId: string, favorite: boolean) {
  const formData = objectToFormData({ filesystemId, favorite });
  return exec<ResponseObject>(server.post("/fs/favoriteworkspace", formData));
}

export function getUserDirectoryTree() {
  return exec<DirectoryTree>(server.get("/fs/getuserdirectorytree"));
}

export async function getUserSearchData() {
  const searchItems = await exec<FilesystemSearchItemJs[]>(server.get("/fs/getusersearchdata"));
  const searchData: FilesystemSearchItem[] = searchItems.map(item => {
    const searchItem: FilesystemSearchItem = {
      isSearchResult: true,
      id: item.id,
      name: item.name,
      type: item.type as FilesystemNodeType,
      subtype: item.subtype,
      updated: jsDateFromAspDateString(item.updated),
      lastUpdatedShortcut: item.lastUpdatedShortcut,
      folderId: item.folderId,
      workspaceId: item.workspaceId,
      viewOnly: item.viewOnly,
      isUserOnly: item.isUserOnly,
      prototypeUrl: item.prototypeUrl,
      shortcutState: item.shortcutState,
      hasPassword: item.hasPassword,
      isUserOnlyWorkspace: item.IsUserOnlyWorkspace,
      resourceDirVersion: item.ResourceDirVersion,
    };
    return searchItem;
  });
  return searchData;
}

export async function getUserDefaultFs() {
  const workspaces = await getUserWorkspaces();
  return workspaces.Filesystems.find(fs => fs.IsDefaultFs);
}

export function getUserWorkspaces() {
  return exec<ListingJs>(
    server.get("/fs/getuserworkspaces", {
      params: {
        skipEntryCount: true,
      },
    }),
  );
}

export function getWorkspaceInvitation(filesystemId: string) {
  return exec<InvitationJs>(
    server.get("/fs/getworkspaceinvitation", {
      params: {
        fsId: filesystemId,
      },
    }),
  );
}

export function getFilesystemRootFolderContents(filesystemId: string) {
  return exec<FilesystemRootListingJs>(
    server.get("/fs/getfilesystemrootfoldercontents", {
      params: {
        filesystemId,
      },
    }),
  );
}

export function getfolderContents(folderId: string) {
  return exec<ListingJs>(
    server.get("/fs/getfoldercontents", {
      params: {
        folderId,
      },
    }),
  );
}

export function getRecents() {
  return exec<ListJs<RecentJs>>(server.get("/fs/getrecents"));
}

export function createRecent(shortcut: string) {
  const formData = objectToFormData({ shortcut });
  return exec<ResponseObject>(server.post("/fs/createrecent", formData));
}

export function getFolderNavById(folderId: string) {
  return exec<FolderNav>(
    server.get("/fs/getfoldernavbyid", {
      params: {
        folderId,
      },
    }),
  );
}

export function getFolderNavByFilesystemId(filesystemId: string) {
  return exec<FolderNav>(
    server.get("/fs/getfoldernavbyfilesystemid", {
      params: {
        filesystemId,
      },
    }),
  );
}

export function getFolderNavByShortcutId(shortcut: string) {
  return exec<FolderNav>(
    server.get("/fs/getfoldernavbyshortcutid", {
      params: {
        shortcut,
      },
    }),
  );
}

export function getNextWorkspaceName() {
  return exec<VarsJs>(server.get("/fs/getNextWorkspaceName"));
}

export function renameFolder(folderInfo: RenameFolderInfo) {
  const formData = objectToFormData({
    ...folderInfo,
  });
  return exec<ResponseObject>(server.post("/fs/renamefolder", formData));
}

export function workspaceCreate(Name: string, userViewOnly?: boolean) {
  const formData = objectToFormData({ Name, userViewOnly });
  return exec<FilesystemFolderInfo>(server.post("/fs/createfilesystem", formData));
}

export function renameWorkspace(model: RenameWorkspaceInfo) {
  const formData = objectToFormData(model);
  return exec<ResponseObject>(server.post("/fs/renamefilesystem", formData));
}

export function shareFileSystem(model: ManageUsersInviteModel) {
  const formData = objectToFormData(model);
  return exec<ResponseObject>(server.post("/fs/sharefilesystem", formData));
}

export function inviteUsersToFileSystem(model: ManageUsersInviteModel, recaptchaToken: string | undefined) {
  const formData = objectToFormData({ ...model, recaptchaToken });
  return exec<ResponseObject>(server.post("/fs/inviteuserstofilesystem", formData));
}

export function shareOrInviteToFileSystem(model: ManageUsersInviteModel, recaptchaToken: string | undefined) {
  if (model.inviteMessage) {
    return inviteUsersToFileSystem(model, recaptchaToken);
  }
  return shareFileSystem(model);
}

export function unshareFileSystem(model: Partial<ManageUsersRemoveModel>) {
  const formData = objectToFormData(model);
  return exec<ResponseObject>(server.post("/fs/unsharefilesystem", formData));
}

export function unjoinShare(model: ManageUsersLeaveModel) {
  const formData = objectToFormData(model);
  return exec<ResponseObject>(server.post("/fs/unjoinshare", formData));
}

export function updateFilesystemOwner(filesystemId: string, email: string) {
  const formData = objectToFormData({
    filesystemId,
    email,
  });
  return exec<ResponseObject>(server.post("/fs/updateFilesystemOwner", formData));
}

export function setSecurityPrefs(model: Partial<ConfigureSecurityModel>) {
  const formData = objectToFormData(model);
  return exec<ResponseObject>(server.post("/fs/setsecurityprefs", formData));
}

export function setSecurityPrefsDefault(model: ConfigureSecurityModel) {
  const formData = objectToFormData(model);
  return exec<ResponseObject>(server.post("/fs/setsecurityprefsdefault", formData));
}

export function workspaceInvitationRespond(workspaceId: string, accept: boolean) {
  const formData = objectToFormData({
    filesystemId: workspaceId,
    accept,
  });
  return exec<ResponseObject>(server.post("/fs/inviterespond", formData));
}

export function getUserProjectsByType(getViewerFolder = false, shortcutTypes?: ShortcutType[]) {
  const formData = objectToFormData({
    getViewerFolder,
    types: shortcutTypes,
  });

  return exec<UserFilesystemResponse>(server.post("/fs/GetUserTreeByType", formData));
}

// Only used for subinstance
export function setUserOnly(filesystemId: string, userOnly: boolean) {
  const formData = objectToFormData({
    filesystemId,
    userOnly,
  });
  return exec<ResponseObject>(server.post("/fs/setUserOnly", formData));
}

export function setReviewersOnly(filesystemId: string, viewerUsers: string[]) {
  const formData = objectToFormData({
    filesystemId,
    viewerUsers,
  });
  return exec<ResponseObject>(server.post("/fs/setReviewersOnly", formData));
}

export function setReviewerOnly(filesystemId: string, userToChange: string, isReviewOnly: boolean) {
  const formData = objectToFormData({
    filesystemId,
    userToChange,
    isReviewOnly,
  });
  return exec<ResponseObject>(server.post("/fs/setReviewerOnly", formData));
}

export function requestInvite(workspaceId: string, shortcut: string) {
  const formData = objectToFormData({ workspaceId, shortcut });
  return exec<RequestInviteResponse>(server.post("/fs/requestInvite", formData));
}

export function getSubinstanceAccounts() {
  return exec<ListJs<AccountRowJs>>(server.get("/fs/getaccounts"));
}
