
import Vue from "vue";

import { resolveApiBaseUrl } from "@/common/axshare/api";
import { enterpriseLicenseCheckFailed } from "@/common/axshare/messages";
import { FilesystemNodeShortcut } from "@/common/fs";
import { uuid } from "@/common/lib";
import { read } from "@/common/lib/cookies";
import { UploadAsset } from "@/services/expo.service";
import { ResponseObjectOf } from "@/services/models/responseObject";
import { ArtboardAdd } from "@/store/expo/actionTypes";
import { AxShare } from "@/store/state";
import {
  NewUpload,
  AddToBatch,
  RemoveFromBatch,
  UpdateProgress,
  UploadSuccess,
  UploadError,
  UploadCancel,
  DismissUpload,
} from "@/store/uploads/mutationTypes";
import { ArtboardUploadBatchSource } from "@/store/uploads/state";

import { prop } from "@/components/utils";

const VTransmitFileStatuses = {
  ADDED: "added",
  QUEUED: "queued",
  ACCEPTED: "queued",
  UPLOADING: "uploading",
  PROCESSING: "uploading",
  CANCELED: "canceled",
  ERROR: "error",
  TIMEOUT: "timeout",
  SUCCESS: "success",
};

interface VTransmitFile {
  id: string;
  accepted: boolean;
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  processing: boolean;
  size: number;
  status: string;
  type: string;
  upload: IUploadStats;
  webkitRelativePath: string;
  width: number;
  height: number;
  xhr: XMLHttpRequest;
  errorMessage: string;
  thumbnailLoaded: boolean;
  nativeFile: File;
  dataUrl: string;
  _nativeFile: any;
  _dataUrl: any;
}

interface IUploadStats {
  bytesSent: number;
  progress: number;
  total: number;
  speed: ISpeedStats;
  start: number;
  end: number;
  time: number;
}

interface ISpeedStats {
  kbps: number;
  mbps: number;
}

export default Vue.extend({
  props: {
    project: prop<FilesystemNodeShortcut>({
      required: true,
    }),

    markedUploadArea: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const state = this.$store.state as AxShare;
    const { axShareConfig } = state as AxShare;

    const files: Record<string, VTransmitFile> = {};
    const filesInProgress: Record<string, VTransmitFile> = {};
    return {
      files,
      filesInProgress,
      batchId: "",
      options: {
        uploadAreaClasses: `asset-uploader-upload-area ${
          this.markedUploadArea ? "asset-uploader-upload-area-marked" : ""
        }`,
        acceptedFileTypes: ["image/*"],
        url: `${resolveApiBaseUrl(axShareConfig)}/expo/addartboard/${this.project.shortcut}`,
        withCredentials: true,
        autoProcessQueue: false,
        uploadMultiple: true,
        clickable: false,
        disabledDraggable: true,
        paramName: "fileData",
        maxFileSize: 5,
        maxConcurrentUploads: 20,
        params: {
          skipPublishNote: true,
        },
      },
    };
  },

  computed: {
    hasFiles(): boolean {
      return Object.keys(this.files).length > 0 || Object.keys(this.filesInProgress).length > 0;
    },

    vueTransmitClasses(): string[] {
      const classes = ["asset-uploader-v-transmit"];
      return classes;
    },

    disableUpload(): boolean {
      return this.$store.getters.enterpriseLicenseCheckFailed;
    },

    disableUploadMessage(): string {
      return enterpriseLicenseCheckFailed;
    },
  },

  methods: {
    triggerBrowse() {
      const uploader = this.$refs.uploader as any;
      if (uploader) {
        uploader.triggerBrowseFiles();
      }
    },

    addFile(file: File) {
      const uploader = this.$refs.uploader as any;
      if (uploader) {
        uploader.addFile(file);
      }
    },

    acceptedFile(file: VTransmitFile): void {
      const artboardId = uuid();
      Vue.set(this.files, artboardId, file);
      this.$emit("files-added");

      if (!this.batchId || this.$store.getters.batchUploadCompletedOrDismissed(this.batchId)) {
        this.batchId = uuid();
        this.$store.commit(
          new NewUpload({
            batchId: this.batchId,
            shortcut: this.project.shortcut,
            projectName: this.project.name,
            source: ArtboardUploadBatchSource.Web,
          })
        );
      }

      const cancelUpload = () =>
        new Promise<void>(resolve => {
          this.cancelUpload(file);
          this.$store.commit(
            new UploadCancel({
              shortcut: this.project.shortcut,
              batchId: this.batchId,
              artboardId,
            })
          );
          resolve();
        });

      this.$store.commit(
        new AddToBatch({
          shortcut: this.project.shortcut,
          batchId: this.batchId,
          artboardId,
          artboardName: file.nativeFile.name,
          cancel: cancelUpload,
        })
      );
    },

    uploadProgress(file: VTransmitFile, progress: number): void {
      const artboardId = this.getArtboardId(file);
      if (!artboardId) {
        console.warn("Upload In Progress not found.");
        return;
      }
      this.$store.commit(
        new UpdateProgress({
          shortcut: this.project.shortcut,
          batchId: this.batchId,
          artboardId,
          progress,
        })
      );
    },

    cancelUpload(file: VTransmitFile): void {
      const uploader = this.$refs.uploader as any;
      if (uploader) {
        uploader.cancelUpload(file);
      }
    },

    removeFileById(id: string): boolean {
      const file = this.files[id];
      if (file) {
        return this.removeFile(file);
      }
      return false;
    },

    removeFile(file: VTransmitFile): boolean {
      const uploadId = this.getArtboardId(file);
      if (uploadId) {
        const uploader = this.$refs.uploader as any;
        if (uploader) {
          uploader.cancelUpload(file);
        }
        Vue.delete(this.files, uploadId);
        Vue.delete(this.filesInProgress, uploadId);
        this.$store.commit(new RemoveFromBatch(this.batchId, uploadId));
        return true;
      }
      return false;
    },

    onFileRemoved(file: VTransmitFile): void {
      if (this.removeFile(file)) {
        if (!this.hasFiles) {
          this.$emit("files-removed");
        }
      }
    },

    async successSingle(file: VTransmitFile, response: ResponseObjectOf<UploadAsset>, __: ProgressEvent) {
      const artboardId = this.getArtboardId(file);
      if (!response.success) {
        this.error(file, response.message);
        return;
      }

      if (!artboardId) {
        console.warn("Upload In Progress not found.");
        return;
      }
      const uploadAssetData = response.data;
      for (let i = 0; i < uploadAssetData.urls.length; i++) {
        const artboard = uploadAssetData.artboards[i];
        const asset = uploadAssetData.assets[i];
        const url = uploadAssetData.urls[i];
        await this.$store.dispatch(new ArtboardAdd(this.project.shortcut, artboard, asset, url));
        this.$store.commit(
          new UploadSuccess({
            shortcut: this.project.shortcut,
            batchId: this.batchId,
            artboardId,
            serverArtboardId: artboard.Id,
          })
        );
      }
    },

    queueComplete() {
      this.$emit("queue-complete");
    },

    error(file: VTransmitFile, message: string) {
      // eslint-disable-next-line no-param-reassign
      file.status = VTransmitFileStatuses.ERROR;
      // eslint-disable-next-line no-param-reassign
      file.errorMessage = `Unable to upload file ${file.name}: ${message}`;

      const artboardId = this.getArtboardId(file);
      if (artboardId) {
        this.$store.commit(
          new UploadError({
            shortcut: this.project.shortcut,
            batchId: this.batchId,
            artboardId,
            errorMessage: message,
          })
        );
      }
    },

    getArtboardId(file: VTransmitFile) {
      const queuedFiles = Object.entries(this.files);
      const filesInProgress = Object.entries(this.filesInProgress);
      const allFiles = [...queuedFiles, ...filesInProgress];
      const existingFile = allFiles.find(([_, v]) => v === file);
      if (existingFile) {
        return existingFile[0];
      }
      return undefined;
    },

    processFiles(): void {
      const uploader = this.$refs.uploader as any;
      if (!uploader) return;
      // mark all files as In-Progress and cleanup queued files
      this.filesInProgress = this.files;
      this.files = {};
      const filesInProgress = Object.values(this.filesInProgress);
      for (const file of filesInProgress) {
        this.uploadProgress(file, 0);
      }
      uploader.processFiles(filesInProgress);
    },

    clear() {
      this.files = {};
      if (this.batchId) this.$store.commit(new DismissUpload(this.batchId));
    },
  },
});
