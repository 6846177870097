
import Vue, { VNode, VNodeData } from "vue";

import { getObjectValueByPath, removeAll } from "@/common/lib";
import { createUniqueTag } from "@/common/utils";

import { arrayProp } from "@/components/utils";

const inputTypes = {
  checkbox: "checkbox",
  radio: "radio",
};

type Value = string | number | boolean | object;

export default Vue.extend({
  props: {
    items: arrayProp<any>({
      required: false,
      default: () => [],
    }),

    itemValueProp: {
      type: String,
      default: "value",
    },

    itemTextProp: {
      type: String,
      default: "text",
    },

    itemDisabledProp: {
      type: String,
      default: "disabled",
    },

    horizontal: {
      type: Boolean,
      default: false,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    value: {
      type: [String, Number, Array, Boolean, Object],
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    name: {
      type: String,
      default: "",
    },
  },

  data() {
    const inputName = this.name || createUniqueTag();
    return { inputName };
  },

  computed: {
    itemClass(): string {
      return this.horizontal ? "item-horizontal" : "item-vertical";
    },

    type(): string {
      return this.multiple ? inputTypes.checkbox : inputTypes.radio;
    },

    selected(): Value[] {
      return this.multiple ? [...this.value] : [this.value];
    },
  },

  methods: {
    isDisabled(item: any): boolean {
      if (!item || !this.itemDisabledProp) return this.disabled;
      return getObjectValueByPath(item, this.itemDisabledProp, this.disabled);
    },

    createInputElement(type: string, item?: any) {
      const self = this;

      const data: VNodeData = {
        attrs: {
          type,
          disabled: this.isDisabled(item),
          name: this.inputName,
        },
        domProps: {
          value: this.itemValue(item),
          checked: this.isSelected(item),
        },
        on: {
          change: (event: any) => {
            this.toggle(event, item);
          },
        },
      };

      return this.$createElement("input", data);
    },

    createLabelElement(label: string) {
      return this.$createElement("span", { class: "ms-1" }, label);
    },

    toggle(event: any, item: any) {
      const itemValue = this.itemValue(item);
      if (this.multiple) {
        const selected = [...this.selected];
        event.target.checked ? selected.push(itemValue) : removeAll(selected, s => s === itemValue);
        this.$emit("input", selected);
      } else {
        this.$emit("input", itemValue);
      }
    },

    isSelected(item: any): boolean {
      return this.selected.includes(this.itemValue(item));
    },

    createProps(item: any, index: number) {
      return {
        item,
        index,
        name: this.inputName,
        value: this.itemValue(item),
        text: this.itemText(item),
        type: this.type,
        checked: this.isSelected(item),
        disabled: this.isDisabled(item),
        toggle: (event: any) => this.toggle(event, item),
      };
    },

    genItem(item: any, index: number) {
      const node = this.$scopedSlots.default
        ? this.$scopedSlots.default(this.createProps(item, index))
        : this.$createElement("label", { class: this.itemClass }, [
          this.createInputElement(this.type, item),
          this.createLabelElement(this.itemText(item)),
        ]);

      return node;
    },

    genItems() {
      return this.items.map(this.genItem);
    },

    itemValue(item: any) {
      return getObjectValueByPath(item, this.itemValueProp);
    },

    itemText(item: any) {
      return getObjectValueByPath(item, this.itemTextProp);
    },
  },

  render(h): VNode {
    const self = this;
    return h("div", this.genItems());
  },
});
