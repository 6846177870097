import { Location, RouteConfig } from "vue-router";
import { Dictionary, Route } from "vue-router/types/router";

import { FilesystemNodeType, FilesystemNodeWorkspace } from "@/common/fs";
import Filesystem from "@/pages/Filesystem.vue";
import ConfigureSecurity from "@/pages/FilesystemConfigureSecurity.vue";
import CreateFolder from "@/pages/FilesystemCreateFolder.vue";
import CreateProject from "@/pages/FilesystemCreateProject.vue";
import FilesystemManage from "@/pages/FilesystemManage.vue";
import UsersInvite from "@/pages/FilesystemUsersInvite.vue";
import UsersLeave from "@/pages/FilesystemUsersLeave.vue";
import FilesystemWorkspace from "@/pages/FilesystemWorkspace.vue";
import adminRouter, { Keys as admin } from "@/router/admin";
import manageAccountRouter, { Keys as manageAccount } from "@/router/manage-account";
import notificationRouter, { Keys as notification } from "@/router/notification";
import { getWorkspaceInvitation } from "@/services/fs.service";
import store from "@/store";
import { ApiCallOptions, ApiCall } from "@/store/actionTypes";
import { NavigationRestore } from "@/store/fs/actionTypes";
import { NodeRemove } from "@/store/fs/mutationTypes";

import AxFilesystemNavigation from "@/components/AxFilesystemNavigation.vue";
import AxFilesystemRecents from "@/components/AxFilesystemRecents.vue";
import AxFilesystemViewer from "@/components/AxFilesystemViewer.vue";
import AxWorkspaceInvitation from "@/components/AxWorkspaceInvitation.vue";

const License = () => import("@/pages/License.vue");

export type Keys =
  | "fs"
  | "fs.manage"
  | "fs.workspace"
  | "fs.workspace.invited"
  | "fs.node"
  | "fs.node.createproject"
  | "fs.node.createfolder"
  | "fs.node.userinvite"
  | "fs.node.userleave"
  | "fs.node.configure"
  | "fs.recents"
  | "invite"
  | notification
  | manageAccount;

const fsRecentsRoute: RouteConfig = {
  path: "/fs/recents",
  name: "recents",
  component: AxFilesystemRecents,
  meta: {
    fsViewer: true,
  },
};

const routes: { readonly [R in Keys]: RouteConfig } = {
  fs: {
    path: "fs",
    name: "fs",
    components: {
      default: Filesystem,
      license: License,
    },

    meta: {
      safeToApplySWUpdate: true,
    },
  },

  "fs.manage": {
    path: "manage",
    name: "fs.manage",
    components: {
      default: FilesystemManage,
      sidebar: AxFilesystemNavigation,
    },
    props: {
      sidebar: true,
    },
    meta: {
      safeToApplySWUpdate: true,
    },
  },

  "fs.recents": fsRecentsRoute,

  "fs.workspace": {
    path: ":workspaceId",
    name: "fs.workspace",
    component: FilesystemWorkspace,
    props: true,
    meta: {
      fsViewer: true,
    },
  },
  "fs.node": {
    path: ":workspaceId/:id",
    name: "fs.node",
    component: AxFilesystemViewer,
    props: true,
    meta: {
      safeToApplySWUpdate: true,
      fsViewer: true,
    },
  },
  "fs.workspace.invited": {
    path: ":workspaceId/invited",
    name: "fs.workspace.invited",
    component: AxWorkspaceInvitation,
    props: true,
    async beforeEnter(to, _, next) {
      const checkInvitation = async () => {
        const { workspaceId } = to.params;
        let hasInvitation;
        try {
          const response = await getWorkspaceInvitation(workspaceId);
          hasInvitation = !!response.FilesystemId;
        } catch {
          hasInvitation = false;
        }
        if (hasInvitation) {
          next();
        } else {
          store.commit(new NodeRemove(workspaceId));

          // trick router that redirect is happening into workspace
          const workspace = {
            id: workspaceId,
            type: FilesystemNodeType.Workspace,
          };
          next(fsRouterLocation(workspace, { refresh: { workspaces: true } }));
        }
      };
      const options: ApiCallOptions = {
        action: checkInvitation,
      };

      await store.dispatch(new ApiCall(options));
    },
  },
  "fs.node.createproject": {
    path: "projects/new",
    name: "fs.node.createproject",
    component: CreateProject,
    props: true,
  },
  "fs.node.createfolder": {
    path: "folders/new",
    name: "fs.node.createfolder",
    component: CreateFolder,
    props: true,
  },
  "fs.node.userinvite": {
    path: "users/invite",
    name: "fs.node.userinvite",
    component: UsersInvite,
    props: true,
  },
  "fs.node.userleave": {
    path: "users/leave",
    name: "fs.node.userleave",
    component: UsersLeave,
    props: true,
  },
  "fs.node.configure": {
    path: "configure",
    name: "fs.node.configure",
    component: ConfigureSecurity,
    props: true,
  },
  invite: {
    // Linking directly to the the invite users to workspace dialog
    // be careful about changing this route path. it should always be present, because RP uses it
    path: "/invite/:id",
    name: "invite",
    async beforeEnter({ params: { id } }, _from, next) {
      const unresolved = routes.fs;
      if (!id) return next(unresolved);

      try {
        await store.dispatch(new NavigationRestore(id, "shortcut"));
        const workspace: FilesystemNodeWorkspace | undefined = store.getters.findWorkspace(id);
        if (!workspace) return next(unresolved);

        const closestFolderId = store.getters.getClosestFolderId(id);

        const params: Required<Pick<FsLocationOptions, "id" | "workspaceId">> = {
          workspaceId: workspace.id,
          id: closestFolderId || workspace.rootFolderId,
        };
        return next({
          name: routes["fs.node.userinvite"].name,
          params,
        });
      } catch {
        return next(unresolved);
      }
    },
  },
  ...notificationRouter.routes,
  ...manageAccountRouter.routes,
};

const config = [
  {
    ...routes.fs,
    redirect: routes["fs.manage"],
    children: [
      {
        ...routes["fs.manage"],
        children: [
          ...notificationRouter.config,
          ...manageAccountRouter.config,
          routes["fs.recents"],
          routes["fs.workspace.invited"],
          routes["fs.workspace"],
          {
            ...routes["fs.node"],
            children: [
              routes["fs.node.createproject"],
              routes["fs.node.createfolder"],
              routes["fs.node.userinvite"],
              routes["fs.node.userleave"],
              routes["fs.node.configure"],
            ],
          },
        ],
      },
    ],
  },
  routes.invite,
];

export interface RefreshOptions {
  contents?: boolean;
  workspaces?: boolean;
}
export interface FsLocationOptions {
  refresh?: RefreshOptions;
  workspaceId?: string;
  id?: string;
}
const refreshDefault: Required<RefreshOptions> = {
  contents: false,
  workspaces: false,
};
const defaultOptions = {
  refresh: refreshDefault,
};
export type RefreshOptionsQuery = Record<keyof RefreshOptions, string>;

const locationBuilders: Record<
string,
(node: { id: string; type: FilesystemNodeType }, options: FsLocationOptions) => Location
> = {
  [FilesystemNodeType.Root]() {
    return { name: routes.fs.name };
  },
  [FilesystemNodeType.Workspace]({ id }) {
    return { name: routes["fs.workspace"].name, params: { workspaceId: id } };
  },
  [FilesystemNodeType.Folder]({ id }, { workspaceId }) {
    const params: Dictionary<string> = workspaceId ? { workspaceId, id } : { id };
    return { name: routes["fs.node"].name, params };
  },
  [FilesystemNodeType.Invitation]({ id }) {
    return { name: routes["fs.workspace.invited"].name, params: { workspaceId: id } };
  },
};

export function fsRouterLocation(
  node?: { id: string; type: FilesystemNodeType } | string,
  options: FsLocationOptions = defaultOptions,
): Location {
  let location: Location = {};
  if (node) {
    if (typeof node === "string") {
      location = { name: routes["fs.node"].name, params: { id: node } };
    } else {
      const { type, id } = node;
      const locationBuilder = locationBuilders[type];
      location = locationBuilder ? locationBuilder(node, options) : { name: routes["fs.node"].name, params: { id } };
    }
  }

  if (options.refresh) {
    const { contents, workspaces } = options.refresh;
    const query: Dictionary<string> = {};
    if (contents) {
      query.contents = String(contents);
    }
    if (workspaces) {
      query.workspaces = String(workspaces);
    }
    location.query = query;
  }

  return location;
}

export default {
  routes,
  config,
};
