
import Vue, {
  VNode, VNodeChildren, VNodeData, VNodeDirective,
} from "vue";

import { directive as clickAway } from "@/mixins/ClickAway";

import AxButton from "@/components/AxButton.vue";
import dialogManager from "@/components/AxDialogManager.vue";
import AxIcon from "@/components/AxIcon.vue";

function validateAttachTarget(val: string | boolean | Node) {
  if (typeof val === "boolean" || typeof val === "string") return true;
  return val.nodeType === Node.ELEMENT_NODE;
}

function getZIndex(el?: Element | null): number {
  if (!el || el.nodeType !== Node.ELEMENT_NODE) return 0;

  const index = +window.getComputedStyle(el).getPropertyValue("z-index");

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(index)) return getZIndex(el.parentNode as Element);
  return index;
}

export default Vue.extend({
  directives: {
    clickAway,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    attach: {
      type: [String, Boolean],
      default: false,
      validator: validateAttachTarget,
    },

    lazy: {
      type: Boolean,
      default: false,
    },

    hideOverlay: {
      type: Boolean,
      default: false,
    },

    fullscreen: {
      type: Boolean,
      default: false,
    },

    transition: {
      type: [String, Boolean],
      default: "fade",
    },

    persistent: {
      type: Boolean,
      default: false,
    },

    disableClickAway: {
      type: Boolean,
      default: false,
    },

    dialogClass: {
      type: String,
      required: false,
      default: "",
    },

    contentClass: {
      type: String,
      required: false,
      default: "",
    },

    wrapperClass: {
      type: String,
      required: false,
      default: "",
    },

    hidden: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      target: null as HTMLElement | null,
      isActive: this.value,
      isBooted: false,
      hasDetached: false,
      stackMinZIndex: 2000,
      stackClass: "ax-dialog__content--active",
      overlay: null as HTMLElement | null,
      overlayTimeout: null as any | null,
      overlayTransitionDuration: 200 + 150, // transition + delay
      zIndex: dialogManager.getBaseZIndex(),
      isTop: true,
      return_focus: null as Element | null,
      ignoreClickAway: false,
    };
  },

  computed: {
    activeZIndex(): number {
      if (typeof window === "undefined") return 0;

      const content = this.$refs.dialog as Element;
      // Return current zindex if not active

      // Return max current z-index (excluding self) + 2
      // (2 to leave room for an overlay below, if needed)
      const index = !this.isActive ? getZIndex(content) : this.getMaxZIndex([content]) + 2;

      return index;
    },

    hasContent(): boolean {
      return this.isBooted || !this.lazy;
    },

    absolute(): boolean {
      return this.attach !== false && this.attach !== "";
    },

    classes(): Record<string, boolean> {
      return {
        [`ax-dialog ${this.dialogClass}`.trim()]: true,
        "ax-dialog--hidden": this.hidden || !this.isTop,
        "ax-dialog--active": this.isActive,
        "ax-dialog--fullscreen": this.fullscreen,
        "ax-dialog--absolute": this.absolute,
      };
    },
    contentClasses(): Record<string, boolean> {
      return {
        [`ax-dialog__content row g-0 ${this.contentClass}`.trim()]: true,
        "ax-dialog__content--active": this.isActive,
      };
    },
    wrapperClasses(): Record<string, boolean> {
      return {
        [`ax-dialog__wrapper ${this.wrapperClass}`.trim()]: true,
      };
    },
  },

  watch: {
    value(val) {
      this.isActive = val;
    },

    isActive(val) {
      if (val) {
        this.isBooted = true;
      }
      if (val) {
        this.show();
      } else {
        this.removeOverlay();
      }
      this.$emit("input", val);
      this.$emit("toggle", val);
    },

    persistent(val) {
      if (!val) this.focusDialog();
    },
  },

  mounted() {
    this.isBooted = this.isActive;
    this.zIndex = dialogManager.getBaseZIndex();
    this.initDetach();
    if (this.isActive) {
      this.show();
    }
  },

  deactivated() {
    this.isActive = false;
  },

  beforeDestroy() {
    this.removeOverlay();
    if (this.hasDetached) {
      const dialog = this.$refs.dialog as HTMLElement | undefined;
      if (!dialog) return;

      this.setEnforceFocus(false);

      try {
        const { parentNode } = dialog;
        if (parentNode) {
          parentNode.removeChild(dialog);
        }
      } catch (e) {
        console.log(e);
      }
    }
  },

  methods: {
    show() {
      dialogManager.registerModal(this);
      this.return_focus = document.activeElement;

      if (!this.fullscreen && !this.hideOverlay) {
        this.genOverlay();
      }
      this.focusDialog();
    },

    focusDialog() {
      const content = this.$refs.content as HTMLElement | null;
      if (content) {
        content.focus();
      }
    },

    onDialogMousedown() {
      // Watch to see if the matching mouseup event occurs outside the dialog
      // And if it does, cancel the clickAway handler
      const dialog = this.$refs.dialog as HTMLElement | undefined;
      if (!dialog) return;
      this.ignoreClickAway = true;
      const onceModalMouseup = (evt: MouseEvent) => {
        dialog.removeEventListener("mouseup", onceModalMouseup);
        if (dialog.contains(evt.target as Element)) {
          this.ignoreClickAway = false;
        }
      };
      dialog.addEventListener("mouseup", onceModalMouseup);
    },

    closeConditional() {
      if (this.ignoreClickAway) {
        this.ignoreClickAway = false;
        return;
      }

      if (this.persistent) return;
      this.close();
    },

    close() {
      this.isActive = false;
    },

    showLazyContent(content: VNodeChildren) {
      return this.hasContent ? content : undefined;
    },

    genCloseButton(): VNode {
      const closeIcon = this.$createElement(AxIcon, {
        props: {
          name: "add",
          size: "auto",
          rotate: "45deg",
        },
      });
      const closeHint = this.$createElement(
        "div",
        {
          class: ["ax-dialog__close-hint"],
        },
      );
      const button = this.$createElement(
        AxButton,
        {
          props: {
            color: "link",
          },
          on: {
            click: () => this.close(),
          },
        },
        [closeIcon, closeHint],
      );
      return this.$createElement(
        "div",
        {
          class: ["ax-dialog__close"],
        },
        [button],
      );
    },

    genOverlay() {
      if (!this.isActive || this.hideOverlay || (this.isActive && this.overlayTimeout) || this.overlay) {
        if (this.overlayTimeout) {
          clearTimeout(this.overlayTimeout);
        }

        return this.overlay && this.overlay.classList.add("ax-dialog-overlay--active");
      }

      this.overlay = document.createElement("div");
      this.overlay.className = "ax-dialog-overlay";

      // eslint-disable-next-line no-nested-ternary
      const parent = this.target ? this.target : this.absolute ? this.$el.parentNode : document.querySelector("#app");

      if (parent) {
        parent.insertBefore(this.overlay, parent.firstChild);
      }

      // eslint-disable-next-line no-unused-expressions
      this.overlay.clientHeight; // Force repaint
      requestAnimationFrame(() => {
        if (!this.overlay) return;

        this.overlay.className += " ax-dialog-overlay--active";

        if (this.absolute) {
          this.overlay.className += " ax-dialog-overlay--absolute";
        }

        this.overlay.style.zIndex = `${this.zIndex - 1}`;

        this.overlay.style.display = this.isTop ? "" : "none";
      });
    },

    removeOverlay() {
      if (!this.overlay) {
        this.afterOverlayTransitioned(() => {
          this.isBooted = false;
        });
        return;
      }

      this.overlay.classList.remove("ax-dialog-overlay--active");
      this.afterOverlayTransitioned(() => {
        try {
          if (this.overlay && this.overlay.parentNode) {
            this.overlay.parentNode.removeChild(this.overlay);
          }
          this.overlay = null;
          this.isBooted = false;
        } catch (e) {
          console.log(e);
        }
      });
    },

    afterOverlayTransitioned(fn: () => void) {
      this.overlayTimeout = setTimeout(() => {
        fn();
        if (this.overlayTimeout) {
          clearTimeout(this.overlayTimeout);
        }
        this.overlayTimeout = null;
      }, this.overlayTransitionDuration);
    },

    genDialog(): VNode {
      const data = {
        ref: "content",
        attrs: {
          tabIndex: "-1",
        },
        class: this.contentClasses,
        on: {
          keydown: (event: KeyboardEvent) => {
            if (event.keyCode !== 27) return;
            event.preventDefault();
            this.closeConditional();
          },
          mousedown: this.onDialogMousedown,
        },
      };
      const children = [];
      const lazyContent = this.$scopedSlots.default && this.showLazyContent(this.$scopedSlots.default({}));
      if (lazyContent) {
        children.push(lazyContent);
      }
      // add close button after content to move it to the end of the tabbing sequence
      if (!this.persistent) {
        children.push(this.genCloseButton());
      }
      const wrapper = this.$createElement(
        "div",
        {
          class: this.wrapperClasses,
        },
        children,
      );
      const dialog = this.$createElement("div", data, [wrapper]);
      return dialog;
    },

    initDetach() {
      if (!this.$refs.dialog || this.hasDetached || this.attach === "" || this.attach === true) {
        return;
      }

      let target: HTMLElement | null = null;
      if (this.attach === false) {
        // Default, detach to app
        target = document.querySelector("#app");
      } else if (typeof this.attach === "string") {
        // CSS selector
        target = document.querySelector(this.attach);
      } else {
        // DOM Element
        target = this.attach as HTMLElement;
      }

      if (!target) {
        console.warn(`Unable to locate target ${this.attach || "#app"}`);
        return;
      }
      this.target = target;

      target.insertBefore(this.$refs.dialog as HTMLElement, target.firstChild);
      this.hasDetached = true;
    },

    getMaxZIndex(exclude: Element[] = [] as Element[]) {
      const base = this.$el;
      const zis = [this.stackMinZIndex, getZIndex(base)];
      const activeElements = [...document.getElementsByClassName(this.stackClass)];

      // Get z-index for all active dialogs
      for (const activeElement of activeElements) {
        if (exclude.indexOf(activeElement) === -1) {
          zis.push(getZIndex(activeElement));
        }
      }

      return Math.max(...zis);
    },

    genContent() {
      const directives: VNodeDirective[] = [{ name: "show", value: this.isActive }];
      if (this.isActive && !this.disableClickAway) {
        directives.push({
          name: "click-away",
          value: {
            handler: () => this.closeConditional(),
            closeConditional: () => this.isActive,
          },
        });
      }
      const data: VNodeData = {
        class: this.classes,
        style: { zIndex: this.zIndex },
        ref: "dialog",
        directives,
      };
      return this.$createElement("div", data, [this.genDialog()]);
    },

    // Turn on/off focusin listener
    setEnforceFocus(enabled: boolean) {
      if (enabled) {
        document.addEventListener("focusin", this.focusHandler);
      } else {
        document.removeEventListener("focusin", this.focusHandler);
      }
    },

    // Document focusin listener
    focusHandler(event: Event) {
      // If focus leaves modal, bring it back
      const modal = this.$refs.dialog as HTMLElement | null;
      const { isTop } = this;
      if (
        isTop
        && this.isActive
        && modal
        && event.target
        && document !== event.target
        && !modal.contains(event.target as Element)
      ) {
        this.focusDialog();
      }
    },

    returnFocusTo() {
      const el = this.return_focus as HTMLElement;
      if (el.focus) {
        el.focus();
      }
    },

    onAfterEnter() {
      this.setEnforceFocus(true);
    },

    onAfterLeave() {
      this.$emit("after-leave");
      this.setEnforceFocus(false);
      requestAnimationFrame(() => {
        this.returnFocusTo();
        this.return_focus = null;
      });
      dialogManager.unregisterModal(this);
    },
  },

  render(h): VNode {
    const children: any[] = [];
    let content = this.genContent();
    if (this.transition) {
      content = this.$createElement(
        "transition",
        {
          props: {
            appear: true,
            name: this.transition,
          },
          on: {
            enter: () => this.focusDialog(),
            afterEnter: () => this.onAfterEnter(),
            afterLeave: () => this.onAfterLeave(),
          },
        },
        [content],
      );
    }

    children.push(content);
    return h(
      "div",
      {
        staticClass: "ax-dialog__container",
        style: {
          display: this.isTop ? "block" : "none",
        },
      },
      children,
    );
  },
});
