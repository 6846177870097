import { GetterTree } from "vuex";

import { ExpoProjectNode, ExpoStore } from "@/store/expo/state";
import { AxShare } from "@/store/state";

const getters: GetterTree<ExpoStore, AxShare> = {
  currentProject(state, _, rootState): ExpoProjectNode | undefined {
    const { route } = rootState;
    if (route && route.params) {
      const { shortcut } = route.params;
      if (shortcut) {
        return state.projects[shortcut.toLowerCase()];
      }
    }
  },

  nextArtboard: (_, g) => (artboardId: string) => {
    const currentProject: ExpoProjectNode = g.currentProject;
    const orderedArtboardIndex = currentProject.artboardsOrder.findIndex(id => artboardId === id);
    if (orderedArtboardIndex >= 0) {
      const nextArtboardId = currentProject.artboardsOrder[orderedArtboardIndex + 1];
      return currentProject.artboards[nextArtboardId];
    }
  },

  previousArtboard: (_, g) => (artboardId: string) => {
    const currentProject: ExpoProjectNode = g.currentProject;
    const orderedArtboardIndex = currentProject.artboardsOrder.findIndex(id => artboardId === id);
    if (orderedArtboardIndex >= 0) {
      const prevArtboardId = currentProject.artboardsOrder[orderedArtboardIndex - 1];
      return currentProject.artboards[prevArtboardId];
    }
  },
};

export default getters;
