
import Vue from "vue";

import AxIcon from "@/components/AxIcon.vue";

export default Vue.extend({
  components: {
    AxIcon,
  },

  props: {
    filled: {
      type: Boolean,
      default: false,
    },

    fillColor: {
      type: String,
      default: "#718096",
    },
  },
});
