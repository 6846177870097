import { Artboard, Asset, Master, Platform, Project, ExpoStaticContent } from "@shared/expo";

import { AssetDimensions } from "@/common/expo/interfaces";

export interface ExpoStore {
  projects: { [shortcutKey: string]: ExpoProjectNode };
}

export interface ExpoProjectNode {
  mode: "expo" | "handoff" | string;
  isProjectLoading: boolean;
  isProjectLoaded: boolean;

  // indicates date after each update in expo project is made
  lastModifiedDate: number;

  // indicates date when last server update was made
  lastSavedDate: number;

  shortcut: string;
  resourceFolder: string;
  resourceAxureFolder: string;
  archiveMode: boolean;
  platform: Platform | undefined;
  projectDimensions: AssetDimensions;
  projectZoom: number;
  assets: {
    [assetId: string]: Asset;
  };
  assetsImages: {
    [assetId: string]: string;
  };
  assetsImagesThumbnails: {
    [assetId: string]: string;
  };
  artboards: {
    [artboardId: string]: Artboard;
  };
  artboardsOrder: string[];
  selectedArtboards: {
    [artboardId: string]: string;
  };
  lastSelectedArtboard: string;
  artboardHotspotOpenedMenu: {
    artboardId: string;
    actionId: string;
  };
  masters: { [key: string]: Master };
  bgImgUrl: string;
  hasArtboards: boolean;
  id: string;
  name: string;
  modifiedOn?: string;
}

export function NewExpoProjectStore(): ExpoProjectNode {
  return {
    mode: "expo",
    isProjectLoading: false,
    isProjectLoaded: false,
    lastModifiedDate: 0,
    lastSavedDate: 0,
    shortcut: "",
    resourceFolder: "",
    resourceAxureFolder: "",
    archiveMode: false,
    platform: undefined,
    projectZoom: 33,
    projectDimensions: {
      width: 0,
      height: 0,
    },
    assets: {},
    assetsImages: {},
    assetsImagesThumbnails: {},
    artboards: {},
    artboardsOrder: [],
    selectedArtboards: {},
    lastSelectedArtboard: "",
    artboardHotspotOpenedMenu: {
      artboardId: "",
      actionId: "",
    },
    masters: {},
    bgImgUrl: "",
    hasArtboards: false,
    id: "",
    name: "",
    modifiedOn: undefined,
  };
}

export function ConvertProjectToPartialExpoProjectNode(project: Project): ExpoProjectNode {
  const projectNode = NewExpoProjectStore();

  projectNode.isProjectLoading = false;
  projectNode.isProjectLoaded = true;
  projectNode.shortcut = project.ShortcutKey;

  projectNode.platform = project.Platform;

  projectNode.projectDimensions = {
    height: project.Platform.Settings.Dimensions.Height,
    width: project.Platform.Settings.Dimensions.Width,
  };

  // for (const artboardId in project.ArtboardIds) {

  // }

  projectNode.artboardsOrder = project.ArtboardIds;

  // projectNode.bgImgUrl = project
  // projectNode.hasArtboards =
  projectNode.id = project.Id;
  projectNode.name = project.ProjectName;
  projectNode.modifiedOn = project.ModifiedOn;

  return projectNode;
}

export function ConvertFullProjectToExpoProjectNode(content: ExpoStaticContent) {
  const projectNode = NewExpoProjectStore();

  projectNode.isProjectLoading = false;
  projectNode.isProjectLoaded = true;
  projectNode.shortcut = content.project.ShortcutKey;

  projectNode.platform = content.project.Platform;
  projectNode.projectDimensions = {
    height: content.project.Platform.Settings.Dimensions.Height,
    width: content.project.Platform.Settings.Dimensions.Width,
  };
  projectNode.assets = content.assets;
  projectNode.assetsImages = content.images;
  projectNode.assetsImagesThumbnails = content.thumbnails;
  projectNode.artboards = content.artboards;
  projectNode.artboardsOrder = Object.keys(content.artboards);

  projectNode.masters = content.masters;
  projectNode.bgImgUrl = content.bgImgLink;
  projectNode.hasArtboards = projectNode.artboardsOrder.length > 0;
  projectNode.id = content.project.Id;
  projectNode.name = content.project.ProjectName;

  return projectNode;
}

const state = (): ExpoStore => ({
  projects: {},
});

export default state;
