
import Vue from "vue";
import { Location } from "vue-router";

import { resolveAfterAuthRedirect } from "@/common/axshare";
import { AxShareConfigModel } from "@/generated/models";
import { ROUTES } from "@/router";
import { GetAdminInfo } from "@/store/admin/actionTypes";
import { AxShare } from "@/store/state";

import AxSignup from "@/components/AxSignup.vue";

export default Vue.extend({
  components: { AxSignup },

  computed: {
    loginRoute(): Location {
      return {
        name: ROUTES.login.name,
        query: this.$route.query,
      };
    },

    axShareConfig(): AxShareConfigModel | null {
      const { axShareConfig } = this.$store.state as AxShare;
      return axShareConfig;
    },
  },

  methods: {
    async authSuccess() {
      await this.$store.dispatch(new GetAdminInfo());
      const to = resolveAfterAuthRedirect(this.$route, this.axShareConfig?.AxShareHost);
      if (to) await this.$router.push(to);
    },
  },
});
