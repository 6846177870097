import { SharedFilesystemMappingJs, SsoResponse } from "@shared/models";

export interface DeleteItemsModel {
  currentFolderId?: string;
  items: Array<string | null>;
  filesystemDelete?: boolean;
}

export interface MoveItemsModel {
  currentFolderId: string;
  destination: string;
  items: Array<string | null>;
}

export const enum ShortcutType {
  Rp = 0,
  TeamRp = 1,
  Lib = 2,
  TeamLib = 3,
  Expo = 4,
  Handoff = 5,
}

export interface BreadcrumbJson {
  name: string;
  isFilesystem: boolean;
  isDefaultFs: boolean;
  id: string;
  fsRootFolderId: string;
}

export interface FolderNav {
  breadcrumbJson: BreadcrumbJson[];
}

export interface FolderCreateModel {
  Name: string;
  FolderId: string;
  currentFolderId: string;
}

export interface RenameFolderInfo {
  Name: string;
  FolderId: string;
  currentFolderId: string;
}

export interface RenameWorkspaceInfo {
  Name: string;
  FilesystemId: string;
}

export interface ManageUsersInviteModel {
  userEmails?: string | string[];
  viewerOnly?: boolean | string;
  currentFolderId?: string;
  filesystemId?: string;
  inviteMessage?: string;
  ignoreAccepted?: boolean;
  ignorePending?: boolean;
}

export interface ManageUsersRemoveModel {
  usersToRemove: string[];
  removeAllUsers: boolean;
  filesystemId: string;
  currentFolderId: string;
  sharedWith: SharedFilesystemMappingJs[];
}

export interface ManageUsersLeaveModel {
  NewOwner: string;
  FilesystemId: string;
  currentFolderId: string;
}

export interface ConfigureSecurityModel {
  FilesystemId: string;
  currentFolderId: string;
  viewerUsers: string[];
  httpsOnly?: boolean;
  userOnly?: boolean;
  isDefaultFs: boolean;
}

export interface DirectoryTree {
  workspacesRoot: DirectoryTreeNode;
}

export interface DirectoryTreeNode {
  id: string;
  name: string;
  defaultWorkspace: boolean;
  isFavorite: boolean;
  isFolder: boolean;
  children: DirectoryTreeNode[];
  owner: SsoResponse;
  explicitlyJoined: boolean;
}

export type FilesystemEntryTree = FilesystemEntryTreeNode[];
export interface FilesystemEntryTreeNode {
  entries: FilesystemEntry[];
  folderId: string;
  folderName: string;
  owner: SsoResponse;
  explicitlyJoined: boolean;
}

export interface FilesystemEntry {
  Name: string;
  Shortcut: string;
  shortcutId: string;
}

export interface UserFilesystemResponse {
  rootFolder: UserFilesystemRootFolder;
}

export interface UserFilesystemRootFolder {
  children: UserFilesystemEntryTreeNode[];
}

export interface UserFilesystemEntryTreeNode {
  children: UserFilesystemEntryTreeNode[];
  name: string;
  id: string;
  filetype: string;
  defaultWorkspace?: boolean;
  owner: SsoResponse;
  explicitlyJoined: boolean;
}
